import { Button } from 'antd'
import React from 'react'
import 'containers/routesMaster/App.css'
import { withRouter } from 'react-router'
import SubHeader from 'utils/subHeader'

class RouteMaster extends React.Component {
  addRouteMaster = () => {
    let qs = ''
    if (this.props.location.search.length > 0) {
      qs = `${this.props.location.search}`
    }
    this.props.history.push({
      pathname: '/route-master/add',
      search: qs,
    })
    //this.props.history.push('/route-master/add')
  }
  uploadRouteMaster = () => {
    let qs = ''
    if (this.props.location.search.length > 0) {
      qs = `${this.props.location.search}`
    }
    this.props.history.push({
      pathname: '/route-master/upload',
      search: qs,
    })
    //this.props.history.push('/route-master/upload')
  }
  cta = () => {
    return (
      <div>
        <Button size="large" type="primary" onClick={this.addRouteMaster}>
          Add New Entry
        </Button>
        <Button
          style={{ marginLeft: 10 }}
          size="large"
          type="primary"
          onClick={this.uploadRouteMaster}
        >
          Upload
        </Button>
      </div>
    )
  }
  render() {
    return (
      <div>
        <SubHeader innerhtml={this.cta} />
        {this.props.children}
      </div>
    )
  }
}

export default withRouter(RouteMaster)
