import { Button, Row } from 'antd'
import React from 'react'
import SubHeader from 'utils/subHeader'
import 'containers/routesMaster/App.css'
import { withRouter } from 'react-router'

class ConfigurationMain extends React.Component {
  Configuration = () => {
    let qs = ''
    if (this.props.location.search.length > 0) {
      qs = `${this.props.location.search}`
    }
    this.props.history.push({
      pathname: '/configuration-list/notification-config',
      search: qs,
    })
  }
  BulkUpload = () => {
    this.props.history.push({
      pathname: '/bulk-upload/configurations',
    })
  }
  cta = () => {
    return (
      <div>
        <Button size="large" type="primary" onClick={this.BulkUpload}>
          Bulk Upload
        </Button>
        <Button size="large" type="primary" onClick={this.Configuration}>
          New Configuration
        </Button>
      </div>
    )
  }

  render() {
    return (
      <Row>
        <SubHeader innerhtml={this.cta} />
        {this.props.children}
      </Row>
    )
  }
}

export default withRouter(ConfigurationMain)
