import React from 'react'
import { Row, Col, Select, Table, Button, Input, Icon, message, Form } from 'antd'
import request from 'utils/request'
import Subheader from 'utils/subHeader'
import { convertQsToJson } from 'utils/common'
import { css } from '@emotion/css'


const Option = Select.Option

class MaterialMaster extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      confirmDirty: false,
      materials: [],
      allMaterials: [],
    }

    this.submitMaterial = this.submitMaterial.bind(this)
  }
  reset = () => {
    this.props.form.resetFields()
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search === '') {
      this.setState({ allMaterials: [], materials: [] })
    } else {
      const parsedDt = convertQsToJson(nextProps.location.search)
      const cId = parsedDt.companyId || ''
      const bId = parsedDt.branchId || ''
      const dId = parsedDt.departmentId || ''
      const materialsAPI = `${
        window._env_.REACT_APP_API_URL
      }/saas/lego/company_materials/fetch?c=${cId}&b=${bId}&d=${dId}&t=CONSIGNOR`
      if (cId !== '') {
        this.fetchMaterials(materialsAPI)
      }
    }
  }

  componentDidMount() {
    const parsedDt = convertQsToJson(this.props.location.search)
    const cId = parsedDt.companyId || ''
    const bId = parsedDt.branchId || ''
    const dId = parsedDt.departmentId || ''
    const materialsAPI = `${
      window._env_.REACT_APP_API_URL
    }/saas/lego/company_materials/fetch?c=${cId}&b=${bId}&d=${dId}&t=CONSIGNOR`
    this.fetchAllMaterials()
    if (cId === '') {
      message.info('Select a company to continue')
    }
    if (cId !== '') {
      this.fetchMaterials(materialsAPI)
    }
  }

  fetchMaterials(materialsAPI) {
    request(materialsAPI).then(
      result => {
        this.setState({ materials: result.data })
      },
      error => {
        message.error('Error while fetching company specific materials')
      }
    )
  }
  fetchAllMaterials() {
    request(`${window._env_.REACT_APP_API_URL}/saas/lego/static_data/materials/fetch`).then(
      result => {
        if (result.success) {
          let sortArray = result.data
          sortArray.sort((a, b) => (a.type > b.type ? 1 : b.type > a.type ? -1 : 0))
          this.setState({ allMaterials: sortArray })
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        message.error('Error while fetching all materials')
      }
    )
  }
  validateToWhitespace = (rule, value, callback) => {
    if (value && value.trim() === '') {
      callback('Blank spaces not allowed')
    } else if (value && value.length > 64) {
      callback('Maximum 64 character allowed')
    } else {
      callback()
    }
  }
  submitMaterial(data, type) {
    const parsedDt = convertQsToJson(this.props.location.search)
    const cId = parsedDt.companyId || ''
    const bId = parsedDt.branchId || ''
    const dId = parsedDt.departmentId || ''
    if (type === 'newMaterials') {
      data = {
        label: data.material,
      }
    } else {
      data = {
        ftMaterialId: data.materialMaster,
      }
    }
    request(
      `${
        window._env_.REACT_APP_API_URL
      }/saas/lego/company_materials/create?c=${cId}&b=${bId}&d=${dId}&t=CONSIGNOR`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(
      result => {
        if (result.success) {
          message.success('Submitted successfully')
          this.reset()
          this.reloaddata()
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        message.error('Error while adding material')
      }
    )
  }
  reloaddata() {
    const parsedDt = convertQsToJson(this.props.location.search)
    const cId = parsedDt.companyId || ''
    const bId = parsedDt.branchId || ''
    const dId = parsedDt.departmentId || ''
    const materialsAPI = `${
      window._env_.REACT_APP_API_URL
    }/saas/lego/company_materials/fetch?c=${cId}&b=${bId}&d=${dId}&t=CONSIGNOR`
    this.fetchMaterials(materialsAPI)
  }
  deleteCompanyMaterial(id) {
    const parsedDt = convertQsToJson(this.props.location.search)
    const cId = parsedDt.companyId || ''
    const bId = parsedDt.branchId || ''
    const dId = parsedDt.departmentId || ''
    request(
      `${
        window._env_.REACT_APP_API_URL
      }/saas/lego/company_materials/delete?c=${cId}&b=${bId}&d=${dId}&t=CONSIGNOR&id=${id}`,
      {
        method: 'DELETE',
      }
    ).then(
      result => {
        if (result.success) {
          message.success('Deleted successfully')
          this.reloaddata()
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        message.error('Error while fetching materials')
      }
    )
  }
  handleSubmitMaterial = e => {
    e.preventDefault()
    this.props.form.validateFields(['materialMaster'], (err, values) => {
      if (!err) {
        this.submitMaterial(values, 'oldMaterials')
      }
    })
  }
  handleSubmitMaterialValue = e => {
    e.preventDefault()
    this.props.form.validateFields(['material'], (err, values) => {
      if (!err) {
        this.submitMaterial(values, 'newMaterials')
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const columns = [
      {
        dataIndex: 'id',
        title: 'Sl No.',
        render: (text, record, index) => index + 1,
      },
      {
        dataIndex: 'label',
        title: 'Material name',
      },
      {
        dataIndex: 'cross',
        title: '',
        render: (text, record) => (
          <div>
            <Icon
              className={css`
                cursor: pointer;
              `}
              type="close-circle"
              theme="filled"
              onClick={() => this.deleteCompanyMaterial(record.id)}
            />
          </div>
        ),
      },
    ]

    const data = this.state.materials

    return (
      <div>
        <Subheader />
        <Row
          className={css`
            padding: 20px;
          `}
          gutter={16}
        >
          <Col span={12}>
            <Form onSubmit={this.handleSubmitMaterial}>
              <Row gutter={16}>
                <Col span={20}>
                  <Form.Item label="Material Master">
                    {getFieldDecorator('materialMaster', {
                      rules: [
                        {
                          required: true,
                          message: 'Please select a material',
                        },
                      ],
                    })(
                      <Select
                        size="large"
                        style={{ width: '100%' }}
                        disabled={this.props.location.search === ''}
                      >
                        {this.state.allMaterials &&
                          this.state.allMaterials.map(material => (
                            <Option key={material.id} value={material.id}>
                              {material.type}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item>
                    <Button
                      className={css`
                        margin-top: 37px;
                        margin-left: 10px;
                      `}
                      shape="circle"
                      size="large"
                      type="primary"
                      icon="plus"
                      htmlType="submit"
                      disabled={this.props.location.search === ''}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Row gutter={16} className={css`padding:20px 2px`}>
              <Col span={12}>
                <Form onSubmit={this.handleSubmitMaterialValue}>
                  <Row gutter={16}>
                    <Col span={16}>
                      <Form.Item label="Material">
                        {getFieldDecorator('material', {
                          rules: [
                            {
                              required: true,
                              message: 'Please enter material ',
                            },
                            {
                              validator: this.validateToWhitespace,
                            },
                          ],
                        })(<Input size="large" disabled={this.props.location.search === ''} />)}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item>
                        <Button
                          className={css`
                            margin-top: 37px;
                          `}
                          shape="circle"
                          size="large"
                          type="primary"
                          icon="plus"
                          htmlType="submit"
                          disabled={this.props.location.search === ''}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Table bordered columns={columns} dataSource={data} rowKey={data => data.id} />
          </Col>
        </Row>
      </div>
    )
  }
}
const addMaterialMaster = Form.create({ name: 'material' })(MaterialMaster)
const addOnlyMaterialMaster = Form.create({ name: 'material' })(MaterialMaster)
export default (MaterialMaster, addMaterialMaster, addOnlyMaterialMaster)
