import React from 'react'
import { CSVLink } from 'react-csv'
import { Col, Row, Button, message, Icon } from 'antd'
import { geocodeByAddress } from 'react-places-autocomplete'
import { HotTable } from '@handsontable/react'
import request from 'utils/request'
import { convertQsToJson } from 'utils/common'
import GeoUtil from 'utils/geoUtils'
import { addressFromLatLng } from 'utils/geocode'
import { constructUrl } from 'utils/common'
import 'containers/routesMaster/App.css'
import 'handsontable-pro/dist/handsontable.full.css'
import ImportFileComponent from 'components/common/ImportFileComponent'
import { css } from '@emotion/css'


const google = window.google

const ColumnHeaders = [
  'Route label',
  'STT (in hours)',
  'Route code',
  'Place code',
  'Address Label',
  'Address',
  'Google Address',
  'Latitude',
  'Longitude',
  'Geofence radius',
  '<span style="color: #f0f0f0">Place Id<span></span>',
  'Status',
]
class UploadRoute extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [[]],
      autocompleteData: [],
      downloadData: [],
      routeId: '',
      lat: '',
      lng: '',
      firstInd: 0,
      lastInd: -1,
      atLeastOneSuccess: false,
      ImportFileComponentKey: Date.now(),
    }
    this.displaySuggestions = this.displaySuggestions.bind(this)
  }

  componentDidMount() {
    if (this.props.location.search === '') {
      message.info('Select a company to continue')
    }
    try {
      const data = JSON.parse(localStorage['data'])
      this.setState({ data })
    } catch (e) {
      console.log('Data not found in localStorage')
    }
  }

  convertQsToJson(str) {
    let pairs = str.slice(1).split('&')
    let result = {}
    pairs.forEach(function(pair) {
      pair = pair.split('=')
      result[pair[0]] = decodeURIComponent(pair[1] || '')
    })

    return JSON.parse(JSON.stringify(result))
  }

  getAddressData(address) {
    let origin
    return new Promise((resolve, reject) => {
      this.getGooglePlaceIdForRoute(address).then(place_id => {
        origin = {
          label: address,
          google: { place_id },
          address: address,
          geofenceRadius: 2,
        }
        resolve(origin)
      })
    })
  }
  getGooglePlaceIdForRoute(address) {
    return geocodeByAddress(address)
      .then(results => results[0].place_id)
      .then(place_id => place_id)
  }
  // async asynced(d) {
  //   return await this.getAddressData(d)
  // }

  async addRoute(routeData, i, _arr) {
    const parsedDt = convertQsToJson(this.props.location.search)
    const cId = parsedDt.companyId || ''
    const bId = parsedDt.branchId || ''
    const dId = parsedDt.departmentId || ''
    if (cId === '') {
      return message.info('Select a company to continue')
    }
    const data = {
      id: undefined,
      code: routeData.rowInfo[4],
      label: routeData.rowInfo[2],
      origin: await this.getAddressData(routeData.rowInfo[0]),
      destination: await this.getAddressData(routeData.rowInfo[1]),
      stt: parseFloat(routeData.rowInfo[3], 10) * 60,
    }

    const hot = this.refs.hot.hotInstance
    hot.setDataAtCell(i, 12, data.origin.lat)
    const url = `${
      window._env_.REACT_APP_API_URL
    }/saas/lego/company_routes/create_or_update?c=${cId}&b=${bId}&d=${dId}&t=CONSIGNOR`

    request(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(
      result => {
        if (result.success) {
          this.setState({ routeId: result.data.id })
          const hot = this.refs.hot.hotInstance
          hot.setDataAtCell(this.firstInd, 5, 'Success')
          this.responseRouteId = result.data.id
          // (index, customArraylength,customArray,subArraindex,subArraLength,subArray)
          setTimeout(() => {
            this.getWayPointsData(
              i,
              _arr.length,
              _arr,
              0,
              _arr[i].subArray.length,
              _arr[i].subArray
            )
          }, 1000)
          //this.getWayPointsData(i, _arr.length, _arr, 0, _arr[i].subArray.length, _arr[i].subArray)
          this.setState({ atLeastOneSuccess: true })
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        this.responseRouteId = ''
        setTimeout(() => {
          this.getWayPointsData(i, _arr.length, _arr, 0, _arr[i].subArray.length, _arr[i].subArray)
        }, 1000)
        const hot = this.refs.hot.hotInstance
        hot.setDataAtCell(this.firstInd, 5, error.message)
      }
    )
  }
  responseRouteId

  async updateLatLng(d) {
    let i = 1
    while (i < d.length) {
      const latitude = d[i][7]
      const longitude = d[i][8]
      const googleAddress = d[i][6]
      const address = d[i][5]

      const tempRow = d[i]
      if (googleAddress === '') {
        const { placeId } = await addressFromLatLng(latitude, longitude)
        tempRow[10] = placeId
      } else {
        await geocodeByAddress(googleAddress)
          .then(results => {
            const { place_id, geometry } = results[0]

            if (
              latitude !== '' &&
              latitude !== undefined &&
              (longitude !== '' && longitude !== undefined)
            ) {
              tempRow[7] = latitude
              tempRow[8] = longitude
            } else {
              tempRow[7] = geometry.location.lat()
              tempRow[8] = geometry.location.lng()
            }

            const distanceBetween = GeoUtil.distance(
              parseFloat(geometry.location.lat()),
              parseFloat(geometry.location.lng()),
              parseFloat(latitude),
              parseFloat(longitude)
            )

            if (distanceBetween > 30) {
              tempRow[11] = 'Error: Distance more than 30kms'
            } else {
              tempRow[10] = place_id
            }
          })
          .catch(error => {
            tempRow[11] = `Error: ${error}`
          })
      }
      if (this.state.data.length == 1 && this.state.data[0].length == 0) {
        this.setState({
          data: [...this.state.data[0], tempRow],
        })
      } else {
        this.setState({
          data: this.state.data.concat([tempRow]),
        })
      }

      i++
    }
  }

  saveData() {
    if (this.props.location.search === '') {
      return message.info('Select a company to continue')
    }

    const finalData = []
    let lastRoute
    const hot = this.refs.hot.hotInstance
    const data = hot.getData()

    data.forEach((routeData, i) => {
      if (routeData[0] != '') {
        if (lastRoute != undefined) finalData.push(lastRoute)

        lastRoute = {
          label: routeData[0],
          stt: routeData[1],
          code: routeData[2],
          places: [
            {
              cpCode: routeData[3],
              google: {
                place_id: routeData[10],
              },
              label: routeData[4],
              address: routeData[5],
              geofenceRadius: routeData[9],
            },
          ],
        }
      } else {
        lastRoute.places.push({
          cpCode: routeData[3],
          google: {
            place_id: routeData[10],
          },
          label: routeData[4],
          address: routeData[5],
          geofenceRadius: routeData[9],
        })
      }
    })

    const createUrl = constructUrl('/saas/lego/company_routes/create', this.props.location.search)
    request(createUrl, {
      method: 'POST',
      body: JSON.stringify(finalData),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(result => {
      const res = result.data
      const hot = this.refs.hot.hotInstance
      const hotData = hot.getData()

      let i = 0
      while (i < hotData.length) {
        if (hot.isEmptyRow(hotData[i])) {
          i++
        } else {
          if (res[i] && res[i].routeId) {
            hot.setDataAtCell(i, 11, 'Success')
            this.setState({ atLeastOneSuccess: true })
          } else {
            const d = hot.getDataAtCell(i, 11)
            hot.setDataAtCell(i, 11, res[i] || d)
          }
          i++
        }
      }
    })
  }

  getRouteID = (ind, len, _arr) => {
    if (!(ind < len)) return

    if (_arr[ind].rowInfo[4]) {
      this.addRoute(_arr[ind], ind, _arr)
    } else {
      this.firstInd = this.firstInd + 1
      this.lastInd = this.lastInd + 1
      this.getRouteID(ind + 1, len, _arr)
    }
  }

  getWayPointsData = (ind, len, _arr, ind2, len2, _arr2) => {
    if (ind2 < len2) {
      let isApicall = false

      if (
        (_arr2[ind2][0] && _arr2[ind2][0].length > 1) ||
        (_arr2[ind2][1] && _arr2[ind2][1].length > 1) ||
        (_arr2[ind2][2] && _arr2[ind2][2].length > 1) ||
        (_arr2[ind2][3] && _arr2[ind2][3].length > 1)
      ) {
        isApicall = true
      }

      if (isApicall) this.addWayPoints(ind, len, _arr, ind2, len2, _arr2)
      else {
        this.firstInd = this.firstInd + 1
        this.lastInd = this.lastInd + 1
        this.getWayPointsData(ind, len, _arr, ind2 + 1, len2, _arr2)
      }
    } else if (ind < len) this.getRouteID(ind + 1, len, _arr)
  }

  getGooglePlaceId(lat, long, waypointaddress) {
    return new Promise((resolve, reject) => {
      if (lat && long) {
        const my_location = {
          lat: Number(lat),
          lng: Number(long),
        }
        const geocoder = new google.maps.Geocoder()
        return geocoder.geocode({ latLng: my_location }, (results, status) => {
          if (
            status === window.google.maps.GeocoderStatus.OK &&
            results[0] &&
            results[0].formatted_address
          ) {
            resolve({ address: results[0].formatted_address, place_id: results[0].place_id })
          }
          reject({ address: waypointaddress })
        })
      } else if (waypointaddress !== null && waypointaddress !== '' && waypointaddress.length > 1) {
        return geocodeByAddress(waypointaddress)
          .then(results => results[0].place_id)
          .then(place_id => resolve({ address: waypointaddress, place_id }))
      }
    })
  }

  lastInd = -1
  firstInd = 0
  addWayPoints(ind, len, _arr, ind2, len2, wayPoitData) {
    let data
    const parsedDt = convertQsToJson(this.props.location.search)
    const cId = parsedDt.companyId || ''
    const bId = parsedDt.branchId || ''
    const dId = parsedDt.departmentId || ''

    let latitude = '',
      longitude = ''

    // if (wayPoitData[ind2][3] !== null) {
    //   addressData = wayPoitData[ind2][3]
    // }

    if (
      wayPoitData[ind2][1] &&
      wayPoitData[ind2][2] &&
      wayPoitData[ind2][1] !== '\n' &&
      wayPoitData[ind2][2] !== '\n'
    ) {
      latitude = wayPoitData[ind2][1]
      longitude = wayPoitData[ind2][2]
      //console.log('lllll', latitude, longitude, wayPoitData[ind2][1], wayPoitData[ind2][2])
    }
    if (latitude === '' && longitude === '' && wayPoitData[ind2][3] === '') {
      setTimeout(() => {
        this.getWayPointsData(ind, len, _arr, ind2 + 1, len2, wayPoitData)
      }, 1000)
      const hot = this.refs.hot.hotInstance
      this.lastInd = this.lastInd + 1
      this.firstInd = this.firstInd + 1
      hot.setDataAtCell(this.lastInd, 11, 'Please enter either lat,long or address')
    }
    this.getGooglePlaceId(latitude, longitude, wayPoitData[ind2][3])
      .then(googlePlaceId => {
        data = {
          label: wayPoitData[ind2][0],
          google: {
            place_id: googlePlaceId.place_id,
          },
          address: googlePlaceId.address,
          geofenceRadius: wayPoitData[ind2][4],
        }

        const url = `${
          window._env_.REACT_APP_API_URL
        }/saas/lego/company_routes_waypoints/create?c=${cId}&b=${bId}&d=${dId}&t=CONSIGNOR&routeId=${
          this.responseRouteId
        }`
        request(url, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }).then(
          result => {
            if (result.success) {
              //this.getWayPointsData(ind, len, _arr, ind2 + 1, len2, wayPoitData)
              setTimeout(() => {
                this.getWayPointsData(ind, len, _arr, ind2 + 1, len2, wayPoitData)
              }, 1000)
              const hot = this.refs.hot.hotInstance
              this.lastInd = this.lastInd + 1
              this.firstInd = this.firstInd + 1
              hot.setDataAtCell(this.lastInd, 11, 'Success')
              return result
            } else {
              result.message.map(msg => message.error(msg))
            }
          },
          error => {
            setTimeout(() => {
              this.getWayPointsData(ind, len, _arr, ind2 + 1, len2, wayPoitData)
            }, 1000)
            const hot = this.refs.hot.hotInstance
            this.lastInd = this.lastInd + 1
            this.firstInd = this.firstInd + 1
            hot.setDataAtCell(this.lastInd, 11, error.message)
            return error
          }
        )
      })
      .catch(address => {
        setTimeout(() => {
          this.getWayPointsData(ind, len, _arr, ind2 + 1, len2, wayPoitData)
        }, 1000)
        const hot = this.refs.hot.hotInstance
        this.lastInd = this.lastInd + 1
        this.firstInd = this.firstInd + 1
        hot.setDataAtCell(this.lastInd, 11, 'Please enter valid lat long')
      })
  }

  saveDraft() {
    try {
      const data = this.refs.hot.hotInstance.getData()
      localStorage['data'] = JSON.stringify(data)
      message.success('Draft saved successfully')
    } catch (e) {
      message.error("Coundn't save draft")
    }
  }

  resetData() {
    localStorage.removeItem('data')
    this.setState({
      data: [[]],
      ImportFileComponentKey: Date.now(),
    })
  }

  removeSuccess() {
    let adata = this.refs.hot.hotInstance.getData()
    adata = adata.filter(s => s[11] !== 'Success')
    this.setState({
      data: adata,
    })
  }

  displaySuggestions(predictions, status) {
    if (status !== google.maps.places.PlacesServiceStatus.OK) {
      return
    }
    const arr = []
    predictions.forEach(function(prediction) {
      arr.push(prediction.description)
    })
    this.setState({ autocompleteData: arr })

    // const hot = this.refs.hot.hotInstance
    // hot.setDataAtCell(this.firstInd, 12, data.origin.lat)
  }
  render() {
    const { data } = this.state
    const dataCopy = [...data]

    return (
      <Col className={css`padding:20px`}>
        <Row>
          <Col span={12} className={css`paddingBottom:20px` }>
            <div style={{ float: 'left' }}>
              <ImportFileComponent
                handleParse={d => this.updateLatLng(d)}
                inputKey={this.state.ImportFileComponentKey}
              />
            </div>
            <Button type="primary" onClick={() => this.saveDraft()}>
              Save as Draft
            </Button>
            <Button type="primary" onClick={() => this.saveData()}>
              Upload
            </Button>
          </Col>
          <Col span={12} className={css`paddingBottom:20px, textAlign:right` }>
            {this.state.atLeastOneSuccess && (
              <a href="javascript:void(0);" onClick={() => this.removeSuccess()}>
                <Icon type="cross" />
                Remove Success
              </a>
            )}
            <CSVLink
              style={{ marginLeft: '20px', marginRight: '20px' }}
              headers={ColumnHeaders}
              data={this.state.downloadData}
              filename={`routes-${new Date().toISOString()}.csv`}
              onClick={() => {
                this.setState({
                  downloadData: this.refs.hot.hotInstance.getData(),
                })
              }}
            >
              <Icon type="download" /> Download
            </CSVLink>
            <a href="#" onClick={() => this.resetData()}>
              <Icon type="undo" /> Reset
            </a>
          </Col>
        </Row>

        <HotTable
          data={dataCopy}
          colHeaders={ColumnHeaders}
          colWidths={[50, 50, 50, 50, 50, 50, 100, 60, 50, 50, 0.1, 50]}
          maxRows={500}
          columns={[
            {},
            {},
            {},
            {},
            {},
            {},
            {
              type: 'autocomplete',
              source: (query, process) => {
                const service = new google.maps.places.AutocompleteService()
                if (query.length > 0) {
                  service.getQueryPredictions({ input: query }, this.displaySuggestions)
                }
                process(this.state.autocompleteData)
              },
              strict: true,
            },
            {},
            {},
            {},
            {},
            {
              readOnly: true,
              className: 'status',
              renderer: (instance, td, row, col, prop, value, cellProperties) => {
                td.style.background = '#dddddd'
                if (value === 'Success') {
                  td.style.color = 'green'
                } else if (value) {
                  td.style.color = 'red'
                }
                td.innerHTML = value

                return td
              },
            },
          ]}
          rowHeaders={true}
          minSpareRows={1}
          minRows={1}
          ref="hot"
          width={1200}
          stretchH="all"
        />
      </Col>
    )
  }
}

export default UploadRoute
