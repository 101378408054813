import React from 'react'
import { Form, message, Button, Row, Col, Input,AutoComplete, Select, Typography } from 'antd'
import { css } from '@emotion/css'

import { convertQsToJson } from 'utils/common'
import fetchData from 'utils/fetchData'

const { Title } = Typography;
const Option = AutoComplete.Option

const EQS_API = window._env_.REACT_APP_EQS_SERVICE_API_URL
const ALERT_CONFIG_API = window._env_.REACT_APP_ALERT_CONFIG_API_URL
const ALERT_CONFIG_SERVICE_API = window._env_.REACT_APP_ALERT_CONFIG_SERVICE_URL


const InputBoxWithLabel = ({ label, value, placeholder, isReqField=true, inputChange }) => {
    return (
        <Col span={5}>
            <label className="subHeaderLabel">
                {isReqField && <span style={{ color: 'red' }}>*</span>}
                {label}
            </label>
            <Input
                className={css`width: 100%;`}
                size="large"
                placeholder={placeholder}
                value={value}
                onChange= {inputChange}
            />
        </Col>
    )
}

const ShowEntityConfig = ({ label, configBoxStyle, getConfigJson, onClickCopy }) => {
    return (
        <Col style={{ padding: '10px', background: '#fff' }}>
            <Row style={{ padding: '10px 10px 10px 22px' }} />
            <Row>
                <Col span={1} />
                <Col span={3} style={{ padding: '8px' }}>
                    <strong>{label}</strong>
                </Col>
                <Col span={15}>
                    <div style={configBoxStyle}>
                        <pre>{getConfigJson}</pre>
                    </div>
                    <Button onClick={onClickCopy} style={{ marginTop: '8px' }}>
                        Copy Config JSON
                    </Button>
                </Col>
            </Row>
        </Col>
    )
}

class LegoAlertConfig extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            companyId: '',
            showConfigFlag: false,
            fteId: '',
            config: {},
            transporter: {
                companyId: '',
                branchId: '',
                departmentId: ''
            },
            consignor: {
                companyId: '',
                branchId: '',
                departmentId: ''
            },
            selectOptions: ["all", "long_stoppage", "route_deviation", "transit_delay", "sta_breach",  "night_driving", "continuous_driving", "over_speeding", "untracked", "tracking_interrupted", "detention", "detention_origin", "detention_destination", "detention_waypoint"],
            alertKey: undefined
        }
    }

    componentDidMount() {
        const { search } = this.props.location;
        const parsedDt = convertQsToJson(search)
        if (!parsedDt.companyId) {
            this.setState({ companyId: '' })
        } else {
            this.setState({ companyId: parsedDt.companyId })
        }
    }
    componentDidUpdate(prevProps) {
        const { search } = this.props.location;

        const prevSearch = prevProps.location.search
        if (search !== prevSearch) {
            const parsedDt = convertQsToJson(search)
            if (!parsedDt.companyId) {
                this.setState({ companyId: '' })
            }
            else {
                this.setState({ companyId: parsedDt.companyId })
            }
        }
    }

    getConfig = (data) => {
        let {consignor, transporter} = this.state;
        if(!consignor.companyId  && !transporter.companyId){
            message.error('Please fill mandatory fields');
            return;
        }
        let transporterObj = null;
        let consignorObj = null;
        let paramsArray = [];

        if(transporter.companyId){
            transporterObj = {
                "companyId" : transporter.companyId,
                "companyType" : "TRANSPORTER",
            }
            transporterObj["branchId"] = transporter.branchId ?  transporter.branchId : null
            transporterObj["departmentId"] = transporter.departmentId ?  transporter.departmentId : null
        }
        if(consignor.companyId){
            consignorObj = {
                "companyId" : consignor.companyId,
                "companyType" : "CONSIGNOR",
            }
            consignorObj["branchId"] = consignor.branchId ?  consignor.branchId : null
            consignorObj["departmentId"] = consignor.departmentId ?  consignor.departmentId : null
        }

        const queryParams = {
            param: JSON.stringify({
              for: [transporterObj, consignorObj]
            })};
          
        // Encode the query parameters
        const encodedParams = Object.keys(queryParams).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`).join('&');

        let url = `${ALERT_CONFIG_API}/v1/feature_permission/deprecated/argusconfig?${encodedParams}`;
        const headers = {
            'token': `Bearer ${localStorage.getItem('satellizer_token')}`
        }

        fetchData(url, 'GET', {}, headers)
        .then(res => {
            return this.getAlertConfig(res, this.state.alertKey)
        })
        .then(res => {
            if (res && res.success && res.data) {
                this.setState({ config: res.data })
            } else {
                this.setState({ config: {} })
            }
            this.setState({ showConfigFlag: true })
        })
        .catch(error => {
            this.setState({ config: {} });
            message.error(error);
        });
    }

    async getAlertConfig(resData, key){
        let reqData = {}
        if(resData && resData.success && resData.data){
            reqData = {...resData.data}
            key = (key == undefined) ? 'all' : key;
            if(key != 'all'){
                Object.keys(resData.data).forEach((e) => {
                    reqData[e] = {
                      [key]: resData.data[e][key]
                    };
                });
            }
        }
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('satellizer_token')}`
        }

        let url = `${ALERT_CONFIG_SERVICE_API}/v1/ticketing/ft_to_lego_config`;
        let fetchOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({data:reqData})
          }
        try {
            const response = await fetch(url, fetchOptions);
            const data = await response.json();
            return data;
        } catch (error) {
            return error;
        }

    }

    handleSubmit = e => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.getConfig(values)
            }
        })
    }

    onSelecthandler = value => {
        this.setState(prevState => ({ 
            ...prevState,
            "alertKey": value 
        }))
        const qs = this.convertJsonToQs({"alertKey":value})
        this.props.history.push({
          pathname: this.props.location.pathname,
          search: `?${qs}`,
        })
    }
    convertJsonToQs(data) {
        return Object.keys(data)
          .map(key => key + '=' + data[key])
          .join('&')
    }

    getJsonString = (jsonData) => {
        let jsonString = ''
        try {
            jsonString = JSON.stringify(jsonData, null, 2)
        }
        catch (err) {
            message.error('Error in getting json string');
        }
        return jsonString;
    }

    copyJsonToClipboard = (jsonData) => {
        try {
            const jsonString = this.getJsonString(jsonData);
            navigator.clipboard.writeText(jsonString);
            message.success('JSON Copied to Clipboard');
        }
        catch (err) {
            message.error('Error in copyJsonToClipboard');
        }
    };

    handleInputChange = (entity, field, value) => {
        this.setState((prevState) => ({
          [entity]: {
            ...prevState[entity],
            [field]: value,
          },
        }));
    };

    resetAllFilter = (entity) => {
        this.setState(prevState =>({
            ...prevState,
            [entity]:{
                companyId: '',
                branchId: '',
                departmentId: ''
            }
        }))
        this.props.history.push({
          pathname: this.props.location.pathname,
        })
      }
    render() {
        const { getFieldDecorator } = this.props.form
        const configBoxStyle = {
            maxHeight: '350px', overflowY: 'auto', border: '1px solid #d9d9d9',
            padding: '8px', background: '#fafafa'
        }
        return (
            <div>
                <Row
                    span={20}
                    className={css`
                    padding: 20px;
                    background: #fff;
                    border-bottom: 1px solid #e8e8e8;
                    `}
                    gutter={16}>
                    <Form onSubmit={this.handleSubmit}>
                        <Col span={20}>
                            <Row key="consignerr">
                                <Title level={2}>Consignor</Title>
                                <Row
                                    span={20}
                                    className={css`
                                    padding: 20px;
                                    background: #fff;
                                    border-bottom: 1px solid #e8e8e8;
                                    `}
                                    gutter={16}
                                >
                                    <InputBoxWithLabel 
                                        label="Company" 
                                        value={this.state["consignor"]["companyId"]}
                                        entity="consignor" 
                                        field="companyId"
                                        placeholder= "Enter Company ID.."
                                        inputChange={(e) => this.handleInputChange("consignor", "companyId", e.target.value)}
                                    />
                                    <InputBoxWithLabel 
                                        label="Branch" 
                                        value={this.state["consignor"]["branchId"]}
                                        entity="consignor" 
                                        field="branchId"
                                        placeholder= "Enter Branch ID.."
                                        isReqField= {false}
                                        inputChange={(e) => this.handleInputChange("consignor", "branchId", e.target.value)}
                                    />
                                    <InputBoxWithLabel 
                                        label="Department" 
                                        value={this.state["consignor"]["departmentId"]}
                                        entity="consignor" 
                                        field="departmentId"
                                        placeholder= "Enter Department ID..."
                                        isReqField= {false}
                                        inputChange={(e) => this.handleInputChange("consignor", "departmentId", e.target.value)}
                                    />
                                    <Col style={{ paddingTop: '27px' }} span={3}>
                                        <Button
                                            disabled={this.state.disableFlag}
                                            type="primary"
                                            size="large"
                                            onClick={(e) => this.resetAllFilter('consignor')}
                                        >
                                            Reset Filter
                                        </Button>
                                    </Col>
                                    <Col className="alignright" span={8}>
                                        {this.props.innerhtml ? this.props.innerhtml() : ''}
                                        {this.props.children}
                                    </Col>
                                </Row>
                            </Row>
                            <Row>
                                <Title level={2}>Transporter</Title>
                                <Row
                                    span={20}
                                    className={css`
                                    padding: 20px;
                                    background: #fff;
                                    border-bottom: 1px solid #e8e8e8;
                                    `}
                                    gutter={16}
                                >
                                    <InputBoxWithLabel 
                                        label="Company" 
                                        value={this.state["transporter"]["companyId"]}
                                        entity="transporter" 
                                        field="companyId"
                                        placeholder= "Enter Company ID.."
                                        inputChange={(e) => this.handleInputChange("transporter", "companyId", e.target.value)}
                                    />
                                    <InputBoxWithLabel 
                                        label="Branch" 
                                        value={this.state["transporter"]["branchId"]}
                                        entity="transporter" 
                                        field="branchId"
                                        placeholder= "Enter Branch ID.."
                                        isReqField= {false}
                                        inputChange={(e) => this.handleInputChange("transporter", "branchId", e.target.value)}
                                    />
                                    <InputBoxWithLabel 
                                        label="Department" 
                                        value={this.state["transporter"]["departmentId"]}
                                        entity="transporter" 
                                        field="departmentId"
                                        placeholder= "Enter Department ID..."
                                        isReqField= {false}
                                        inputChange={(e) => this.handleInputChange("transporter", "departmentId", e.target.value)}
                                    />
                                    <Col style={{ paddingTop: '27px' }} span={3}>
                                        <Button
                                            disabled={this.state.disableFlag}
                                            type="primary"
                                            size="large"
                                            onClick={(e) => this.resetAllFilter('transporter')}
                                        >
                                            Reset Filter
                                        </Button>
                                    </Col>
                                    <Col className="alignright" span={8}>
                                        {this.props.innerhtml ? this.props.innerhtml() : ''}
                                        {this.props.children}
                                    </Col>
                                </Row>
                            </Row>
                            <Row>
                                {/* <Col span={1} /> */}
                                <Row
                                    span={20}
                                    className={css`
                                    padding: 20px;
                                    background: #fff;
                                    border-bottom: 1px solid #e8e8e8;
                                    `}
                                    gutter={16}
                                >
                                    <Col span={4}>
                                        <label className="subHeaderLabel">Alert</label>
                                        <Select
                                            disabled={false}
                                            className="dropdownwidth"
                                            placeholder="Select alert key"
                                            size="large"
                                            onSelect={this.onSelecthandler}
                                            value={this.state.alertKey}
                                            >
                                            {
                                                this.state.selectOptions.map((option, key) => (
                                                    <Option key={key} value={option}>
                                                        {option}
                                                    </Option>
                                                ))
                                            }
                                        </Select>
                                    </Col>
                                    <Col style={{ paddingTop: '27px', paddingLeft: '20%' }} span={3}>
                                        <Button
                                            // disabled={!this.state.companyId}
                                            type="primary"
                                            size="large"
                                            htmlType='submit'
                                        >
                                            GET Config
                                        </Button>
                                    </Col>
                                </Row>
                            </Row>
                        </Col>
                    </Form>
                </Row>
                <Row style={{ padding: '10px 10px 10px 22px' }} />
                {this.state.showConfigFlag
                    ?
                    <ShowEntityConfig
                        label='Config:'
                        configBoxStyle={configBoxStyle}
                        getConfigJson={this.getJsonString(this.state.config)}
                        onClickCopy={() => this.copyJsonToClipboard(this.state.config)}
                    />
                    : ''
                }
            </div >
        )
    }
}

const Lego_Alert_Config_Form = Form.create({ name: 'Lego_Alert_Config_Form' })(LegoAlertConfig)
export default Lego_Alert_Config_Form