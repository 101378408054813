import React from 'react'
import { Col, Row, Button, message, Icon } from 'antd'
import { HotTable } from '@handsontable/react'
import request from 'utils/request'
import { convertQsToJson } from 'utils/common'
import 'handsontable-pro/dist/handsontable.full.css'
import 'containers/routesMaster/App.css'
import 'containers/routesMaster/App.css'
import moment from 'moment'
import { css } from '@emotion/css'

class UploadRouteMaster extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      routeMasterData: [[]],
      autocompleteData: [],
      trasporterURL: '',
      vehicleURL: '',
      materialURL: '',
      routeURL: '',
      routesData: {},
      trasnportesrData: [],
      allTrasnporters: '',
      vehiclesData: [],
      allVehicles: '',
      materialsData: [],
      allMaterials: '',
      routeCodes: [],
    }
  }
  countIndex
  componentDidMount() {
    if (this.props.location.search === '') {
      message.info('Select a company to continue')
    } else {
      this.constructUrl(this.props.location.search)
    }
    try {
      const routeMasterData = JSON.parse(localStorage['routeMasterData'])
      const routeCodes = JSON.parse(localStorage['routeCodes'])
      this.setState({ routeMasterData, routeCodes })
    } catch (e) {
      console.log('Data not found in localStorage')
    }
  }
  componentWillReceiveProps(nextProps) {
    this.constructUrl(nextProps.location.search)
  }
  constructUrl(qs) {
    const parsedDt = convertQsToJson(qs)
    const cId = parsedDt.companyId || ''
    const bId = parsedDt.branchId || ''
    const dId = parsedDt.departmentId || ''
    const trasporterURL = `${
      window._env_.REACT_APP_API_URL
    }/saas/lego/company_partners/fetch?c=${cId}&b=${bId}&d=${dId}&t=CONSIGNOR&pt=TRANSPORTER`
    const vehicleURL = `${
      window._env_.REACT_APP_API_URL
    }/saas/lego/company_vehicles/fetch?c=${cId}&b=${bId}&d=${dId}&t=CONSIGNOR`
    const materialURL = `${
      window._env_.REACT_APP_API_URL
    }/saas/lego/company_materials/fetch?c=${cId}&b=${bId}&d=${dId}&t=CONSIGNOR`
    const routeURL = `${
      window._env_.REACT_APP_API_URL
    }/saas/lego/company_routes/fetch?c=${cId}&b=${bId}&d=${dId}&t=CONSIGNOR`
    this.setState({
      trasporterURL: trasporterURL,
      vehicleURL: vehicleURL,
      materialURL: materialURL,
      routeURL: routeURL,
    })
    this.fetchRoutes(routeURL)
    this.fetchTransporters(trasporterURL)
    this.fetchVehicles(vehicleURL)
    this.fetchMaterials(materialURL)
  }
  fetchRoutes(url) {
    request(url).then(
      result => {
        if (result.success) {
          let routes = {}
          result.data.forEach(data => {
            if (data.code) {
              routes[data.code] = { data }
              routes[data.code].display = data.label ? `${data.code} - ${data.label}` : data.code
            }
          })
          this.setState({ routesData: routes })
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        message.error('Error while fetching materials')
      }
    )
  }
  fetchTransporters(url) {
    request(url).then(
      result => {
        if (result.success) {
          const trasportData = result.data.map(data => `${data.name}`)
          this.setState({ trasnportesrData: trasportData, allTrasnporters: result.data })
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        message.error('Error while fetching materials')
      }
    )
  }
  fetchVehicles(url) {
    request(url).then(
      result => {
        if (result.success) {
          const vehicleData = result.data.map(data => `${data.label}`)
          this.setState({ vehiclesData: vehicleData, allVehicles: result.data })
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        message.error('Error while fetching materials')
      }
    )
  }
  fetchMaterials(url) {
    request(url).then(
      result => {
        if (result.success) {
          const material = result.data.map(data => `${data.label}`)
          this.setState({ materialsData: material, allMaterials: result.data })
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        message.error('Error while fetching materials')
      }
    )
  }
  convertQsToJson(str) {
    let pairs = str.slice(1).split('&')
    let result = {}
    pairs.forEach(function(pair) {
      pair = pair.split('=')
      result[pair[0]] = decodeURIComponent(pair[1] || '')
    })
    return JSON.parse(JSON.stringify(result))
  }
  saveDraft() {
    try {
      const routeMasterData = this.refs.hot.hotInstance.getData()
      localStorage['routeMasterData'] = JSON.stringify(routeMasterData)
      localStorage['routeCodes'] = JSON.stringify(this.state.routeCodes)
      message.success('Draft saved successfully')
    } catch (e) {
      message.error("Coundn't save draft")
    }
  }
  resetData() {
    this.setState({ routeMasterData: [[]] })
    localStorage.removeItem('routeMasterData')
    localStorage.removeItem('routeCodes')
  }
  saveData() {
    if (this.props.location.search === '') {
      return message.info('Select a company to continue')
    }
    this.countIndex = -1
    this.manitainindexArray = []
    let routeMasterArray = []
    const hot = this.refs.hot.hotInstance
    const routeMasterData = hot.getData()
    routeMasterData.forEach((routeMasterData, i) => {
      if (!hot.isEmptyRow(routeMasterData)) {
        this.manitainindexArray.push(i)
        routeMasterArray.push(routeMasterData)
      }
    })
    this.recursiveAddRouteMastaerData(0, routeMasterArray.length, routeMasterArray)
  }
  recursiveAddRouteMastaerData(index, length, routeMasterArray) {
    if (index < length) {
      this.routeMasterJsonData(index, length, routeMasterArray)
    }
  }
  routeMasterJsonData(index, length, data) {
    let routeInfo = this.state.routeCodes[this.manitainindexArray[0]]
    const parsedDt = convertQsToJson(this.props.location.search)
    const cId = parsedDt.companyId || ''
    const bId = parsedDt.branchId || ''
    const dId = parsedDt.departmentId || ''
    let trsaporter_id,
      vehicle_id,
      materil_id,
      routeidFlag = true,
      traporterFlag = true,
      vehicleFlag = true,
      materialflag = true,
      isAPICAll = false,
      numberFlag = false,
      loadingFlag = false,
      unloadingFlag = false,
      detentionatLoad = false,
      detentionatunLoad = false,
      sttFlag = false
    if (true) {
      numberFlag = isNaN(Number(data[index][4].trim().replace(/,/g, '')))
      if (!numberFlag) {
        numberFlag = isNaN(data[index][4]) || parseInt(data[index][4]) <= 0 ? true : false
      }
    }

    if (data[index][6]) {
      loadingFlag = isNaN(Number(data[index][6].trim().replace(/,/g, '')))
      if (!loadingFlag) {
        loadingFlag = isNaN(data[index][6]) || parseInt(data[index][6]) <= 0 ? true : false
      }
    }
    if (data[index][7]) {
      unloadingFlag = isNaN(Number(data[index][7].trim().replace(/,/g, '')))
      if (!unloadingFlag) {
        unloadingFlag = isNaN(data[index][7]) || parseInt(data[index][7]) <= 0 ? true : false
      }
    }
    if (data[index][8]) {
      detentionatLoad = isNaN(Number(data[index][8].trim().replace(/,/g, '')))
      if (!detentionatLoad) {
        detentionatLoad = isNaN(data[index][8]) || parseInt(data[index][8]) <= 0 ? true : false
      }
    }
    if (data[index][9]) {
      detentionatunLoad = isNaN(Number(data[index][9].trim().replace(/,/g, '')))
      if (!detentionatunLoad) {
        detentionatunLoad = isNaN(data[index][9]) || parseInt(data[index][9]) <= 0 ? true : false
      }
    }
    if (data[index][10]) {
      sttFlag = isNaN(Number(data[index][10].trim().replace(/,/g, '')))
      if (!sttFlag) {
        sttFlag = isNaN(data[index][10]) || parseInt(data[index][10]) <= 0 ? true : false
      }
    }

    if (
      numberFlag ||
      loadingFlag ||
      unloadingFlag ||
      detentionatLoad ||
      detentionatunLoad ||
      sttFlag
    ) {
      const hot = this.refs.hot.hotInstance
      hot.setDataAtCell(this.manitainindexArray.shift(), 11, 'Please enter valid number')
      this.recursiveAddRouteMastaerData(index + 1, data.length, data)
      isAPICAll = true
    }
    if (routeInfo) {
      routeidFlag = false
    } else {
      routeidFlag = true
    }

    if (data[index][1] !== '' && data[index][1] !== null && data[index][1] !== '\n') {
      for (let i = 0; i < this.state.allTrasnporters.length; i++) {
        if (
          this.state.allTrasnporters[i].id !== null &&
          data[index][1].trim().toLowerCase() ===
            this.state.allTrasnporters[i].name.trim().toLowerCase()
        ) {
          trsaporter_id = this.state.allTrasnporters[i].id
          traporterFlag = false
          break
        } else {
          traporterFlag = true
        }
      }
    } else {
      traporterFlag = true
    }
    if (data[index][2] !== '' && data[index][2] !== null && data[index][2] !== '\n') {
      for (let i = 0; i < this.state.allVehicles.length; i++) {
        if (
          this.state.allVehicles[i].id !== '' &&
          data[index][2].trim().toLowerCase() ===
            this.state.allVehicles[i].label.trim().toLowerCase()
        ) {
          vehicle_id = this.state.allVehicles[i].id
          vehicleFlag = false
          break
        } else {
          vehicleFlag = true
        }
      }
    } else {
      vehicleFlag = true
    }
    if (data[index][3] !== '' && data[index][3] !== null && data[index][3] !== '\n') {
      for (let i = 0; i < this.state.allMaterials.length; i++) {
        if (
          this.state.allMaterials[i].id !== '' &&
          data[index][3].trim().toLowerCase() ===
            this.state.allMaterials[i].label.trim().toLowerCase()
        ) {
          materil_id = this.state.allMaterials[i].id
          materialflag = false
          break
        } else {
          materialflag = true
        }
      }
    } else {
      materialflag = true
    }
    if (routeidFlag && traporterFlag && vehicleFlag && materialflag) {
      const hot = this.refs.hot.hotInstance
      hot.setDataAtCell(
        this.manitainindexArray.shift(),
        11,
        'Please enter valid route,transport,vehicle and material'
      )
      this.recursiveAddRouteMastaerData(index + 1, data.length, data)
      isAPICAll = true
    } else if (routeidFlag) {
      const hot = this.refs.hot.hotInstance
      hot.setDataAtCell(this.manitainindexArray.shift(), 11, 'Please enter valid route')
      this.recursiveAddRouteMastaerData(index + 1, data.length, data)
      isAPICAll = true
    } else if (traporterFlag) {
      const hot = this.refs.hot.hotInstance
      hot.setDataAtCell(this.manitainindexArray.shift(), 11, 'Please enter valid transport')
      this.recursiveAddRouteMastaerData(index + 1, data.length, data)
      isAPICAll = true
    } else if (vehicleFlag) {
      const hot = this.refs.hot.hotInstance
      hot.setDataAtCell(this.manitainindexArray.shift(), 11, 'Please enter valid vehicle')
      this.recursiveAddRouteMastaerData(index + 1, data.length, data)
      isAPICAll = true
    } else if (materialflag) {
      const hot = this.refs.hot.hotInstance
      hot.setDataAtCell(this.manitainindexArray.shift(), 11, 'Please enter valid material')
      this.recursiveAddRouteMastaerData(index + 1, data.length, data)
      isAPICAll = true
    }
    if (isAPICAll) {
      return
    }
    let convertedDate = moment(data[index][5], 'DD/MM/YYYY')
      .toDate()
      .getTime()
    const addRouteData = {
      partnerId: parseInt(trsaporter_id),
      validFrom: convertedDate,
      vehicleId: parseInt(vehicle_id),
      materialId: parseInt(materil_id),
      loadType: 'FTL',
      data: [],
    }
    if (parseInt(data[index][4]))
      addRouteData.data.push({
        key: 'ROUTE_COST',
        value: parseInt(data[index][4]),
        unit: 'FIXED',
      })
    if (parseInt(data[index][6]))
      addRouteData.data.push({
        key: 'LOADING_FEE',
        value: parseInt(data[index][6]),
        unit: 'FIXED',
      })
    if (parseInt(data[index][7]))
      addRouteData.data.push({
        key: 'UNLOADING_FEE',
        value: parseInt(data[index][7]),
        unit: 'FIXED',
      })
    if (parseInt(data[index][8]))
      addRouteData.data.push({
        key: 'LOADING_DETENTION_COST',
        value: parseInt(data[index][8]),
        unit: 'DAY',
      })
    if (parseInt(data[index][9]))
      addRouteData.data.push({
        key: 'UNLOADING_DETENTION_COST',
        value: parseInt(data[index][9]),
        unit: 'DAY',
      })
    if (parseInt(data[index][10]))
      addRouteData.data.push({
        key: 'STANDARD_TRANSIT_TIME',
        value: parseFloat(data[index][10]) * 60,
        unit: 'FIXED',
      })

    request(
      `${
        window._env_.REACT_APP_API_URL
      }/saas/lego/company_routes_master/create?c=${cId}&b=${bId}&d=${dId}&t=CONSIGNOR&pt=TRANSPORTER&routeId=${
        routeInfo.id
      }`,
      {
        method: 'POST',
        body: JSON.stringify(addRouteData),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(
      result => {
        if (result.success) {
          const hot = this.refs.hot.hotInstance
          hot.setDataAtCell(this.manitainindexArray.shift(), 11, 'Success')
          this.recursiveAddRouteMastaerData(index + 1, data.length, data)
        } else {
          const hot = this.refs.hot.hotInstance
          hot.setDataAtCell(this.manitainindexArray.shift(), 11, result.message[0])
          this.recursiveAddRouteMastaerData(index + 1, data.length, data)
          // result.message.map(msg => message.error(msg))
        }
      },
      error => {
        console.log('errors', error)
        const hot = this.refs.hot.hotInstance
        hot.setDataAtCell(this.manitainindexArray.shift(), 11, 'Error')
        this.recursiveAddRouteMastaerData(index + 1, data.length, data)
      }
    )
  }

  render() {
    return (
      <Row>
        <Row
          className={css`
            padding: 20px;
          `}
        >
          <Col span={12} className={css`paddingBottom:20px`}>
            <Button type="primary" onClick={() => this.saveData()}>
              Save
            </Button>
            <Button type="primary" onClick={() => this.saveDraft()}>
              Save as Draft
            </Button>
          </Col>
          <Col span={12} className={css`paddingBottom:20px, textAlign:right`}>
            <Button type="primary" onClick={() => this.resetData()}>
              <Icon type="undo" /> Reset
            </Button>
          </Col>
        </Row>
        <Row
          className={css`
            padding: 20px
          `}
        >
          <HotTable
            data={this.state.routeMasterData}
            startCols={12}
            colHeaders={[
              'Route Code - Route Label',
              'Transporter Name',
              'Vehicle Type',
              'Material Type',
              'Freight Cost(in INR)',
              'Valid From',
              'Loading Fee(in INR)',
              'Unloading Fee(in INR)',
              'Detention charges at loading (in INR/DAY)',
              'Detention charges at unloading (in INR/DAY)',
              'STT (in hours)',
              'Status',
            ]}
            colWidths={[50, 50, 50, 50, 50, 50, 50, 60, 60, 50, 50, 50]}
            manualColumnResize={true}
            maxRows={550}
            manualRowResize={true}
            columns={[
              {
                type: 'dropdown',
                source: Object.values(this.state.routesData).map(item => item.display),
                strict: false,
              },
              {
                type: 'dropdown',
                source: this.state.trasnportesrData,
                strict: false,
              },
              {
                type: 'dropdown',
                source: this.state.vehiclesData,
                strict: false,
              },
              {
                type: 'dropdown',
                source: this.state.materialsData,
                strict: false,
              },
              {},
              {
                type: 'date',
                dateFormat: 'DD/MM/YYYY',
                correctFormat: true,
                datePickerConfig: {
                  firstDay: 0,
                  showWeekNumber: true,
                  numberOfMonths: 1,
                },
              },
              {},
              {},
              {},
              {},
              {},
              {
                readOnly: true,
                className: 'status',
                renderer: (instance, td, row, col, prop, value, cellProperties) => {
                  td.style.background = '#dddddd'
                  if (value === 'Success') {
                    td.style.color = 'green'
                  } else if (value) {
                    td.style.color = 'red'
                  }
                  td.innerHTML = value
                  return td
                },
              },
            ]}
            rowHeaders={true}
            minSpareRows={1}
            minRows={1}
            ref="hot"
            width={1200}
            stretchH="all"
            afterChange={(changes, src) => {
              var cellProp = {}
              for (let i in changes) {
                if (changes && changes[i][1] === 0) {
                  for (let code in this.state.routesData) {
                    let routeData = this.state.routesData[code]
                    if (changes[i][3] === code || changes[i][3] === routeData.display) {
                      this.state.routeCodes[changes[i][0]] = routeData.data
                      break
                    }
                  }
                }
              }
              return cellProp
            }}
          />
        </Row>
      </Row>
    )
  }
}
export default UploadRouteMaster
