import { Row } from 'antd'
import React from 'react'
import EntityConfigHeader from './entity.js'
import 'containers/routesMaster/App.css'
import { withRouter } from 'react-router'

class EntityConfigContainer extends React.Component {
  render() {
    return (
      <Row>
        <EntityConfigHeader/>
        {this.props.children}
      </Row>
    )
  }
}

export default withRouter(EntityConfigContainer)
