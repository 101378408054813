import React from 'react'
import { Col, Row, Select, AutoComplete, message, Button, Input } from 'antd'
import { withRouter } from 'react-router'
import request from 'utils/request'
import { convertQsToJson, sortListBasedOnKey } from 'utils/common'
import _ from 'lodash'
import { css } from '@emotion/css'
import 'containers/routesMaster/App.css'

const Option = AutoComplete.Option
class EntityConfigHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      allCompany: [],
      allDepartments: [],
      allBranches: [],
      consignors: [],
      allRelatedCompany: [],
      allRelatedBranches: [],
      allRelatedDepartments: [],
      disableFlag: false,
      userID: '',
      groupID: '',
      selected: {
        companyId: '',
        companyName: '',
        companyType: '',
        combinedName: '',
        consignorId: '',
        consignorName: '',
        departmentId: '',
        departmentName: '',
        branchId: '',
        branchName: '',
        relatedCompanyId: '',
        relatedCompanyName: '',
        relatedBranchId: '',
        relatedBranchName: '',
        relatedDepartmentId: '',
        relatedDepartmentName: '',
        relatedCombinedName: '',
        uID: '',
        gID: '',
      },
    }
  }

  componentWillReceiveProps(nextProps) {
    let disbleFilter = nextProps.location.pathname.split('/')
    const disableFlag = (disbleFilter[2] === 'edit-notification-config') || (disbleFilter[2] === 'edit');
    this.setState({ disableFlag })

    const qData = convertQsToJson(nextProps.location.search)

    const cn = qData['companyName']
    const cId = qData['companyId']
    const cT = qData['companyType']
    const comN = qData['combinedName']
    const bn = qData['branchName'] || ''
    const bId = qData['branchId'] || ''
    const dn = qData['departmentName'] || ''
    const dId = qData['departmentId'] || ''
    const uID = qData['uID'] || ''
    const gID = qData['gID'] || ''
    const rcId = qData['relatedCompanyId'] || ''
    const rbId = qData['relatedBranchId'] || ''
    const rdId = qData['relatedDepartmentId'] || ''
    const rcn = qData['relatedCompanyName'] || ''
    const rComN = qData['relatedCombinedName'] || ''
    const rbn = qData['relatedBranchName'] || ''
    const rdn = qData['relatedDepartmentName'] || ''
    const conID = qData['consignorId'] || ''
    const conN = qData['consignorName'] || ''
    const { selected } = this.state

    if (comN) {
      selected.combinedName = comN
    } else if (cn && cT) {
      selected.combinedName = `${cn} (${cT})`
    }
    selected.companyName = cn
    selected.companyId = cId
    selected.companyType = cT
    selected.branchName = bn
    selected.branchId = bId
    selected.departmentName = dn
    selected.departmentId = dId
    selected.uID = uID
    selected.gID = gID
    selected.relatedCompanyId = rcId
    selected.relatedBranchId = rbId
    selected.relatedDepartmentId = rdId
    selected.relatedCompanyName = rcn
    selected.relatedCombinedName = rComN
    selected.relatedBranchName = rbn
    selected.relatedDepartmentName = rdn
    selected.consignorId = conID
    selected.consignorName = conN

    this.setState({
      selected,
    })
  }

  componentDidMount() {
    const cn = this.fetchKey('companyName')
    const cId = this.fetchKey('companyId')
    const cT = this.fetchKey('companyType')
    const comN = this.fetchKey('combinedName')
    const bn = this.fetchKey('branchName') || ''
    const bId = this.fetchKey('branchId') || ''
    const dn = this.fetchKey('departmentName') || ''
    const dId = this.fetchKey('departmentId') || ''
    const uID = this.fetchKey('uID') || ''
    const gID = this.fetchKey('gID') || ''
    const rcId = this.fetchKey('relatedCompanyId') || ''
    const rbId = this.fetchKey('relatedBranchId') || ''
    const rdId = this.fetchKey('relatedDepartmemtId') || ''
    const rcn = this.fetchKey('relatedCompanyName') || ''
    const rComN = this.fetchKey('relatedCombinedName') || ''
    const rbn = this.fetchKey('relatedBranchName') || ''
    const rdn = this.fetchKey('relatedDepartmentName') || ''
    const conID = this.fetchKey('consignorId') || ''
    const conN = this.fetchKey('consignorName') || ''
    const { selected } = this.state

    if (comN) {
      selected.combinedName = comN
    } else if (cn && cT) {
      selected.combinedName = `${cn} (${cT})`
    }
    selected.companyName = cn
    selected.companyId = cId
    selected.companyType = cT
    selected.branchName = bn
    selected.branchId = bId
    selected.departmentName = dn
    selected.departmentId = dId
    selected.uID = uID
    selected.gID = gID
    selected.relatedCompanyId = rcId
    selected.relatedBranchId = rbId
    selected.relatedDepartmentId = rdId
    selected.relatedCompanyName = rcn
    selected.relatedCombinedName = rComN
    selected.relatedBranchName = rbn
    selected.relatedDepartmentName = rdn
    selected.consignorId = conID
    selected.consignorName = conN

    this.setState({
      selected,
    })

    if (cId !== undefined) {
      // this.getAllDepartmentsAndBranches(parseInt(cId))
      if (bId !== '') {
        this.getAllDepartments()
      }
      if (cT === 'TRANSPORTER') {
        this.getConsignors(cId)
      }
    }
  }

  setRelatedBranchDetails = relatedBranchDetails => {
    this.setState({ allRelatedBranches: relatedBranchDetails })
    if (this.fetchKey('relatedBranchName') !== undefined) {
      const dt = this.fetchKey('relatedBranchName')
      const bId = this.fetchKey('relatedBranchId')
      const { selected } = this.state
      selected.relatedBranchName = dt
      selected.relatedBranchId = bId
      this.setState({
        selected,
      })

      this.onSelectRelatedBranch(bId)
    }
  }

  setBranchDetails = branchDetails => {
    this.setState({ allBranches: branchDetails })
    if (this.fetchKey('branchName') !== undefined) {
      const dt = this.fetchKey('branchName')
      const bId = this.fetchKey('branchId')
      const { selected } = this.state
      selected.branchName = dt
      selected.branchId = bId
      this.setState({
        selected,
      })

      this.onSelectBranch(bId)
    }
  }

  getAllDepartmentsAndBranches = (companyID, isRelatedCompany) => {
    request(
      `${window._env_.REACT_APP_API_URL}/saas/lego/company/branch_departments?id=${companyID}`
    ).then(
      result => {
        if (result.success) {
          if(result.data){
            sortListBasedOnKey(result.data, 'name')
          }
          if (!isRelatedCompany) {
            this.setBranchDetails(result.data)
          } else {
            this.setRelatedBranchDetails(result.data)
          }
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      err => console.log('error is ', err)
    )
  }

  getConsignors = companyID => {
    request(
      `${window._env_.REACT_APP_API_URL}/saas/lego/company_partners/fetch?c=${companyID}&t=TRANSPORTER&pt=CONSIGNOR`
    ).then(
      result => {
        if (result.success) {
          this.setState({ consignors: result.data })
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      err => console.log('error is ', err)
    )
  }

  getAllCompany = (data, isRelatedCompany) => {
    request(`${window._env_.REACT_APP_API_URL}/saas/lego/company/search?q=${data}`).then(
      result => {
        if (result.success) {
          const companyDetails = _.map(result.data, a => {
            return {
              id: a.id,
              name: a.name,
              type: a.type,
              combinedName: `${a.name} (${a.type})`,
            }
          })
          isRelatedCompany
            ? this.setState({
                allRelatedCompany: companyDetails,
              })
            : this.setState({
                allCompany: companyDetails,
              })
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      () => {
        console.log('fail')
      }
    )
  }

  getAllBranches = (data, isRelatedBranch) => {
    let companyId = this.state.selected.companyId

    if (isRelatedBranch) {
      companyId = this.state.selected.relatedCompanyId
    }

    request(
      `${window._env_.REACT_APP_API_URL}/saas/lego/company/branch_search?cId=${companyId}&q=${data}`
    )
      .then(result => {
        if (result && result.success && result.data) {
          const branchDetails = _.map(result.data, a => {
            return {
              id: a.old_branch_id,
              name: a.name,
            }
          })
          isRelatedBranch
            ? this.setState({
                allRelatedBranches: branchDetails,
              })
            : this.setState({
                allBranches: branchDetails,
              })
        } else {
          result.message.map(msg => message.error(msg))
        }
      })
      .catch(error => {
        console.log('error is ', error)
      })
  }

  getAllDepartments = isRelatedDepartment => {
    let companyId = this.state.selected.companyId
    let branchId = this.state.selected.branchId

    if (isRelatedDepartment) {
      companyId = this.state.selected.relatedCompanyId
      branchId = this.state.selected.relatedBranchId
    }

    request(
      `${window._env_.REACT_APP_API_URL}/saas/lego/company/departments?cId=${companyId}&bId=${branchId}`
    )
      .then(result => {
        if (result && result.success && result.data) {
          sortListBasedOnKey(result.data, 'name')
          const departmentDetails = _.map(result.data, a => {
            return {
              id: a.old_department_id,
              name: a.name,
            }
          })
          isRelatedDepartment
            ? this.setState({
                allRelatedDepartments: departmentDetails,
              })
            : this.setState({
                allDepartments: departmentDetails,
              })
        } else {
          result.message.map(msg => message.error(msg))
        }
      })
      .catch(error => {
        console.log('error is ', error)
      })
  }

  convertJsonToQs(data) {
    return Object.keys(data)
      .map(key => key + '=' + data[key])
      .join('&')
  }

  fetchKey(key) {
    const qData = convertQsToJson(this.props.location.search)
    return qData[key]
  }

  getCompanyFromUrl() {
    const companyName = this.fetchKey('companyName')
    // const companyId = this.fetchKey('companyId')
    // this.getAllDepartmentsAndBranches(parseInt(companyId))
    return companyName
  }

  onSelectCompany = value => {
    const company = this.state.allCompany.find(o => o.combinedName === value)
    const sel = this.state.selected

    sel.companyId = company.id
    sel.companyName = company.name
    sel.combinedName = company.combinedName
    sel.companyType = company.type
    sel.consignorId = ''
    sel.consignorName = ''
    sel.departmentId = ''
    sel.departmentName = ''
    sel.branchId = ''
    sel.branchName = ''
    sel.uID = ''
    sel.gID = ''
    this.setState({ selected: sel, allBranches: [], allDepartments: [] })
    const qs = this.convertJsonToQs(sel)

    // this.getAllDepartmentsAndBranches(company.id)
    if (company.type === 'TRANSPORTER') {
      this.getConsignors(company.id)
    }

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: `?${qs}`,
    })
  }

  onSelectRelatedCompany = value => {
    const company = this.state.allRelatedCompany.find(o => o.combinedName === value)
    const { selected } = this.state

    selected.relatedCompanyId = company.id
    selected.relatedCompanyName = company.name
    selected.relatedCombinedName = company.combinedName
    selected.relatedDepartmentId = ''
    selected.relatedDepartmentName = ''
    selected.relatedBranchId = ''
    selected.relatedBranchName = ''

    this.setState({ selected, allRelatedBranches: [], allRelatedDepartments: [] })
    const qs = this.convertJsonToQs(selected)

    // this.getAllDepartmentsAndBranches(company.id, true)
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: `?${qs}`,
    })
  }

  onSelectBranch = value => {
    const sel = this.state.selected
    let oldbranch = ''
    let newbranch = ''
    oldbranch = sel.branchName
    sel.branchId = parseInt(value)
    if (
      this.state.allBranches.length !== 0 &&
      this.state.allBranches.find(o => o.id === parseInt(value))
    ) {
      sel.branchName = this.state.allBranches.find(o => o.id === parseInt(value)).name
    }
    this.setState({ selected: sel })
    newbranch = sel.branchName
    if (oldbranch !== newbranch) {
      const t = this.state.selected
      t.departmentName = ''
      t.departmentId = ''

      this.setState({ selected: t, allDepartments: [] })
    }
    if (
      this.state.allBranches.length !== 0 &&
      this.state.allBranches.find(o => o.id === parseInt(value))
    ) {
      this.setState({
        allDepartments: this.state.allBranches.find(o => o.id === parseInt(value)).departments,
      })
    }
    const qs = this.convertJsonToQs(sel)

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: `?${qs}`,
    })
  }

  onSelectBranchV2 = value => {
    const sel = this.state.selected

    const branch = this.state.allBranches.find(o => o.name === value)

    sel.departmentId = ''
    sel.departmentName = ''
    sel.branchId = branch.id
    sel.branchName = branch.name
    this.setState({ selected: sel })

    this.getAllDepartments()

    const qs = this.convertJsonToQs(sel)

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: `?${qs}`,
    })
  }

  onSelectRelatedBranch = value => {
    const { selected, allRelatedBranches } = this.state
    let oldbranch = ''
    let newbranch = ''
    oldbranch = selected.relatedBranchName
    selected.relatedBranchId = parseInt(value)
    if (allRelatedBranches.length !== 0 && allRelatedBranches.find(o => o.id === parseInt(value))) {
      selected.relatedBranchName = allRelatedBranches.find(o => o.id === parseInt(value)).name
    }
    this.setState({ selected })
    newbranch = selected.relatedBranchName
    if (oldbranch !== newbranch) {
      const { selected } = this.state
      selected.relatedDepartmentName = ''
      selected.relatedDepartmentId = ''

      this.setState({ selected, allRelatedDepartments: [] })
    }
    if (allRelatedBranches.length !== 0 && allRelatedBranches.find(o => o.id === parseInt(value))) {
      this.setState({
        allRelatedDepartments: allRelatedBranches.find(o => o.id === parseInt(value)).departments,
      })
    }
    const qs = this.convertJsonToQs(selected)

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: `?${qs}`,
    })
  }

  onSelectRelatedBranchV2 = value => {
    const sel = this.state.selected

    const branch = this.state.allRelatedBranches.find(o => o.name === value)

    sel.relatedDepartmentId = ''
    sel.relatedDepartmentName = ''
    sel.relatedBranchId = branch.id
    sel.relatedBranchName = branch.name
    this.setState({ selected: sel })

    this.getAllDepartments(true)

    const qs = this.convertJsonToQs(sel)

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: `?${qs}`,
    })
  }

  onSelectDepartment = value => {
    const sel = this.state.selected
    sel.departmentId = parseInt(value)
    sel.departmentName = this.state.allDepartments.find(o => o.id === parseInt(value)).name
    this.setState({ selected: sel })
    const qs = this.convertJsonToQs(sel)
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: `?${qs}`,
    })
  }

  onSelectRelatedDepartment = value => {
    const { selected } = this.state
    selected.relatedDepartmentId = parseInt(value)
    selected.relatedDepartmentName = this.state.allRelatedDepartments.find(
      o => o.id === parseInt(value)
    ).name
    this.setState({ selected })
    const qs = this.convertJsonToQs(selected)
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: `?${qs}`,
    })
  }
  onSelectConsignor = value => {
    const sel = this.state.selected
    sel.consignorId = value
    sel.consignorName = this.state.consignors.find(o => o.cbdt.c === parseInt(value)).name
    this.setState({ selected: sel })

    const qs = this.convertJsonToQs(sel)

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: `?${qs}`,
    })
  }

  resetAllFilter = () => {
    const sel = this.state.selected

    sel.companyId = ''
    sel.companyName = ''
    sel.companyType = ''
    sel.combinedName = ''
    sel.consignorId = ''
    sel.consignorName = ''
    sel.departmentId = ''
    sel.departmentName = ''
    sel.branchId = ''
    sel.branchName = ''
    sel.uID = ''
    sel.gID = ''
    sel.relatedCompanyId = ''
    sel.relatedBranchId = ''
    sel.relatedDepartmentId = ''
    sel.relatedCompanyName = ''
    sel.relatedCombinedName = ''
    sel.relatedBranchName = ''
    sel.relatedDepartmentName = ''
    this.setState({
      selected: sel,
      allCompany: [],
      allBranches: [],
      allDepartments: [],
      allRelatedCompany: [],
      allRelatedBranches: [],
      allRelatedDepartments: [],
    })
    this.props.history.push({
      pathname: this.props.location.pathname,
    })
  }

  handleSearch = value => {
    if (value.length >= 3) {
      this.getAllCompany(value)
    } else {
      this.setState({
        allCompany: [],
        allDepartments: [],
        allBranches: [],
      })
    }
  }

  handleBranchSearch = value => {
    if (value.length >= 3) {
      this.getAllBranches(value)
    } else {
      this.setState({
        allDepartments: [],
        allBranches: [],
      })
    }
  }

  handleRelatedSearch = value => {
    if (value.length >= 3) {
      this.getAllCompany(value, true)
    } else {
      this.setState({
        allRelatedCompany: [],
        allRelatedDepartments: [],
        allRelatedBranches: [],
      })
    }
  }

  handleRelatedBranchSearch = value => {
    if (value.length >= 3) {
      this.getAllBranches(value, true)
    } else {
      this.setState({
        allRelatedDepartments: [],
        allRelatedBranches: [],
      })
    }
  }

  onChangeHandler = v => {
    if (v === '') {
      this.resetAllFilter()
    }

    const obj = this.state.allCompany.find(o => o.id === parseInt(v))
    if (obj) {
      const newS = this.state.selected
      newS.combinedName = obj.combinedName
      this.setState({ selected: newS })
    } else {
      this.setState({ selected: { combinedName: v } })
    }
  }

  resetBranchFilter = () => {
    const sel = this.state.selected
    sel.departmentId = ''
    sel.departmentName = ''
    sel.branchId = ''
    sel.branchName = ''
    this.setState({
      selected: sel,
      allBranches: [],
      allDepartments: [],
    })

    const qs = this.convertJsonToQs(sel)

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: `?${qs}`,
    })
  }

  onBranchChangeHandler = v => {
    if (v === '') {
      this.resetBranchFilter()
    } 
    const obj = this.state.allBranches.find(o => o.id === parseInt(v))
    const newS = this.state.selected
    if (obj) {
      newS.branchName = obj.branchName
      this.setState({ selected: newS })
    } else {
      this.setState({ selected: { ...newS, branchName: v } })
    }
    
  }

  onRelatedChangeHandler = v => {
    const obj = this.state.allRelatedCompany.find(o => o.id === parseInt(v))
    const { selected } = this.state
    if (obj) {
      selected.relatedCombinedName = obj.combinedName
      this.setState({ selected })
    } else {
      this.setState({ selected: { ...selected, relatedCombinedName: v } })
    }
  }

  onRelatedBranchChangeHandler = v => {
    const obj = this.state.allRelatedBranches.find(o => o.id === parseInt(v))
    const { selected } = this.state
    if (obj) {
      selected.relatedBranchName = obj.relatedBranchName
      this.setState({ selected })
    } else {
      this.setState({ selected: { ...selected, relatedBranchName: v } })
    }
  }

  handleUserChange = evt => {
    this.setState({ userID: evt.target.value })
    const sel = this.state.selected

    sel.uID = evt.target.value
    const qs = this.convertJsonToQs(sel)
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: `?${qs}`,
    })
  }
  handleGroupIDChange = evt => {
    this.setState({ groupID: evt.target.value })
    const sel = this.state.selected

    sel.gID = evt.target.value
    const qs = this.convertJsonToQs(sel)
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: `?${qs}`,
    })
  }

  render() {
    const {
      selected,
      disableFlag,
      allRelatedCompany,
      allRelatedBranches,
      allRelatedDepartments,
    } = this.state
    const companies =
      this.state.allCompany.length !== 0 &&
      this.state.allCompany.map(data => (
        <Option key={data.combinedName}>{data.combinedName}</Option>
      ))
    const branches =
      this.state.allBranches.length !== 0 &&
      this.state.allBranches.map(data => <Option key={data.name}>{data.name}</Option>)
    const relatedCompanies =
      allRelatedCompany.length !== 0 &&
      allRelatedCompany.map(data => <Option key={data.combinedName}>{data.combinedName}</Option>)
    const relatedBranches =
      allRelatedBranches.length !== 0 &&
      allRelatedBranches.map(data => <Option key={data.name}>{data.name}</Option>)
    return (
      <div>
        <Row
          span={20}
          className = {css`
          padding: 20px;
          background: #fff;
          border-bottom: 1px solid #e8e8e8;
          `}
          gutter={16}
        >
        <Col span={5}>
            <label className="subHeaderLabel">Company</label>
            <AutoComplete
              disabled={disableFlag}
              className ={css`
                width: 100%;
             `}
              size="large"
              dataSource={companies}
              onSelect={this.onSelectCompany}
              onSearch={_.debounce(this.handleSearch, 500)}
              onChange={this.onChangeHandler}
              placeholder="Search company"
              value={this.state.selected.combinedName}
            />
          </Col>
          <Col span={4}>
            <label className="subHeaderLabel">Branch</label>
            <AutoComplete
              disabled={disableFlag}
              className={css`
                width: 100%;
              `}
              size="large"
              dataSource={branches}
              onSelect={this.onSelectBranchV2}
              onSearch={_.debounce(this.handleBranchSearch, 500)}
              onChange={this.onBranchChangeHandler}
              placeholder="Search branch"
              value={this.state.selected.branchName}
            />
          </Col>      
          <Col span={4}>
            <label className="subHeaderLabel">Department</label>
            <Select
              disabled={disableFlag}
              className="dropdownwidth"
              placeholder="Select department"
              size="large"
              onSelect={this.onSelectDepartment}
              value={this.state.selected.departmentName}
            >
              {this.state.allDepartments &&
                this.state.allDepartments.length > 0 &&
                this.state.allDepartments.map((department, key) => (
                  <Option key={department.id} value={department.id}>
                    {department.name}
                  </Option>
                ))}
            </Select>
          </Col>
          <Col style={{ paddingTop: '27px' }} span={3}>
            <Button
              disabled={this.state.disableFlag}
              type="primary"
              size="large"
              onClick={this.resetAllFilter}
            >
              Reset Filter
            </Button>
          </Col>
          <Col className="alignright" span={8}>
            {this.props.innerhtml ? this.props.innerhtml() : ''}
            {this.props.children}
          </Col>
        </Row>
        {/* {this.state.selected.companyType === 'TRANSPORTER' && (
          <Row
            className={css`
                padding: 20px;
                background: #fff;
                border-bottom: 1px solid #e8e8e8;
            `}
          >
            <Col span={4}>
              <label className="subHeaderLabel">Consignor</label>
              <Select
                className="dropdownwidth"
                placeholder="Select a consignor"
                size="large"
                onSelect={this.onSelectConsignor}
                value={this.state.selected.consignorName}
              >
                {this.state.consignors.length > 0 &&
                  this.state.consignors
                    .filter(s => {
                      return s.cbdt.c !== undefined
                    })
                    .map(con => (
                      <Option key={con.cbdt.c} value={con.cbdt.c}>
                        {con.name}
                      </Option>
                    ))}
              </Select>
            </Col>
          </Row>
        )} */}
        {this.props.location.pathname === '/configuration-list' ||
        this.props.location.pathname === '/configuration-list/notification-config' ? (
          <>
            <Row
              className={css`
                padding: 20px;
                background: #fff;
                border-bottom: 1px solid #e8e8e8;
              `}
            >
              <Col style={{ marginLeft: '10px' }} span={4}>
                <label>User Id</label>
                <Input
                  value={selected.uID}
                  onChange={this.handleUserChange}
                  size="large"
                  type="number"
                />
              </Col>
              <Col style={{ marginLeft: '10px' }} span={4}>
                <label>Group Id</label>
                <Input
                  value={selected.gID}
                  onChange={this.handleGroupIDChange}
                  size="large"
                  type="number"
                />
              </Col>
            </Row>
            <Row
              className={css`
                margin-left: 20px;
              `}
            >
              <label className="subHeaderLabel">Related Party</label>
            </Row>
            <Row
              span={20}
              className={css`
                padding: 20px;
                background: #fff;
                border-bottom: 1px solid #e8e8e8;
              `}
              gutter={16}
            >
              <Col span={5}>
                <label className="subHeaderLabel">Company</label>
                <AutoComplete
                  disabled={disableFlag}
                  className={css`
                    width: 100%;
                  `}
                  size="large"
                  dataSource={relatedCompanies}
                  onSelect={this.onSelectRelatedCompany}
                  onSearch={_.debounce(this.handleRelatedSearch, 500)}
                  onChange={this.onRelatedChangeHandler}
                  placeholder="Search company"
                  value={selected.relatedCombinedName}
                />
              </Col>
              <Col span={4}>
                <label className="subHeaderLabel">Branch</label>
                <AutoComplete
                  disabled={disableFlag}
                  className={css`
                    width: 100%;
                  `}
                  size="large"
                  dataSource={relatedBranches}
                  onSelect={this.onSelectRelatedBranchV2}
                  onSearch={_.debounce(this.handleRelatedBranchSearch, 500)}
                  onChange={this.onRelatedBranchChangeHandler}
                  placeholder="Search branch"
                  value={selected.relatedBranchName}
                />
              </Col>
              <Col span={4}>
                <label className="subHeaderLabel">Department</label>
                <Select
                  disabled={disableFlag}
                  className="dropdownwidth"
                  placeholder="Select department"
                  size="large"
                  onSelect={this.onSelectRelatedDepartment}
                  value={selected.relatedDepartmentName}
                >
                  {allRelatedDepartments &&
                    allRelatedDepartments.length > 0 &&
                    allRelatedDepartments.map((department, key) => (
                      <Option key={department.id} value={department.id}>
                        {department.name}
                      </Option>
                    ))}
                </Select>
              </Col>
            </Row>
          </>
        ) : null}
      </div>
    )
  }
}
export default withRouter(EntityConfigHeader)
