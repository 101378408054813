import { Button, Row } from 'antd'
import React from 'react'
import SubHeader from 'utils/subHeader'
import 'containers/routesMaster/App.css'
import { withRouter } from 'react-router'

class KnownPlaces extends React.Component {
  showAddPlace = () => {
    this.props.history.push({
      pathname: '/known-places/add',
      search: this.props.location.search,
    })
  }

  showUploadPlace = () => {
    this.props.history.push({
      pathname: '/known-places/upload',
      search: this.props.location.search,
    })
  }

  cta = () => {
    return (
      <div>
        <Button size="large" type="primary" onClick={this.showAddPlace}>
          Add known place
        </Button>
        <Button
          style={{ marginLeft: 10 }}
          size="large"
          type="primary"
          onClick={this.showUploadPlace}
        >
          Upload
        </Button>
      </div>
    )
  }

  render() {
    return (
      <Row>
        <SubHeader innerhtml={this.cta} />
        {this.props.children}
      </Row>
    )
  }
}

export default withRouter(KnownPlaces)
