import React from 'react'
import { Row, Col, Cascader, Table, Button, Input, Icon, Form, message } from 'antd'
import _ from 'lodash'
import Subheader from 'utils/subHeader'
import request from 'utils/request'
import { css } from '@emotion/css'
import { convertQsToJson, getQueryParams } from 'utils/common'

const API_URL = window._env_.REACT_APP_API_URL
const convertVehicleTypes = data => {
  return _.chain(data)
    .groupBy('bodyType')
    .map((val, key) => ({
      value: key,
      label: key,
      children: _.chain(val)
        .groupBy('length')
        .map((val, key) => ({
          value: key,
          label: key,
          children: val.map((val, key) => ({
            value: `${val.weight.value} ${val.weight.unit}`,
            label: `${val.weight.value} ${val.weight.unit}`,
            id: val.id,
          })),
        }))
        .value(),
    }))
    .value()
}

class VehicleMaster extends React.Component {
  state = {
    vehicleList: [],
    userType: 'CONSIGNOR',
    allVehicles: [],
    ConvertedVehicles: [],
    companyId: '',
    branchId: '',
    departmentId: '',
  }

  fetchVehicleData = queryParams => {
    const parsedDt = convertQsToJson(queryParams)
    if (!_.isEmpty(parsedDt)) {
      const { companyId, branchId, departmentId } = parsedDt
      if (companyId) {
        const queryParam = {
          c: Number(companyId),
          b: Number(branchId) || '',
          d: Number(departmentId) || '',
          t: this.state.userType,
        }
        this.setState({
          companyId,
          branchId,
          departmentId,
        })
        request(`${API_URL}/saas/lego/company_vehicles/fetch?${getQueryParams(queryParam)}`).then(
          response => {
            if (response.success) {
              this.setState({
                vehicleList: response.data,
              })
            } else {
              response.message.map(msg => message.error(msg))
            }
          }
        )
      } else {
        message.info('Select a company to continue', 3)
      }
    }
  }

  componentDidMount() {
    this.fetchVehicleData(this.props.location.search)
    request(`${window._env_.REACT_APP_API_URL}/saas/lego/static_data/vehicles/fetch`)
      .then(res => {
        if (res.success) {
          const vData = convertVehicleTypes(res.data)
          this.setState({ ConvertedVehicles: vData, allVehicles: res.data })
        } else {
          res.message.map(msg => message.error(msg))
        }
      })
      .catch(() => message.error('Something went wrong. Please try again', 2))
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.fetchVehicleData(this.props.location.search)
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search === '') {
      this.setState({ vehicleList: [], allVehicles: [], companyId: '' })
    } else {
      this.fetchVehicleData(this.props.location.search)
    }
  }

  handleDeleteVehicle = recordId => {
    const { companyId, branchId, departmentId, userType, vehicleList } = this.state
    const queryParam = {
      c: companyId,
      b: branchId || '',
      d: departmentId || '',
      t: userType,
      id: recordId,
    }
    request(`${API_URL}/saas/lego/company_vehicles/delete?${getQueryParams(queryParam)}`, {
      method: 'DELETE',
    })
      .then(res => {
        if (res.success) {
          message.success('Deleted Vehicle record successfully', 2)
          const filteredVehicles = vehicleList.filter(vehicle => vehicle.id !== recordId)
          this.setState({
            vehicleList: filteredVehicles,
          })
        } else {
          res.message.map(msg => message.error(msg))
        }
      })
      .catch(() => message.error('Something went wrong. Please try again', 2))
  }

  handleVehicleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields(['vehicle'], (err, values) => {
      if (!err) {
        this.submitVehicleDt(values.vehicle, 'normal')
      }
    })
  }

  handleCustomVehicleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields(['customVehicle'], (err, values) => {
      if (!err) {
        this.submitVehicleDt(values.customVehicle, 'custom')
      }
    })
  }

  submitVehicleDt = (vehicleInfo, type) => {
    debugger
    let formDt = {}
    let vehicleLabel
    if (type === 'normal') {
      const { allVehicles } = this.state
      const weight = vehicleInfo && vehicleInfo[2]
      const vehicleDt = allVehicles.filter(
        vehicle =>
          vehicle.bodyType === vehicleInfo[0] &&
          vehicle.length === vehicleInfo[1] &&
          vehicle.weight.value + ' ' + vehicle.weight.unit === weight
      )
      vehicleLabel = `${vehicleInfo[0]} - ${vehicleInfo[1]} - ${weight}`
      formDt.ftVehicleId = vehicleDt[0].id
    } else {
      vehicleLabel = vehicleInfo
      formDt.label = vehicleLabel
    }

    const { companyId, branchId, departmentId, userType, vehicleList } = this.state
    const queryParam = {
      c: companyId,
      b: branchId || '',
      d: departmentId || '',
      t: userType,
    }

    request(`${API_URL}/saas/lego/company_vehicles/create?${getQueryParams(queryParam)}`, {
      method: 'post',
      body: JSON.stringify(formDt),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.success) {
          this.setState({
            vehicleList: [
              ...vehicleList,
              {
                id: res.data.vehicleId,
                label: vehicleLabel,
              },
            ],
          })
          this.props.form.resetFields()
          message.info('Vehicle created succesfully')
        } else {
          res.message.map(msg => message.error(msg))
        }
      })
      .catch(() => {
        message.error('Something went wrong. Please try again', 2)
      })
  }

  render() {
    const { vehicleList, ConvertedVehicles, companyId } = this.state
    const { getFieldDecorator } = this.props.form
    const columns = [
      {
        dataIndex: 'sl',
        title: 'Sl No.',
        key: 'index',
        render: (text, record, index) => index + 1,
      },
      {
        dataIndex: 'label',
        title: 'Client Vehicle List',
      },
      {
        dataIndex: 'cross',
        title: '',
        render: (text, record) => (
          <div>
            <Icon
              className={css`
                cursor: pointer;
              `}
              type="close-circle"
              theme="filled"
              onClick={() => this.handleDeleteVehicle(record.id)}
            />
          </div>
        ),
      },
    ]

    return (
      <div>
        <Subheader />
        <Row
          className={css`
            padding: 20px;
          `}
          gutter={16}
        >
          <Col span={12}>
            <Form onSubmit={this.handleVehicleSubmit}>
              <Row gutter={16}>
                <Col span={20}>
                  <Form.Item label="Vehicle Type">
                    {getFieldDecorator('vehicle', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter vehicle type',
                        },
                      ],
                    })(
                      <Cascader
                        size="large"
                        className={css`width:100%`}
                        placeholder="Type of Vehicle"
                        options={ConvertedVehicles}
                        disabled={!companyId}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Form.Item>
                  <Col span={4}>
                    <Button
                      className={css`
                        margin-top: 37px;
                        margin-left: 10px;
                      `}
                      shape="circle"
                      size="large"
                      type="primary"
                      icon="plus"
                      disabled={!companyId}
                      htmlType="submit"
                    />
                  </Col>
                </Form.Item>
              </Row>
            </Form>
            <Row gutter={16} className={css`padding:20px 2px`}>
              <Col span={12}>
                <Form onSubmit={this.handleCustomVehicleSubmit}>
                  <Row gutter={16}>
                    <Col span={16}>
                      <Form.Item label="Custom Vehicle Type">
                        {getFieldDecorator('customVehicle', {
                          rules: [
                            {
                              required: true,
                              message: 'Please enter custom vehicle ',
                            },
                          ],
                        })(
                          <Input
                            size="large"
                            placeholder="Enter the vehicle type"
                            disabled={!companyId}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item>
                        <Button
                          className={css`
                            margin-top: 37px;
                          `}
                          shape="circle"
                          size="large"
                          type="primary"
                          icon="plus"
                          htmlType="submit"
                          disabled={!companyId}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Table
              bordered
              columns={columns}
              dataSource={vehicleList}
              rowKey={record => record.id}
            />
          </Col>
        </Row>
      </div>
    )
  }
}
const addVehicleSelectFromCascader = Form.create({ name: 'cascader' })(VehicleMaster)
const addCustomVehicleCustom = Form.create({ name: 'custom_vehicle' })(VehicleMaster)
export default (VehicleMaster, addVehicleSelectFromCascader, addCustomVehicleCustom)
