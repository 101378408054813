import React, { PureComponent } from 'react'
import { Helmet } from 'react-helmet'

class Login extends PureComponent {
  
  render() {
    return (
      <div>
        <Helmet>
          <script type="text/javascript">
            {(function() {
              var randomh = Math.random()
              var e = document.getElementsByTagName('script')[0]
              var d = document.createElement('script')
              d.src = 'https://widgets.freighttiger.com/login.js?v=' + randomh + ''
              d.type = 'text/javascript'
              d.async = true
              e.parentNode.insertBefore(d, e)
            })()}
          </script>
        </Helmet>
        <div
          data-widget="ft-auth"
          data-prop-color={'#F6BF43'}
          data-prop-mobile={'022 - 40264036'}
          data-prop-email={'support@freighttiger.com'}
          data-prop-phoenix_url={window._env_.REACT_APP_TRANSDB_API_URL}
          data-prop-auth_url={window._env_.REACT_APP_AUTH_URL}
          data-prop-cbdd_url={window._env_.REACT_APP_ENTITY_SERVICE_URL}
          data-prop-user_url={window._env_.REACT_APP_USER_URL}
          data-prop-return-url={'/'}
          data-prop-with-o-t-p={'true'}
          data-prop-use-whoami={'false'}
          data-prop-use-desks={'true'}
        />
      </div>
    )
  }
}

export default Login
