import React, { Component, createRef } from 'react'
import Geosuggest from 'react-geosuggest'
import { Row, Col, Button, Input, Form, message, Divider } from 'antd'
import _ from 'lodash'
import qs from 'query-string'
import { convertQsToJson, constructUrl } from 'utils/common'
import request from 'utils/request'
import './geosuggest.css'
import 'containers/routesMaster/App.css'
import { css } from '@emotion/css'


const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
}

class AddRoutes extends Component {
  constructor(props) {
    super(props)
    this.loadingPoint = createRef()
    this.unloadingPoint = createRef()
  }
  state = {
    edit: false,
    knownPlaces: {
      loadingPoint: [],
      unloadingPoint: [],
    },
    routeData: {},
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ edit: true }, () => {
        this.fetchRouteData(this.props.match.params.id)
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    const parsedDt = convertQsToJson(nextProps.location.search)
    const cId = parsedDt.companyId || ''
    const bId = parsedDt.branchId || ''
    const dId = parsedDt.departmentId || ''
    const url = `${
      window._env_.REACT_APP_API_URL
    }/saas/lego/company_routes/create_or_update?c=${cId}&b=${bId}&d=${dId}&t=CONSIGNOR`
    this.setState({ url: url })
  }

  createLabel = (area, label, address) => {
    const arr = [area, label, address]
    let dirtyObj = {}
    let text = ''
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] && !dirtyObj[arr[i]]) {
        text = text ? text + ' - ' + arr[i] : arr[i]
        dirtyObj[arr[i]] = 1
      }
    }
    return text
  }

  enrichKnownPlaces = knownPlaces => {
    const uniqueKp = {}
    const places = knownPlaces
      ? knownPlaces.filter(fix => {
          const kpLabel = this.createLabel(fix.cpCode, fix.label, fix.address)
          fix.className = 'geosuggest__fixture'
          fix.key = fix.cpId
          fix.label = kpLabel
          if (uniqueKp[kpLabel]) return
          uniqueKp[kpLabel] = fix.cpId
          return fix
        })
      : []
    return places
  }

  getKnownPlaces = (searchText, type) => {
    if (searchText.length >= 3 && this.props.location.search) {
      const parsedDt = convertQsToJson(this.props.location.search)
      const { companyId: c, companyType: t } = parsedDt
      const params = {
        q: searchText,
        c,
        t,
        children: true,
      }
      const url = `${window._env_.REACT_APP_API_URL}/saas/lego/company_places/search?${qs.stringify(
        params
      )}`
      request(url).then(result => {
        if (result.success) {
          const { knownPlaces } = this.state
          knownPlaces[type] = this.enrichKnownPlaces(result.data)
          this.setState({ knownPlaces }, () => {
            if (!this[type].current.state.isSuggestsHidden) {
              this[type].current.onAfterInputChange()
            }
          })
        }
      })
    }
  }

  fetchRouteData(id) {
    const url = constructUrl('/saas/lego/company_routes/fetch', this.props.location.search, {
      id: id,
    })
    request(url).then(
      result => {
        if (result.success) {
          const data = result.data[0]
          this.props.form.setFieldsValue({
            id: data.id,
            code: data.code,
            label: data.label,
            stt: data.stt,
            "origin['loadingGoogleAddress']": data.origin.address,
            "origin['google']['place_id']": data.origin.google.place_id,
            // "origin['cpCode']": data.origin.cpCode,
            // "origin['label']": data.origin.label,
            // "origin['address']": data.origin.address,
            // "origin['geofenceRadius']": data.origin.geofenceRadius,
            "destination['unloadingGoogleAddress']": data.destination.address,
            "destination['google']['place_id']": data.destination.google.place_id,
            // "destination['cpCode']": data.destination.cpCode,
            // "destination['label']": data.destination.label,
            // "destination['address']": data.destination.address,
            // "destination['geofenceRadius']": data.destination.geofenceRadius
          })
          this.setState({ routeData: data })
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        message.error('Error while fetching routes data')
      }
    )
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      delete values['origin']['loadingGoogleAddress']
      delete values['destination']['unloadingGoogleAddress']
      if (!err) {
        this.addRoutes(values)
      }
    })
  }

  convertQsToJson(str) {
    let pairs = str.slice(1).split('&')

    let result = {}
    pairs.forEach(function(pair) {
      pair = pair.split('=')
      result[pair[0]] = decodeURIComponent(pair[1] || '')
    })

    return JSON.parse(JSON.stringify(result))
  }

  addRoutes = data => {
    if (this.props.location.search === '') {
      return message.error('Select a company to continue')
    }
    const addRoutesData = {
      ...data,
      stt: data.stt,
    }
    request(this.state.url, {
      method: 'POST',
      body: JSON.stringify(addRoutesData),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(
      result => {
        if (result.success) {
          if (this.state.edit) {
            message.success('Routes updated successfully !')
          } else {
            message.success('Routes added successfully !')
          }

          this.props.history.push({
            pathname: '/routes',
            search: this.props.location.search,
          })
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        message.error('Error while adding')
      }
    )
  }

  handleLocationChange = (dt, type) => {
    if (dt && dt.gmaps && dt.gmaps.place_id) {
      if (type === 'loadingPoint') {
        this.props.form.setFieldsValue({
          "origin['google']['place_id']": dt.gmaps.place_id,
        })
      } else {
        this.props.form.setFieldsValue({
          "destination['google']['place_id']": dt.gmaps.place_id,
        })
      }
    }
  }

  validateToWhitespace = (rule, value, callback) => {
    if (value && value.trim() === '') {
      callback('Blank spaces not allowed')
    } else {
      callback()
    }
  }

  validateNumber = (rule, value, callback) => {
    if (parseInt(value) <= 0) {
      callback('Please enter a number greater than 0')
    } else {
      callback()
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const {
      knownPlaces: { loadingPoint, unloadingPoint },
      routeData,
    } = this.state
    return (
      <Row
        gutter={16}
        className={css`
          margin-top: 30px;
        `}
      >
        <Col span={16} offset={4}>
          {this.state.edit ? (
            <h2 style={{ textAlign: 'center', marginBottom: '40px' }}>Update Route</h2>
          ) : (
            <h2 style={{ textAlign: 'center', marginBottom: '40px' }}>Add Route</h2>
          )}
          <Divider />
          <Form onSubmit={this.handleSubmit}>
            <Form.Item style={{ marginBottom: 0 }}>
              {getFieldDecorator('id')(<Input type="hidden" />)}
            </Form.Item>
            <Form.Item label="Route Code" {...formItemLayout}>
              {getFieldDecorator('code', {
                rules: [{ required: false, message: 'Please enter route code' }],
              })(<Input size="large" placeholder="Unique for the CBD Level" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="Route Label">
              {getFieldDecorator('label', {
                rules: [
                  { required: true, message: 'Please enter route label' },
                  {
                    validator: this.validateToWhitespace,
                  },
                ],
              })(<Input size="large" placeholder="Enter Route label" />)}
            </Form.Item>
            <Form.Item label="STT (in Hour)" {...formItemLayout}>
              {getFieldDecorator('stt', {
                rules: [
                  { required: false, message: 'Please enter stt' },
                  { validator: this.validateNumber },
                ],
              })(<Input type="number" size="large" placeholder="Standard Transit Time" />)}
            </Form.Item>
            <Divider />
            <h3>Loading point</h3>
            <Form.Item {...formItemLayout} label="Loading Point google address">
              {getFieldDecorator("origin['loadingGoogleAddress']", {
                rules: [
                  { required: true, message: 'Please enter loading point' },
                  {
                    validator: this.validateToWhitespace,
                  },
                ],
              })(
                <Geosuggest
                  ref={this.loadingPoint}
                  inputClassName="ant-input routeInput"
                  suggestsClassName="ant-select-dropdown ant-select-dropdown-menu"
                  suggestItemClassName="ant-select-dropdown-menu-item"
                  suggestItemActiveClassName="ant-select-dropdown-menu-item-active"
                  placeholder="Search locations!"
                  initialValue={routeData && routeData.origin && routeData.origin.address}
                  fixtures={loadingPoint}
                  onSuggestSelect={val => this.handleLocationChange(val, 'loadingPoint')}
                  onChange={value => this.getKnownPlaces(value, 'loadingPoint')}
                  country="in"
                  highlightMatch={false}
                  autoComplete={'off'}
                  maxFixtures={10}
                />
              )}
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              {getFieldDecorator("origin['google']['place_id']")(<Input type="hidden" />)}
            </Form.Item>
            <Form.Item label="Place code" {...formItemLayout}>
              {getFieldDecorator("origin['cpCode']")(
                <Input size="large" placeholder="Place code" />
              )}
            </Form.Item>
            <Form.Item label="Address label" {...formItemLayout}>
              {getFieldDecorator("origin['label']")(
                <Input size="large" placeholder="Address label" />
              )}
            </Form.Item>
            <Form.Item label="Address" {...formItemLayout}>
              {getFieldDecorator("origin['address']")(<Input size="large" placeholder="Address" />)}
            </Form.Item>
            <Form.Item label="Geofence Radius" {...formItemLayout}>
              {getFieldDecorator("origin['geofenceRadius']", {
                initialValue: 2,
                rules: [
                  {
                    message: 'Number should be between 0.01 to 50',
                    pattern: /\b(0?[1-9]|[1-4][0-9]|5[0])\b/g,
                  },
                ],
              })(<Input size="large" placeholder="Enter Geofence Radius in km" />)}
            </Form.Item>
            <Divider />
            <h3>Unloading point</h3>
            <Form.Item {...formItemLayout} label="Unloading Point google address">
              {getFieldDecorator("destination['unloadingGoogleAddress']", {
                rules: [
                  { required: true, message: 'Please enter unloading point' },
                  {
                    validator: this.validateToWhitespace,
                  },
                ],
              })(
                <Geosuggest
                  ref={this.unloadingPoint}
                  inputClassName="ant-input routeInput"
                  suggestsClassName="ant-select-dropdown ant-select-dropdown-menu"
                  suggestItemClassName="ant-select-dropdown-menu-item"
                  suggestItemActiveClassName="ant-select-dropdown-menu-item-active"
                  placeholder="Search locations!"
                  style={{ height: '40px' }}
                  initialValue={routeData && routeData.destination && routeData.destination.address}
                  fixtures={unloadingPoint}
                  onSuggestSelect={val => this.handleLocationChange(val, 'unloadingPoint')}
                  onChange={val => this.getKnownPlaces(val, 'unloadingPoint')}
                  country="in"
                  highlightMatch={false}
                  autoComplete={'off'}
                  maxFixtures={10}
                />
              )}
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              {getFieldDecorator("destination['google']['place_id']")(<Input type="hidden" />)}
            </Form.Item>
            <Form.Item label="Place code" {...formItemLayout}>
              {getFieldDecorator("destination['cpCode']")(
                <Input size="large" placeholder="Place code" />
              )}
            </Form.Item>
            <Form.Item label="Address label" {...formItemLayout}>
              {getFieldDecorator("destination['label']")(
                <Input size="large" placeholder="Address label" />
              )}
            </Form.Item>
            <Form.Item label="Address" {...formItemLayout}>
              {getFieldDecorator("destination['address']")(
                <Input size="large" placeholder="Address" />
              )}
            </Form.Item>
            <Form.Item label="Geofence Radius" {...formItemLayout}>
              {getFieldDecorator("destination['geofenceRadius']", {
                initialValue: 2,
                rules: [
                  {
                    message: 'Number should be between 0.01 to 50',
                    pattern: /\b(0?[1-9]|[1-4][0-9]|5[0])\b/g,
                  },
                ],
              })(<Input size="large" placeholder="Enter Geofence Radius in km" />)}
            </Form.Item>
            <Form.Item
              className={css`
                text-align: center;
              `}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    )
  }
}
const AddConsigneeForm = Form.create({ name: 'dynamic_rule' })(AddRoutes)
export default (AddRoutes, AddConsigneeForm)
