import React, { Component } from 'react';
import { InputNumber, Form, message, Button, Row, Col, Input } from 'antd';
import { css } from '@emotion/css';
import { convertQsToJson } from 'utils/common';
import fetchData from 'utils/fetchData';

const TRIP_SERVICE_URL = window._env_.REACT_APP_ALERT_CONFIG_API_URL;
const SATELLIZER_TOKEN = localStorage.getItem('satellizer_token');

class ETAPreferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: '',
      minDistance: undefined,
      avgSpeed: undefined,
    };
  }

  componentDidMount() {
    this.handleQueryParams();
  }

  componentDidUpdate(prevProps) {
    const { search } = this.props.location;
        const prevSearch = prevProps.location.search;
        if (search !== prevSearch) {
          const parsedDt = convertQsToJson(search);
          if (parsedDt.companyId) {
            this.setState({ companyId: parsedDt.companyId }, () => {
                this.fetchEtaPreferencesData();
            });
          } else {
            this.props.form.resetFields();
            this.setState({
                companyId: '',
                minDistance: undefined,
                avgSpeed: undefined
            });
          }
        } 
  }

  handleQueryParams = () => {
    const { search } = this.props.location;
    const parsedDt = convertQsToJson(search);

    if (!parsedDt.companyId) {
      this.setState({ companyId: '' });
      message.info('Select a company to continue');
    } else {
      this.setState({ companyId: parsedDt.companyId }, this.fetchEtaPreferencesData);
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.companyId === '') {
      message.info('Select a company to save');
    } else {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          this.saveEtaPreferencesConfig(values);
        }
      });
    }
  };

  saveEtaPreferencesConfig = (data) => {
    const { companyId } = this.state;

    if (data && (data.minDistance || data.avgSpeed)) {
      const payload = {
        companyId,
        engineSetting: {
          minDistance: data.minDistance,
          avgSpeed: data.avgSpeed,
        },
      };

      const url = `${TRIP_SERVICE_URL}/v1/lego/eta/preferences/add`;

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${SATELLIZER_TOKEN}`,
        },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response && response.success) {
            message.success('Saved Successfully');
            this.fetchEtaPreferencesData();
          } else {
            message.error('Something went wrong...');
          }
        })
        .catch((error) => {
          message.error('Something went wrong');
        });
    }
  };

  fetchEtaPreferencesData = () => {
    const { companyId } = this.state;

    if (companyId) {
      const queryParams = `companyId=${companyId}`;
      const url = `${TRIP_SERVICE_URL}/v1/lego/eta/preferences/get?${queryParams}`;
      const headers = {
        Authorization: `Bearer ${SATELLIZER_TOKEN}`,
      };

      fetchData(url, 'GET', {}, headers)
        .then((res) => {
          if (res && res.data && res.data.length > 0) {
            const engineSetting = JSON.parse(res.data[0].engineSetting);

            if (engineSetting.engines && engineSetting.engines.length > 0) {
              const speedBasedTransformer = engineSetting.engines.find((e) => e.id === 'SpeedBasedTransformer');

              if (speedBasedTransformer) {
                this.setState({
                  minDistance: speedBasedTransformer.param.minDistance,
                  avgSpeed: speedBasedTransformer.param.avgSpeed,
                });
              }
            }
          } else {
            this.setState({
              minDistance: undefined,
              avgSpeed: undefined,
            });
          }
        })
        .catch(() => {
          this.setState({
            minDistance: undefined,
            avgSpeed: undefined,
          });
        });
    }
  };

  handleChange = (value, type) => {
    if (type === 'distance') {
      this.setState({ minDistance: value });
    }
    if (type === 'speed') {
      this.setState({ avgSpeed: value });
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {minDistance, avgSpeed} = this.state

    return (
      <div>
        <Row
          span={20}
          className={css`
            padding: 20px;
            background: #fff;
            border-bottom: 1px solid #e8e8e8;
          `}
          gutter={16}
        >
          <Form onSubmit={this.handleSubmit}>
            <Row style={{ padding: '10px' }}>
              <Col span={6}>
                <label>
                  <h3>ETA Engine Preferences Setting</h3>
                </label>
              </Col>
            </Row>
            <Row style={{ padding: '10px' }}>
              <Col span={3}>
                <label>
                  <span style={{ color: 'red' }}>*</span>Minimum distance:
                </label>
              </Col>
              <Col span={2}>
                <Form.Item>
                  {getFieldDecorator('minDistance', {
                    rules: [
                      { required: true, message: 'This field is required' },
                      {
                        pattern: /^(?:2[0-9]|3[0-9]{2}|[2-9]\d|1\d{2}|400)$/,
                        message: 'Please enter a valid number between 20 to 400',
                      },
                    ],
                    initialValue: minDistance,
                  })(<InputNumber size="large" min={1} onChange={(e) => this.handleChange(e, 'distance')} />)}
                </Form.Item>
              </Col>
              <Col span={1}></Col>
              <Col span={3}>
                <label>
                  <span style={{ color: 'red' }}>*</span>Average Speed:
                </label>
              </Col>
              <Col span={2}>
                <Form.Item>
                  {getFieldDecorator('avgSpeed', {
                    rules: [
                      { required: true, message: 'This field is required' },
                      {
                        pattern: /^(?:[3-9]|[1-5]\d|60)(\.\d+)?$/,
                        message: 'Please enter a valid number between 3 and 60',
                      },
                    ],
                    initialValue: avgSpeed,
                  })(<InputNumber size="large" min={1} onChange={(e) => this.handleChange(e, 'speed')} />)}
                </Form.Item>
              </Col>
              <Col span={2}></Col>
              <Col span={3}>
                <Button type="primary" size="large" htmlType="submit">
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </Row>
      </div>
    );
  }
}

const ETA_Preferences_Form = Form.create({ name: 'ETA_Preferences_Form' })(ETAPreferences);
export default ETA_Preferences_Form;
