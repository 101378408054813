import { Button, Row,message } from 'antd'
import React from 'react'
import SubHeader from 'utils/subHeader'
import 'containers/routesMaster/App.css'
import { withRouter } from 'react-router'

class SidebarMenu extends React.Component {
  
  addSideMenu = () => {
    const { search } = this.props.location;
    if(search){
      this.props.history.push({
        pathname: '/sidebar-menu/add',
        search: search,
      })
     } else{
        message.error('Select a company to set side bar menu override')
     }
  }


  setExceptionReport = () => {
    return (
      <div>
        <Button size="large" type="primary" onClick={this.addSideMenu}>
          Set SideMenuBar 
        </Button>
      </div>
    )
  }

  render() {
    return (
      <Row>
        <SubHeader innerhtml={this.setExceptionReport} />
        {this.props.children}
      </Row>
    )
  }
}

export default withRouter(SidebarMenu)
