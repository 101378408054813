import React from 'react'
import { Row, Button, Table, Checkbox, Modal, message } from 'antd'
import { convertQsToJson } from 'utils/common'
import fetchData from 'utils/fetchData'
import { getCbdt } from 'components/dailySummaryReportSputnik/getCbdt';
const SPUTLINK_API = window._env_.REACT_APP_SPUTNIK_API_URL
class DailySummarySputnikList extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      allConfiguration: [],
      checked: false,
      visible: false,
      modeToggle: '',
      customerConfigId: '',
      cbdt:null,
    }
  }

   componentDidMount() {
    const { search } = this.props.location;
    const parsedDt = convertQsToJson(search)
    if (!parsedDt.companyId) {
      message.info('Select a company to continue')
    }else{
      this.getDailySummaryList(search);
    }
  }

  componentDidUpdate(prevProps){
    const { search } = this.props.location;
    if (search !== prevProps.location.search){
      this.getDailySummaryList(search);
    }
  }

  getDailySummaryList = async() =>{
      const { search } = this.props.location;
      const cbdt  = await getCbdt(search)
      this.setState({
         cbdt: cbdt
      })
      if(cbdt){
       this.fetchAllconfigurations(search)
      }
  }

  handleOnChange = data => {
    this.setState({
      visible: true,
      modeToggle: data.active,
      customerConfigId: data.id,
    })
  }

  handleCancel = () => {
    this.setState({ visible: false })
  }
  
  handleNotification = () => {
    const { customerConfigId,modeToggle} = this.state;
    const active = modeToggle === 1 ? 0 : 1;
    let url =`${SPUTLINK_API}/saas/v1/rs/update/${customerConfigId}`
    let payload = { 'active':active }
    fetchData(url,'PUT',payload).then(res => {
        if (res.success === true) {
          this.setState({ visible: false })
          this.getDailySummaryList(this.props.location.search);
        }
    }).catch(err => {
      message.error('Error while updating report')
  });
  }

  gotoEditConfigForm = data => {
    let qs = this.props.location.search;
    if (qs.length > 0) {
      this.props.history.push({
        pathname: `/sputnik-daily-summary/edit/${data.id}`,
        search: qs,
      })
    }
  }
  
  
  fetchAllconfigurations(){
    let url = `${SPUTLINK_API}/saas/v1/rs/list?`
    let payload= {
      entity_id:this.state.cbdt,
      type:'DAILY_TRIP_SUMMARY'
    }
    fetchData(url,'GET',payload).then(result => {
          if (result.success) {
            this.setState({ allConfiguration: result.data })
          } else {
            result.message.map(msg => message.error(msg))
          }
      }
      ).catch(err => 
        message.error('Error while fetching report')
      )
  }


  render() { 
    const { allConfiguration,visible,modeToggle } =this.state;
    const columns = [
      {
        title: 'Event Id',
        key: 'id',
        dataIndex: 'id',
      },
      {
        title: 'Event Name',
        key: 'type',
        dataIndex: 'type',
      },
      {
        title: 'Enable Notificaton',
        key: 'isEnabled',
        render: (text, record, i) => {
          return (
            <Checkbox checked={record.active} onChange={() => this.handleOnChange(record)} />
          )
        },
      }, {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
            <span>
              <Button disabled={!record.active}  onClick={() => this.gotoEditConfigForm(record)}>Edit</Button>
            </span>
        ),
      }
    ];

    return (
      <div>
        <Row style={{ padding: '10px 10px 10px 22px' }} />
        <Row>
          <Table
            columns={columns}
            dataSource={allConfiguration}
            rowKey={record => record.customerConfigId}
          />
        </Row>
        <Modal
          width={450}
          height={450}
          footer={null}
          title="Enable Configuration"
          visible={ visible }
          onCancel={this.handleCancel}
        >
          <p>
            Are you sure want to{' '}
            <span>{modeToggle ?'disable':'enable' } notification </span> 
          </p>
          <div style={{ textAlign: 'right' }}>
            <Button onClick={() => this.handleNotification()}>Yes</Button>
            <Button onClick={this.handleCancel}>No</Button>
          </div>
        </Modal>
      </div>
    )
  }
}
export default DailySummarySputnikList
