import { Form, Select, InputNumber, Button, Row, Col, message } from "antd";
import React from "react";
import "containers/routesMaster/App.css";
import { withRouter } from "react-router";
import { convertQsToJson } from "utils/common";
import fetchData from "utils/fetchData";
import { css } from "@emotion/css";
import ftRole from "components/sidebarmenu/ftrole";
import './sidebarmenu.scss';
const Option = Select.Option
const TRANSDB_API = window._env_.REACT_APP_TRANSDB_API_URL;
class SidebarMenuConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      customerConfigId: null,
      menulist:[],
      fetchCompleteList: [],
      roles: false,
      userId: false,
      landingPage:''
    };
  }
  
  componentDidMount() {
    const { search } = this.props.location;
    const parsedDt = convertQsToJson(search);
    if (!parsedDt.companyId) {
      message.info("Select a company to continue");
    } else {
      this.fetchMenuList();
      if (this.props.match.params.id) {
        this.setState(
          { edit: true, customerConfigId: this.props.match.params.id },
          () => {
            this.fetchSideBarMenuById(this.props.match.params.id, parsedDt);
            this.fetchLandingPage(parsedDt);
          }
        );
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { search } = this.props.location;
    const prevSearch = prevProps.location.search;
    if (search !== prevSearch) {
      const parsedDt = convertQsToJson(search);
      if (parsedDt.companyId) {
        this.fetchMenuList();
        this.fetchSideBarMenuById(this.props.match.params.id, parsedDt);
        this.fetchLandingPage(parsedDt);
      }
    }
  }

  handleMenuList = (slugs) => {
    let slugCount = slugs.length;
    if (slugCount && slugCount > 0) {
      let slug = slugs[slugCount - 1]
      let completeMenuList = this.state["fetchCompleteList"]
      let slugData = completeMenuList.find((data) => data.slug === slug)
      if (slugData && slugData.parent) {
        let parentSlugData = completeMenuList.find((data) => data.id === slugData.parent)
        if (parentSlugData) {
          const parentSlugIndex = slugs.indexOf(parentSlugData.slug)
          if (parentSlugIndex === -1) {
            slugs.push(parentSlugData.slug)
          }
        }
      }
    }
    this.setState({ menulist: slugs })
  }

  fetchMenuList = () => {
    let url = `${TRANSDB_API}saas/data/onBoardingAPI/fetchSideBarMenuList?`;
    let payload = {
      accessKey: process.env.REACT_APP_TRANSDB_KEY,
      companyId: 1,
      active: 1,
    };
    fetchData(url, "GET", payload)
      .then((res) => {
        if (res.result.status) {
          this.setState({
            fetchCompleteList: res.result.data,
          });
          return res.result.data;
        } else {
          message.error(res.result.message);
        }
      })
      .catch((error) => {
        message.error(error);
      });
  };

  saveSidebarMenu = (data) => {
    let url = `${TRANSDB_API}saas/data/onBoardingAPI/updateSideMenuList?`;
    const { search } = this.props.location;
    const { edit, customerConfigId, menulist } = this.state;
    const parsedDt = convertQsToJson(search);
    if (!parsedDt.companyId) {
      return message.error("Select a company to continue");
    }
    let payload = {
      accessKey: process.env.REACT_APP_TRANSDB_KEY,
      companyId: parsedDt.companyId || "",
      branchId: parsedDt.branchId || "",
      extracontext: menulist.map((menu) => menu),
      roles: data.roles ? data.roles : null,
      userId: data.userId ? data.userId : null,
      enabled: 1,
    };
    if(edit){
      payload.id = customerConfigId;
    }
    fetchData(url, "POST", payload)
      .then((res) => {
        if (res.status == "ok") {
          message.success(res.result.message);
        } else {
          message.error(res.result.message);
        }
        this.props.history.push({
          pathname: "/sidebar-menu",
          search: this.props.location.search,
        });
      })
      .catch((error) => {
        message.error(error);
      });
  };

  fetchSideBarMenuById = (id, parsedDt, value, text) => {
    const url = `${TRANSDB_API}saas/data/onBoardingAPI/fetchSideBarMenuConfigById?`;
    let payload = {
      id: id,
      accessKey: process.env.REACT_APP_TRANSDB_KEY,
      companyId: parsedDt.companyId || "",
      branchId: parsedDt.branchId || "",
    };
    if (text == "roles") {
      payload.roles = value ? value : "";
    } else {
      payload.userId = value ? value : "";
    }
    fetchData(url, "GET", payload)
      .then((res) => {
        if (res.status == "ok") {
          const menulist = res.result.data;
          this.setState({
            menulist: menulist,
          });
          this.props.form.setFieldsValue({
            menulist: menulist,
          });
          return res.result;
        }
      })
      .catch((error) => {
        message.error(error);
      });
  };

  fetchLandingPage = (parsedDt, value, text) => {
    const url = `${TRANSDB_API}saas/data/onBoardingAPI/fetchLandingPage?`;
    let payload = {
      accessKey: process.env.REACT_APP_TRANSDB_KEY,
      companyId: parsedDt.companyId || "",
      branchId: parsedDt.branchId || "",
    };
    if (text == "roles") {
      payload.roles = value ? value : "";
    } else {
      payload.userId = value ? value : "";
    }
    fetchData(url, "GET", payload)
      .then((res) => {
        if (res.status == "ok") {
          const landingPage = res.result;
          this.setState({
            landingPage,
          });
          this.props.form.setFieldsValue({
            landingPage
          });
        }
      })
      .catch((error) => {
        message.error(error);
      });
  };

  onSelectRoleUser = (value, text) => {
    const { search } = this.props.location;
    const parsedDt = convertQsToJson(search);
    if (text == "roles") {
      this.setState({ roles: false, userId: true });
    } else {
      this.setState({ roles: true, userId: false });
    }
    this.fetchSideBarMenuById(
      this.props.match.params.id,
      parsedDt,
      value,
      text
    );
    this.fetchLandingPage(parsedDt,value,text);
  };

  saveLandingPage = (data) => {
    let url = `${TRANSDB_API}saas/data/onBoardingAPI/updateLandingPage?`;
    const { search } = this.props.location;
    const parsedDt = convertQsToJson(search);
    if (!parsedDt.companyId) {
      return message.error("Select a company to continue");
    }
    let payload = {
      accessKey: process.env.REACT_APP_TRANSDB_KEY,
      companyId: parsedDt.companyId || "",
      branchId: parsedDt.branchId || "",
      roles: data.roles ? data.roles : null,
      userId: data.userId ? data.userId : null,
      landingPage: data.landingPage? data.landingPage: null,
      enabled: 1,
    };
    fetchData(url,"POST", payload)
      .then((res) => {
        if (res.status == "ok") {
          return res.result.message
        }
    })
    .catch((error) => {
        message.error(error);
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if(values.landingPage){
          this.saveLandingPage(values);
        }
        this.saveSidebarMenu(values)
      }
    });
  };

  getLandingPageMenuList = (list = []) => {
    if(list && list.length) {
      return list.filter(l => {
        const isNotVoid = l.url && l.url != 'javascript:void(0)'
        const isDeprecated = l.name.endsWith('(Deprecated)') || l.name.endsWith('(Depreciated)')

        return isNotVoid && !isDeprecated
      })
    }

    return []
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { edit, roles, userId, fetchCompleteList,menulist } = this.state;
    const { search } = this.props.location;
    const parsedDt = convertQsToJson(search);
    const landingPageMenuList = this.getLandingPageMenuList(fetchCompleteList)
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    return (
      <div className='sidebarForm' id='bharghav'>
           <Row > 
             <h2 className="headingMargin">
              {edit ? "Update Side Menu" : "Set Side Menu"}
            </h2>
            <Form {...formItemLayout} onSubmit={this.handleSubmit}>
            <Col span={12}>
            <Row > 
            {!parsedDt.branchId ? (
                <Form.Item label='Role Of User'>
                  {getFieldDecorator("roles", {
                    rules: [
                      {
                        required: false,
                        message: "Please select roles names",
                      },
                    ],
                  })(
                    <Select
                      onSelect={(e) => this.onSelectRoleUser(e, "roles")}
                      disabled={roles}
                    >
                      {ftRole.roles &&
                        ftRole.roles.map((e, key) => {
                          return (
                            <Option key={key} value={e.value}>
                              {e.label}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              ) : (
                ""
              )}
            </Row>  
            <Row> 
                {!parsedDt.branchId ? (
                  <Form.Item label='Set It On UserId'>
                    {getFieldDecorator("userId", {
                      rules: [
                        {
                          required: false,
                          type: "number",
                          message: "Please enter userId",
                        },
                      ],
                    })(
                      <InputNumber
                        style={{ width: "100%" }}
                        disabled={userId}
                        onChange={(e) => this.onSelectRoleUser(e, "userId")}
                      />
                    )}
                  </Form.Item>
                ) : (
                  ""
                )}
            </Row>  
            <Row>  
            <Form.Item label="Landing Page">
                    {getFieldDecorator('landingPage', {
                      rules: [
                        { 
                          required: false,
                          message: 'Please select landingPage' 
                        }
                      ],
                    })(
                      <Select> 
                        { landingPageMenuList && landingPageMenuList.map((data) => {
                          return (
                            <Option defaultValue="none"  key={data.name} value={data.url}>
                              {data.name}
                            </Option>
                          );
                          })
                        }
                      </Select>
                    )}
            </Form.Item>
            </Row>  
            </Col>  
            <Col span={12}>
            <Row >
              <Form.Item label='Menu List'>
                {getFieldDecorator("menulist", {
                  rules: [
                    {
                      required: true,
                      message: "Please select menulist",
                    },
                  ],
                })(
                  <Select mode='tags' tokenSeparators={[","]} onChange={this.handleMenuList}>
                    {fetchCompleteList &&
                      fetchCompleteList.map((data) => {
                        return (
                          <Option key={data.id} value={data.slug}>
                            {data.name}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Row>
            </Col>
            <Col span={24} offset={5}> 
            <Form.Item style={{ marginTop: "20px", textAlign: "center" }}>
                <Button type='primary' htmlType='submit'>
                  Submit Menu List
                </Button>
                <Button
                  className='cta'
                  onClick={() =>
                    this.props.history.push({
                      pathname: "/sidebar-menu",
                      search: search,
                    })
                  }
                >
                Back
              </Button>
            </Form.Item>
            </Col> 
            </Form>
            </Row> 
      </div>
    );
  }
}
const WrappedAddPlacesForm = Form.create({ name: "register" })(
  SidebarMenuConfig
);
export default withRouter(WrappedAddPlacesForm);
