import qs from 'query-string'
export default function fetchData(url,type,payload, customHeaders) {

  let headers;
  if(customHeaders){
    headers = {
      ...customHeaders
    }
  }
  else{
    headers = {
      'Content-Type': 'application/json',
      'token': localStorage.getItem('satellizer_token')
    }
  }
  const options = {
    method: type,
    headers: headers,
  }
  
  if (type === 'POST' || type === 'PUT') {
    options.body = JSON.stringify(payload)
  }
  
  if (type === 'GET') { 
    url+= qs.stringify(payload)
  }

  return fetch(url, { ...options })
    .then(res => res.json())
    .then(response => response)
    .catch(err => err)
}