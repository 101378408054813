import React from 'react'
import { Row, Button, Table, Checkbox, Modal, message } from 'antd'
import { convertQsToJson } from 'utils/common'
import fetchData from 'utils/fetchData'
const PHOENIX_API = window._env_.REACT_APP_TRANSDB_API_URL

class AlertTicketList extends React.Component {

  constructor(props) {
    super(props)
    
    this.state = {
      allConfiguration: [],
      checked: false,
      visible: false,
      modeToggle: '',
      customerConfigId: '',
      dtVar:{}
    }
  }

  componentDidMount() {
    const { search } = this.props.location;
    const parsedDt = convertQsToJson(search)
    if (!parsedDt.companyId) {
      message.info('Select a company to continue')
    }else{
      this.queryParams(parsedDt)
    }
  } 

  componentDidUpdate(prevProps){
    const { search } = this.props.location;
    const parsedDt = convertQsToJson(search)
    const  prevSearch = prevProps.location.search
    if (search !== prevSearch){
        this.queryParams(parsedDt)
    }
  }

  queryParams = (parsedDt) => {
    const dtVar = {
      cId : parsedDt.companyId || '',
      bId : parsedDt.branchId || '',
      dId : parsedDt.departmentId || ''
    }
    this.setState({ dtVar },() =>{
      this.getUIConfig();
     }
    )
  }

  handleOnChange = data => {
    this.setState({  
        visible: true,
        modeToggle: data.enabled,
        customerConfigId: data.id, 
      },()=>{})
  }

  handleCancel = () => {
    this.setState({ visible: false },() => { 
    })
  }
   
  handleNotification = () => {
    let url =`${PHOENIX_API}saas/data/onBoardingAPI/toggleUIConfig?customerConfigId=${this.state.customerConfigId}`
    fetchData(url,'POST').then(res => {
        if (res.result.status === true) {
          this.setState({ visible: false })
          this.getUIConfig();
        }
    }).catch(error => {
      message.error('Error while disabling dashboard')
    });
    this.props.history.push({
      pathname: '/display-alert-ticket',
      search: this.props.location.search,
    })    
  }
  
  goEditConfigForm = data => {
    let qs = this.props.location.search;
    if (qs.length > 0) {
      this.props.history.push({
        pathname: `/display-alert-ticket/edit/${data.id}`,
        search: qs,
      })
    }
  }
  
  getUIConfig(){
    const {cId,bId,dId}=this.state.dtVar;
    let url = `${PHOENIX_API}saas/data/onBoardingAPI/getAlertTicketUIConfig?companyId=${cId}&branchId=${
      bId}&departmentId=${dId}`;
    let payload= {
      companyId:cId,
      branchId:bId
    }
    fetchData(url,'GET',payload).then(res => {
         const {data ,success,message }= res.result;
          if(success == true){
            this.setState({ allConfiguration:data})
          }else{
            this.setState({ allConfiguration:[]});
            message.error(message);
          }
    }).catch(error => {
      message.error('Error while fetching data');
    });   
  }   

  render() {  
    const columns = [
      {
        title: 'Module Id',
        key: 'id',
        dataIndex: `id`,
      },
      {
        title: 'Module Type',
        key: 'featureKey',
        dataIndex: `featureKey`,
      },
      {
        title: 'Enable Module',
        key: 'enabled',
        render: (text, record, i) => {
          return (
            <Checkbox checked={record.enabled} onChange={() => this.handleOnChange(record)} />
          )
        },
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
            <span>
              <Button disabled={!record.enabled} onClick={() => this.goEditConfigForm(record)}>Edit</Button>
            </span>
        ),
      },
    ];
    const { allConfiguration,visible,modeToggle}= this.state;
    return (
      <div>
        <Row style={{ padding: '10px 10px 10px 22px' }} />
        <Row>
          <Table
            columns={columns}
            dataSource={allConfiguration}
            rowKey={record => record.customerConfigId}
          />
        </Row>
        <Modal
          width={450}
          height={450}
          footer={null}
          title="Enable Configuration"
          visible={ visible }
          onCancel={this.handleCancel}
        >
          <p>
            Are you sure want to{' '}
            { modeToggle ? <span>disable</span> : <span>enable</span>} UI Config ?
          </p>
          <div style={{ textAlign: 'right' }}>
            <Button onClick={() => this.handleNotification()}>Yes</Button>
            <Button onClick={this.handleCancel}>No</Button>
          </div>
        </Modal>
      </div>
    )
  }
}


export default AlertTicketList
