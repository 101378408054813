import { Form, Select, Button, Row, Col, message } from 'antd'
import React from 'react'
import 'containers/routesMaster/App.css'
import { withRouter } from 'react-router'
import { convertQsToJson } from 'utils/common'
import { getCbdt } from 'components/dailySummaryReportSputnik/getCbdt';
import fetchData from 'utils/fetchData';
import cron from 'components/dailySummaryReportSputnik/cron';
import '../ExceptionReport/exception.scss';
const Option = Select.Option
const SPUTLINK_API = window._env_.REACT_APP_SPUTNIK_API_URL;

class DailySummaryConfigSputnik extends React.Component {

        constructor(props) {
            super(props)
            this.state = {
                cbdt: null,
                edit: false,
                job_uuid: '',
                emailId: []
            }
        }

        componentDidMount() {
            const { search } = this.props.location;
            const parsedDt = convertQsToJson(search)
            if (!parsedDt.companyId) {
                message.info('Select a company to continue')
            } else {
                this.resolvedCbdt()
                if (this.props.match.params.id) {
                    this.setState({ edit: true }, () => {
                        this.fetchDailySputNikReport(this.props.match.params.id)
                    })
                }
            }
        }

        componentDidUpdate(prevProps) {
            const { search } = this.props.location;
            if (search !== prevProps.location.search) {
                const parsedDt = convertQsToJson(search)
                if (parsedDt.companyId) {
                    this.resolvedCbdt()
                }
            }
        }

        fetchDailySputNikReport = id => {
            const url = `${SPUTLINK_API}/saas/v1/rs/report/${id}`;
            fetchData(url, 'GET').then(
                response => {
                    const { data, success } = response;
                    if (success === true) {
                        const { entity_id, id, definition, job_request } = data;
                        const { job_uuid } = job_request;
                        this.setState({
                            setreportcbdt: entity_id,
                            customerConfigId: id,
                            job_uuid
                        })
                        const { email_recipients, schedule } = definition;
                        this.props.form.setFieldsValue({
                            emailId: email_recipients.split(','),
                            timings: schedule.cron
                        })
                    }
                }
            ).catch(error => {
                message.error('Error while fetching report')
            });
        }

        resolvedCbdt = async() => {
            const { search } = this.props.location;
            const cbdt = await getCbdt(search)
            this.setState({
                cbdt: cbdt
            })
        }

        addDailySummaryReport = data => {
            let url = `${SPUTLINK_API}/saas/v1/rs/definition/create`
            const { search } = this.props.location;
            const parsedDt = convertQsToJson(search);
            if (!parsedDt.companyId) {
                return message.error('Select a company to continue')
            }
            let formData = this.makeItemObject(parsedDt, data)
            delete formData.job_uuid;
            fetchData(url, 'POST', formData).then(
                result => {
                    if (result.success == true) {
                        message.success('Report set sucessfully')
                        this.props.history.push({
                            pathname: '/sputnik-daily-summary',
                            search: search,
                        });
                    } else {
                        message.error('There is something wrong')
                    }
                }
            ).catch(error => {
                message.error('Error while creating report')
            });
        }

        updateDailySummaryReport = data => {
            let url = `${SPUTLINK_API}/saas/v1/rs/update/${this.state.customerConfigId}`
            const { search } = this.props.location;
            const parsedDt = convertQsToJson(search)
            if (!parsedDt.companyId) {
                return message.error('Select a company to continue')
            }
            const formData = this.makeItemObject(parsedDt, data)
            fetchData(url, 'PUT', formData).then(
                    result => {
                        if (result.success == true) {
                            message.success('Report updated sucessfully')
                            this.props.history.push({
                                pathname: '/sputnik-daily-summary',
                                search: search,
                            });
                        } else {
                            result.message.map(msg => message.error(msg))
                        }
                    }
                )
                .catch(error => {
                    message.error('Error while updating report')
                });
        }

        makeItemObject = (parsedDt, data) => {
            const { cbdt, job_uuid } = this.state;
            let itemObject;
            if (parsedDt.branchId) {
                itemObject = {
                    "entity_id": cbdt,
                    "type": "DAILY_TRIP_SUMMARY",
                    "job_uuid": job_uuid,
                    "definition": {
                        "email_recipients": data.emailId.join(','),
                        "company_id": parsedDt.companyId,
                        "branch_id": parsedDt.branchId,
                        "schedule": {
                            "cron": data.timings,
                            "type": "DAILY"
                        }
                    }
                }
                return itemObject;
            } else {
                itemObject = {
                    "entity_id": cbdt,
                    "type": "DAILY_TRIP_SUMMARY",
                    "job_uuid": job_uuid,
                    "definition": {
                        "email_recipients": data.emailId.join(','),
                        "company_id": parsedDt.companyId,
                        "schedule": {
                            "cron": data.timings,
                            "type": "DAILY"
                        }
                    }
                }
                return itemObject;
            }
        }

        handleSubmit = e => {
            e.preventDefault()
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    if (this.state.edit) {
                        this.updateDailySummaryReport(values)
                    } else {
                        this.addDailySummaryReport(values)
                    }
                }
            })
        }

        render() {
            const { getFieldDecorator } = this.props.form;
            const { emailId, edit } = this.state;
            const formItemLayout = {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 8 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 16 },
                },
            }

            return (
            <div className="exceptionConfigForm">        
              <Row gutter={20}>
                <Col span={12} offset={6}>
                    <h2 style={{ textAlign: 'center', marginBottom: '40px' }}>Set SputNik Daily Summary Report</h2>
                  <Form {...formItemLayout} onSubmit={this.handleSubmit}>  
                  <Form.Item    label="Email Id's">
                  {getFieldDecorator('emailId', {
                        rules: [
                          {
                            required: true,
                            message: 'Please select EmailId',
                          },
                        ],
                      })(
                      <Select mode="tags" tokenSeparators={[',']}>
                         {emailId}
                      </Select>
                      )}
                  </Form.Item>
                  <Form.Item
                      label="Set Report Timings"
                    >
                      {getFieldDecorator('timings', {
                        rules: [
                          {
                            required: true,
                            message: 'Enter timings',
                          },
                        ],
                      })(
                      <Select>
                        {cron.crons && cron.crons.map((e) => {
                          return <Option  key={e.value} value={e.value}>{e.label}</Option>;
                        })}
                      </Select>
                      )}
                    </Form.Item>
                    <Form.Item style={{ marginTop: '20px', textAlign: 'center' }}>
                      <Button type="primary" htmlType="submit">
                        Submit Daily Summary Report
                      </Button>
                      <Button className="cta" onClick={() => this.props.history.push({
                          pathname: '/sputnik-daily-summary',
                          search:this.props.location.search,
                         })}>
                          Back
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </div>
            )
          }
        }

const WrappedAddPlacesForm = Form.create({ name: 'register' })(DailySummaryConfigSputnik)
export default withRouter(WrappedAddPlacesForm)