import React from 'react'
import { Row, Button, Table, Checkbox, Modal, message } from 'antd'
import { constructCbdtUrl,convertQsToJson} from 'utils/common'
import request from 'utils/request'
import fetchData from 'utils/fetchData'
import SubHeader from 'utils/subHeader'
const SPUTNIK_API = window._env_.REACT_APP_SPUTNIK_API_URL


class ExceptionReportList extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      allConfiguration: [],
      checked: false,
      visible: false,
      modeToggle: '',
      customerConfigId: '',
      cbdt:null,
    }
  }

  componentDidMount() {
    const { search } =this.props.location;
    const parsedDt = convertQsToJson(search)
    if (!parsedDt.companyId) {
      message.info('Select a company to continue')
    }else{
      this.getExceptionReportList(search);
    }
  } 

  componentDidUpdate(prevProps){
    const { search } = this.props.location;
    const  prevSearch = prevProps.location.search
    if (search !== prevSearch){
        this.getExceptionReportList(search);
    }
  }

  getExceptionReportList = async() =>{
      const cbdt  = await this.getCbdt()
      this.setState({
         cbdt: cbdt
      })
      if(cbdt){
       this.fetchAllconfigurations(this.props.location.search)
      }
  }

  handleOnChange = data => {
    this.setState({
      visible: true,
      modeToggle: data.active,
      customerConfigId: data.id,
    })
  }

  handleCancel = () => {
    this.setState({ visible: false })
  }
  
  handleNotification = () => {
    const { customerConfigId,modeToggle} = this.state;
    const active = modeToggle === 1 ? 0 : 1;
    let url =`${SPUTNIK_API}/saas/v1/rs/update/${customerConfigId}`
    let payload = { 'active':active }
      fetchData(url,'PUT',payload).then(res => {
          if (res.success === true) {
            this.setState({ visible: false })
            this.getExceptionReportList(this.props.location.search);
          }
      })
      .catch(error => {
        message.error('Error while disabling report')
      });
  }
  
  gotoEditConfigForm = data => {
    let qs= this.props.location.search;
    if (qs.length > 0) {
      this.props.history.push({
        pathname: `/exception-report/edit/${data.id}`,
        search: qs,
      })
    }
  }
  
  fetchAllconfigurations() {
    let url = `${SPUTNIK_API}/saas/v1/rs/list?`
    let payload= {
      entity_id:this.state.cbdt,
      type:'ALERT'
    }
    fetchData(url,'GET',payload).then(result => {
          if (result.success) {
            this.setState({ allConfiguration: result.data })
          } else {
            result.message.map(msg => message.error(msg))
          }
      }
      ).catch(error => {
        message.error('Error while fetching report')
      });   
  }

   getCbdt = () => {
    const { search } =this.props.location;
    const parsedDtThis = convertQsToJson(search)
    if(parsedDtThis.companyId){
      const cbdtUrl = constructCbdtUrl('/saas/lego/cbdt',search)
       return request(cbdtUrl,{
        method: 'GET',
      }).then(result => {
        if(result.success){
          return result.data.id
        }else{
          message.error(result.message[0])
        }     
      })
    }  
  }


  render() {  
    const columns = [
      {
        title: 'Event Id',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Event Name',
        key: 'type',
        dataIndex: 'type',
      },
      {
        title: 'Enable Notificaton',
        key: 'isEnabled',
        render: (text, record, i) => {
          return (
            <Checkbox checked={record.active} onChange={() => this.handleOnChange(record)} />
          )
        },
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
            <span>
              <Button disabled={!record.active} onClick={() => this.gotoEditConfigForm(record)}>Edit</Button>
            </span>
        ),
      },
    ];
    const { allConfiguration,visible,modeToggle}= this.state;
    return (
      <div>
        <Row style={{ padding: '10px 10px 10px 22px' }} />
        <Row>
          <Table
            columns={columns}
            dataSource={allConfiguration}
            rowKey={record => record.customerConfigId}
          />
        </Row>
        <Modal
          width={450}
          height={450}
          footer={null}
          title="Enable Configuration"
          visible={ visible }
          onCancel={this.handleCancel}
        >
          <p>
            Are you sure want to{' '}
            {modeToggle ? <span>disable</span> : <span>enable</span>} notification?
          </p>
          <div style={{ textAlign: 'right' }}>
            <Button onClick={() => this.handleNotification()}>Yes</Button>
            <Button onClick={this.handleCancel}>No</Button>
          </div>
        </Modal>
      </div>
    )
  }
}
export default ExceptionReportList
