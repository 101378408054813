const ftRole = {
    roles : [      
        { key: "1", value:"9", label:"Admin" },
        { key: "2", value:"10", label:"Transporter-Operator" },
        { key: "3", value:"21", label:"Babu" },
        { key: "4", value:"35", label:"CONSIGNEE-ADMIN" },
        { key: "5", value:"8", label:"Broker" },
        { key: "6", value:"12", label:"Truck-Driver" }
    ]
}

export default ftRole;