import { Button } from 'antd'
import React from 'react'
import 'containers/routesMaster/App.css'
import SubHeader from 'utils/subHeader'
import { withRouter } from 'react-router'
class Transporters extends React.Component {
  addTransporters = () => {
    this.props.history.push('/transporters/add')
  }

  viewTransporters = () => {
    this.props.history.push('/transporters')
  }
  cta = () => {
    return (
      <div>
        <Button size="large" type="primary" onClick={this.addTransporters}>
          Add Transporters
        </Button>
        <Button
          style={{ marginLeft: 10 }}
          size="large"
          type="primary"
          onClick={this.viewTransporters}
        >
          View Transporters
        </Button>
      </div>
    )
  }
  render() {
    return (
      <div>
        <SubHeader innerhtml={this.cta} />
        {this.props.children}
      </div>
    )
  }
}
export default withRouter(Transporters)
