import jsonwebtoken from 'jsonwebtoken';
import fetchData from './fetchData';
const ACL_SERVICE = window._env_.REACT_APP_ACCESS_CONTROL_SERVICE_URL

const tokenKeys = {
    USER_PERMISSIONS: 'ft_user_permissions',
    DESK_ACCESS_TOKEN :'ft_desk_token'
};

const setPermission = () => {
    const deskLoginToken = getForKey(tokenKeys.DESK_ACCESS_TOKEN);
    const deskTokenPayload = jsonwebtoken.decode(deskLoginToken);
    window.localStorage.setItem(tokenKeys.USER_PERMISSIONS, JSON.stringify(deskTokenPayload.permissions));
};

const getAllPermission = async () => {
    return fetchData(`${ACL_SERVICE}/v1/accessControl/permissions`,'GET').then(result => {
        return result.data
    }).catch(function(error) {
        console.log(error);
  })
}

const getForKey = (key) => window.localStorage.getItem(key);

export {
    tokenKeys,
    getForKey,
    setPermission,
    getAllPermission
};
  
