import { Button, Row,message } from 'antd'
import React from 'react'
import SubHeader from 'utils/subHeader'
import 'containers/routesMaster/App.css'
import { withRouter } from 'react-router'

class DailySummaryReportSputnik extends React.Component {
  
  // For adding report
  showAddDailySummaryReportSputNik = () => {
    if(this.props.location.search){
      this.props.history.push({
        pathname: '/sputnik-daily-summary/add',
        search: this.props.location.search,
      })
    } else{
      return message.error('Select a company to set daily summary report')
    }
  }


  setSputNikDailySummaryReport = () => {
    return (
      <div>
        <Button size="large" type="primary" onClick={this.showAddDailySummaryReportSputNik}>
          Set Daily summary Report of Sputnik
        </Button>
      </div>
    )
  }

  render() {
    return (
      <Row>
        <SubHeader innerhtml={this.setSputNikDailySummaryReport} />
        {this.props.children}
      </Row>
    )
  }
}

export default withRouter(DailySummaryReportSputnik)


