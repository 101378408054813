export default function InputField(closureType){
    switch (closureType) {
        case "ACTION_AUTO_CLOSURE_ON_ENTRY":
            return  [
                {
                    value: 'companyName',
                    label: 'Company Name',
                    inputType: 'text',
                    required:true,
                },
                {
                    value: 'threshold',
                    label: 'Close Trip after number of days',
                    inputType: 'number',
                    required:false,
                },
                {
                    value: 'geofenceRadiusInMeter',
                    label: 'Please enter geo fence radius in meter',
                    inputType: 'number',
                    required:true,
                    placeholder: '3000'
                }
            ];
        case "ACTION_AUTO_CLOSURE_ON_EXIT":
            return [
                {
                    value: 'companyName',
                    label: 'Company Name',
                    inputType: 'text',
                    required:true,
                },
                {
                    value: 'threshold',
                    label: 'Close Trip after number of days',
                    inputType: 'number',
                    required:false,
                },
                {
                    value: 'geofenceRadiusInMeter',
                    label: 'Please enter geo fence radius in meter',
                    inputType: 'number',
                    required:true,
                    placeholder: '3000'
                }
            ];
        case "AUTO_CLOSURE_RULE_BASED":
            return [
                {
                    value: 'threshold',
                    label: 'Close Trip after number of days',
                    inputType: 'number',
                    required:true,
                }
            ];
        case "AUTO_CLOSURE_EPOD":
            return [ 
                {
                    value: 'threshold',
                    label: 'Close Trip after number of days',
                    inputType: 'number',
                    required:true,
            }];    
        case "ACTION_AUTO_CLOSURE_ON_STA_BREACH":
            return [ 
                {
                    value: 'loading_time_buffer',
                    label: 'Loading Time Buffer (In Sec)',
                    inputType: 'number',
                    required:true,
                },
                {
                    value: 'unloading_time_buffer',
                    label: 'Unloading Time Buffer (In Min)',
                    inputType: 'number',
                    required:true,
                }
            ];
        case "ACTION_AUTO_CLOSURE_ON_LAST_MILE":
            return [ 
                {
                    value: 'lastMileMinutes',
                    label: 'Last Mile Trip Closure Minutes',
                    inputType: 'number',
                    required:true,
            }];
        case "AUTO_CLOSURE_CUSTOM":
                return [ 
                    {
                        value: 'threshold',
                        label: 'Close Trip after number of days',
                        inputType: 'number',
                        required:true,
                }];
        default:
            break;
    }
}