import React, { Component } from 'react'
import { Input, Row, Table, Button, message, Modal, Form } from 'antd'
import Geosuggest from 'react-geosuggest'
import moment from 'moment'
import request from 'utils/request'
import { convertQsToJson } from 'utils/common'
import 'containers/routesMaster/App.css'
import './geosuggest.css'
import { css } from '@emotion/css'
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
}
class ListRoutes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      routesData: [],
      visible: false,
      updateRouteData: {},
      url: '',
    }
  }

  componentDidMount() {
    const parsedDt = convertQsToJson(this.props.location.search)
    if (!parsedDt.companyId) {
      message.info('Select a company to continue')
    } else {
      const url = this.constructUrl(this.props.location.search)
      this.fetchRoutesData(url)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search === '') {
      this.setState({ routesData: [] })
    }
    const url = this.constructUrl(nextProps.location.search)
    const editUrl = this.constructEditUrl(nextProps.location.search)
    this.setState({ editUrl: editUrl })

    const parsedDt = convertQsToJson(nextProps.location.search)
    if (parsedDt.companyId !== undefined) {
      this.fetchRoutesData(url)
    }
  }

  constructUrl(qs) {
    const parsedDt = convertQsToJson(qs)

    const cId = parsedDt.companyId || ''
    const bId = parsedDt.branchId || ''
    const dId = parsedDt.departmentId || ''
    const url = `${
      window._env_.REACT_APP_API_URL
    }/saas/lego/company_routes/fetch?c=${cId}&b=${bId}&d=${dId}&t=CONSIGNOR`

    return url
  }

  constructEditUrl(qs) {
    const parsedDt = convertQsToJson(qs)

    const cId = parsedDt.companyId || ''
    const bId = parsedDt.branchId || ''
    const dId = parsedDt.departmentId || ''
    const editUrl = `${
      window._env_.REACT_APP_API_URL
    }/saas/lego/company_routes/create_or_update?c=${cId}&b=${bId}&d=${dId}&t=CONSIGNOR`

    return editUrl
  }

  fetchKey(key) {
    const qData = convertQsToJson(this.props.location.search)
    return qData[key]
  }

  fetchRoutesData(url) {
    request(url).then(
      result => {
        if (result.success) {
          this.setState({ routesData: result.data })
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        message.error('Error while fetching routes data')
      }
    )
  }

  editRoute(record) {
    // const updateRouteData = record
    // updateRouteData.stt = updateRouteData.stt == null ? null : (updateRouteData.stt / 60).toFixed(2)

    // this.setState({ visible: true })
    // this.setState({ updateRouteData })

    this.props.history.push({
      pathname: `/routes/${record.id}/edit`,
      search: this.props.location.search,
    })
  }

  handleCancel = () => {
    this.setState({ visible: false })
  }

  validateToWhitespace = (rule, value, callback) => {
    if (value && value.trim() === '') {
      callback('Blank spaces not allowed')
    } else if (value !== undefined && value.length === 0) {
      callback('Please enter a value')
    } else {
      callback()
    }
  }

  validateNumber = (rule, value, callback) => {
    if (parseInt(value) <= 0) {
      callback('Please enter a number greater than 0')
    } else {
      callback()
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.updateRoutes(values)
      }
    })
  }

  updateRoutes = data => {
    const updateRoutesData = {
      id: this.state.updateRouteData.id,
      code: data.routeCode,
      label: data.routeLabel,
      origin: {
        label: this.state.updateRouteData.origin.label,
        google: {
          place_id: this.state.updateRouteData.origin.google.place_id,
        },
        address: this.state.updateRouteData.origin.address,
        geofenceRadius: this.state.updateRouteData.origin.geofenceRadius,
      },
      destination: {
        label: this.state.updateRouteData.destination.label,
        google: {
          place_id: this.state.updateRouteData.destination.google.place_id,
        },
        address: this.state.updateRouteData.destination.address,
        geofenceRadius: this.state.updateRouteData.destination.geofenceRadius,
      },
      stt: parseFloat(data.standardTransistTime) * 60,
    }
    request(this.state.editUrl, {
      method: 'POST',
      body: JSON.stringify(updateRoutesData),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(
      result => {
        if (result.success) {
          message.success('Route updated successfully !')
          this.props.history.push({
            pathname: '/routes',
            search: this.props.location.search,
          })
          this.setState({ visible: false })
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      () => {
        message.error('Error while updating')
      }
    )
  }

  handleOriginChange = data => {
    if (!data) {
      return
    }
    const newOrigin = {}
    newOrigin.label = data.label
    newOrigin.google = { place_id: data.placeId }
    newOrigin.address = data.description
    newOrigin.geofenceRadius = 2

    const updateRouteData = { ...this.state.updateRouteData }
    updateRouteData.origin = newOrigin
    this.setState({ updateRouteData })
  }

  handleDestinationChange = data => {
    if (!data) {
      return
    }
    const newDestination = {}
    newDestination.label = data.label
    newDestination.google = { place_id: data.placeId }
    newDestination.address = data.description
    newDestination.geofenceRadius = 2

    const updateRouteData = { ...this.state.updateRouteData }
    updateRouteData.destination = newDestination
    this.setState({ updateRouteData })
  }

  render() {
    const { getFieldDecorator } = this.props.form

    const columns = [
      {
        dataIndex: 'origin.address',
        title: 'Loading Point',
      },
      {
        dataIndex: 'destination.address',
        title: 'Unloading Point',
      },
      {
        dataIndex: 'label',
        title: 'Route Label',
      },
      { dataIndex: 'code', title: 'Route Code' },
      {
        dataIndex: 'updatedBy',
        title: 'Updated By',
      },
      {
        dataIndex: 'updatedAt',
        sorter: (a, b) => {
          return moment(a.updatedAt).unix() - moment(b.updatedAt).unix()
        },
        title: 'Time Stamp',
        render: text => <div>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</div>,
      },
      {
        dataIndex: 'cta',
        title: '',
        render: (text, record) => (
          <div className="sideButtons">
            <div>
              <Button onClick={() => this.editRoute(record)} icon="edit" />
              {/* <Button icon="delete" /> */}
            </div>
          </div>
        ),
      },
    ]

    const data = this.state.routesData

    return (
      <div>
        <Row>
          <Table
            columns={columns}
            dataSource={data}
            rowKey={record => record.id}
            locale={{ emptyText: 'No routes found for the given selection' }}
          />
        </Row>
        <Row>
          <Modal
            width={'700px'}
            visible={this.state.visible}
            title="Edit Route"
            okText="Create"
            onCancel={this.handleCancel}
            footer={null}
          >
            <Form onSubmit={this.handleSubmit}>
              <Form.Item {...formItemLayout} label="Loading Point">
                {getFieldDecorator('loadingLabel', {
                  rules: [
                    {
                      validator: this.validateToWhitespace,
                    },
                  ],
                })(
                  <Geosuggest
                    inputClassName="ant-input ant-input-lg"
                    suggestsClassName="ant-select-dropdown ant-select-dropdown-menu"
                    suggestItemClassName="ant-select-dropdown-menu-item"
                    suggestItemActiveClassName="ant-select-dropdown-menu-item-active"
                    placeholder="Search locations"
                    autoComplete={'off'}
                    fixtures={this.props.knownPlace}
                    onSuggestSelect={data => this.handleOriginChange(data)}
                    country="in"
                    initialValue={
                      this.state.updateRouteData.origin && this.state.updateRouteData.origin.address
                    }
                    autoActivateFirstSuggest
                  />
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="Unloading Point">
                {getFieldDecorator('UnLoadingPoint', {
                  rules: [
                    {
                      validator: this.validateToWhitespace,
                    },
                  ],
                })(
                  <Geosuggest
                    inputClassName="ant-input ant-input-lg"
                    suggestsClassName="ant-select-dropdown ant-select-dropdown-menu"
                    suggestItemClassName="ant-select-dropdown-menu-item"
                    suggestItemActiveClassName="ant-select-dropdown-menu-item-active"
                    placeholder="Search locations"
                    autoComplete={'off'}
                    fixtures={this.props.knownPlace}
                    onSuggestSelect={data => this.handleDestinationChange(data)}
                    initialValue={
                      this.state.updateRouteData.destination &&
                      this.state.updateRouteData.destination.address
                    }
                    country="in"
                    autoActivateFirstSuggest
                  />
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="Route Label">
                {getFieldDecorator('routeLabel', {
                  rules: [
                    { required: true, message: 'Please enter route label' },
                    {
                      validator: this.validateToWhitespace,
                    },
                  ],
                  initialValue: this.state.updateRouteData.label,
                })(<Input size="large" />)}
              </Form.Item>
              <Form.Item label="STT (in Hour)" {...formItemLayout}>
                {getFieldDecorator('standardTransistTime', {
                  rules: [
                    { required: false, message: 'Please enter stt' },
                    { validator: this.validateNumber },
                  ],
                  initialValue: this.state.updateRouteData.stt,
                })(<Input type="number" size="large" placeholder="Standard Transit Time" />)}
              </Form.Item>
              <Form.Item label="Route Code" {...formItemLayout}>
                {getFieldDecorator('routeCode', {
                  rules: [{ required: false, message: 'Please enter route code' }],
                  initialValue: this.state.updateRouteData.code,
                })(<Input size="large" placeholder="Unique for the CBD Level" />)}
              </Form.Item>

              <Form.Item
                className={css`
                  text-align: center;
                `}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </Row>
      </div>
    )
  }
}
const editRouteForm = Form.create({ name: 'edit_Route' })(ListRoutes)
export default (ListRoutes, editRouteForm)
