const PI = Math.PI

const METER_PER_KILOMETER = 1000
const KILOMETER_PER_MILE = 1.609344
const NAUTICAL_MILE_PER_MILE = 0.8684
const MILE_PER_MINUTE = 1.1515
const MINUTE_PER_DEGREE = 60
const SECOND_PER_MINUTE = 60
const DEGREE_PER_RADIAN = 180.0 / PI

const METER_PER_MILE = METER_PER_KILOMETER * KILOMETER_PER_MILE
const MILE_PER_DEGREE = MILE_PER_MINUTE * MINUTE_PER_DEGREE
const MILE_PER_SECOND = MILE_PER_MINUTE / SECOND_PER_MINUTE
const MILE_PER_RADIAN = MILE_PER_DEGREE * DEGREE_PER_RADIAN

const METER = 'METER',
  KILOMETER = 'KILOMETER',
  MILE = 'MILE',
  NAUTICAL_MILE = 'NAUTICAL_MILE',
  RADIAN = 'RADIAN',
  DEGREE = 'DEGREE',
  MINUTE = 'MINUTE',
  SECOND = 'SECOND'

const DEFAULT_DISTANCE_UNIT = DEGREE

Math.toRadians = angdeg => {
  return (angdeg / 180.0) * PI
}

/*
 * @author Bhargav Kolla <bhargav.kolla[at]freighttiger.com>
 */

class GeoUtil {
  static convertUnit(distance, fromUnit, toUnit) {
    if (!distance || !fromUnit || !toUnit || fromUnit === toUnit) return distance

    /* Convert from fromUnit to DistanceUnit.MILE */
    switch (fromUnit) {
      case METER:
        distance = distance / METER_PER_MILE
        break
      case KILOMETER:
        distance = distance / KILOMETER_PER_MILE
        break
      case MILE:
        break
      case NAUTICAL_MILE:
        distance = distance / NAUTICAL_MILE_PER_MILE
        break
      case RADIAN:
        distance = distance * MILE_PER_RADIAN
        break
      case DEGREE:
        distance = distance * MILE_PER_DEGREE
        break
      case MINUTE:
        distance = distance * MILE_PER_MINUTE
        break
      case SECOND:
        distance = distance * MILE_PER_SECOND
        break
      default:
        return 0
    }

    /* Convert from DistanceUnit.MILE to toUnit */
    switch (toUnit) {
      case METER:
        distance = distance * METER_PER_MILE
        break
      case KILOMETER:
        distance = distance * KILOMETER_PER_MILE
        break
      case MILE:
        break
      case NAUTICAL_MILE:
        distance = distance * NAUTICAL_MILE_PER_MILE
        break
      case RADIAN:
        distance = distance / MILE_PER_RADIAN
        break
      case DEGREE:
        distance = distance / MILE_PER_DEGREE
        break
      case MINUTE:
        distance = distance / MILE_PER_MINUTE
        break
      case SECOND:
        distance = distance / MILE_PER_SECOND
        break
      default:
        return 0
    }

    return distance
  }

  static distance(latitude1, longitude1, latitude2, longitude2, unit = 'KILOMETER') {
    if (!unit) unit = DEFAULT_DISTANCE_UNIT

    latitude1 = Math.toRadians(latitude1)
    longitude1 = Math.toRadians(longitude1)
    latitude2 = Math.toRadians(latitude2)
    longitude2 = Math.toRadians(longitude2)

    let lambda = longitude2 - longitude1

    let distance =
      Math.sin(latitude1) * Math.sin(latitude2) +
      Math.cos(latitude1) * Math.cos(latitude2) * Math.cos(lambda)
    distance = Math.acos(Math.min(Math.max(distance, -1.0), 1.0))

    distance = GeoUtil.convertUnit(distance, RADIAN, unit)

    return distance
  }
}

export default GeoUtil
