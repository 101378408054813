import { Form,Select,InputNumber,Input, Button ,Row,Col,message, Empty } from 'antd'
import React from 'react'
import 'containers/routesMaster/App.css'
import { withRouter } from 'react-router'
import {constructCbdtUrl,convertQsToJson} from 'utils/common'
import request from 'utils/request'
import fetchData from 'utils/fetchData';
import cronException from 'components/ExceptionReport/cronException';
import tripStatuses from 'components/ExceptionReport/tripStatuses';
import FilterKeys from 'components/ExceptionReport/filter';
import setOperatorList from 'components/ExceptionReport/operator';
import alertsName from 'components/ExceptionReport/alertsName';
import { css } from '@emotion/css'
import './exception.scss';
const Option = Select.Option
const SPUTNIK_API = window._env_.REACT_APP_SPUTNIK_API_URL

class ExceptionReportConfig extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      cbdt:null,
      edit: false,
      onboardedEvent: [],
      customerConfigId:null,
      emailId:[],
      job_uuid:'',
      inputType:[],
      setOpeartorList :[''],
      inputvalue:[''],
      addField:[''],
      operators:[], 
      input:[],
      allFlag: {
        addFieldDisable: true,
      },
      disable:false
    }
  }

  componentDidMount() {
    const { search } = this.props.location;
    const parsedDt = convertQsToJson(search)
    if (!parsedDt.companyId) {
        message.info('Select a company to continue')
    }else{
        this.resolvedCbdt()
        if(this.props.match.params.id){
        this.setState({ edit: true}, () => { 
          this.fetchExceptionReport(this.props.match.params.id)
        })
       }
    }
  }

  componentDidUpdate(prevProps){
    const { search } =this.props.location;
    const  prevSearch = prevProps.location.search;
    if (search !== prevSearch ){
      const parsedDt = convertQsToJson(search)
      if(parsedDt.companyId){
        this.resolvedCbdt()
      }
    }
  }

  resolvedCbdt = async() => {
    const cbdt  = await this.getCbdt()
    this.setState({
       cbdt: cbdt
    })
  }

  addExceptionReport = data => {  
    let url =`${SPUTNIK_API}/saas/v1/rs/definition/create`;
    const { search } =this.props.location;
    const parsedDt = convertQsToJson(search)
    if (!parsedDt.companyId) {
      return message.error('Select a company to continue')
    }
    const formData = this.makeItemObject(data);
    delete formData.job_uuid;
    fetchData(url,'POST',formData).then(
         result => {
          if (result.success == true ) {
              message.success('Report set sucessfully')
              this.props.history.push({
                pathname: '/exception-report',
                search: search,
              })
            } else {
              message.error('There is something wrong')
            }
          },    
      )
      .catch(error => {
        message.error('Error while creating report')
      });   
  }
   
  updateExceptionReport = data => {  
    let url =`${SPUTNIK_API}/saas/v1/rs/update/${this.state.customerConfigId}`
    const { search } = this.props.location;
    const parsedDt = convertQsToJson(search)
    if (!parsedDt.companyId) {
      return message.error('Select a company to continue')
    }
    const formData = this.makeItemObject(data)
    fetchData(url,'PUT',formData).then(
          result => {
            if (result.success == true ) {
              message.success('Report updated sucessfully')
              this.props.history.push({
                pathname: '/exception-report',
                search: search,
              });
            } else {
              result.message.map(msg => message.error(msg))
            }
          }
        ).catch(error => {
          message.error('Error while updating report')
    });   
  }

  makeItemObject = (data) => {
    const { cbdt,job_uuid,addField,operators,inputvalue} = this.state;
    let tripStatuses = [];
    data.tripStatus.map((status) => {
      tripStatuses.push(parseInt(status));
    })
     let  itemObject = {
        	"entity_id": cbdt,
          "type": "ALERT",
          "job_uuid": job_uuid,
        	"definition": {
        		"email_recipients":data.emailId.join(','),
        		"params": {
        			"date": {
        				"alert": {
        					"closed": {
        						"close_time": {
        							"duration_mins":data.duration,
        						}
        					},
        					"inprogress": {
        						"start_time": {
        							"duration_mins": 45000
        						}
        					}
        				},
        				"trip": {
        					"constraint": {
        						"value":data.triphours 
        					},
        					"type": "HOURS"
        				}
        			},
        			"event": data.alerts,
        			"format": "xlsx",
        			"tripStatus": tripStatuses
            },
        		"schedule": {
        			"cron":data.timings,
        			"type": "DAILY"
        		}
        	}
    }
       let filters =[];
        for(let i=0 ; i < addField.length;i++){
          if((addField[i] !== "" && inputvalue[i] !== "" || ( inputvalue[i].length>0))){
            filters.push({
              "key": addField[i],
              [operators[i]]:inputvalue[i]
            })
            itemObject["definition"]["filters"]=filters;
          }
        }
    return itemObject;
  }

  fetchExceptionReport = (id) => {
    const url = `${SPUTNIK_API}/saas/v1/rs/report/${id}`;
    fetchData(url,'GET').then(
      response => {
        this.setInputField(response);
      } 
    ).catch(error => {
      message.error('Error while fetching report')
    });
  }

  setInputField= (response) => {
    const { entity_id,definition,job_request,id } = response.data;
    const { addField,inputvalue,operators } = this.state;
    const { email_recipients,params,schedule,filters } = definition;
    const { job_uuid } = job_request;
    let tempValue=[];
    let tempField = [];
    let tempOperators = [];
      if(filters){
        for (let i = 0; i < filters.length; i++){
          tempField.push(filters[i].key);
          let index = Object.keys(filters[i])[1] !=='key'? 1:0;
          tempValue.push(Object.values(filters[i])[index]);
          tempOperators.push(Object.keys(filters[i])[index])
          this.handleFilter(i,filters[i].key);
        }
      }
      const items = {
        addFieldDisable: 'addFieldDisable',
      }
      const newObj = {}
      Object.keys(items).forEach((key, value) => {
        let itemList = tempField ? tempField:addField;
        newObj[items[key]] = itemList.length > 1 ? false : true
        return newObj
      })
      const field = tempField.length > 0 ? tempField:addField;
      const operator =tempOperators.length > 0 ? tempOperators:operators;
      const invalue =tempValue.length > 0 ? tempValue:inputvalue;
      let tripStatusArray = [];
      params.tripStatus.map((status) => {
        tripStatusArray.push(status.toString())
      })
      this.setState({
        addField:field,
        operators:operator,
        allFlag:newObj,
        setreportcbdt:entity_id,
        customerConfigId:id,
        job_uuid,
        inputvalue:invalue
      })
      this.props.form.setFieldsValue({
        inputvalue:invalue,
        addField:field,
        operators:operator,
        alerts: params.event,
        emailId: email_recipients.split(','),  
        triphours: params.date.trip.constraint.value,
        timings: schedule.cron,
        duration: params.date.alert.closed.close_time.duration_mins,
        tripStatus: tripStatusArray
      })
  }
  
  getCbdt = () => {
    const {search} =this.props.location;
    const parsedDtThis = convertQsToJson(search)
    if(parsedDtThis.companyId){
      const cbdtUrl = constructCbdtUrl('/saas/lego/cbdt',search)
      return  request(cbdtUrl,{
        method: 'GET',
      }).then(result => {
        if(result.success){
          return result.data.id
        }else{
          message.error(result.message[0])
        }     
      }).catch(error => {
        message.error('Error while fetching cbdt');
      }); 
    }  
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (this.state.edit) {
          this.updateExceptionReport(values)
        } else {
          this.addExceptionReport(values)
        }
      }
    })
  }

  addFields = (addField,operators,inputvalue,flag) => {
    let tempField = this.state[addField]
    let tempOperator = this.state[operators]
    let tempInputValue = this.state[inputvalue]
    const { allFlag } = this.state
    if (tempField[tempField.length - 1]) {
      tempField.push('')
      tempOperator.push('')
      tempInputValue.push('')
    }
    if (tempField.length > 1) {
      allFlag[flag] = false
    }
    this.setState({ addField: tempField,operators:tempOperator,inputvalue:tempInputValue,allFlag })
  }
  

  deleteFields = (addField,operators,inputvalue,index, flag) => {
    let tempField = this.state[addField]
    let tempOperator = this.state[operators]
    let tempInputValue = this.state[inputvalue]
    const { allFlag } = this.state
    tempField.splice(index, 1)
    tempOperator.splice(index, 1)
    tempInputValue.splice(index, 1)
    this.props.form.setFieldsValue({[addField]:tempField,[operators]:tempOperator,[inputvalue]:tempInputValue})
    if (tempField.length === 1) {
      allFlag[flag] = true
    }
    this.setState({ allFlag, type: tempField})
  }

  handleFilter = (i,value) => {
    const { inputType, addField, setOpeartorList ,inputvalue,operators} = this.state;
      FilterKeys.filters.forEach((filter) => {
        if(filter.value === value){
          let s = inputType
          let t = addField
          let u = setOpeartorList;
          s[i] = filter.inputType
          t[i] = value
          u[i] = setOperatorList(filter.fieldType);
          let w = inputvalue
          inputvalue[i] = ''
          let x = operators
          x[i] = ''
          this.setState({ 
            inputType:s,
            addField:t,
            setOpeartorList:u,
            disable:true,
            inputvalue:w,
            operators:x
          });
          this.props.form.setFieldsValue({
            inputvalue:w,
            operators:x
          })
      }
    })
  }

  handleChangeOperator = (i,value) => {
    let t = this.state.operators
    t[i] = value
    this.setState({ 
      operators: t
    })
  }

  handleChangeInputValue = (i,e) => {
    const { inputvalue, operators}=this.state;
    let t = inputvalue
    if(e.target.type == 'text'){
      if(operators[i] == 'in' ||operators[i] == 'not_in'){
        t[i] = e.target.value.split(",")
      }else{
        t[i] = e.target.value
      }
    }else{
      t[i] = parseInt(e.target.value)
    }
    this.setState({ inputvalue: t })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { emailId,edit,inputType,setOpeartorList ,addField ,disable } = this.state
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
  }
   
  return (
        <div className="exceptionConfigForm" stylecss={css`padding:20px;` }>
            {edit ? (
              <h2 style={{ textAlign:'center',marginBottom: '40px'}} >Update Exception Report</h2>
            ):(
              <h2 style={{ textAlign:'center',marginBottom: '40px'}} >Set Exception Report</h2>
            )}
          <Form {...formItemLayout} onSubmit={this.handleSubmit}  > 
            <Row gutter={16}>  
            <Col span={11}>
            <Form.Item  label="Email ID">
            {getFieldDecorator('emailId', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select EmailId',
                    },
                  ],
                })(
                <Select mode="tags" tokenSeparators={[',']}>
                   {emailId}
                </Select>
                )}
            </Form.Item>
            </Col>
            <Col span={11}>   
            <Form.Item    label="Alerts">
            {getFieldDecorator('alerts', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select alerts names',
                    },
                  ],
                })(
                <Select mode="tags" tokenSeparators={[',']}>
                   {alertsName.alerts && alertsName.alerts.map((e) => {
                    return <Option key={e.value} value={e.value}>{e.label}</Option>;
                    })}
                </Select>
                )}
            </Form.Item>
            </Col>
            <Col span={11}>
            <Form.Item label="Set Report Timings">
                {getFieldDecorator('timings', {
                  rules: [
                    {
                      required: true,
                      message: 'Enter timings',
                    },
                  ],
                })(
                <Select>
                {cronException.crons && cronException.crons.map((e) => {
                    return <Option   key={e.value} value={e.value}>{e.label}</Option>;
                })}
                </Select>
                )}
            </Form.Item> 
            </Col>
            <Col span={11}>
              <Form.Item label="Alerts Closed Minutes">
                {getFieldDecorator('duration', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter alerts closed duration in minutes',
                    },
                  ],
                })(<InputNumber style={{ width:'100%' }}   />)}
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item label="Total Trip hours">
                {getFieldDecorator('triphours', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter total trip hours in hours ',
                    },
                  ],
                })(<InputNumber style={{ width:'100%' }}  />)}
              </Form.Item>  
            </Col>
            
            <Col span={11}>
            <Form.Item label="Trip Status">
                {getFieldDecorator('tripStatus', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter trip statuses',
                    },
                  ],
                })(
                <Select mode="tags" tokenSeparators={[',']}>
                {tripStatuses.tripStatus && tripStatuses.tripStatus.map((e) => {
                    return <Option key={e.value} value={e.value}>{e.label}</Option>;
                })}
                </Select>
                )}
            </Form.Item> 
            </Col>

            <Col span={4}></Col>
            <Col span={2}>
            <Button  className="cta" href="javascript4:void(0);"
                  onClick={() => this.addFields('addField', 'operators','inputvalue','addFieldDisable')}>
                  + Add More Filter
            </Button>
            </Col>
            </Row> 
            {addField && addField.map((item, i) => (
            <div key={i} >
            <Row gutter={16} className="filter">  
            <Col span={7}>
              <Form.Item label={`Filter ${i+1}`}>
                {getFieldDecorator(`addField[${i}]`, {
                rules: [
                  {
                    required: false,
                    whitespace: true,
                    message: 'Please select Filter',
                  }
                ]
                })(
                <Select defaultValue="none"  placeholder = "Please Select Filter"  onChange={this.handleFilter.bind(this, i)} >
                  {FilterKeys.filters && FilterKeys.filters.map((e) => {
                     return <Option  key={e.value} value={e.value}>{e.label}</Option>;
                  })}
                </Select>
                )}
               </Form.Item> 
            </Col>
            <Col span={7}>
              <Form.Item label={`Operator ${i+1}`}>
                {getFieldDecorator(`operators[${i}]`, {
                  rules: [
                    {
                      required: disable,
                      message: 'Please enter operator',
                    },
                  ],
                })(
                  <Select defaultValue="none"  placeholder = "Please Select Operator" onChange={this.handleChangeOperator.bind(this,i)} >
                    {setOpeartorList[i] && setOpeartorList[i].map((operator) => {
                      return <Option  key={operator.value} value={operator.value}>{operator.label}</Option>;
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col> 
             <Col span={7}>
             <Form.Item label={`Value ${i+1}`}>
                {getFieldDecorator(`inputvalue[${i}]`, {
                  rules: [
                    {
                      required: disable,
                      message: 'Please enter valid input value',
                    },
                  ],
                })(
                <Input
                    placeholder = "Please Fill Input Value"
                    onChange={this.handleChangeInputValue.bind(this, i)}
                    type={inputType[`${i}`]}
                />
              )}
              </Form.Item>
              </Col>
              <Col span={3}>
                <Button className="deleteButton"
                    onClick={() =>
                      this.deleteFields('addField', 'operators','inputvalue',i, 'addFieldDisable')
                    }>
                  X
                </Button>
              </Col>
              </Row> 
            </div>
            ))}
          <Row style={{ textAlign: 'center' }}>
            <Col span={6}></Col>
            <Col span={16}>
              <Form.Item >
                <Button type="primary" htmlType="submit">
                  Submit Exception Report
                </Button>
                <Button className="cta" onClick={() => this.props.history.push({
                    pathname: '/exception-report',
                    search:this.props.location.search,
                   })}>
                    Back
                </Button>
              </Form.Item>
            </Col>
          </Row>
      </Form>
  </div>
  )
  }
}

const WrappedAddPlacesForm = Form.create({ name: 'register' })(ExceptionReportConfig)
export default withRouter(WrappedAddPlacesForm)
