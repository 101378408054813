import React from 'react'
import { CSVLink } from 'react-csv'
import { Button, Row, Col, Icon, message } from 'antd'
import { HotTable } from '@handsontable/react'
import _ from 'lodash'
import request from 'utils/request'
import GeoUtil from 'utils/geoUtils'
import { addressFromLatLng } from 'utils/geocode'
import { convertQsToJson, constructUrl } from 'utils/common'
import { geocodeByAddress } from 'react-places-autocomplete'
import 'handsontable-pro/dist/handsontable.full.css'
import ImportFileComponent from 'components/common/ImportFileComponent'

const google = window.google

class KnownPlacesUpload extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      downloadData: [],
      isLoaded: false,
      placesData: [[]],
      autocompleteData: [],
      company: null,
      branch: null,
      department: null,
      ImportFileComponentKey: Date.now(),
      atLeastOneSuccess: false,
    }

    this.displaySuggestions = this.displaySuggestions.bind(this)
  }

  componentDidMount() {
    const parsedDt = convertQsToJson(this.props.location.search)
    const cId = parsedDt.companyId || ''
    const bId = parsedDt.branchId || ''
    const dId = parsedDt.departmentId || ''

    this.setState({
      company: cId,
      branch: bId,
      department: dId,
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search === '') {
      this.setState({ routesData: [] })
    }

    const parsedDt = convertQsToJson(nextProps.location.search)
    const cId = parsedDt.companyId || ''
    const bId = parsedDt.branchId || ''
    const dId = parsedDt.departmentId || ''

    this.setState({
      company: cId,
      branch: bId,
      department: dId,
    })
  }

  async saveKnownPlaces() {
    if (!this.state.company) {
      message.error('Please select a company before saving')
      return
    }

    const data = this.refs.hot.hotInstance.getData()

    if (data.length == 1 && data[0].filter(d => d !== null).length == 0) {
      message.error('No data to upload, upload a file or paste some data')
      return
    }

    const parsedDt = convertQsToJson(this.props.location.search)

    if (
      Object.keys(parsedDt).length > 1 &&
      parsedDt.companyType == 'TRANSPORTER' &&
      _.isEmpty(parsedDt.consignorId)
    ) {
      message.error('Please select a consignor to proceed')
      return
    }

    const createUrl = constructUrl(
      '/saas/lego/company_partners/create',
      this.props.location.search,
      { bulkUpload: true, allOrNone: false, pt: 'CONSIGNEE' }
    )
    const url = createUrl

    let addKnownPlaceData = _.map(data, s => {
      const x = 1
      return {
        code: s[0],
        name: s[1],
        type: s[2],
        phone: s[3],
        email: s[4],
        place: {
          cpCode: s[11],
          google: {
            place_id: s[10],
          },
          label: s[5],
          address: s[6],
          geofenceRadius: s[12],
        },
      }
    })

    addKnownPlaceData = addKnownPlaceData.filter(s => s.place.google.place_id !== null)

    await request(url, {
      method: 'POST',
      body: JSON.stringify(addKnownPlaceData),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(result => {
      const res = result.data
      const hot = this.refs.hot.hotInstance
      const hotData = hot.getData()

      let i = 0
      while (i < hotData.length) {
        if (hot.isEmptyRow(hotData[i])) {
          i++
        } else {
          if (res[i] && res[i].id) {
            hot.setDataAtCell(i, 13, 'Success')
            this.setState({ atLeastOneSuccess: true })
          } else {
            hot.setDataAtCell(i, 13, res[i])
          }
          i++
        }
      }
    })
  }

  async updateLatLng(d) {
    let i = 1
    while (i < d.length) {
      const tempRow = d[i]
      if (d[i][1] === '') {
        const { placeId } = await addressFromLatLng(d[i][8], d[i][9])
        tempRow[10] = placeId
      } else {
        await geocodeByAddress(d[i][7])
          .then(results => {
            const { place_id, geometry } = results[0]

            if (
              d[i][8] !== '' &&
              d[i][8] !== undefined &&
              (d[i][9] !== '' && d[i][9] !== undefined)
            ) {
              tempRow[8] = d[i][8]
              tempRow[9] = d[i][9]
            } else {
              tempRow[8] = geometry.location.lat()
              tempRow[9] = geometry.location.lng()
            }

            const distanceBetween = GeoUtil.distance(
              parseFloat(geometry.location.lat()),
              parseFloat(geometry.location.lng()),
              parseFloat(d[i][8]),
              parseFloat(d[i][9])
            )

            if (distanceBetween > 30) {
              tempRow[13] = 'Error: Distance more than 30kms'
            } else {
              tempRow[10] = place_id
            }
          })
          .catch(error => {
            tempRow[13] = `Error: ${error}`
          })
      }

      if(this.state.placesData.length == 1 && this.state.placesData[0].length == 0){
        this.setState({
          placesData: [...this.state.placesData[0], tempRow],
        })
      } else {
        this.setState({
          placesData: this.state.placesData.concat([tempRow]),
        })
      }

      i++
    }
  }

  displaySuggestions(predictions, status) {
    if (status !== google.maps.places.PlacesServiceStatus.OK) {
      return
    }
    const arr = []
    predictions.forEach(function(prediction) {
      arr.push(prediction.description)
    })
    this.setState({ autocompleteData: arr })
  }

  removeSuccess() {
    let data = this.refs.hot.hotInstance.getData()
    data = data.filter(s => s[13] !== 'Success')
    this.setState({
      placesData: data,
    })
  }

  resetData() {
    this.setState({
      placesData: [[]],
      autocompleteData: [],
      ImportFileComponentKey: Date.now(),
    })
  }

  cta() {
    return (
      <div>
        <Col xs={12}>
          <div style={{ float: 'left' }}>
            <ImportFileComponent
              handleParse={d => this.updateLatLng(d)}
              inputKey={this.state.ImportFileComponentKey}
            />
          </div>
          <Button type="primary" onClick={() => this.saveKnownPlaces()}>
            Submit
          </Button>
        </Col>
        <Col xs={12} style={{ textAlign: 'right' }}>
          {this.state.atLeastOneSuccess && (
            <a href="javascript:void(0);" onClick={() => this.removeSuccess()}>
              <Icon type="cross" />
              Remove Success
            </a>
          )}
          <CSVLink
            style={{ marginLeft: '20px' }}
            headers={[
              'Consignee code',
              'Consignee name',
              'Type',
              'Contact no',
              'Email id',
              'Address label',
              'Address',
              'Google Address',
              'Latitude',
              'Longitude',
            ]}
            data={this.state.downloadData}
            filename={'consignees.csv'}
            onClick={() => {
              this.setState({
                downloadData: this.refs.hot.hotInstance.getData(),
              })
            }}
          >
            <Icon type="download" /> Download
          </CSVLink>
          <Button type="primary" style={{ marginLeft: '20px' }} onClick={() => this.resetData()}>
            <Icon type="undo" /> Reset
          </Button>
        </Col>
      </div>
    )
  }

  render() {
    const { placesData } = this.state
    const placesDataCopy = [...placesData] // this is required to be done because when passing state object directly, HOT was adding a blank object after every row
    return (
      <div>
        <Row style={{ padding: '20px 20px 0 20px' }}>{this.cta()}</Row>
        <Row style={{ padding: '20px' }}>
          <HotTable
            data={placesDataCopy}
            startCols={4}
            colHeaders={[
              'Consignee code',
              'Consignee name',
              'Type',
              'Contact no',
              'Email id',
              'Address label',
              'Address',
              'Google Address',
              'Latitude',
              'Longitude',
              '<span style="color: #f0f0f0">Geocoding Status<span>',
              'Address Code',
              'Geofence radius',
              'Success Status',
            ]}
            colWidths={[50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 0.1, 50, 50, 50]}
            manualColumnResize={true}
            maxRows={550}
            manualRowResize={true}
            columns={[
              {},
              {},
              {
                type: 'dropdown',
                source: ['CUSTOMER', 'WAREHOUSE', 'JIT_WAREHOUSE', 'DC', 'MDC', 'RDC', 'OEM'],
              },
              {},
              {},
              {},
              {},
              {
                type: 'autocomplete',
                source: (query, process) => {
                  const service = new google.maps.places.AutocompleteService()
                  if (query.length > 0) {
                    service.getQueryPredictions({ input: query }, this.displaySuggestions)
                  }

                  process(this.state.autocompleteData)
                },
                strict: true,
              },
              {},
              {},
              {},
              {},
              {},
              {
                renderer: (instance, td, row, col, prop, value, cellProperties) => {
                  td.style.background = '#dddddd'
                  if (value === 'Success') {
                    td.style.color = 'green'
                  } else if (value) {
                    td.style.color = 'red'
                  }
                  td.innerHTML = value
                  return td
                },
              },
            ]}
            rowHeaders={true}
            minSpareRows={1}
            minRows={1}
            ref="hot"
            width={1200}
            stretchH="all"
            afterChange={async (changes, src) => {
              if (src == 'CopyPaste.paste' || src == 'edit') {
                const addressChanges = changes.filter(c => {
                  return c[1] == 7
                })
                for (let i = 0; i < addressChanges.length; i++) {
                  const data = this.refs.hot.hotInstance.getData()
                  const row = data[addressChanges[i][0]]

                  if (row[7] == '' || row[7] == undefined || row[7] == null || row[7] == '\n') {
                    const { placeId } = await addressFromLatLng(row[8], row[9])
                    data[addressChanges[i][0]][13] = null
                    data[addressChanges[i][0]][10] = placeId
                  } else {
                    await geocodeByAddress(addressChanges[i][3])
                      .then(results => {
                        const { place_id, geometry } = results[0]

                        if (
                          row[8] !== '' &&
                          row[8] !== undefined &&
                          row[8] !== null &&
                          row[8] !== '\n' &&
                          (row[9] !== '' &&
                            row[9] !== undefined &&
                            row[9] !== null &&
                            row[9] !== '\n')
                        ) {
                          // data[addressChanges[i][0]][8] = row[8]
                          // data[addressChanges[i][0]][9] = row[9]
                          data[addressChanges[i][0]][8] = geometry.location.lat()
                          data[addressChanges[i][0]][9] = geometry.location.lng()
                          data[addressChanges[i][0]][13] = null
                          data[addressChanges[i][0]][10] = place_id
                        } else {
                          data[addressChanges[i][0]][8] = geometry.location.lat()
                          data[addressChanges[i][0]][9] = geometry.location.lng()
                          data[addressChanges[i][0]][13] = null
                          data[addressChanges[i][0]][10] = place_id
                        }

                        const distanceBetween = GeoUtil.distance(
                          parseFloat(geometry.location.lat()),
                          parseFloat(geometry.location.lng()),
                          parseFloat(row[8]),
                          parseFloat(row[9])
                        )

                        if (distanceBetween > 30) {
                          data[addressChanges[i][0]][13] = 'Error: Distance more than 30kms'
                        } else {
                          data[addressChanges[i][0]][13] = null
                          data[addressChanges[i][0]][10] = place_id
                        }
                      })
                      .catch(error => {
                        data[addressChanges[i][0]][13] = `Error: ${error}`
                      })
                  }
                  this.setState({
                    placesData: data,
                  })
                }

                const llchanges = changes.filter(c => {
                  return c[1] == 8 || c[1] == 9
                })

                for (let i = 0; i < llchanges.length; i++) {
                  const data = this.refs.hot.hotInstance.getData()
                  const row = data[llchanges[i][0]]

                  if (row[8] && row[9]) {
                    const { placeId, address } = await addressFromLatLng(row[8], row[9])
                    data[llchanges[i][0]][10] = placeId
                    data[llchanges[i][0]][7] = address
                  } else {
                    console.log('either lat or long is missing')
                  }

                  this.setState({
                    placesData: data,
                  })
                }
              }
            }}
          />
        </Row>
      </div>
    )
  }
}

export default KnownPlacesUpload
