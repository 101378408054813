// eslint-disable-next-line
// jsx-a11y/anchor-is-valid
import React, { Component } from 'react'
import { Row, Col, Table, Button, Input, Select, message } from 'antd'
import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import _ from 'lodash'
import DragableBodyRow from './dragableRow'
import Map from '../../utils/Map'
import SubHeader from '../../utils/subHeader'
import MapSearchBox from '../../utils/mapSearchBox'
import request from '../../utils/request'
import { convertQsToJson, getQueryParams } from '../../utils/common'
import './list.scss'
import { css } from '@emotion/css'
const Option = Select.Option

const API_URL = window._env_.REACT_APP_API_URL
class DragableTable extends Component {
  state = {
    companyId: '',
    branchId: '',
    departmentId: '',
    type: 'CONSIGNOR',
    routes: [],
    selectedRouteId: '',
    selectedRoute: {},
    selectedKey: '',
    editedData: {},
    sourceDestination: [],
    wayPoints: [],
    lastSequence: 1,
    disableAddBtn: false,
    showDirection: false,
  }

  processQueryParams = ({ companyId, branchId, departmentId, type, selectedRouteId, id }) => {
    const queryParam = {
      c: Number(companyId),
      b: Number(branchId) || '',
      d: Number(departmentId) || '',
      t: type,
    }
    if (selectedRouteId) {
      queryParam.routeId = selectedRouteId
    }
    if (id) {
      queryParam.id = id
    }
    return getQueryParams(queryParam)
  }

  fetchRouteData = queryParams => {
    const parsedDt = convertQsToJson(queryParams)
    if (!_.isEmpty(parsedDt)) {
      const { companyId, branchId, departmentId } = parsedDt
      if (companyId) {
        parsedDt.type = this.state.type
        const params = this.processQueryParams(parsedDt)
        this.setState({
          companyId,
          branchId,
          departmentId,
        })
        request(`${API_URL}/saas/lego/company_routes/fetch?${params}`).then(response => {
          if (response.success) {
            this.setState({
              routes: response.data,
            })
          } else {
            response.message.map(msg => message.error(msg))
          }
        })
      } else {
        message.info('Select a company to continue', 3)
      }
    }
  }

  componentDidMount() {
    this.fetchRouteData(this.props.location.search)
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.setState({
        routes: [],
        sourceDestination: [],
        wayPoints: [],
        selectedRouteId: '',
        selectedRoute: {},
      })
      this.fetchRouteData(this.props.location.search)
    }
  }

  components = {
    body: {
      row: DragableBodyRow,
    },
  }

  deleteRecord = record => {
    const { wayPoints } = this.state
    const stateDt = { ...this.state, id: record.id }
    const params = this.processQueryParams(stateDt)

    request(`${API_URL}/saas/lego/company_routes_waypoints/delete?${params}`, {
      method: 'DELETE',
    })
      .then(res => {
        if (res.success) {
          const removedDt = wayPoints.filter(wayPointData => wayPointData.key !== record.key)
          let seqNo = 1
          const setSeq = removedDt.map((item, index) => {
            seqNo++
            return Object.assign({ ...item, sequence: index + 1 })
          })
          this.setState({
            wayPoints: setSeq,
            lastSequence: seqNo,
          })
          message.success('Waypoint deleted succesfully')
        } else {
          res.message.map(msg => message.error(msg))
        }
      })
      .catch(() => {
        message.error('Error while deleting waypoint', 2)
      })
  }

  editRecord = record => {
    const { wayPoints } = this.state
    const index = wayPoints.findIndex(dt => dt.key === record.key)
    wayPoints[index] = record
    this.setState({
      wayPoints,
      selectedKey: record.key,
    })
  }

  saveRecord = record => {
    const { label, address, geofenceRadius, googlePlaceId, cpCode } = record
    if (label && googlePlaceId) {
      const { wayPoints } = this.state
      const params = this.processQueryParams(this.state)
      const index = wayPoints.findIndex(dt => dt.key === record.key)
      delete wayPoints[index]['geoCode']
      const formDt = {
        label,
        address,
        cpCode,
        google: {
          place_id: googlePlaceId,
        },
        geofenceRadius,
      }
      request(`${API_URL}/saas/lego/company_routes_waypoints/create?${params}`, {
        method: 'post',
        body: JSON.stringify(formDt),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(res => {
          if (res.success) {
            wayPoints[index].id = res.data.id
            this.setState({
              wayPoints,
              selectedKey: '',
              disableAddBtn: false,
              showDirection: true,
            })
            message.success('Waypoint added succesfully')
          } else {
            res.message.map(msg => message.error(msg))
          }
        })
        .catch(() => {
          message.error('Something went wrong. Please try again', 2)
        })
    }
  }

  moveRow = (dragRow, hoverRow) => {
    const { wayPoints } = this.state
    const dragIndex = dragRow && wayPoints.findIndex(dt => dt.key === dragRow.key)
    const hoverIndex = hoverRow && wayPoints.findIndex(dt => dt.key === hoverRow.key)
    const item = wayPoints.splice(dragIndex, 1)[0]
    wayPoints.splice(hoverIndex, 0, item)
    const setSeq = wayPoints.map((item, index) => Object.assign({ ...item, sequence: index + 1 }))
    this.setState({
      wayPoints: setSeq,
    })
  }

  addWayPoint = (lat = '', lng = '') => {
    const { lastSequence } = this.state
    const newKey = new Date().getTime()
    this.setState(prevState => ({
      wayPoints: [
        ...prevState.wayPoints,
        {
          sequence: lastSequence,
          key: newKey,
          google: {
            geometry: {
              location: {
                lat,
                lng,
              },
            },
          },
        },
      ],
      selectedKey: newKey,
      lastSequence: prevState.lastSequence + 1,
      disableAddBtn: true,
      showDirection: false,
    }))
  }

  changeFieldValue = (evt, field, recordDt) => {
    const { wayPoints } = this.state
    const index = wayPoints.findIndex(dt => dt.key === recordDt.key)
    if (field === 'geoCode') {
      const geoCodeSplitDt = evt.target.value.split(',')
      wayPoints[index]['lat'] = Number(geoCodeSplitDt[0])
      wayPoints[index]['lng'] = Number(geoCodeSplitDt[1])
    }
    if (field === 'geofenceRadius') {
      if (evt.target.value < 0) {
        message.error('Geo Fence Radius must be positive')
      }
    }
    wayPoints[index][field] = evt.target.value
    this.setState({
      wayPoints,
    })
  }

  cancelRecord = recordKey => {
    const { wayPoints } = this.state
    const index = wayPoints.findIndex(dt => dt.key === recordKey)
    wayPoints.splice(index, 1)
    this.setState(prevState => ({
      wayPoints,
      selectedKey: '',
      disableAddBtn: false,
      lastSequence: prevState.lastSequence - 1,
    }))
  }

  changeWayPoint = (record, lat, lng, address) => {
    const { wayPoints } = this.state
    const wayPtIndex = wayPoints.findIndex(wayPt => wayPt.key === record.key)
    wayPoints[wayPtIndex].lat = lat.toFixed(7)
    wayPoints[wayPtIndex].lng = lng.toFixed(7)
    if (address) {
      wayPoints[wayPtIndex].address = address
    }
    this.setState({
      wayPoints,
    })
  }

  addLatLng = (googlePlaceId, latLng, address, recordKey) => {
    const { wayPoints } = this.state
    const wayPointIndex = wayPoints.findIndex(wayPt => wayPt.key === recordKey)
    wayPoints[wayPointIndex].googlePlaceId = googlePlaceId || ''
    wayPoints[wayPointIndex].address = address || ''

    let location = {}
    if (latLng) {
      const { lat, lng } = latLng
      location.lat = lat.toFixed(7)
      location.lng = lng.toFixed(7)
    } else {
      location.lat = ''
      location.lng = ''
    }
    wayPoints[wayPointIndex].google = { geometry: { location } }

    this.setState({
      wayPoints,
      selectedKey: recordKey,
    })
  }

  handleRouteChange = routeId => {
    if (routeId) {
      const { routes } = this.state
      const stateDt = { ...this.state, selectedRouteId: routeId }
      const params = this.processQueryParams(stateDt)
      const selectedRoute = routes.filter(route => route.id === routeId)[0]
      const { origin, destination } = selectedRoute
      origin.type = 'origin'
      origin.sequence = 'O'
      origin.key = new Date().getTime()
      destination.type = 'destination'
      destination.sequence = 'D'
      destination.key = new Date().getTime() + 1
      const sourceDestination = [origin, destination]
      request(`${API_URL}/saas/lego/company_routes_waypoints/fetch?${params}`).then(
        response => {
          if (response.success) {
            let seqNo = 1
            const wayPointDt = response.data || []
            if (wayPointDt.length > 0) {
              const seqDt = wayPointDt.map((item, index) => {
                seqNo++
                return (
                  item &&
                  Object.assign({ ...item, sequence: index + 1, key: new Date().getTime() + index })
                )
              })
              this.setState({
                wayPoints: seqDt,
                selectedRoute,
                selectedRouteId: routeId,
                sourceDestination,
                lastSequence: seqNo,
                disableAddBtn: false,
              })
            } else {
              this.setState({
                selectedRoute,
                selectedRouteId: routeId,
                sourceDestination,
                lastSequence: seqNo,
                disableAddBtn: false,
                wayPoints: [],
              })
            }
          } else {
            response.message.map(msg => message.error(msg))
          }
        },
        err => {
          message.error('Something went wrong. Please try again', 2)
          this.setState({
            disableAddBtn: false,
            wayPoints: [],
            sourceDestination: [],
          })
        }
      )
    } else {
      this.setState({
        disableAddBtn: false,
        wayPoints: [],
        selectedRoute: {},
        selectedRouteId: '',
        sourceDestination: [],
      })
    }
  }

  render() {
    const {
      selectedKey,
      wayPoints,
      sourceDestination,
      routes,
      selectedRouteId,
      disableAddBtn,
      showDirection,
    } = this.state
    const allWayPoints =
      sourceDestination.length > 0
        ? [sourceDestination[0], ...wayPoints, sourceDestination[1]]
        : wayPoints
    const columns = [
      {
        title: <p style={{ width: '130px' }}>Label</p>,
        dataIndex: 'label',
        key: 'label',

        render: (text, record) =>
          selectedKey === record.key ? (
            <Input value={text} onChange={e => this.changeFieldValue(e, 'label', record)} />
          ) : (
            <span>{text}</span>
          ),
      },
      {
        title: <p style={{ width: '130px' }}>Code</p>,
        dataIndex: 'cpCode',
        key: 'cpCode',
        render: (text, record) =>
          selectedKey === record.key ? (
            <Input value={text} onChange={e => this.changeFieldValue(e, 'cpCode', record)} />
          ) : (
            <span>{text}</span>
          ),
      },
      {
        title: <p style={{ width: '140px' }}>Geo-Code</p>,
        dataIndex: 'geoCode',
        key: 'geoCode',

        render: (text, record) => {
          const {
            google: {
              geometry: {
                location: { lat, lng },
              },
            },
          } = record
          const latLng = lat && lng && `${lat},${lng}`
          return selectedKey === record.key ? (
            <div>
              <div className={css`position:relative`}>
                <MapSearchBox
                  addLatLng={this.addLatLng}
                  recordKey={record.key}
                  value={record.address}
                />
              </div>
            </div>
          ) : (
            <span>{latLng}</span>
          )
        },
      },
      {
        title: <p style={{ width: '145px' }}>Address</p>,
        dataIndex: 'address',
        key: 'address',
        render: (text, record) => {
          return selectedKey === record.key ? (
            <Input value={text} onChange={e => this.changeFieldValue(e, 'address', record)} />
          ) : (
            <span>{text}</span>
          )
        },
      },
      {
        title: 'Geo Fence Radius(in kms)',
        width: '15%',
        dataIndex: 'geofenceRadius',
        key: 'geofenceRadius',
        render: (text, record) => {
          return selectedKey === record.key ? (
            <Input
              type="number"
              min="0"
              value={text}
              onChange={e => this.changeFieldValue(e, 'geofenceRadius', record)}
            />
          ) : (
            <span>{Number(text).toFixed(2)}</span>
          )
        },
      },
      {
        title: 'Sequence',
        dataIndex: 'sequence',
        key: 'sequence',
        width: '9%',
      },
      {
        title: 'Action',
        width: '16%',
        render: (text, record) =>
          selectedKey === record.key ? (
            <div>
              <Button
                type="primary"
                onClick={() => this.saveRecord(record)}
                disabled={
                  !record.label || !record.googlePlaceId || Number(record.geofenceRadius) < 0
                }
              >
                Save
              </Button>
              <Button onClick={() => this.cancelRecord(record.key)}>Cancel</Button>
            </div>
          ) : (
            <Button type="danger" onClick={() => this.deleteRecord(record)}>
              Delete
            </Button>
          ),
      },
    ]
    return (
      <Row>
        <SubHeader />
        <div className={css` padding:20px`}>
          <Row className={css`padding: 0 0 20px 0`}>
            <Col xs={12}>
              <Select onSelect={this.handleRouteChange} value={selectedRouteId}>
                {routes &&
                  routes.map(route => (
                    <Option key={route.id} value={route.id}>
                      {route.label}
                    </Option>
                  ))}
                <Option value="">{'Select a Route'}</Option>
              </Select>
            </Col>
          </Row>
          <Row>
            <Table
              style={{ margin: '-20px' }}
              columns={columns}
              dataSource={wayPoints}
              components={this.components}
              pagination={false}
              bordered
              onRow={index => ({
                index,
                moveRow: this.moveRow,
              })}
            />
          </Row>
          <Row className="addWayPointContainer">
            <Button
              type="primary"
              onClick={this.addWayPoint}
              style={{ margin: 0 }}
              disabled={disableAddBtn || !selectedRouteId}
            >
              +Add
            </Button>
          </Row>
          {allWayPoints.length > 0 && (
            <Row className={css`paddingTop:10px, height:500px`}>
              <Map
                places={allWayPoints}
                setlnglat={this.addWayPoint}
                changeWayPoint={this.changeWayPoint}
                showDirection={showDirection}
              />
            </Row>
          )}
        </div>
      </Row>
    )
  }
}

const WayPointList = DragDropContext(HTML5Backend)(DragableTable)

export default WayPointList
