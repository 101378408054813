import { Button, Row,message } from 'antd'
import React from 'react'
import SubHeader from 'utils/subHeader'
import 'containers/routesMaster/App.css'
import { withRouter } from 'react-router'

class ExceptionReport extends React.Component {
  
  addExceptionReport = () => {
    const { search } = this.props.location;
    if(search){
      this.props.history.push({
        pathname: '/exception-report/add',
        search: search,
      })
     } else{
        message.error('Select a company to Exception Report')
     }
  }


  setExceptionReport = () => {
    return (
      <div>
        <Button size="large" type="primary" onClick={this.addExceptionReport}>
          Set Exception Report 
        </Button>
      </div>
    )
  }

  render() {
    return (
      <Row>
        <SubHeader innerhtml={this.setExceptionReport} />
        {this.props.children}
      </Row>
    )
  }
}

export default withRouter(ExceptionReport)
