export const geocodeByPlaceIdFix = (placeId, fields) => {
  const geocoder = new window.google.maps.places.PlacesService(
    new window.google.maps.Map(document.getElementById('bharghav'))
  )
  const OK = window.google.maps.places.PlacesServiceStatus.OK

  return new Promise((resolve, reject) => {
    geocoder.getDetails({ placeId, fields }, (results, status) => {
      if (status !== OK) {
        reject(status)
      }
      resolve(results)
    })
  })
}
