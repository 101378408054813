import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import RoutesMain from 'components/legoRoutes/routesMain'
import AddRoutes from 'components/legoRoutes/addRoutes'
import UploadRoute from 'components/legoRoutes/uploadRoute'
import ListRoutes from 'components/legoRoutes/listRoutes'
import RouteMaster from 'components/routemaster/routemaster'
import RouteMasterList from 'components/routemaster/routemasterlist'
import AddNewRoute from 'components/routemaster/addnewroute'
import UploadRouteMaster from 'components/routemaster/uploadroutemaster'
import VehicleMaster from 'components/vehiclemaster/vehiclemaster'
import Transporters from 'components/transporters/transporters'
import ViewTransporters from 'components/transporters/viewtransporters'
import AddTransporters from 'components/transporters/addtransporters'
import MaterialMaster from 'components/materialmaster/materialmaster'
import WayPointList from 'containers/wayPoint/list'
import KnownPlaces from 'containers/KnownPlaces'
import ListKnownPlaces from 'components/knownPlaces/list'
import AddKnownPlaces from 'components/knownPlaces/add'
import UploadKnownPlaces from 'components/knownPlaces/upload'

import AlertSetting from 'containers/alertsetting'
import AlertsList from 'components/alertsettings/list'
import UploadAlerts from 'components/alertsettings/upload'

import AlertTicketUIConfig from 'containers/alertTicketUISetting'
import AlertTicketUIConfigList from 'components/alertTicketUISetting/list'
import AddAlertTicketUIConfig from 'components/alertTicketUISetting/add'

import AutoClosure from 'containers/autoclosure'
import AutoClosureList from 'components/autoclosure/list'
import AutoClosureConfig from 'components/autoclosure/config'

import SidebarMenu from 'containers/sidebarmenu'
import SidebarMenuList from 'components/sidebarmenu/list'
import SidebarMenuAdd from 'components/sidebarmenu/add'

import DashboardSetting from 'containers/dashboard'
import DashboardList from 'components/dashboardsettings/list'
import DashboardConfig from 'components/dashboardsettings/config'

import DailySummaryReportSputnik from 'containers/DailySummaryReportSputnik'
import DailySummarySputnikList from 'components/dailySummaryReportSputnik/DailySummarySputnikList'
import AddDailySummaryReportSputnik from 'components/dailySummaryReportSputnik/dailySummaryConfigSputnik'


import ExceptionReport from 'containers/ExceptionReport'
import ExceptionReportList from 'components/ExceptionReport/exceptionReportList'
import AddExceptionReport from 'components/ExceptionReport/exceptionReportConfig'


import Consignees from 'containers/consignees'
import ListConsignees from 'components/consignees/list'
import AddConsignees from 'components/consignees/add'
import UploadConsignee from 'components/consignees/upload'
import ConfigurationMain from 'components/templateCreation/configurationMain'
import EventList from 'components/templateCreation/eventList'
import NotificationConfig from 'components/templateCreation/notificationConfing'
import ConfigurationList from 'components/templateCreation/configurationList'
import EPODConfigurations from 'components/epodConfigurations/epodConfigurations'
import APConfiguration from 'components/apConfiguration/apConfiguration'
import APConfigFormPage from 'components/apConfiguration/apConfigFormPage'
import InsertManualPing from 'components/manualPing/insertPing'
import TripDataForm from 'components/trip/tripData'

import LegoAlertConfigContainer from 'containers/legoAlertConfig'
import LegoAlertConfigFormPage from '../components/legoAlertConfig/alertConfigMain'
import LegoConfigContainer from 'containers/legoConfig'
import LegoConfigFormPage from 'components/legoConfig/config'
import EntityConfigContainer from 'containers/entityConfig'
import EntityConfigFormPage from 'components/entityConfig/config'
import MapViewContainer from 'containers/mapView'
import EnableMapViewFormPage from 'components/mapView/enableMapView'
import LastMileThresholdContainer from 'containers/lastMileThreshold'
import LastMileThresholdFormPage from '../components/lastMileThreshold/lastMile'
import EtaConfigContainer from '../containers/etaPreferences'
import EtaConfigFormPage from '../components/etaPreferences/etaConfig'
import BulkUpload from '../components/bulkUpload/bulk-upload'


export default Routes => {
  return (
    <Switch>
      <Redirect exact from="/" to="/configuration-list" />
      <Route path="/routes">
        <RoutesMain>
          <Route exact path="/routes" component={ListRoutes} />
          <Route path="/routes/add" component={AddRoutes} />
          <Route path="/routes/:id/edit" component={AddRoutes} />
          <Route path="/routes/upload" component={UploadRoute} />
        </RoutesMain>
      </Route>
      <Route path="/route-master">
        <RouteMaster>
          <Route exact path="/route-master/" component={RouteMasterList} />
          <Route exact path="/route-master/add" component={AddNewRoute} />
          <Route exact path="/route-master/upload" component={UploadRouteMaster} />
        </RouteMaster>
      </Route>
      <Route exact strict path="/way-point" component={WayPointList} />
      <Route exact strict path="/vehicle-master" component={VehicleMaster} />
      <Route path="/transporters">
        <Transporters>
          <Route exact path="/transporters" component={ViewTransporters} />
          <Route exact path="/transporters/:id/edit" component={AddTransporters} />
          <Route path="/transporters/add" component={AddTransporters} />
        </Transporters>
      </Route>
      <Route exact strict path="/materialmaster" component={MaterialMaster} />
      <Route path="/known-places">
        <KnownPlaces>
          <Route exact path="/known-places" component={ListKnownPlaces} />
          <Route path="/known-places/add" component={AddKnownPlaces} />
          <Route path="/known-places/:id/edit" component={AddKnownPlaces} />
          <Route path="/known-places/upload" component={UploadKnownPlaces} />
        </KnownPlaces>
      </Route>
      <Route path="/alert-settings">
        <AlertSetting>
          <Route exact strict path="/alert-settings" component={AlertsList} />
          <Route exact strict path="/alert-settings/upload" component={UploadAlerts} />
        </AlertSetting>
      </Route>  
      <Route path="/dashboard-settings">
        <DashboardSetting>
          <Route exact strict path="/dashboard-settings" component={DashboardList} />
          <Route exact  path="/dashboard-settings/edit/:id/" component={DashboardConfig} />
          <Route exact strict path="/dashboard-settings/add" component={DashboardConfig} />
        </DashboardSetting> 
      </Route>  
      <Route path="/sidebar-menu">
        <SidebarMenu>
          <Route exact strict path="/sidebar-menu" component={SidebarMenuList} />
          <Route path="/sidebar-menu/edit/:id/" component={SidebarMenuAdd} />
          <Route exact strict path="/sidebar-menu/add" component={SidebarMenuAdd} />
        </SidebarMenu>
      </Route>
      <Route path="/sputnik-daily-summary">
        <DailySummaryReportSputnik>
          <Route exact strict path="/sputnik-daily-summary" component={DailySummarySputnikList} />
          <Route exact strict path="/sputnik-daily-summary/add" component={AddDailySummaryReportSputnik} />
          <Route exact path="/sputnik-daily-summary/edit/:id/"   component={AddDailySummaryReportSputnik} />
        </DailySummaryReportSputnik>
      </Route>
      <Route path="/display-alert-ticket">
        <AlertTicketUIConfig>
          <Route exact strict path="/display-alert-ticket" component={AlertTicketUIConfigList} />
          <Route exact strict path="/display-alert-ticket/add" component={AddAlertTicketUIConfig} />
          <Route path="/display-alert-ticket/edit/:id/"   component={AddAlertTicketUIConfig} />
        </AlertTicketUIConfig>
      </Route>
      <Route path="/auto-closure">
        <AutoClosure>
          <Route exact strict path="/auto-closure" component={AutoClosureList} />
          <Route exact strict path="/auto-closure/add" component={AutoClosureConfig} />
          <Route path="/auto-closure/edit/:id/"   component={AutoClosureConfig} />
        </AutoClosure>
      </Route>    
      <Route path="/exception-report">
        <ExceptionReport>
          <Route exact strict path="/exception-report" component={ExceptionReportList} />
          <Route exact strict path="/exception-report/add" component={AddExceptionReport} />
          <Route exact path="/exception-report/edit/:id/"   component={ AddExceptionReport } />
        </ExceptionReport>
      </Route>
      <Route path="/consignees">
        <Consignees>
          <Route exact path="/consignees" component={ListConsignees} />
          <Route path="/consignees/add" component={AddConsignees} />
          <Route path="/consignees/:id/edit" component={AddConsignees} />
          <Route path="/consignees/upload" component={UploadConsignee} />
        </Consignees>
      </Route>
      <Route exact strict path="/event-list" component={EventList} />
      <Route path="/configuration-list">
        <ConfigurationMain>
          <Route exact path="/configuration-list" component={ConfigurationList} />
          <Route
            exact
            strict
            path="/configuration-list/notification-config"
            component={NotificationConfig}
          />
          <Route
            exact
            strict
            path="/configuration-list/edit-notification-config/:customerConfigId"
            component={NotificationConfig}
          />
        </ConfigurationMain>
      </Route>
      <Route exact path="/epod-configuration" component={EPODConfigurations} />
      <Route exact path="/ap-configuration" component={APConfiguration} />
      <Route exact path="/ap-configuration/add" component={APConfigFormPage} />
      <Route exact path="/ap-configuration/:id/edit" component={APConfigFormPage} />

      <Route path="/alert-configuration">
        <LegoAlertConfigContainer>
          <Route exact path="/alert-configuration" component={LegoAlertConfigFormPage} />
        </LegoAlertConfigContainer>
      </Route>
      <Route path="/configurations">
        <LegoConfigContainer>
          <Route exact path="/configurations" component={LegoConfigFormPage} />
        </LegoConfigContainer>
      </Route>
      <Route path="/entity-detail">
        <EntityConfigContainer>
          <Route exact path="/entity-detail" component={EntityConfigFormPage} />
        </EntityConfigContainer>
      </Route>
      <Route path="/utilities/enable-map-view">
        <MapViewContainer>
          <Route exact path="/utilities/enable-map-view" component={EnableMapViewFormPage} />
        </MapViewContainer>
      </Route>
      <Route path="/utilities/last-mile-threshold">
        <LastMileThresholdContainer>
          <Route exact path="/utilities/last-mile-threshold" component={LastMileThresholdFormPage} />
        </LastMileThresholdContainer>
      </Route>
      <Route path="/utilities/eta-preferences">
        <EtaConfigContainer>
          <Route exact path="/utilities/eta-preferences" component={EtaConfigFormPage} />
        </EtaConfigContainer>
      </Route>
      <Route exact path="/utilities/insert-manual-ping" component={InsertManualPing} />
      <Route exact path="/utilities/trip-data" component={TripDataForm} />
      <Route exact path="/bulk-upload/:component" component={BulkUpload} />  
    </Switch>
  )
}
