import React from 'react'
import { Button, Row, Col, Icon, message } from 'antd'
import { HotTable } from '@handsontable/react'
import _ from 'lodash'
import fetchData from 'utils/fetchData'
import { convertQsToJson, constructPhoenixUrl } from 'utils/common'
import ImportFileComponent from 'components/common/ImportFileComponent'

class AlertsUpload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      downloadData: [],
      isLoaded: false,
      alertsData: [[]],
      autocompleteData: [],
      autocompleteDataPlaceId: [],
      company: null,
      branch: null,
      department: null,
      atLeastOneSuccess: false,
      ImportFileComponentKey: Date.now(),
    }

    this.formatLink =
      // 'https://docs.google.com/spreadsheets/d/1QB5uxEmOjg0-4RHdEC89-SkLOTzrfBt9/edit#gid=218026192'
      'https://docs.google.com/spreadsheets/d/1zBC4NFWyMPDQ3EoiLoUmZ9Czwc7j3Zob/edit#gid=218026192'
  }

  componentDidMount() {
    const parsedDt = convertQsToJson(this.props.location.search)
    const cId = parsedDt.companyId || ''
    const bId = parsedDt.branchId || ''
    const dId = parsedDt.departmentId || ''

    this.setState({
      company: cId,
      branch: bId,
      department: dId,
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search === '') {
      this.setState({ routesData: [] })
    }

    const parsedDt = convertQsToJson(nextProps.location.search)
    const cId = parsedDt.companyId || ''
    const bId = parsedDt.branchId || ''
    const dId = parsedDt.departmentId || ''

    

    this.setState({
      company: cId,
      branch: bId,
      department: dId,
    })
  }

  async saveAlertData() {
    const data = this.refs.hot.hotInstance.getData()
    if (data.length == 1 && data[0].filter((d) => d !== null).length == 0) {
      message.error('No data to upload, upload a file or paste some data')
      return
    }
    const createUrl = constructPhoenixUrl(
      'saas/data/automation/setAlerts?accessKey=' + `${process.env.REACT_APP_TRANSDB_KEY}` + '&',
      this.props.location.search,
      {
        bulkUpload: true,
        allOrNone: false,
      }
    )
    let type = 'POST'
    let payload = _.map(data, (s) => {
      return {
        CompanyId: s[0],
        branchId: s[1],
        Alert_Name: s[2],
        config_one: s[3],
        config_two: s[4],
        config_three: s[5],
        Buffer: s[6],
        emailId_level_one: s[7],
        emailId_level_two: s[8],
        emailId_level_three: s[9],
        geofence_entry_time: s[10],
        interrupted_manual_min: s[11],
        interrupted_auto_min: s[12],
        start_time: s[13],
        end_time: s[14],
        cd_break_min: s[15],
        cd_break_min_trip_distance_km: s[16],
        licencePlate: s[17],
        location_asset: s[18],
        close_old_trips: s[19],
        enable_ticket: s[20],
        trip_creation_time_gap_mins: s[21],
        primary_attributes: s[22],
        longstoppage_l1_short_threshold: s[23],
        longstoppage_l1_mid_threshold: s[24],
        longstoppage_l1_long_threshold: s[25],
        longstoppage_l2_short_threshold: s[26],
        longstoppage_l2_mid_threshold: s[27],
        longstoppage_l2_long_threshold: s[28],
        longstoppage_l3_short_threshold: s[29],
        longstoppage_l3_mid_threshold: s[30],
        longstoppage_l3_long_threshold: s[31],
        route_deviation_away_from_destination_minimum_distance_percent: s[32],
        route_deviation_away_from_destination_minimum_distance_km: s[33],
        transit_time_buffer: s[34],
        loading_time_buffer: s[35],
        transit_delay_threshold: s[36]
      }
    })

    fetchData(createUrl, type, payload)
      .then((result) => {
        if (result.result.status == true) {
          const res = result.result.data ? result.result.data : null
          message.success(`Alert config set successfully for companyIds [ ${res} ] `)
        } else {
          message.error('Alert config already exists')
        }
        this.props.history.push({
          pathname: '/alert-settings',
          search: this.props.location.search,
        })
      })
      .catch((error) => {
        message.error(error)
      })
  }

  async updateAlertData(d) {
    let i = 1
    const hot = this.refs.hot.hotInstance
    if (hot.isEmptyRow(d[1])) {
      message.error('Please add some data to the csv before uploading')
      return
    }

    while (i < d.length) {
      const tempRow = d[i]
      if (this.state.alertsData.length == 1 && this.state.alertsData[0].length == 0) {
        this.setState({
          alertsData: [...this.state.alertsData[0], tempRow],
        })
      } else {
        this.setState({
          alertsData: this.state.alertsData.concat([tempRow]),
        })
      }
      i++
    }
  }

  removeSuccess() {
    let data = this.refs.hot.hotInstance.getData()
    data = data.filter((s) => s[8] !== 'Success')
    this.setState({
      alertsData: data,
    })
  }

  resetData() {
    this.setState({
      alertsData: [[]],
      ImportFileComponentKey: Date.now(),
    })
  }

  cta() {
    return (
      <div>
        <Col xs={9}>
          <div style={{ float: 'left' }}>
            <ImportFileComponent
              handleParse={(d) => this.updateAlertData(d)}
              inputKey={this.state.ImportFileComponentKey}
            />
          </div>
          <Button type="primary" onClick={() => this.saveAlertData()}>
            Submit
          </Button>
        </Col>
        <Col xs={3}>
          <div>
            <a href={this.formatLink} target="_blank">
              Please Refer Format
            </a>
          </div>
        </Col>
        <Col xs={12} style={{ textAlign: 'right' }}>
          {this.state.atLeastOneSuccess && (
            <a href="javascript:void(0);" onClick={() => this.removeSuccess()}>
              <Icon type="cross" />
              Remove Success
            </a>
          )}
          <Button type="primary" style={{ marginLeft: '20px' }} onClick={() => this.resetData()}>
            <Icon type="undo" /> Reset
          </Button>
        </Col>
      </div>
    )
  }

  render() {
    const { alertsData } = this.state
    const alertsDataCopy = [...alertsData] // this is required to be done because when passing state object directly, HOT was adding a blank object after every row
    return (
      <div>
        <div style={{ display: 'none' }} id="bharghav" />
        <Row style={{ padding: '20px 20px 0 20px' }}>{this.cta()}</Row>
        <Row style={{ padding: '20px' }} width={100}>
          <HotTable
            data={alertsDataCopy}
            afterScrollHorizontally={true}
            width={100}
            colHeaders={[
              '<p class="tooltip" title="Place code">CompanyId</p>',
              'branchId',
              'Alert Name ',
              'Configuration (Level 1 )',
              'Configuration ( Level 2 )',
              'Configuration ( Level 3 )',
              'Buffer',
              'Email ID ( Level 1 )',
              'Email ID ( Level 2 )',
              'Email ID ( Level 3 )',
              'geofence_entry_time',
              'interrupted_manual_min',
              'interrupted_auto_min',
              'start time',
              'end time',
              'cd_break_min',
              'cd_break_min_trip_distance_km',
              'licencePlate',
              'location_asset',
              'close_old_trips',
              'Enable Tickets',
              'Trip Creation gap mins',
              'Primary Attributes',
              'longstoppage_l1_short_threshold',
              'longstoppage_l1_mid_threshold',
              'longstoppage_l1_long_threshold',
              'longstoppage_l2_short_threshold',
              'longstoppage_l2_mid_threshold',
              'longstoppage_l2_long_threshold',
              'longstoppage_l3_short_threshold',
              'longstoppage_l3_mid_threshold',
              'longstoppage_l3_long_threshold',
              'route_deviation_away_from_destination_minimum_distance_percent',
              'route_deviation_away_from_destination_minimum_distance_km',
              'transit_time_buffer',
              'loading_time_buffer',
              'transit_delay_threshold'
              // '<span style="color: #f0f0f0">Geocoding Status<span>',
              // 'Success Status',
            ]}
            manualColumnResize={true}
            maxRows={600}
            manualRowResize={true}
            columns={new Array(37).fill({})}
            rowHeaders={true}
            minRows={1}
            ref="hot"
            width={1600}
            stretchH="all"
            afterChange={async (changes, src) => {
              if (src == 'CopyPaste.paste' || src == 'edit') {
                //both address and lat lng changes. Basically a row was pasted
                if (
                  changes[3] !== undefined &&
                  changes[4] !== undefined &&
                  changes[5] !== undefined &&
                  changes[3][3] !== '\n' &&
                  changes[5][3] !== '' &&
                  changes[4][3] !== '\n' &&
                  changes[5][3] !== '\n' &&
                  changes[4][3] !== '' &&
                  changes[5][3] !== ''
                ) {
                  return
                }
                const llchanges = changes.filter((c) => {
                  return c[1] == 4 || c[1] == 5
                })

                for (let i = 0; i < llchanges.length; i++) {
                  const data = this.refs.hot.hotInstance.getData()
                  const row = data[llchanges[i][0]]

                  this.setState({
                    alertsData: data,
                  })
                }
              }
            }}
          />
        </Row>
      </div>
    )
  }
}

export default AlertsUpload
