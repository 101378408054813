import React from 'react'
import { Table, message } from 'antd'
import request from 'utils/request'
import { convertQsToJson } from 'utils/common'
class RouteMasterList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      routesData: [],
    }
  }

  componentDidMount() {
    const parsedDt = convertQsToJson(this.props.location.search)
    if (!parsedDt.companyId) {
      message.info('Select a company to continue')
    } else {
      this.constructUrl(this.props.location.search)
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search === '') {
      this.setState({ routesData: [] })
    } else {
      this.constructUrl(nextProps.location.search)
    }
  }
  constructUrl(qs) {
    const parsedDt = convertQsToJson(qs)
    const cId = parsedDt.companyId || ''
    const bId = parsedDt.branchId || ''
    const dId = parsedDt.departmentId || ''
    const url = `${
      window._env_.REACT_APP_API_URL
    }/saas/lego/company_routes_master/fetch?c=${cId}&b=${bId}&d=${dId}&t=CONSIGNOR&pt=TRANSPORTER`
    if (cId) {
      this.fetchRouteMasterList(url)
    }
  }

  fetchKey(key) {
    const qData = convertQsToJson(this.props.location.search)
    return qData[key]
  }

  fetchRouteMasterList(url) {
    request(url).then(
      result => {
        if (result.success) {
          let routesData = result.data
          routesData.forEach(element => {
            element.validFrom = new Date(element.validFrom).toLocaleDateString()
            element.data.forEach(item => {
              if (item.key === 'ROUTE_COST')
                element.routeCost = item.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            })
            delete element.data
          })
          this.setState({ routesData })
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        message.error('Error while fetching routes data')
      }
    )
  }

  render() {
    const columns = [
      {
        dataIndex: 'partnerName',
        title: 'Transporter',
      },
      {
        dataIndex: 'routeLabel',
        title: 'Route',
      },
      {
        dataIndex: 'vehicleType',
        title: 'Vehicle Type',
      },
      {
        dataIndex: 'materialType',
        title: 'Material Type',
      },
      {
        dataIndex: 'validFrom',
        title: 'Valid From',
      },

      {
        dataIndex: 'routeCost',
        title: 'Freight Cost',
        render: (text, record) => <span> &#8377; {record.routeCost}</span>,
      },
    ]

    const routeMasterData = this.state.routesData

    return (
      <Table
        columns={columns}
        dataSource={routeMasterData}
        locale={{ emptyText: 'No routes found for the given selection' }}
        rowKey={record => record.partner_name}
      />
    )
  }
}
export default RouteMasterList
