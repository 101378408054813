import { Button, Row } from 'antd'
import React from 'react'
import SubHeader from 'utils/subHeader'
import 'containers/routesMaster/App.css'
import { withRouter } from 'react-router'

class Consignees extends React.Component {
  showAddConsignee = () => {
    this.props.history.push({
      pathname: '/consignees/add',
      search: this.props.location.search,
    })
  }

  showUploadConsignee = () => {
    this.props.history.push({
      pathname: '/consignees/upload',
      search: this.props.location.search,
    })
  }

  cta = () => {
    return (
      <div>
        <Button size="large" type="primary" onClick={this.showAddConsignee}>
          Add consignee
        </Button>
        <Button
          style={{ marginLeft: 10 }}
          size="large"
          type="primary"
          onClick={this.showUploadConsignee}
        >
          Upload
        </Button>
      </div>
    )
  }

  render() {
    return (
      <Row>
        <SubHeader innerhtml={this.cta} />
        {this.props.children}
      </Row>
    )
  }
}

export default withRouter(Consignees)
