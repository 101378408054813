import React from 'react'
import {
  Row,
  Form,
  Input,
  Select,
  Col,
  Divider,
  Button,
  Modal,
  message,
  Anchor,
  Steps,
  Radio,
} from 'antd'
import Editor from './editor'
import './templateStyle.css'
import { convertQsToJson, sortListBasedOnKey } from 'utils/common'
import fetchData from 'utils/fetchData'
import { css } from '@emotion/css'

const Option = Select.Option
const { Link } = Anchor
const Step = Steps.Step
const RadioGroup = Radio.Group
const Notification_API = window._env_.REACT_APP_NOTIFICATION_API_URL
const { TextArea } = Input

const WhatsappHeaderFooterSection = ({ title, onClick }) => {
  return (
    <Row style={{ paddingBottom: '25px' }} gutter={16}>
      <Col style={{ textAlign: 'right', paddingTop: '8px' }} span={6}>
        {title}
      </Col>
      <Col span={18}>
        <Anchor style={{ width: '55%' }} onClick={onClick} affix={false}>
          <Link title={title} />
        </Anchor>
      </Col>
    </Row>
  )
}

const DynamicWebhookConfig = ({ label, decorator, initialValue, getFieldDecorator, required, message }) => {
  return (
    <Row gutter={16}>
      <Col style={{ textAlign: 'right', paddingTop: '8px' }} span={6}>
        <label>
          {required ? <span style={{ color: 'red' }}>*</span> : ''} {label} :
        </label>
      </Col>
      <Col span={10}>
        <Form.Item>
          {getFieldDecorator(decorator, {
            initialValue: initialValue,
            rules: [{ required: required, message: message }]
          })(<Input size="large" />)}
        </Form.Item>
      </Col>
    </Row>
  )
}

const WhatsappHeaderFooterModal = ({ title, visible, content, saveContent, handlePopup, handleCancel, tripInfo, copyToClipboard }) => {
  return (
    <Modal
      width={1200}
      footer={null}
      title={title}
      visible={visible}
      onCancel={handleCancel}
    >
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <div style={{ width: '40%', display: 'flex' }}>
          <div style={{ width: '50%', height: '300px', overflow: 'auto' }}>
            {tripInfo &&
              tripInfo.map((item, index) => (
                <ol
                  style={{ cursor: 'pointer' }}
                  className="rounded-list"
                  key={index}
                  onClick={() => copyToClipboard(item.key)}
                >
                  <li>{item.key}</li>
                </ol>
              ))}
          </div>
        </div>
        <div style={{ border: '1px solid #ccc', width: '60%' }}>
          <span style={{ fontSize: 9, color: 'red', marginLeft: '15px' }}>
            Note: To paste in Windows use CTR+V and In Mac use CMD+V
          </span>
          <div>
            <div
              style={{
                display: 'flex',
                paddingBottom: '5px',
                border: '1px solid #ccc',
                marginLeft: '15px',
                width: '80%',
              }}
            >
              <TextArea
                rows={4}
                placeholder={title}
                value={content}
                onChange={saveContent}
              />
            </div>
            <div style={{ marginLeft: '15px' }}>
              <Button
                className={css`
                        margin-top: 1%;
                      `}
                size="large"
                type="primary"
                htmlType="submit"
                onClick={handlePopup}
                disabled={false}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

class NotificationConfig extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileNumbers: [''],
      toEmailList: [''],
      toCcList: [''],
      toBccList: [''],
      allFlag: {
        mobileDisableFlag: true,
        toEmailDisable: true,
        toCcDisable: true,
        toBccDisable: true,
      },
      mode: '',
      visible: false,
      goToNext: true,
      emailSubject: false,
      tripInfo: [],
      content: '',
      selectedSection: '',
      current: 0,
      smsContent: '',
      smsContentFlag: false,
      subjectContent: '',
      subjectContentFlag: false,
      onboardedEvent: [],
      cId: '',
      bId: '',
      dId: '',
      uId: '',
      gId: '',
      rcId: '',
      rbId: '',
      rdId: '',
      eID: '',
      eventExisted: false,
      configId: '',
      subjectId: '',
      bodyId: '',
      editURL: '',
      editedData: '',
      eventName: '',
      headerContent: '',
      bodyContent: '',
      footerContent: '',
      selectedContent: 'bodyContent',
      eventValue: {},
      eventArray: [],
      surroundedType: '',
      headerContent: '',
      footerContent: '',
      bodyContent: '',
      headerTemplateId: '',
      footerTemplateId: '',
      methodType: '',
      Authkey: '',
      Header: '',
      User: '',
      Password: '',
      contentType: '',
      whatsappHeader: false,
      whatsappFooter: false,
      authType: 'BASIC',
      dynamicTokenFlag: false,
      dynamicConfig: {
        method: '',
        url: '',
        contentType: '',
        authType: '',
        authkey: '',
        header: '',
        content: '',
        accessTokenJsonPath: '',
        ttlJsonPath: ''
      }
    }
  }
  editTemplate = url => {
    return fetchData(url)
      .then(result => {
        return result;
      })
      .catch(function(result) {
        console.log(result)
      })
  }
  setDefaultValue = data => {
    const items = {
      mobilenumbers: 'mobileDisableFlag',
      emailTo: 'toEmailDisable',
      emailBcc: 'toBccDisable',
      emailCc: 'toCcDisable',
    }
    const newObj = {}
    Object.keys(items).forEach((key, value) => {
      let itemList = data[key] ? data[key].split(',') : []
      newObj[items[key]] = itemList.length > 1 ? false : true
      return newObj
    })
    this.setState({
      allFlag: newObj,
    })
    this.props.form.setFieldsValue({ event: data.eventId })
    let tmp = this.state
    tmp.bodyContent = data.body
    tmp.headerContent = data.header ? data.header : ''
    tmp.footerContent = data.footer ? data.footer : ''
    this.setState({ tmp })
    const dynamicWebhookConfig = data.dynamicWebhookConfigs || this.state.dynamicConfig
    this.setState(
      {
        configId: data.customerConfigId,
        subjectId: data.subjectTemplateId,
        bodyId: data.bodyTemplateId,
        mode: data.mode,
        eventName: data.eventName,
        smsContent: data.body,
        subjectContent: data.subject,
        content: data.body,
        headerTemplateId: data.headerTemplateId,
        footerTemplateId: data.footerTemplateId,
        mobileNumbers: data.mobilenumbers && data.mobilenumbers.split(','),
        toCcList: data.emailCc && data.emailCc.split(','),
        toEmailList: data.emailTo && data.emailTo.split(','),
        toBccList: data.emailBcc && data.emailBcc.split(','),
        methodType: data.method,
        URL: data.url,
        Authkey: data.authkey,
        Header: data.header,
        User: data.user,
        Password: data.password,
        contentType: data.contentType,
        headerContent: data.header,
        footerContent: data.footer,
        authType: data.authType,
        dynamicConfig: dynamicWebhookConfig,
      },
      () => {
        if (data.mobilenumbers !== null) {
          const mobileNumbers = data.mobilenumbers && data.mobilenumbers.split(',')
          if(mobileNumbers === ''){
            this.setState({ mobileNumbers: [''] })
          }
          for (let i = 0; i < mobileNumbers.length; i++) {
            this.props.form.setFieldsValue({
              [`mobileNumbers[${i}]`]: mobileNumbers[i],
            })
          }
        }
        if (data.emailTo !== null) {
          const emailTo = data.emailTo && data.emailTo.split(',')
          for (let i = 0; i < emailTo.length; i++) {
            this.props.form.setFieldsValue({
              [`toEmailList[${i}]`]: emailTo[i],
            })
          }
        }
        if (data.emailCc) {
          const cclist = data.emailCc && data.emailCc.split(',')
          for (let i = 0; i < cclist.length; i++) {
            this.props.form.setFieldsValue({
              [`toCcList[${i}]`]: cclist[i],
            })
          }
        } else {
          this.setState({ toCcList: [''] })
        }
        if (data.emailBcc) {
          const bccList = data.emailBcc && data.emailBcc.split(',')
          for (let i = 0; i < bccList.length; i++) {
            this.props.form.setFieldsValue({
              [`toBccList[${i}]`]: bccList[i],
            })
          }
        } else {
          this.setState({ toBccList: [''] })
        }
      }
    )
    if(data.dynamicWebhookConfigs){
      this.setState({ dynamicTokenFlag: true })
    }
  }
  async componentDidMount() {
    let config = this.props.location.pathname.split('/')
    let url = `${Notification_API}/notification/edit-configuration?customer_config_id=${config[3]}`
    const resp = await this.allOnBoarderEvent()
    this.setState({ onboardedEvent: resp && resp.value, editURL: url })
    if (config[3]) {
      this.editTemplate(this.state.editURL).then(result => {
        if (result.status === 'INTERNAL_SERVER_ERROR') {
          return
        }
        this.setState({ editedData: result })
        this.setDefaultValue(result)
        this.setState({ onboardedEvent: resp && resp.value, editURL: url })
      })
    }
    const result = (await this.getAllConfig()) || null
    let temp = []
    let count = 0
    for (let i = 0; i < result && result.value.length; i++) {
      temp.push({
        key: result.value[i],
        options: [
          { key: 'IF', val: ++count },
          { key: 'IF-ELSE', val: ++count },
        ],
      })
    }
    this.setState({ tripInfo: temp })
  }
  async getAllConfig() {
    return await fetchData(`${Notification_API}/notification/config-form-fields`)
      .then(result => {
        return result;
      })
      .catch(function(result) {
        console.log(result)
      })
  }
  async allOnBoarderEvent() {
    return await fetchData(`${Notification_API}/notification/onboarded-events`)
      .then(result => {
        if(result && result.value){
          sortListBasedOnKey(result.value, 'name')
        }
        return result;
      })
      .catch(function(result) {
        console.log(result)
      })
  }

  componentWillReceiveProps(nextProps) {
    const parsedDt = convertQsToJson(nextProps.location.search)
    const cId = parsedDt.companyId || ''
    const bId = parsedDt.branchId || ''
    const dId = parsedDt.departmentId || ''
    const uId = parsedDt.uID || ''
    const gId = parsedDt.gID || ''
    const rcId = parsedDt.relatedCompanyId || ''
    const rbId = parsedDt.relatedBranchId || ''
    const rdId = parsedDt.relatedDepartmentId || ''
    this.setState({ cId, bId, dId, uId, gId, rcId, rbId, rdId })
  }

  convertQsToJson(str) {
    let pairs = str.slice(1).split('&')
    let result = {}
    pairs.forEach(function(pair) {
      pair = pair.split('=')
      result[pair[0]] = decodeURIComponent(pair[1] || '')
    })
    return JSON.parse(JSON.stringify(result))
  }
  copyToClipboard = item => {
    var textField = document.createElement('textarea')
    if (this.state.surroundedType) {
      if (this.state.surroundedType === 'IF') {
        textField.innerText = '<#if ' + item + '??>${' + item + '}</#if>'
      } else {
        textField.innerText = '<#if ' + item + '??>${' + item + '}<#else></#if>'
      }
    } else if (this.state.mode !== 'WEBHOOKS') {
      textField.innerText = '${' + item + '}'
    } else {
      textField.innerText = '${' + item + '}'
    }
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    message.info('${' + item + '} ' + ' Copied to Clipboard')
    var t = '${' + item + '}'

    this.setState({
      clipboardtext: t,
    })
  }
  handleEmailSection = section => {
    this.setState({ selectedContent: section })
  }
  onChangeContent = evt => {
    this.setState({ bodyContent: evt.target.value })
  }
  sendDataToServer = data => {
    this.setState({ goToNext: false, current: 1 })
  }
  saveMobileContent = evt => {
    this.setState({ smsContent: evt.target.value })
    if (this.state.smsContent !== '') {
      this.setState({ smsContentFlag: false })
    }
  }

  handleMobileContent = (e) => {
    e.preventDefault()
    const { mode } = this.state
    if (mode === 'WEBHOOKS') {
      return this.setState({ visible: false })
    }
    if (this.state.smsContent === '' || !this.state.smsContent) {
      this.setState({ smsContentFlag: true })
    } else {
      this.setState({ visible: false })
    }
  }
  savesubjectContent = evt => {
    this.setState({ subjectContent: evt.target.value })
    if (this.state.subjectContent !== '') {
      this.setState({ subjectContentFlag: false })
    }
  }
  handleSubjectContent = () => {
    if (this.state.subjectContent === '') {
      this.setState({ subjectContentFlag: true })
    } else {
      this.setState({ emailSubject: false, eventValue: {} })
    }
  }

  saveHeaderFooterContent = (type, evt) => {
    this.setState({ [type]: evt.target.value })
  }
  handleHeaderFooterPopup = (type) => {
    this.setState({ [type]: false})
  }

  preview = () => {
    this.setState({ goToNext: true, current: 0 })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.createNotification(values)
      }
    })
  }
  createNotification(templatedata) {
    const { cId, uId } = this.state
    if (cId !== '' || uId !== '') {
      const {
        configId,
        bId,
        dId,
        gId,
        rcId,
        rbId,
        rdId,
        subjectId,
        bodyId,
        footerTemplateId,
        headerTemplateId,
        smsContent,
        headerContent,
        footerContent,
        bodyContent,
        subjectContent,
      } = this.state
      const data = {
        customerConfigId: configId ? parseInt(configId) : null,
        companyId: parseInt(cId),
        branchId: parseInt(bId),
        departmentId: parseInt(dId),
        userId: parseInt(uId),
        groupId: parseInt(gId),
        relatedCompanyId: parseInt(rcId),
        relatedBranchId: parseInt(rbId),
        relatedDepartmentId: parseInt(rdId),
        eventId: parseInt(templatedata.event),
        mode: templatedata.mode,
      }
      if (templatedata.mode === 'SMS') {
        data.mobilenumbers = templatedata.mobileNumbers.join(',')
        data.body = smsContent
        if (smsContent === '' || !smsContent) {
          return message.info('Please enter sms content')
        }
      } else {
        if (templatedata.mode === 'EMAIL') {
          data.subjectTemplateId = subjectId ? parseInt(subjectId) : null
          data.bodyTemplateId = bodyId ? parseInt(bodyId) : null
          data.footerTemplateId = parseInt(footerTemplateId)
          data.headerTemplateId = parseInt(headerTemplateId)
          data.header = headerContent
          data.footer = footerContent
          data.body = bodyContent
          data.emailTo = templatedata.toEmailList.join(',')
          data.emailCc = templatedata.toCcList.filter(Boolean).join(',')
          data.emailBcc = templatedata.toBccList.filter(Boolean).join(',')
          data.subject = subjectContent
          if (subjectContent === '' || !subjectContent) {
            return message.info('Please enter email subject')
          }
          if (!bodyContent || bodyContent.content === '') {
            return message.info('Please enter email body content')
          }
        }
      }
      if (templatedata.mode === 'WEBHOOKS') {
        data.bodyTemplateId = bodyId ? parseInt(bodyId) : null
        data.body = smsContent
        data.method = templatedata.method
        data.url = templatedata.url
        data.authkey = templatedata.authkey
        data.header = templatedata.header
        data.user = templatedata.user
        data.password = templatedata.password
        data.contentType = templatedata.contentType
        data.authType = templatedata.authType

        if (templatedata.dynamicConfig) {
          const { method, url, contentType, authType } = templatedata.dynamicConfig
          if (method && url && contentType && authType) {
            data.dynamicWebhookConfigs = templatedata.dynamicConfig
          }
        }
      }
      if(templatedata.mode === 'WHATSAPP'){
        data.mobilenumbers = templatedata.mobileNumbers.join(',')
        data.body = smsContent
        if (smsContent === '' || !smsContent) {
          return message.info('Please enter whatsapp content')
        }
        data.header = headerContent
        data.footer = footerContent
      }
      fetchData(`${Notification_API}/notification/save-configuration`,'POST',data)
        .then(res => {
          if(res){
            if (templatedata.mode) {
              if (configId === '') {
                message.info(`${templatedata.mode} event created successfully`)
              } else {
                message.info(`${templatedata.mode} event updated successfully`)
              }
            }
            this.props.history.push({
              pathname: '/configuration-list',
              search: this.props.location.search,
            })
          }else{
          }
        })
        .catch(err => console.log(err))
    } else {
      message.info('Plese selecet either company or user ')
    }
  }
  checkEventCreated = () => {
    this.isEventCreated().then(result => {
      if (result) {
        this.setDefaultTemplate(result)
        if (result.configured) {
          this.setState({ eventExisted: true })
          message.error('This event is already created')
        }
      } else {
        this.setState({ eventExisted: false })
      }
    })
  }
  setDefaultTemplate = eventTemplate => {
    if (this.state.mode !== 'EMAIL') {
      this.setState({
        smsContent: eventTemplate.default_body,
      })
    } else {
      this.setState({
        bodyContent: eventTemplate.default_body,
        subjectContent: eventTemplate.default_subject,
      })
    }
  }
  handelMethodChange = e => {
    this.setState({ methodType: e })
  }

  handleWebhooksConfig = (type, e) => {
    if(type === 'dynamicTokenFlag'){
      let t = this.state[type]
      this.setState({ [type]: !t })
    }
    else{
      this.setState({ [type]: e })
    }
  }

  handelModeChange = e => {
    this.setState({ mode: e, smsContent: '' }, () => {
      if (this.state.eID) {
        this.checkEventCreated()
      }
    })
  }
  handelContentTypeChange = e => {
    this.setState({ contentType: e })
  }
  handleEvent = e => {
    this.setState({ eID: e }, () => {
      if (this.state.mode) {
        this.checkEventCreated()
      }
    })
  }

  isEventCreated() {
    const { cId, bId, dId, mode, eID, uId, gId, rcId, rbId, rdId } = this.state
    return fetchData(
      `${Notification_API}/notification/mode-configuration?company_id=${cId}&branch_id=${bId}&department_id=${dId}&mode=${mode}&event_id=${eID}&user_id=${uId}&group_id=${gId}&related_company_id=${rcId}&related_branch_id=${rbId}&related_department_id=${rdId}`
    )
      .then(result => result)
      .catch(function(result) {
        console.log(result)
      })
  }
  reSetAll = () => {
    this.setState({ eventValue: {} })
  }
  onChangeEvent = (e, item) => {
    const str = e.target.value % 2 === 0 ? 'IF-ELSE' : 'IF'
    const eventValue = this.state.eventValue
    eventValue[item.key] = e.target.value
    this.setState({
      eventValue,
      surroundedType: str,
    })
  }
  handleCancel = () => {
    this.setState({
      visible: false,
      emailSubject: false,
      selectedSection: '',
      smsContentFlag: false,
      subjectContentFlag: false,
      eventValue: {},
      surroundedType: '',
      whatsappHeader: false,
      whatsappFooter: false,
    })
  }
  subjectPopUp = () => {
    this.setState({ emailSubject: true })
  }
  editMode = e => {
    this.setState({ visible: true, selectedSection: 'Body' })
    e.preventDefault()
  }
  headerPopUp = e => {
    e.preventDefault()
    this.setState({ whatsappHeader: true })
  }
  footerPopUp = e => {
    e.preventDefault()
    this.setState({ whatsappFooter: true })
  }
  setValue = evt => {
    this.setState({ mobileValue: evt.target.value })
  }
  handleMobileChange = (i, e) => {
    let t = this.state.mobileNumbers
    t[i] = e.target.value
    this.setState({ mobileNumbers: t })
  }

  addFields = (type, flag) => {
    let tempVal = this.state[type]
    const { allFlag } = this.state
    if (tempVal[tempVal.length - 1]) {
      tempVal.push('')
    }
    if (tempVal.length > 1) {
      allFlag[flag] = false
    }
    this.setState({ type: tempVal, allFlag })
  }
  deleteFields = (type, index, flag) => {
    let tempVal = this.state[type]
    const { allFlag } = this.state
    tempVal.splice(index, 1)
    this.props.form.setFieldsValue({ [type]: tempVal })
    if (tempVal.length === 1) {
      allFlag[flag] = true
    }
    this.setState({ allFlag, type: tempVal })
  }
  handleToEmailChange = (i, e) => {
    let t = this.state.toEmailList
    t[i] = e.target.value
    this.setState({ toEmailList: t })
  }

  handleCcEmailChange = (i, e) => {
    let t = this.state.toCcList
    t[i] = e.target.value
    this.setState({ toCcList: t })
  }

  handleBccEmailChange = (i, e) => {
    let t = this.state.toBccList
    t[i] = e.target.value
    this.setState({ toBccList: t })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const {
      eventExisted,
      tripInfo,
      methodType,
      URL,
      Authkey,
      Header,
      User,
      Password,
      selectedContent,
      authType,
    } = this.state
    return (
      <div>
        <h3 className={css`textAlign:center, marginTop:15px`}>Notification Configuration </h3>
        <Divider />
        <Row>
          <Col span={16}>
            <Form onSubmit={this.handleSubmit}>
              <Row gutter={16}>
                <Col style={{ textAlign: 'right', paddingTop: '8px' }} span={6}>
                  <label>
                    {' '}
                    <span style={{ color: 'red' }}>*</span> Event :
                  </label>
                </Col>
                <Col span={10}>
                  <Form.Item>
                    {getFieldDecorator('event', {
                      rules: [{ required: true, message: 'Please select event' }],
                    })(
                      <Select
                        disabled={this.state.configId ? true : false}
                        size="large"
                        placeholder="Select a event"
                        onChange={this.handleEvent}
                      >
                        {this.state.onboardedEvent &&
                          this.state.onboardedEvent.map(item => (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col style={{ textAlign: 'right', paddingTop: '8px' }} span={6}>
                  <label>
                    <span style={{ color: 'red' }}>*</span> Mode :
                  </label>
                </Col>
                <Col span={10}>
                  <Form.Item>
                    {getFieldDecorator('mode', {
                      initialValue: this.state.mode,
                      rules: [{ required: true, message: 'Please select template mode' }],
                    })(
                      <Select
                        disabled={this.state.configId ? true : false}
                        onChange={this.handelModeChange}
                        size="large"
                        placeholder="Select a mode"
                      >
                        <Option value="SMS">SMS</Option>
                        <Option value="EMAIL">EMAIL</Option>
                        <Option value="WEBHOOKS">WEBHOOKS</Option>
                        <Option value="WHATSAPP">WHATSAPP</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {(this.state.mode === 'SMS' || this.state.mode === 'WHATSAPP') ? (
                <Row gutter={16}>
                  <Col style={{ textAlign: 'right', paddingTop: '8px' }} span={6}>
                    <label>
                      {this.state.mode === 'SMS' ? (<span style={{ color: 'red' }}>*</span>) : null}
                      Mobile :
                    </label>
                  </Col>
                  <Col span={18}>
                    {this.state.mobileNumbers &&
                      this.state.mobileNumbers.map((item, i) => (
                        <Row key={i} gutter={16}>
                          <Col span={13} style={{ paddingRight: 0 }}>
                            <Form.Item>
                              {getFieldDecorator(`mobileNumbers[${i}]`, {
                                rules: [
                                  {
                                    required: this.state.mode === 'SMS' ? true : false,
                                    pattern: new RegExp('^[6-9]\\d{9}$'),
                                    message: 'Please enter Mobile Number',
                                  },
                                ],
                              })(
                                <Input
                                  size="large"
                                  // type="number"
                                  onChange={this.handleMobileChange.bind(this, i)}
                                />
                              )}
                            </Form.Item>
                          </Col>

                          <Col style={{ paddingTop: '4px' }} span={2}>
                            <Button
                              disabled={this.state.allFlag.mobileDisableFlag}
                              onClick={() =>
                                this.deleteFields('mobileNumbers', i, 'mobileDisableFlag')
                              }
                            >
                              X
                            </Button>
                          </Col>
                        </Row>
                      ))}
                  </Col>
                </Row>
              ) : null}
              {this.state.mode === 'EMAIL' ? (
                <Row style={{ paddingBottom: '25px' }} gutter={16}>
                  <Col style={{ textAlign: 'right', paddingTop: '8px' }} span={6}>
                    <span style={{ color: 'red' }}>*</span> Subject
                  </Col>
                  <Col span={18}>
                    <Anchor style={{ width: '55%' }} onClick={this.subjectPopUp} affix={false}>
                      <Link title="Subject" />
                    </Anchor>
                  </Col>
                </Row>
              ) : null}
              {(this.state.mode === 'SMS' || this.state.mode === 'WHATSAPP') ? (
                <Row style={{ paddingBottom: '15px' }} gutter={16}>
                  <Col style={{ paddingLeft: '25px' }} offset={11} span={13}>
                    <a
                      href="javascript3:void(0);"
                      onClick={() => this.addFields('mobileNumbers', 'mobileDisableFlag')}
                    >
                      + Add Mobile Number
                    </a>
                  </Col>
                </Row>
              ) : null}
              {this.state.mode === 'EMAIL' ? (
                <Row gutter={16}>
                  <Col style={{ textAlign: 'right', paddingTop: '8px' }} span={6}>
                    <label>
                      <span style={{ color: 'red' }}>*</span> To :
                    </label>
                  </Col>
                  <Col span={18}>
                    {this.state.toEmailList &&
                      this.state.toEmailList.map((item, i) => (
                        <Row key={i} gutter={16}>
                          <Col span={13} style={{ paddingRight: 0 }}>
                            <Form.Item>
                              {getFieldDecorator(`toEmailList[${i}]`, {
                                rules: [
                                  {
                                    required: true,
                                    // pattern: new RegExp(
                                    //   '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}'
                                    //   '[a-zA-Z]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]{2,3}$'
                                    // ),
                                    message: 'Please enter valid email',
                                  },
                                ],
                              })(
                                <Input
                                  size="large"
                                  type="text"
                                  onChange={this.handleToEmailChange.bind(this, i)}
                                />
                              )}
                            </Form.Item>
                          </Col>
                          <Col style={{ paddingTop: '4px' }} span={2}>
                            <Button
                              disabled={this.state.allFlag.toEmailDisable}
                              onClick={() => this.deleteFields('toEmailList', i, 'toEmailDisable')}
                            >
                              X
                            </Button>
                          </Col>
                        </Row>
                      ))}
                  </Col>
                </Row>
              ) : null}
              {this.state.mode === 'EMAIL' ? (
                <Row style={{ paddingBottom: '15px' }} gutter={16}>
                  <Col offset={13} span={11}>
                    <a
                      href="javascript4:void(0);"
                      onClick={() => this.addFields('toEmailList', 'toEmailDisable')}
                    >
                      + Add To Email
                    </a>
                  </Col>
                </Row>
              ) : null}
              {this.state.mode === 'EMAIL' ? (
                <Row gutter={16}>
                  <Col style={{ textAlign: 'right', paddingTop: '8px' }} span={6}>
                    <label>Cc :</label>
                  </Col>
                  <Col span={18}>
                    {this.state.toCcList &&
                      this.state.toCcList.map((item, i) => (
                        <Row key={i} gutter={16}>
                          <Col span={13} style={{ paddingRight: 0 }}>
                            <Form.Item>
                              {getFieldDecorator(`toCcList[${i}]`, {
                                rules: [
                                  {
                                    required: false,
                                    // pattern: new RegExp(
                                    //   '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}'
                                    //   '[a-zA-Z]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]{2,3}$'
                                    // ),
                                    message: 'Please enter valid email',
                                  },
                                ],
                              })(
                                <Input
                                  size="large"
                                  type="text"
                                  onChange={this.handleCcEmailChange.bind(this, i)}
                                />
                              )}
                            </Form.Item>
                          </Col>
                          <Col style={{ paddingTop: '4px' }} span={2}>
                            <Button
                              disabled={this.state.allFlag.toCcDisable}
                              onClick={() => this.deleteFields('toCcList', i, 'toCcDisable')}
                            >
                              X
                            </Button>
                          </Col>
                        </Row>
                      ))}
                  </Col>
                </Row>
              ) : null}
              {this.state.mode === 'EMAIL' ? (
                <Row style={{ paddingBottom: '15px' }} gutter={16}>
                  <Col offset={13} span={11}>
                    <a
                      href="javascript6:void(0);"
                      onClick={() => this.addFields('toCcList', 'toCcDisable')}
                    >
                      + Add To Cc
                    </a>
                  </Col>
                </Row>
              ) : null}
              {this.state.mode === 'EMAIL' ? (
                <Row gutter={16}>
                  <Col style={{ textAlign: 'right', paddingTop: '8px' }} span={6}>
                    <label>Bcc :</label>
                  </Col>
                  <Col span={18}>
                    {this.state.toBccList &&
                      this.state.toBccList.map((item, i) => (
                        <Row key={i} gutter={16}>
                          <Col span={13} style={{ paddingRight: 0 }}>
                            <Form.Item>
                              {getFieldDecorator(`toBccList[${i}]`, {
                                rules: [
                                  {
                                    required: false,
                                    // pattern: new RegExp(
                                    //   '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}'
                                    //    '[a-zA-Z]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]{2,3}$'
                                    // ),
                                    message: 'Please enter valid email',
                                  },
                                ],
                              })(
                                <Input
                                  size="large"
                                  type="text"
                                  onChange={this.handleBccEmailChange.bind(this, i)}
                                />
                              )}
                            </Form.Item>
                          </Col>
                          <Col style={{ paddingTop: '4px' }} span={2}>
                            <Button
                              disabled={this.state.allFlag.toBccDisable}
                              onClick={() => this.deleteFields('toBccList', i, 'toBccDisable')}
                            >
                              X
                            </Button>
                          </Col>
                        </Row>
                      ))}
                  </Col>
                </Row>
              ) : null}
              {this.state.mode === 'EMAIL' ? (
                <Row style={{ paddingBottom: '15px' }} gutter={16}>
                  <Col offset={13} span={11}>
                    <a
                      href="javascript1:void(0);"
                      onClick={() => this.addFields('toBccList', 'toBccDisable')}
                    >
                      + Add To Bcc
                    </a>
                  </Col>
                </Row>
              ) : null}
              {this.state.mode === 'WEBHOOKS' ? (
                <div>
                  <Row gutter={16}>
                    <Col style={{ textAlign: 'right', paddingTop: '8px' }} span={6}>
                      <label>
                        <span style={{ color: 'red' }}>*</span> Method :
                      </label>
                    </Col>
                    <Col span={10}>
                      <Form.Item>
                        {getFieldDecorator('method', {
                          initialValue: methodType,
                          rules: [{ required: true, message: 'Please enter method' }],
                        })(
                          <Select
                            // disabled={this.state.configId ? true : false}
                            onChange={this.handelMethodChange}
                            size="large"
                            placeholder="Select a method"
                          >
                            <Option value="GET">GET</Option>
                            <Option value="POST">POST</Option>
                            <Option value="PUT">PUT</Option>
                            <Option value="DELETE">DELETE</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col style={{ textAlign: 'right', paddingTop: '8px' }} span={6}>
                      <label>
                        <span style={{ color: 'red' }}>*</span> URL :
                      </label>
                    </Col>
                    <Col span={10}>
                      <Form.Item>
                        {getFieldDecorator('url', {
                          initialValue: URL,
                          rules: [{ required: true, message: 'Please enter url' }],
                        })(<Input size="large" />)}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col style={{ textAlign: 'right', paddingTop: '8px' }} span={6}>
                      <label>
                        <span style={{ color: 'red' }}>*</span> Content Type :
                      </label>
                    </Col>
                    <Col span={10}>
                      <Form.Item>
                        {getFieldDecorator('contentType', {
                          initialValue: this.state.contentType,
                          rules: [{ required: true, message: 'Please select content type' }],
                        })(
                          <Select
                            //  disabled={this.state.configId ? true : false}
                            onChange={this.handelContentTypeChange}
                            size="large"
                            placeholder="Select content type"
                          >
                            <Option value="JSON">JSON</Option>
                            <Option value="XML">XML</Option>
                            <Option value="URL_ENCODED">URL_ENCODED</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col style={{ textAlign: 'right', paddingTop: '8px' }} span={6}>
                      <label>
                      <span style={{ color: 'red' }}>*</span> Auth Type :
                      </label>
                    </Col>
                    <Col span={10}>
                      <Form.Item>
                        {getFieldDecorator('authType', {
                          initialValue: authType,
                          rules: [{ required: true, message: 'Please select auth type' }],
                        })(
                          <Select
                            onChange={this.handleWebhooksConfig.bind(this, 'authType')}
                            size="large"
                            placeholder="Select authType"
                          >
                            <Option value="BASIC">BASIC</Option>
                            <Option value="BEARER">BEARER (For Dynamic Token)</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col style={{ textAlign: 'right', paddingTop: '8px' }} span={6}>
                      <label>Authkey :</label>
                    </Col>
                    <Col span={10}>
                      <Form.Item>
                        {getFieldDecorator('authkey', {
                          initialValue: Authkey,
                          rules: [{ required: false, message: 'Please enter url' }],
                        })(<Input value={Authkey} size="large" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col style={{ textAlign: 'right', paddingTop: '8px' }} span={6}>
                      <label>Header :</label>
                    </Col>
                    <Col span={10}>
                      <Form.Item>
                        {getFieldDecorator('header', {
                          initialValue: Header,
                          rules: [{ required: false, message: 'Please enter url' }],
                        })(<Input size="large" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col style={{ textAlign: 'right', paddingTop: '8px' }} span={6}>
                      <label>User :</label>
                    </Col>
                    <Col span={10}>
                      <Form.Item>
                        {getFieldDecorator('user', {
                          initialValue: User,
                          rules: [{ required: false, message: 'Please enter url' }],
                        })(<Input size="large" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col style={{ textAlign: 'right', paddingTop: '8px' }} span={6}>
                      <label>Password :</label>
                    </Col>
                    <Col span={10}>
                      <Form.Item>
                        {getFieldDecorator('password', {
                          initialValue: Password,
                          rules: [{ required: false, message: 'Please enter password' }],
                        })(<Input size="large" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ) : null}
              {this.state.mode && this.state.mode === 'WHATSAPP' ? (
                <WhatsappHeaderFooterSection title="Header" onClick={this.headerPopUp} />
              ) : null}
              {this.state.mode && this.state.mode === 'WHATSAPP' ? (
                <WhatsappHeaderFooterSection title="Footer" onClick={this.footerPopUp} />
              ) : null}
              {this.state.mode ? (
                <Row style={{ paddingBottom: '25px' }} gutter={16}>
                  <Col style={{ textAlign: 'right', paddingTop: '8px' }} span={6}>
                    {this.state.mode !== 'WEBHOOKS' ? <span style={{ color: 'red' }}>*</span> : ''}{' '}
                    Content :
                  </Col>
                  <Col span={18}>
                    <Anchor style={{ width: '55%' }} onClick={e => this.editMode(e)} affix={false}>
                      <Link title="Content" />
                    </Anchor>
                  </Col>
                </Row>
              ) : null}

              {this.state.mode === 'WEBHOOKS' ?
                (<Row style={{ paddingBottom: '8px' }}>
                  <Divider />
                  <Button onClick={this.handleWebhooksConfig.bind(this, 'dynamicTokenFlag')}>
                    <h3> {this.state.dynamicTokenFlag ? "Remove": "Add"} Dynamic Token Config </h3>
                  </Button>
                </Row>) : ''
              }
              {(this.state.mode === 'WEBHOOKS' && (this.state.dynamicTokenFlag)) ? (
                <div>
                  <Row gutter={16}>
                    <Col style={{ textAlign: 'right', paddingTop: '8px' }} span={6}>
                      <label>
                        <span style={{ color: 'red' }}>*</span> Method :
                      </label>
                    </Col>
                    <Col span={10}>
                      <Form.Item>
                        {getFieldDecorator('dynamicConfig.method', {
                          initialValue: this.state.dynamicConfig.method,
                          rules: [{ required: true, message: 'Please enter method' }]
                        })(
                          <Select
                            onChange={this.handleWebhooksConfig.bind(this, 'dynamicConfig.method')}
                            size="large"
                            placeholder="Select a method"
                          >
                            <Option value="GET">GET</Option>
                            <Option value="POST">POST</Option>
                            <Option value="PUT">PUT</Option>
                            <Option value="DELETE">DELETE</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <DynamicWebhookConfig
                    label="URL"
                    decorator='dynamicConfig.url'
                    initialValue={this.state.dynamicConfig.url}
                    getFieldDecorator={getFieldDecorator}
                    required={true}
                    message={'Please enter url'}
                  />
                  <Row gutter={16}>
                    <Col style={{ textAlign: 'right', paddingTop: '8px' }} span={6}>
                      <label>
                        <span style={{ color: 'red' }}>*</span> Content Type :
                      </label>
                    </Col>
                    <Col span={10}>
                      <Form.Item>
                        {getFieldDecorator('dynamicConfig.contentType', {
                          initialValue: this.state.dynamicConfig.contentType,
                          rules: [{ required: true, message: 'Please select content type' }]
                        })(
                          <Select
                            onChange={this.handleWebhooksConfig.bind(this, 'dynamicConfig.contentType')}
                            size="large"
                            placeholder="Select dynamic content type"
                          >
                            <Option value="JSON">JSON</Option>
                            <Option value="XML">XML</Option>
                            <Option value="URL_ENCODED">URL_ENCODED</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col style={{ textAlign: 'right', paddingTop: '8px' }} span={6}>
                      <label>
                        <span style={{ color: 'red' }}>*</span> Auth Type :
                      </label>
                    </Col>
                    <Col span={10}>
                      <Form.Item>
                        {getFieldDecorator('dynamicConfig.authType', {
                          initialValue: this.state.dynamicConfig.authType,
                          rules: [{ required: true, message: 'Please select auth type' }]
                        })(
                          <Select
                            onChange={this.handleWebhooksConfig.bind(this, 'dynamicConfig.authType')}
                            size="large"
                            placeholder="Select authType"
                          >
                            <Option value="BASIC">BASIC</Option>
                            <Option value="BEARER">BEARER</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <DynamicWebhookConfig
                    label="Authkey"
                    decorator='dynamicConfig.authkey'
                    initialValue={this.state.dynamicConfig.authkey}
                    getFieldDecorator={getFieldDecorator}
                    required={false}
                  />
                  <DynamicWebhookConfig
                    label="Header"
                    decorator='dynamicConfig.header'
                    initialValue={this.state.dynamicConfig.header}
                    getFieldDecorator={getFieldDecorator}
                    required={false}
                  />
                  <DynamicWebhookConfig
                    label="Content"
                    decorator='dynamicConfig.content'
                    initialValue={this.state.dynamicConfig.content}
                    getFieldDecorator={getFieldDecorator}
                    required={false}
                  />
                  <DynamicWebhookConfig
                    label="Access Token Json Path"
                    decorator='dynamicConfig.accessTokenJsonPath'
                    initialValue={this.state.dynamicConfig.accessTokenJsonPath}
                    getFieldDecorator={getFieldDecorator}
                    required={false}
                  />
                  <DynamicWebhookConfig
                    label="TTL Json Path"
                    decorator='dynamicConfig.ttlJsonPath'
                    initialValue={this.state.dynamicConfig.ttlJsonPath}
                    getFieldDecorator={getFieldDecorator}
                    required={false}
                  />
                </div>
              ) : null}
              {this.state.mode === 'WEBHOOKS'?<Divider />:''}
              <Form.Item>
                <Row gutter={16}>
                  <Col offset={10} span={8}>
                    <Button
                      disabled={eventExisted}
                      size="large"
                      style={{ padding: '10px' }}
                      type="primary"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Col>
          <Modal
            width={1200}
            footer={null}
            title={`Template`}
            visible={this.state.visible}
            onCancel={this.handleCancel}
          >
            {this.state.mode === 'EMAIL' ? (
              <Row style={{ padding: '0 0 25px 10px' }} gutter={16}>
                <Col span={5}>
                  <Select
                    defaultValue={this.state.selectedSection}
                    style={{ width: '100%' }}
                    onChange={this.handleEmailSection}
                    size="large"
                    placeholder="Select section"
                  >
                    <Option value="headerContent">HEADER</Option>
                    <Option value="footerContent">FOOTER</Option>
                    <Option value="bodyContent">BODY</Option>
                  </Select>
                </Col>
              </Row>
            ) : null}
            {this.state.mode === 'WEBHOOKS' ? (
              <p style={{ padding: '0 0 0 30px', fontWeight: 'bold' }}>
                surrounded{' '}
                <Button style={{ marginLeft: '20px' }} onClick={this.reSetAll}>
                  Reset All
                </Button>
              </p>
            ) : (
              ''
            )}
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <div style={{ width: '30%', display: 'flex', height: '300px', overflow: 'auto' }}>
                {this.state.mode === 'WEBHOOKS' ? (
                  <div style={{ width: '50%' }}>
                    {tripInfo &&
                      tripInfo.map(item => (
                        <div key={item}>
                          <RadioGroup
                            style={{ padding: '13px' }}
                            onChange={e => this.onChangeEvent(e, item)}
                            value={this.state.eventValue[item.key]}
                          >
                            {item.options &&
                              item.options.map((option, i) => (
                                <Radio key={i} value={option.val}>
                                  {option.key}
                                </Radio>
                              ))}
                          </RadioGroup>
                        </div>
                      ))}
                  </div>
                ) : null}
                <div style={{ width: '50%' }}>
                  {tripInfo &&
                    tripInfo.map((item, index) => (
                      <ol
                        style={{ cursor: 'pointer' }}
                        className="rounded-list"
                        key={index}
                        onClick={() => {
                          this.copyToClipboard(item.key)
                        }}
                      >
                        <li>{item.key}</li>
                      </ol>
                    ))}
                </div>
              </div>
              <div style={{ border: '1px solid #ccc', width: '60%' }}>
                <span style={{ fontSize: 9, color: 'red', marginLeft: '15px' }}>
                  Note: To paste in Windows use CTR+V and In Mac use CMD+V
                </span>
                {this.state.mode !== 'EMAIL' ? (
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        paddingBottom: '5px',
                        border: '1px solid #ccc',
                        marginLeft: '15px',
                        width: '80%',
                      }}
                    >
                      <textarea
                        style={{
                          outline: 'none',
                          border: 'none',
                          width: '100%',
                          padding: '10px',
                          textIndent: '9px',
                        }}
                        placeholder="Content"
                        value={this.state.smsContent}
                        onChange={this.saveMobileContent}
                      />
                    </div>
                    <div>
                      {' '}
                      {this.state.smsContentFlag ? (
                        <span style={{ fontSize: 10, color: 'red', marginLeft: '15px' }}>
                          {this.state.mode === 'WHATSAPP' ? `Please enter whatsapp content` :`Please enter sms content`}
                        </span>
                      ) : null}
                    </div>
                    <div style={{ marginLeft: '15px' }}>
                      <Button
                        className={css`
                          margin-top: 1%;
                        `}
                        size="large"
                        type="primary"
                        htmlType="submit"
                        onClick={this.handleMobileContent}
                        disabled={false}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                ) : this.state.selectedSection ? (
                  <div>
                    <div
                      className={css`
                        padding: 20px;
                        margin: 10px;
                      `}
                    >
                      <TextArea
                        rows={10}
                        placeholder="Content"
                        value={this.state[selectedContent]}
                        onChange={this.onChangeContent}
                      />
                    </div>
                    <div>
                      {' '}
                      {this.state.subjectContentFlag ? (
                        <span style={{ fontSize: 10, color: 'red', marginLeft: '15px' }}>
                          Plese enter subject
                        </span>
                      ) : null}
                    </div>
                    <div style={{ marginLeft: '15px' }}>
                      <Button
                        className={css`
                          margin-top: 1%;
                        `}
                        size="large"
                        type="primary"
                        htmlType="submit"
                        onClick={this.handleCancel}
                        disabled={!this.state[selectedContent]}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </Modal>
          {this.state.emailSubject ? (
            <Modal
              width={1200}
              footer={null}
              title="Subject"
              visible={this.state.emailSubject}
              onCancel={this.handleCancel}
            >
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <div style={{ width: '40%', display: 'flex' }}>
                  <div style={{ width: '50%', height: '300px', overflow: 'auto' }}>
                    {tripInfo &&
                      tripInfo.map((item, index) => (
                        <ol
                          style={{ cursor: 'pointer' }}
                          className="rounded-list"
                          key={index}
                          onClick={() => {
                            this.copyToClipboard(item.key)
                          }}
                        >
                          <li>{item.key}</li>
                        </ol>
                      ))}
                  </div>
                </div>
                <div style={{ border: '1px solid #ccc', width: '60%' }}>
                  <span style={{ fontSize: 9, color: 'red', marginLeft: '15px' }}>
                    Note: To paste in Windows use CTR+V and In Mac use CMD+V
                  </span>
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        paddingBottom: '5px',
                        border: '1px solid #ccc',
                        marginLeft: '15px',
                        width: '80%',
                      }}
                    >
                      <TextArea
                        rows={4}
                        placeholder="Content"
                        value={this.state.subjectContent}
                        onChange={this.savesubjectContent}
                      />
                    </div>
                    <div>
                      {' '}
                      {this.state.subjectContentFlag ? (
                        <span style={{ fontSize: 10, color: 'red', marginLeft: '15px' }}>
                          Plese enter subject
                        </span>
                      ) : null}
                    </div>
                    <div style={{ marginLeft: '15px' }}>
                      <Button
                        className={css`
                          margin-top: 1%;
                        `}
                        size="large"
                        type="primary"
                        htmlType="submit"
                        onClick={() => this.handleSubjectContent()}
                        disabled={false}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          ) : null}
          {this.state.whatsappHeader ? (
            <WhatsappHeaderFooterModal
              title="Header"
              visible={this.state.whatsappHeader}
              content={this.state.headerContent}
              saveContent={this.saveHeaderFooterContent.bind(this, 'headerContent')}
              handlePopup={() => this.handleHeaderFooterPopup('whatsappHeader')}
              handleCancel={this.handleCancel}
              tripInfo={tripInfo}
              copyToClipboard={this.copyToClipboard}
            />
          ) : null}
          {this.state.whatsappFooter ? (
            <WhatsappHeaderFooterModal
              title="Footer"
              visible={this.state.whatsappFooter}
              content={this.state.footerContent}
              saveContent={this.saveHeaderFooterContent.bind(this, 'footerContent')}
              handlePopup={() => this.handleHeaderFooterPopup('whatsappFooter')}
              handleCancel={this.handleCancel}
              tripInfo={tripInfo}
              copyToClipboard={this.copyToClipboard}
            />
          ) : null}
        </Row>
      </div>
    )
  }
}
const Notification_Config_Form = Form.create({ name: 'Config_Form' })(NotificationConfig)
export default Notification_Config_Form
