import React from 'react'
import { Form, message, Button, Row, Col, AutoComplete, Table } from 'antd'
import { css } from '@emotion/css'

import { convertQsToJson } from 'utils/common'
import fetchData from 'utils/fetchData'
import configKeys from './configKeys'

const Option = AutoComplete.Option
const EQS_API = window._env_.REACT_APP_EQS_SERVICE_API_URL
const CONFIG_SERVICE_API = window._env_.REACT_APP_CONFIG_SERVICE_API_URL


const ShowEntityConfig = ({label1, label2, configBoxStyle, fteId, getConfigJson, onClickCopy}) => {
    return (
        <Col style={{ padding: '10px', background: '#fff' }}>
            <Row >
                <Col span={1} />
                <Col span={3} style={{ padding: '8px' }}>
                    <strong>{label1}</strong>
                </Col>
                <Col span={15} style={configBoxStyle}>
                    {fteId}
                </Col>
            </Row>
            <Row style={{ padding: '10px 10px 10px 22px' }} />
            <Row>
                <Col span={1} />
                <Col span={3} style={{ padding: '8px' }}>
                    <strong>{label2}</strong>
                </Col>
                <Col span={15}>
                    <div style={configBoxStyle}>
                        <pre>{getConfigJson}</pre>
                    </div>
                    <Button onClick={onClickCopy} style={{ marginTop: '8px' }}>
                        Copy Config JSON
                    </Button>
                </Col>
            </Row>
        </Col>
    )
}

class LegoConfig extends React.Component {
    constructor(props) {
        super(props)
        let configKeyList = configKeys.data && configKeys.data.map((key) => (
            <Option key={key}>{key}</Option>
        ))
        this.state = {
            companyId: '',
            showConfigFlag: false,
            fteId: '',
            configKey: '',
            config: {},
            configKeyList: configKeyList
        }
    }

    componentDidMount() {
        const { search } = this.props.location;
        const parsedDt = convertQsToJson(search)
        if (!parsedDt.companyId) {
            this.setState({ companyId: '' })
            message.info('Select a company to continue')
        } else {
            this.setState({ companyId: parsedDt.companyId })
            this.getFTEId(parsedDt);
        }
    }
    componentDidUpdate(prevProps) {
        const { search } = this.props.location;
        const prevSearch = prevProps.location.search
        if (search !== prevSearch) {
            const parsedDt = convertQsToJson(search)
            if (!parsedDt.companyId) {
                this.setState({ companyId: '' })
            }
            else {
                this.setState({ companyId: parsedDt.companyId })
                this.getFTEId(parsedDt);
            }
        }
    }

    getFTEId = (parsedDt) => {
        let url = `${EQS_API}/v1/company/get-fteid?`
        let payload = {
            c: parsedDt.companyId || '',
            b: parsedDt.branchId || '',
            d: parsedDt.departmentId || ''
        }
        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('satellizer_token')}`
        }

        fetchData(url, 'GET', payload, headers).then((res) => {
            if (res && res.success && res.data) {
                this.setState({ fteId: res.data.fteid })
            } else {
                this.setState({ fteId: '' })
            }
        }).catch(error => {
            this.setState({ fteId: '' });
            message.error(error);
        });

        this.setState({ showConfigFlag: false })
    }

    getConfig = (data) => {
        let url = `${CONFIG_SERVICE_API}/v1/config/entity/${this.state.fteId}/key/${data.configKey}`
        const headers = {
            'token': `Bearer ${localStorage.getItem('satellizer_token')}`
        }
        
        fetchData(url, 'GET', {}, headers).then(res => {
            if (res && res.success && res.data) {
                this.setState({ config: res.data })
            } else {
                this.setState({ config: {} })
            }
        }).catch(error => {
            this.setState({ config: {} });
            message.error(error);
        });

        this.setState({ showConfigFlag: true })
        this.setState({ configKey: data.configKey })
    }

    handleSubmit = e => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.getConfig(values)
            }
        })
    }

    handleConfigKeySearch = (inp) => {
        const filteredKeys = configKeys.data &&
            configKeys.data.filter((key) => key.startsWith(inp.toLowerCase())).map((key) => (
                <Option key={key}>{key}</Option>
            ))
        this.setState({ configKeyList: filteredKeys })
    }

    getJsonString = (jsonData) => {
        let jsonString = ''
        try{
            jsonString = JSON.stringify(jsonData, null, 2)
        }
        catch(err){
            message.error('Error in getting json string');
        }
        return jsonString;
    }

    copyJsonToClipboard = (jsonData) => {
        try{
            const jsonString = this.getJsonString(jsonData);
            navigator.clipboard.writeText(jsonString);
            message.success('JSON Copied to Clipboard');
        }
        catch(err){
            message.error('Error in copyJsonToClipboard');
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form
        const configBoxStyle = {
            maxHeight: '350px', overflowY: 'auto', border: '1px solid #d9d9d9',
            padding: '8px', background: '#fafafa'
        }
        return (
            <div>
                <Row
                    span={20}
                    className={css`
                    padding: 20px;
                    background: #fff;
                    border-bottom: 1px solid #e8e8e8;
                    `}
                    gutter={16}>
                    <Form onSubmit={this.handleSubmit}>
                        <Col span={20}>
                            <Row>
                                <Col span={6}>
                                    <label className="subHeaderLabel">
                                        <span style={{ color: 'red' }}>*</span>Config Key
                                    </label>
                                    <Form.Item>
                                        {getFieldDecorator("configKey", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Please enter config key",
                                                },
                                            ],
                                        })(<AutoComplete
                                            className={css`width: 100%;`}
                                            size="large"
                                            dataSource={this.state.configKeyList}
                                            onSearch={this.handleConfigKeySearch}
                                            placeholder="Search Config Key"
                                        />)}

                                    </Form.Item>
                                </Col>
                                <Col span={1} />
                                <Col style={{ paddingTop: '27px' }} span={3}>
                                    <Button
                                        disabled={!this.state.companyId}
                                        type="primary"
                                        size="large"
                                        htmlType='submit'
                                    >
                                        GET Config
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Form>
                </Row>
                <Row style={{ padding: '10px 10px 10px 22px' }} />
                {this.state.showConfigFlag && this.state.configKey ?
                    <ShowEntityConfig
                        label1='Entity ID:'
                        label2='Config:'
                        configBoxStyle={configBoxStyle}
                        fteId={this.state.fteId}
                        getConfigJson={this.getJsonString(this.state.config)}
                        onClickCopy={() => this.copyJsonToClipboard(this.state.config)}
                    />
                    : ''
                }
            </div >
        )
    }
}

const Lego_Config_Form = Form.create({ name: 'Lego_Config_Form' })(LegoConfig)
export default Lego_Config_Form