import React, { Component } from 'react'
import { compose, withProps, lifecycle, withHandlers } from 'recompose'
import { withGoogleMap, GoogleMap, DirectionsRenderer } from 'react-google-maps'
import CircleMarker from './circleMarker'
import mapStyle from './MapStyle.json'

export default class Map extends Component {
  render() {
    const { places, showDirection } = this.props
    return (
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <DLMap
          places={places}
          setlnglat={this.props.setlnglat}
          changeWayPoint={this.props.changeWayPoint}
          showDirection={showDirection}
        />
      </div>
    )
  }
}

function makeDirectionRequest(config, DirectionsService) {
  return new Promise((resolve, reject) => {
    DirectionsService.route(config, (result, status) => {
      if (status === window.google.maps.DirectionsStatus.OK) {
        resolve(result)
      } else {
        reject(result)
      }
    })
  })
}

const refs = {
  map: undefined,
}

const DLMap = compose(
  withProps({
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withGoogleMap,
  withHandlers(() => {
    return {
      onMapMounted: () => ref => {
        refs.map = ref
      },
      // handleClick: props => e => {
      //   const lat = e.latLng.lat().toFixed(7)
      //   const lng = e.latLng.lng().toFixed(7)
      //   props.setlnglat(lat, lng)
      // },
    }
  }),
  lifecycle({
    drawRoute(places) {
      places = places.map(place => place.google.geometry.location)
      const directionServicePromises = []
      const bounds = new window.google.maps.LatLngBounds()
      const DirectionsService = new window.google.maps.DirectionsService()
      this.setState({ directions: [] })
      for (let i = 0; i < places.length - 1; i++) {
        if (places[i].lat && places[i].lng && places[i + 1].lat && places[i + 1].lng) {
          const origin = new window.google.maps.LatLng(places[i].lat, places[i].lng)
          const destination = new window.google.maps.LatLng(places[i + 1].lat, places[i + 1].lng)
          bounds.extend(new window.google.maps.LatLng(places[i].lat, places[i].lng))
          bounds.extend(new window.google.maps.LatLng(places[i + 1].lat, places[i + 1].lng))
          refs.map && refs.map.fitBounds(bounds)
          const baseConfig = {
            origin: origin,
            destination: destination,
            travelMode: window.google.maps.TravelMode.DRIVING,
          }
          directionServicePromises.push(makeDirectionRequest(baseConfig, DirectionsService))
        }
      }
      Promise.all(directionServicePromises)
        .then(responses => {
          this.setState({ directions: responses })
        })
        .catch(err => err)
    },
    componentDidMount() {
      const { places } = this.props
      this.drawRoute(places)
    },
    componentWillReceiveProps(nextProps) {
      if (window.google && nextProps.showDirection) {
        this.drawRoute(nextProps.places)
      }
    },
  })
)(props => {
  const { directions, places } = props
  return (
    <GoogleMap
      defaultZoom={10}
      ref={props.onMapMounted}
      // onClick={props.handleClick}
      defaultOptions={{
        fullscreenControl: true,
        panControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        styles: mapStyle,
      }}
    >
      {places.map(place => {
        let navigationColor = ''
        switch (place.type) {
          case 'origin':
            navigationColor = '#6DB54F'
            break
          case 'destination':
            navigationColor = '#F9666F'
            break
          default:
            navigationColor = '#BDB76B'
        }
        const {
          sequence,
          key,
          id,
          type,
          google: {
            geometry: {
              location: { lat, lng },
            },
          },
        } = place
        return (
          <CircleMarker
            key={key}
            icon={{
              path: window.google.maps.SymbolPath.CIRCLE,
              scale: 10,
              strokeWeight: 4,
              fillOpacity: 1,
              fillColor: '#FFFFFF',
              strokeColor: navigationColor,
            }}
            zIndex={8000}
            label={{
              text: String(sequence),
              color: navigationColor,
              fontSize: '9px',
              fontWeight: 'bold',
            }}
            position={{
              lat: parseFloat(lat, 10),
              lng: parseFloat(lng, 10),
            }}
            draggable={!id && !type}
            wayPoint={place}
            changeWayPoint={props.changeWayPoint}
          />
        )
      })}
      {!!(directions && directions.length) &&
        directions.map((direction, index) => {
          return (
            <DirectionsRenderer
              options={{
                suppressMarkers: true,
                polylineOptions: {
                  strokeOpacity: 0,
                  icons: [
                    {
                      icon: {
                        path: 'M 0,-1 0,1',
                        strokeOpacity: 0.4,
                        strokeColor: '#1890ff',
                        scale: 3,
                      },
                      offset: '0',
                      repeat: '15px',
                    },
                  ],
                },
                preserveViewport: true,
              }}
              key={`dir_${index}`}
              directions={direction}
            />
          )
        })}
    </GoogleMap>
  )
})
