export default function request(url, options = {}) {
  let mustHeader = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem('satellizer_token')
  }
  options.headers = options.headers || {}
  let fetchOptions = {
    ...options,
    headers: { ...options.headers, ...mustHeader }
  }
  return fetch(url, fetchOptions)
    .then(res => res.json())
    .then(response => response)
    .catch(err => err)
}