import { Form, Input, Row, Col, Button, message } from 'antd'
import React from 'react'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import { GoogleMap, withGoogleMap, Marker } from 'react-google-maps'
import { convertQsToJson } from 'utils/common'
import _ from 'lodash'
import mapStyle from 'utils/MapStyle.json'
import 'containers/routesMaster/App.css'
import { withRouter } from 'react-router'
import { constructUrl } from 'utils/common'
import request from 'utils/request'
import { addressFromLatLng } from 'utils/geocode'
import parseDate from 'read-excel-file/commonjs/parseDate'
import { geocodeByPlaceIdFix } from 'utils/geocodeByPlaceIdFix'
import { css } from '@emotion/css'

class AddPlacesForm extends React.Component {
  state = {
    latitude: null,
    longitude: null,
    address: null,
    googlePlaceId: null,
    edit: false,
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ edit: true }, () => {
        this.fetchKpData(this.props.match.params.id)
      })
    }
  }

  fetchKpData = id => {
    const url = constructUrl('/saas/lego/company_places/fetch', this.props.location.search, {
      cpId: id,
    })
    request(url).then(
      result => {
        if (result.success) {
          const { address, label, google, geofenceRadius, cpCode } = result.data[0]
          this.props.form.setFieldsValue({
            address,
            addressLabel: label,
            googleAddress: google.formatted_address,
            latitude: google.geometry.location.lat,
            longitude: google.geometry.location.lng,
            geofenceRadius: geofenceRadius,
            cpCode: cpCode,
          })
          this.setState({
            address,
            knownPlaceData: result.data[0],
            googlePlaceId: google.place_id,
            latitude: google.geometry.location.lat,
            longitude: google.geometry.location.lng,
          })
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        message.error('Error while fetching routes data')
      }
    )
  }

  addKnownPlace = data => {
    const createUrl = constructUrl('/saas/lego/company_places/create', this.props.location.search)
    if (this.props.location.search === '') {
      return message.error('Select a company to continue')
    }

    const addKnownPlaceData = [
      {
        google: {
          place_id: this.state.googlePlaceId,
        },
        label: data.addressLabel,
        address: data.address,
        cpCode: data.cpCode,
        geofenceRadius: data.geofenceRadius,
      },
    ]
    request(createUrl, {
      method: 'POST',
      body: JSON.stringify(addKnownPlaceData),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(
      result => {
        if (result.success) {
          message.success('Company place added successfully !')
          this.props.history.push({
            pathname: '/known-places',
            search: this.props.location.search,
          })
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        message.error('Error while adding')
      }
    )
  }

  updateKnownPlace = data => {
    const id = this.props.match.params.id
    const updateUrl = constructUrl('/saas/lego/company_places/update', this.props.location.search)

    if (this.props.location.search === '') {
      return message.error('Select a company to continue')
    }

    const kPdata = {
      cpId: id,
      label: data.addressLabel,
      address: data.address,
      cpCode: data.cpCode,
      geofenceRadius: data.geofenceRadius,
    }

    if (this.state.knownPlaceData.partnerId) {
      kPdata.partnerId = this.state.knownPlaceData.partnerId
    }

    request(updateUrl, {
      method: 'POST',
      body: JSON.stringify(kPdata),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(
      result => {
        if (result.success) {
          message.success(`Known place ${id} updated successfully !`)
          this.props.history.push({
            pathname: '/known-places',
            search: this.props.location.search,
          })
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        message.error('Error while updating')
      }
    )
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const parsedDt = convertQsToJson(this.props.location.search)

        if (
          Object.keys(parsedDt).length > 1 &&
          parsedDt.companyType == 'TRANSPORTER' &&
          _.isEmpty(parsedDt.consignorId)
        ) {
          message.error('Please select a consignor to proceed')
          return
        }

        if (this.state.edit) {
          this.updateKnownPlace(values)
        } else {
          this.addKnownPlace(values)
        }
      }
    })
  }

  handleSelect = (address, placeId) => {
    this.props.form.setFieldsValue({
      googleAddress: address,
    })

    if (this.state.edit) {
      return
    }

    geocodeByPlaceIdFix(placeId, ['name', 'geometry', 'place_id', 'formatted_address'])
      .then(results => {
        console.log(results)
        const { place_id, geometry, formatted_address, name } = results
        this.props.form.setFieldsValue({
          latitude: geometry.location.lat(),
          longitude: geometry.location.lng(),
          address: formatted_address,
          addressLabel: name,
        })
        this.setState({
          address: formatted_address,
          addressLabel: name,
          googlePlaceId: place_id,
          latitude: geometry.location.lat(),
          longitude: geometry.location.lng(),
        })
      })
      .catch(error => console.error('Error', error))
  }

  setAddressFromLat = e => {
    this.setState({ latitude: e.target.value }, async () => {
      if (this.state.longitude) {
        const { latLng, address, placeId, shortName } = await addressFromLatLng(
          this.state.latitude,
          this.state.longitude
        )

        this.props.form.setFieldsValue({
          address,
          googleAddress: address,
          addressLabel: shortName,
        })
        this.setState({
          latitude: latLng.lat,
          longitude: latLng.lng,
          googlePlaceId: placeId,
        })
      } else {
        console.error('longitude not set')
      }
    })
  }

  setAddressFromLng = e => {
    this.setState({ longitude: e.target.value }, async () => {
      if (this.state.latitude) {
        const { latLng, address, placeId, shortName } = await addressFromLatLng(
          this.state.latitude,
          this.state.longitude
        )

        this.props.form.setFieldsValue({
          address,
          googleAddress: address,
          addressLabel: shortName,
        })
        this.setState({
          latitude: latLng.lat,
          longitude: latLng.lng,
          googlePlaceId: placeId,
        })
      } else {
        console.error('latitude not set')
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    }

    const Map = withGoogleMap(props => (
      <GoogleMap
        defaultZoom={15}
        defaultCenter={{ lat: props.lat, lng: props.lng }}
        defaultOptions={{
          fullscreenControl: true,
          panControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          styles: mapStyle,
        }}
      >
        <Marker position={{ lat: props.lat, lng: props.lng }} />
      </GoogleMap>
    ))

    return (
      <div
        className={css`
          padding: 20px;
        `}
      >
        <div style={{ display: 'none' }} id="bharghav" />
        <Row gutter={16}>
          <Col span={12} offset={6}>
            {this.state.edit ? (
              <h2 style={{ textAlign: 'center', marginBottom: '40px' }}>Update known place</h2>
            ) : (
              <h2 style={{ textAlign: 'center', marginBottom: '40px' }}>Add a new known place</h2>
            )}
            <Form {...formItemLayout} onSubmit={this.handleSubmit}>
              <Form.Item label="Google address">
                {getFieldDecorator('googleAddress', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter address',
                    },
                  ],
                })(
                  <PlacesAutocomplete onSelect={this.handleSelect}>
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div>
                        <Input
                          {...getInputProps({
                            placeholder: 'Type an address here..',
                            className: 'location-search-input',
                          })}
                          disabled={this.state.edit ? true : false}
                        />
                        <div
                          className="autocomplete-dropdown-container"
                          csss={css`
                            position: 'absolute',
                            zIndex: '1000',
                            overflow: 'scroll',
                            width: '472px',
                            borderRadius: '4px',`
                          }
                        >
                          {loading && <div>Loading...</div>}
                          {suggestions.map(suggestion => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item'
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                              : { backgroundColor: '#ffffff', cursor: 'pointer' }
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <div className={css`padding: 8px`}>{suggestion.description}</div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                )}
              </Form.Item>
              <Form.Item
                label="Latitude"
                help={this.state.edit ? 'The field cannot be edited' : ''}
              >
                {getFieldDecorator('latitude', {
                  rules: [
                    {
                      required: true,
                      message: 'Enter latitude',
                    },
                  ],
                })(
                  <Input
                    onChange={this.setAddressFromLat}
                    disabled={this.state.edit ? true : false}
                  />
                )}
              </Form.Item>
              <Form.Item
                label="Longitude"
                help={this.state.edit ? 'The field cannot be edited' : ''}
              >
                {getFieldDecorator('longitude', {
                  rules: [
                    {
                      required: true,
                      message: 'Enter longitude',
                    },
                  ],
                })(
                  <Input
                    onChange={this.setAddressFromLng}
                    disabled={this.state.edit ? true : false}
                  />
                )}
              </Form.Item>
              <Form.Item label="Address label">
                {getFieldDecorator('addressLabel', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter an Display Address',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Code">
                {getFieldDecorator('cpCode', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter a code',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Address">
                {getFieldDecorator('address', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter an address',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Geofence Radius">
                {getFieldDecorator('geofenceRadius', {
                  initialValue: 2,
                })(<Input />)}
              </Form.Item>
              {this.state.latitude && this.state.longitude && (
                <Map
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `400px`, marginTop: '40px' }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  lat={parseFloat(this.state.latitude)}
                  lng={parseFloat(this.state.longitude)}
                />
              )}
              <Form.Item style={{ marginTop: '20px', textAlign: 'center' }}>
                <Button type="primary" htmlType="submit">
                  Submit known place
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    )
  }
}

const WrappedAddPlacesForm = Form.create({ name: 'register' })(AddPlacesForm)
export default withRouter(WrappedAddPlacesForm)
