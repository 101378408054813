export const configKeys = {
    data: [
        'trip', 
        'indent',
        'trip.geofence',
        'trip.consent_nudge',
        'trip.geofence1',
        'trip.handleSearch1',
        'indent.geofence',
        'indent.handleSearch',
        'indent.geofence1',
        'indent.handleSearch1',
        'add',
        'edit'
    ]
}

export default configKeys