import { Row, Col, Menu } from 'antd'
import React from 'react'
import { withRouter } from 'react-router-dom'
import 'containers/routesMaster/App.css'

const routesList = [
  'configuration-list',
  'event-list',
  'ap-configuration',
  'sidebar-menu',
  'alert-settings',
  'sputnik-daily-summary',
  'exception-report',
  'dashboard-settings',
  'display-alert-ticket',
  'auto-closure',
  'alert-configuration',
  'configurations',
  'entity-detail',
  'utilities',
  // 'insert-manual-ping'
  // 'routes',
  // 'route-master',
  // 'way-point',
  // 'vehicle-master',
  // 'materialmaster',
  // 'known-places',
  // 'consignees',
  // 'transporters',
  // 'template',
  // 'epod-configuration',
]

const subMenus = {
  'utilities': [
    { 'key': 'insert-manual-ping', 'label': 'insert-manual-ping' },
    { 'key': 'trip-data', 'label': 'trip-data' },
    { 'key': 'enable-map-view', 'label': 'enable-map-view' },
    { 'key': 'last-mile-threshold', 'label': 'last-mile-threshold' },
    { 'key': 'eta-preferences', 'label': 'eta-preferences' }
  ]
}

class SideBar extends React.Component {

  gotoRoutes = (data) => {
        let qs = ''
    if (this.props.location.search.length > 0) {
      qs = `${this.props.location.search}`
    }
    this.props.history.push({
      pathname: data,
      search: qs,
    })
  }
  
  getClassName(path) {
    return this.props.location.pathname.split('/')[1] === path ? 'ant-menu-item-selected' : ''
  }


  renderSubMenuItems(subMenuItems, parentRoute) {
        return subMenuItems.map(item => (
      <Menu.Item
        key={`${parentRoute}/${item.key}`}
        onClick={() => this.gotoRoutes(`/${parentRoute}/${item.key}`)}
        className={this.getClassName(`${parentRoute}/${item.key}`)}
      >
        {item.label}
      </Menu.Item>
    ));
  }

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col>
            <Menu style={{ height: 786 }}>
              {routesList.map(route => (
                subMenus[route] ? (
                  <Menu.SubMenu key={route} title={route}>
                    {this.renderSubMenuItems(subMenus[route], route)}
                  </Menu.SubMenu>
                ) : (
                <Menu.Item
                  key={route}
                  onClick={() => this.gotoRoutes(`/${route}`)}
                  className={this.getClassName(route)}
                >
                  {route}
                </Menu.Item>
                )
              ))}
            </Menu>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
export default withRouter(SideBar)
