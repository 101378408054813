import React from 'react'
import { Row, Button, Table, message } from 'antd'
import { convertQsToJson} from 'utils/common'
import fetchData from 'utils/fetchData'
const TRANSDB_API = window._env_.REACT_APP_TRANSDB_API_URL

class SidebarMenuList extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      allConfiguration: [],
      customerConfigId: '',
    }
  }

  componentDidMount() {
    const { search } =this.props.location;
    const parsedDt = convertQsToJson(search)
    if (!parsedDt.companyId) {
      message.info('Select a company to continue')
    }else{
      this.fetchAllconfigurations(parsedDt);
    }
  } 

  componentDidUpdate(prevProps){
    const { search } = this.props.location;
    const  prevSearch = prevProps.location.search
    if (search !== prevSearch){
        const parsedDt = convertQsToJson(search)
        this.fetchAllconfigurations(parsedDt);
    }
  }
  
  gotoEditConfigForm = data => {
    let qs= this.props.location.search;
    if (qs.length > 0) {
      this.props.history.push({
        pathname: `/sidebar-menu/edit/${data.id}`,
        search: qs,
      })
    }
  }
  
  fetchAllconfigurations = (parsedDt) => {
    let url = `${TRANSDB_API}saas/data/onBoardingAPI/fetchSideBarMenuConfig?`
    let payload= {
      accessKey:process.env.REACT_APP_TRANSDB_KEY,
      companyId:parsedDt.companyId || '',
      branchId:parsedDt.branchId || '',
      enabled:1
    }
    fetchData(url,'GET',payload).then(res => {
          if (res.result.status){
            this.setState({ allConfiguration: res.result.data })
          } else {
            this.setState({ allConfiguration: '' })
            message.error(res.result.message);
          }
      }
      ).catch(error => {
        this.setState({ allConfiguration: '' });
        message.error(error);
      });   
  }

  render() {  
    const columns = [
      {
        title: 'Feature Name',
        key: 'featureKey',
        dataIndex: 'featureKey',
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
            <span>
              <Button onClick={() => this.gotoEditConfigForm(record)}>Edit</Button>
            </span>
        ),
      },
    ];

    const { allConfiguration}= this.state;
    return (
      <div>
        <Row style={{ padding: '10px 10px 10px 22px' }} />
        <Row>
          <Table
            columns={columns}
            dataSource={allConfiguration}
            rowKey={record => record.customerConfigId}
          />
        </Row>
      </div>
    )
  }
}
export default SidebarMenuList
