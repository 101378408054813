export const Cron = {
    crons: [
        { value: '0 30 2 * * ?', label: 'Everyday 8 am' },
        { value: '0 0 8 * * ?', label: 'Everyday 8:30 am' },
        { value: '0 30 3 * * ?', label: 'Everyday 9 am' },
        { value: '0 0 4 * * ?', label: 'Everyday 9:30 am' },
        { value: '0 30 4 * * ?', label: 'Everyday 10 am' },
        { value: '0 0 5 * * ?', label: 'Everyday 10:30 am' },
        { value: '0 30 5 * * ?', label: 'Everyday 11 am' },
        { value: '0 30 6 * * ?', label: 'Everyday 12 pm' },
        { value: '0 30 7 * * ?', label: 'Everyday 1 pm' },
        { value: '0 30 8 * * ?', label: 'Everyday 2 pm' },
        { value: '0 30 9 * * ?', label: 'Everyday 3 pm' },
        { value: '0 30 10 * * ?', label: 'Everyday 4 pm' },
        { value: '0 30 11 * * ?', label: 'Everyday 5 pm' },
        { value: '0 30 12 * * ?', label: 'Everyday 6 pm' },
        { value: '0 30 13 * * ?', label: 'Everyday 7 pm' },
        { value: '0 30 14 * * ?', label: 'Everyday 8 pm' },
        { value: '0 30 15 * * ?', label: 'Everyday 9 pm' },
        { value: '0 30 16 * * ?', label: 'Everyday 10 pm' },
        { value: '0 30 17 * * ?', label: 'Everyday 11 pm' },
        { value: '0 30 18 * * ?', label: 'Everyday 12 am' },
        { value: '0 30 1 * * ?', label: 'Everyday 7 am' },
        { value: '0 30 0 * * ?', label: 'Everyday 6 am' }
    ]
}

export default Cron