import React from 'react'
import { Form, Input, Button, Row, Col, Select, DatePicker, message, Divider } from 'antd'
import request from 'utils/request'
import { convertQsToJson } from 'utils/common'
import 'containers/routesMaster/App.css'
import moment from 'moment'
import { css } from '@emotion/css'
const { Option } = Select
const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 12 },
}

class AddNewRoute extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      vehicles: [],
      materials: [],
      transporters: [],
      routes: [],
      transporterName: '',
      validFromDate: '',
      companyRouteID: '',
      trasporterURL: '',
      vehicleURL: '',
      materialURL: '',
      routeURL: '',
    }
  }

  componentDidMount() {
    const parsedDt = convertQsToJson(this.props.location.search)
    const cId = parsedDt.companyId || ''
    if (cId === '') {
      message.info('Select a company to continue')
    } else {
      this.constructUrl(this.props.location.search)
    }
  }
  // componentWillReceiveProps(nextProps) {
  //   debugger
  //   this.constructUrl(nextProps.location.search)
  // }
  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.setState({
        routes: [],
        companyRouteID: '',
      })
      this.constructUrl(this.props.location.search)
    }
  }
  constructUrl(qs) {
    const parsedDt = convertQsToJson(qs)
    const cId = parsedDt.companyId || ''
    const bId = parsedDt.branchId || ''
    const dId = parsedDt.departmentId || ''
    const trasporterURL = `${
      window._env_.REACT_APP_API_URL
    }/saas/lego/company_partners/fetch?c=${cId}&b=${bId}&d=${dId}&t=CONSIGNOR&pt=TRANSPORTER`
    const vehicleURL = `${
      window._env_.REACT_APP_API_URL
    }/saas/lego/company_vehicles/fetch?c=${cId}&b=${bId}&d=${dId}&t=CONSIGNOR`
    const materialURL = `${
      window._env_.REACT_APP_API_URL
    }/saas/lego/company_materials/fetch?c=${cId}&b=${bId}&d=${dId}&t=CONSIGNOR`
    const routeURL = `${
      window._env_.REACT_APP_API_URL
    }/saas/lego/company_routes/fetch?c=${cId}&b=${bId}&d=${dId}&t=CONSIGNOR`
    this.setState({
      trasporterURL: trasporterURL,
      vehicleURL: vehicleURL,
      materialURL: materialURL,
      routeURL: routeURL,
    })
    if (cId !== '') {
      this.fetchAllRoute(routeURL)
      this.fetchTransporters(trasporterURL)
      this.fetchVehicle(vehicleURL)
      this.fetchMaterialType(materialURL)
    }
  }

  fetchAllRoute(url) {
    request(url).then(
      result => {
        this.setState({
          routes: result.data,
        })
      },
      error => {
        console.log('fail')
      }
    )
  }
  fetchTransporters(url) {
    request(url).then(
      result => {
        if (result.success) {
          this.setState({
            transporters: result.data,
          })
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        console.log('fail')
      }
    )
  }
  fetchVehicle(url) {
    request(url).then(
      result => {
        if (result.success) {
          this.setState({
            vehicles: result.data,
          })
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        message.error('Error while fetching vehicles')
      }
    )
  }
  fetchMaterialType(url) {
    request(url).then(
      result => {
        if (result.success) {
          this.setState({
            materials: result.data,
          })
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        message.error('Error while fetching materials')
      }
    )
  }
  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.addRouteMaster(values)
      }
    })
  }
  validateNumber = (rule, value, callback) => {
    if (parseInt(value) <= 0) {
      callback('Please enter a number greater than 0')
    } else {
      callback()
    }
  }
  addRouteMaster(data) {
    const parsedDt = convertQsToJson(this.props.location.search)
    const cId = parsedDt.companyId || ''
    const bId = parsedDt.branchId || ''
    const dId = parsedDt.departmentId || ''
    const routeMasterData = {
      partnerId: data.transporterName,
      validFrom: this.state.validFromDate,
      vehicleId: data.vehicleType,
      materialId: data.materialType,
      loadType: 'FTL',
      data: [],
    }

    if (data.freightCost)
      routeMasterData.data.push({
        key: 'ROUTE_COST',
        value: parseInt(data.freightCost),
        unit: data.unit,
      })
    if (data.loadingFee)
      routeMasterData.data.push({
        key: 'LOADING_FEE',
        value: parseInt(data.loadingFee),
        unit: 'FIXED',
      })
    if (data.unLoadingFee)
      routeMasterData.data.push({
        key: 'UNLOADING_FEE',
        value: parseInt(data.unLoadingFee),
        unit: 'FIXED',
      })
    if (data.detentionChargesAtLoading)
      routeMasterData.data.push({
        key: 'LOADING_DETENTION_COST',
        value: parseInt(data.detentionChargesAtLoading),
        unit: 'DAY',
      })
    if (data.detentionChargesAtUnloading)
      routeMasterData.data.push({
        key: 'UNLOADING_DETENTION_COST',
        value: parseInt(data.detentionChargesAtUnloading),
        unit: 'DAY',
      })
    if (data.statndardTransitTime)
      routeMasterData.data.push({
        key: 'STANDARD_TRANSIT_TIME',
        value: parseFloat(data.statndardTransitTime) * 60,
        unit: 'FIXED',
      })
    
    request(
      `${
        window._env_.REACT_APP_API_URL
      }/saas/lego/company_routes_master/create?c=${cId}&b=${bId}&d=${dId}&t=CONSIGNOR&pt=TRANSPORTER&routeId=${
        this.state.companyRouteID
      }`,
      {
        method: 'POST',
        body: JSON.stringify(routeMasterData),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(
      result => {
        if (result.success) {
          message.info('Route master added successfully')
          this.props.history.push({
            pathname: '/route-master',
            search: this.props.location.search,
          })
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        console.log('fail')
      }
    )
  }
  onChangeDate = (date, dateString) => {
    if (date !== null) {
      let convertoMiliSecond = moment(dateString, 'DD/MM/YYYY')
        .toDate()
        .getTime()
      console.log('moment_date:::', convertoMiliSecond)
      // let convertoMiliSecond = date._d.getTime()
      this.setState({ validFromDate: convertoMiliSecond })
    }
  }
  handleRouteChange = data => {
    this.setState({ companyRouteID: data })
  }
  checkSpaces = (rule, value, callback) => {
    if (value && value.trim() === '') {
      callback('Blank spaces not allowed')
    } else {
      callback()
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <div
        className={css`
          padding: 20px;
        `}
      >
        <Row gutter={16}>
          <Row className={css`margin-left:30px;width:100%`}>
            <Col xs={12}>
              <Select
                onChange={this.handleRouteChange}
                value={this.state.companyRouteID}
                className={css`width:290px`}
              >
                {this.state.routes &&
                  this.state.routes.map(route => (
                    <Option key={route.id} value={route.id}>
                      {route.code} - {route.label}
                    </Option>
                  ))}
                <Option value="">{'Select a Route Code - Route Label'}</Option>
              </Select>
              {this.state.companyRouteID === '' ? (
                <p>
                  <span className={css`color:#f5222d`}>*</span> Please select route
                </p>
              ) : (
                ''
              )}
            </Col>
          </Row>
          <Divider />
          {this.state.companyRouteID !== '' ? (
            <Col span={16}>
              <Form onSubmit={this.handleSubmit}>
                <Form.Item {...formItemLayout} label="Transporter name">
                  {getFieldDecorator('transporterName', {
                    rules: [{ required: true, message: 'Please select transporter name!' }],
                  })(
                    <Select>
                      {this.state.transporters &&
                        this.state.transporters.map(transporter => (
                          <Option key={transporter.id} value={transporter.id}>
                            {transporter.name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>

                <Form.Item {...formItemLayout} label="Vehicle type">
                  {getFieldDecorator('vehicleType', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select a vehicle type',
                      },
                    ],
                  })(
                    <Select>
                      {this.state.vehicles.length > 0 &&
                        this.state.vehicles.map(vehicle => (
                          <Option key={vehicle.id} value={vehicle.id}>
                            {vehicle.label}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '40%', textAlign: 'right', marginTop: '10px' }}>
                    <label style={{ color: 'rgba(0,0,0,0.85)' }}>
                      <span style={{ color: '#f5222d', marginRight: '4px' }}>*</span>Freight Cost :
                    </label>
                  </div>
                  <div style={{ width: '30%', marginLeft: '10px' }}>
                    <Form.Item>
                      {getFieldDecorator('freightCost', {
                        rules: [
                          {
                            required: true,
                            message: 'Please enter freight cost',
                          },
                          { validator: this.validateNumber },
                        ],
                      })(<Input type="number" />)}
                    </Form.Item>
                  </div>
                  <div style={{ width: '20%' }}>
                    <Form.Item>
                      {getFieldDecorator('unit', {
                        rules: [
                          {
                            required: true,
                            message: 'Please select unit',
                          },
                        ],
                      })(
                        <Select>
                          <Option value="TON">TON</Option>
                          <Option value="TRUCK">TRUCK</Option>
                        </Select>
                      )}
                    </Form.Item>
                  </div>
                </div>
                <Form.Item {...formItemLayout} label="Valid from">
                  {getFieldDecorator('validFrom', {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter valid from',
                      },
                    ],
                  })(<DatePicker format="DD/MM/YYYY" onChange={this.onChangeDate} />)}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Material Type">
                  {getFieldDecorator('materialType', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select a material type',
                      },
                    ],
                  })(
                    <Select>
                      {this.state.materials &&
                        this.state.materials.map(material => (
                          <Option key={material.id} value={material.id}>
                            {material.label}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Loading fee (in INR)">
                  {getFieldDecorator('loadingFee', {
                    rules: [
                      {
                        required: false,
                        message: 'Please enter loading fee',
                      },
                      { validator: this.validateNumber },
                    ],
                  })(<Input type="number" />)}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Unloading fee (in INR)">
                  {getFieldDecorator('unLoadingFee', {
                    rules: [
                      {
                        required: false,
                        message: 'Please enter unloading fee',
                      },
                      { validator: this.validateNumber },
                    ],
                  })(<Input type="number" />)}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Detention charges at loading (in INR/DAY)">
                  {getFieldDecorator('detentionChargesAtLoading', {
                    rules: [
                      {
                        required: false,
                        message: 'Please enter Detention Charges at loading',
                      },
                      { validator: this.validateNumber },
                    ],
                  })(<Input type="number" />)}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Detention charges at unloading (in INR/DAY)">
                  {getFieldDecorator('detentionChargesAtUnloading', {
                    rules: [
                      {
                        required: false,
                        message: 'Please enter  Detention Charges at unloading',
                      },
                      { validator: this.validateNumber },
                    ],
                  })(<Input type="number" />)}
                </Form.Item>
                <Form.Item {...formItemLayout} label="STT (in Hour)">
                  {getFieldDecorator('statndardTransitTime', {
                    rules: [
                      {
                        required: false,
                        message: 'Please enter STT',
                      },
                      { validator: this.validateNumber },
                    ],
                  })(<Input />)}
                </Form.Item>
                <Form.Item
                  className={css`
                    text-align: center;
                  `}
                >
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          ) : (
            ''
          )}
        </Row>
      </div>
    )
  }
}
const AddConsigneeForm = Form.create({ name: 'dynamic_rule' })(AddNewRoute)
export default (AddNewRoute, AddConsigneeForm)
