import React from 'react'
import { Row, Button, Table, Checkbox, Modal, message } from 'antd'
import { convertQsToJson , constructPhoenixUrl} from 'utils/common'
import fetchData from 'utils/fetchData'
const TRANSDB_API = window._env_.REACT_APP_TRANSDB_API_URL

class AlertList extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      allConfiguration: [],
      checked: false,
      visible: false,
      modeToggle: null,
      alertConfigId: null,
      toggleAlert:false
    }
  }

  componentDidMount() {
    const { search } =this.props.location;
    const parsedDt = convertQsToJson(search)
    if (!parsedDt.companyId) {
      message.info('Select a company to continue')
    }else{
      this.getAlertList(search);
    }
  } 

  componentDidUpdate(prevProps){
    const { search } = this.props.location;
    const  prevSearch = prevProps.location.search
    if (search !== prevSearch){
        this.getAlertList(search);
    }
  }

  getAlertList = async(qs) => {
      if(qs){
       this.fetchAllconfigurations(qs)
      }
  }

  handleOnChange = (data,field,ticket) => {
      if(data && data.id && field){
        if(field === 'enableAlert'){
          this.setState({
            visible: true,
            alertConfigId: data.id,
            modeToggle: data.enabled,
            toggleAlert:true
        })
        }else{
          this.setState({
            visible: true,
            alertConfigId: data.id,
            modeToggle: ticket,
            toggleAlert:false
        })
        }
      }
  }

  handleCancel = () => {
    this.setState({ visible: false })
  }
  
  handleNotification = async() => {
      const { alertConfigId, toggleAlert} = this.state;
      const { search } =this.props.location;
      if(alertConfigId){
        const url = this.createTargetURL(toggleAlert,alertConfigId);
        await fetchData(url,'POST').then(res => {
          if (res.result.status === true) {
            this.setState({ visible: false })
            this.getAlertList(search)
          }
        })
        .catch(error => {
          message.error('Error while disabling report')
        });    
        this.props.history.push({
            pathname: '/alert-settings',
            search,
        })
      }
  }

  createTargetURL= (toggleAlert,alertConfigId) => {
    if(toggleAlert){
      let url =`${TRANSDB_API}saas/data/automation/toggleAlert?accessKey=${process.env.REACT_APP_TRANSDB_KEY}&alertId=`+`${alertConfigId}` 
      return url ;
    }else{
      let url =`${TRANSDB_API}saas/data/automation/toggleAlertTickets?accessKey=${process.env.REACT_APP_TRANSDB_KEY}&alertId=`+`${alertConfigId}`
      return url ;
    }
  }

  
  fetchAllconfigurations(qs) {
    const url = constructPhoenixUrl('saas/data/automation/getAlerts?accessKey='+`${process.env.REACT_APP_TRANSDB_KEY}`, qs)  
    fetchData(url,'GET').then(result => {
          if (result.status ==='ok'){
            this.setState({ allConfiguration: result.result.data })
          } else {
            result.message.map(msg => message.error(msg))
          }
      }
      ).catch(error => {
        message.error('Error while fetching report')
      });
  }


  render() {
    const { allConfiguration,visible,modeToggle,toggleAlert}= this.state;  
    const columns = [
      {
        title: 'Alert Id',
        key: 'id',
        dataIndex: 'id',
      },
      {
        title: 'Alert Name',
        key: 'type',
        dataIndex: 'featureKey',
      },
      {
        title: 'Enable Alert',
        key: 'isEnabled',
        render: (enabled, record, i) => {
          return (
            <Checkbox checked={record.enabled} onChange={() => this.handleOnChange(record,'enableAlert')} />
          )
        },
      },
      {
        title: 'Enable Ticket',
        key: 'isEnabledTicket',
        render: (enabled, record, i) => {
          if(record && record.extraContext){
            const tmp = JSON.parse(record.extraContext);
            if(tmp[0]['ticketing'] && tmp[0]['ticketing'] && tmp[0]['ticketing']['raise_ticket']){
             let  ticket = tmp[0]['ticketing']['raise_ticket']?tmp[0]['ticketing']['raise_ticket']:false;
                return (
                  <Checkbox checked={ticket} onChange={() => this.handleOnChange(record,'enableTicket',ticket)} />
                )
            }else{
                let ticket = false;
                return (
                  <Checkbox checked={ticket} onChange={() => this.handleOnChange(record,'enableTicket',ticket)} />
                )
            }
          }
        },
      }
    ];
    return (
      <div> 
        <Row style={{ padding: '10px 10px 10px 22px' }} />
        <Row>
          <Table
            columns={columns}
            dataSource={allConfiguration}
            rowKey={record => record.alertConfigId}
          />
        </Row>
        <Modal
          width={450}
          height={450}
          footer={null}
          title="Enable Configuration"
          visible={ visible }
          onCancel={this.handleCancel}
        >
          <p>
            Are you sure want to{' '}
          { modeToggle ? <span>disable</span> : <span>enable</span>}{ toggleAlert ? <span> notification </span>: <span> Ticket </span> }
          </p>
          <div style={{ textAlign: 'right' }}>
            <Button onClick={() => this.handleNotification()}>Yes</Button>
            <Button onClick={this.handleCancel}>No</Button>
          </div>
        </Modal>
      </div>
    )
  }
}
export default AlertList
