import React from 'react'
import { Form, message, Button, Row, Col, Input, AutoComplete, Select } from 'antd'
import { css } from '@emotion/css'

import fetchData from 'utils/fetchData'
const Option = AutoComplete.Option

const TRIP_SNAPSHOT_URL = window._env_.REACT_APP_TRIP_SNAPSHOT_API_URL
const TRIP_SERVICE_URL = window._env_.REACT_APP_ALERT_CONFIG_API_URL


const ShowTripData = ({ label, configBoxStyle, getConfigJson, onClickCopy }) => {
    return (
        <Col style={{ padding: '10px', background: '#fff' }}>
            <Row style={{ padding: '10px 10px 10px 22px' }} />
            <Row>
                <Col span={1} />
                <Col span={3} style={{ padding: '8px' }}>
                    <strong>{label}</strong>
                </Col>
                <Col span={15}>
                    <div style={configBoxStyle}>
                        <pre>{getConfigJson}</pre>
                    </div>
                    <Button onClick={onClickCopy} style={{ marginTop: '8px' }}>
                        Copy trip JSON
                    </Button>
                </Col>
            </Row>
        </Col>
    )
}

class TripData extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tripId: '',
            section: undefined,
            showConfigFlag: false,
            selectOptions: ['full', "alerts", "assets", "associated_parties", "trip_details", "indent", "analytics", "locations"],
            data: {}
        }
    }

    getConfig = (key) => {
        let { tripId, section } = this.state;
        if (tripId != '' && section === undefined) {
            section = 'full'
        }

        if (key) section = key;

        if (section === "associated_parties") section = "parties"

        let url = `${TRIP_SNAPSHOT_URL}/api/v1/trip/${tripId}/${section}`;

        if (section === "locations") {
            url = `${TRIP_SNAPSHOT_URL}/api/v1/db/trip/${tripId}/${section}`;
        }

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('satellizer_token')}`
        }

        fetchData(url, 'GET', {}, headers).then(res => {
            if (res && res.success && res.data) {
                if (key === 'trip_details') {
                    let company_id = res.data.created_by.company_id
                    url = `${TRIP_SNAPSHOT_URL}/api/v1/syncpersist/trip/${tripId}/full?company_id=${company_id}`;
                    fetchData(url, 'GET', {}, headers).then(res => {
                        if (res && res.success && res.data) {
                            message.info('Trip sync to ES Successfully')
                        } else {
                            message.info('Trip sync to ES Successfully')
                        }
                    })
                } else {
                    this.setState(prevState => ({
                        ...prevState,
                        data: res.data
                    }))
                    this.setState({ showConfigFlag: true })
                }
            } else {
                this.setState(prevState => ({
                    ...prevState,
                    data: {}
                }))
                message.info('Invalid Trip ID')
            }
        }).catch(error => {
            message.error('Something went wrong');
        });

        // this.setState({ showConfigFlag: true })
    }

    getTripData = (e) => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.getConfig()
            }
        })
    }

    syncTripInES = (e) => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.getConfig('trip_details')
            }
        })
    }

    getTripAnalytics = (e) => {
        e.preventDefault()

        let url = `${TRIP_SERVICE_URL}/v1/lego/trip/${this.state.tripId}/analytics`;
        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('satellizer_token')}`
        }
        this.props.form.validateFields((err, values) => {
            if (!err) {
                fetchData(url, 'GET', {}, headers).then(res => {
                    if (res && res.success && Object.keys(res.data).length > 0) {
                        this.setState({ data: res.data })
                        this.setState({ showConfigFlag: true })
                    } else {
                        message.info('Invalid Trip ID')
                    }
                })
                    .catch(err => message.info('Something went wrong'))
            }
        })
    }

    replayTrip = (e) => {
        e.preventDefault()
        let url = `${TRIP_SERVICE_URL}/v1/lego/trip/${this.state.tripId}/analytics/replay`;
        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('satellizer_token')}`
        }
        this.props.form.validateFields((err, values) => {
            if (!err) {
                fetchData(url, 'GET', {}, headers).then(res => {
                    if (res && res.data && res.data.message) {
                        message.info(res.data.message)
                    } else {
                        message.error('Invalid Trip ID')
                    }
                })
                    .catch(err => message.info('Something went wrong'))
            }
        })
    }

    replayTripWithUpdatedConfig = (e) => {
        e.preventDefault()
        let url = `${TRIP_SERVICE_URL}/v1/lego/trip/${this.state.tripId}/analytics/replay/updatedconfig`;
        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('satellizer_token')}`
        }
        this.props.form.validateFields((err, values) => {
            if (!err) {
                fetchData(url, 'GET', {}, headers).then(res => {
                    if (res && res.data && res.data.message) {
                        message.info(res.data.message)
                    } else {
                        message.error('Invalid Trip ID')
                    }
                })
                    .catch(err => message.info('Something went wrong'))
            }
        })
    }

    handleSubmit = e => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.getConfig(values)
            }
        })
    }

    getJsonString = (jsonData) => {
        let jsonString = ''
        try {
            jsonString = JSON.stringify(jsonData, null, 2)
        }
        catch (err) {
            message.error('Error in getting json string');
        }
        return jsonString;
    }

    copyJsonToClipboard = (jsonData) => {
        try {
            const jsonString = this.getJsonString(jsonData);
            navigator.clipboard.writeText(jsonString);
            message.success('JSON Copied to Clipboard');
        }
        catch (err) {
            message.error('Error in copyJsonToClipboard');
        }
    };

    handleInputChange = (field, value) => {
        this.setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    onSelecthandler = value => {
        this.setState(prevState => ({
            ...prevState,
            "section": value
        }))
        const qs = this.convertJsonToQs({ "section": value })
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: `?${qs}`,
        })
    }
    convertJsonToQs(data) {
        return Object.keys(data)
            .map(key => key + '=' + data[key])
            .join('&')
    }

    resetAllFilter = () => {
        this.setState(
            {
                tripId: '',
                section: undefined,
                showConfigFlag: false,
                selectOptions: this.state.selectOptions,
                data: {}
            },
            () => {
                this.props.form.resetFields();
                this.forceUpdate();
            }
        )
        this.props.history.push({
            pathname: this.props.location.pathname,
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form
        const configBoxStyle = {
            maxHeight: '350px', overflowY: 'auto', border: '1px solid #d9d9d9',
            padding: '8px', background: '#fafafa'
        }
        return (
            <div>
                <Row>
                    <Form>
                        <Row key="trip"
                            span={30}
                            className={css`
                                padding: 30px;
                                background: #fff;
                                border-bottom: 1px solid #e8e8e8;
                                `}
                            gutter={16}
                        >
                            <Col span={5}>
                                <label className="subHeaderLabel"><span style={{ color: 'red' }}>*</span>Trip ID</label>
                                <Form.Item>
                                    {getFieldDecorator('tripId', {
                                        rules: [{ required: true, message: 'Please enter trip id' }]
                                    })(
                                        <Input
                                            className={css`width: 100%;`}
                                            size="large"
                                            placeholder='Enter trip Id'
                                            value={this.state.tripId}
                                            onChange={(e) => this.handleInputChange('tripId', e.target.value)}
                                        />
                                    )}
                                </Form.Item>

                            </Col>
                            <Col span={5}>
                                <label className="subHeaderLabel">Section</label>
                                <Select
                                    disabled={false}
                                    className="dropdownwidth"
                                    placeholder="Select section"
                                    size="large"
                                    onSelect={this.onSelecthandler}
                                    value={this.state.section}
                                >
                                    {
                                        this.state.selectOptions.map((option, key) => (
                                            <Option key={key} value={option}>
                                                {option}
                                            </Option>
                                        ))
                                    }
                                </Select>
                            </Col>

                            <Col style={{ paddingTop: '27px' }} span={3}>
                                <Button
                                    disabled={this.state.disableFlag}
                                    type="primary"
                                    size="large"
                                    onClick={(e) => this.resetAllFilter('consignor')}
                                >
                                    Reset Filter
                                </Button>
                            </Col>
                            <Col className="alignright" span={8}>
                                {this.props.innerhtml ? this.props.innerhtml() : ''}
                                {this.props.children}
                            </Col>
                        </Row>
                        <Row
                            span={30}
                            className={css`
                                padding: 30px;
                                background: #fff;
                                border-bottom: 1px solid #e8e8e8;
                                `}
                            gutter={16}
                        >
                            <Col style={{}} span={3}>
                                <Button
                                    type="primary"
                                    size="large"
                                    htmlType='submit'
                                    onClick={(e) => this.getTripData(e)}
                                >
                                    GET Data
                                </Button>
                            </Col>
                            <Col style={{}} span={3}>
                                <Button
                                    type="primary"
                                    size="large"
                                    htmlType='submit'
                                    onClick={(e) => this.getTripAnalytics(e)}
                                >
                                    Show Analytics
                                </Button>
                            </Col>
                            <Col style={{}} span={3}>
                                <Button
                                    style={{marginLeft: "40px"}}
                                    type="primary"
                                    size="large"
                                    htmlType='submit'
                                    onClick={(e) => this.replayTrip(e)}
                                >
                                    Replay
                                </Button>
                            </Col>
                            <Col style={{}} span={5}>
                                <Button
                                    style={{marginLeft: "20px"}}
                                    type="primary"
                                    size="large"
                                    htmlType='submit'
                                    onClick={(e) => this.replayTripWithUpdatedConfig(e)}
                                >
                                    Replay with Updated config
                                </Button>
                            </Col>
                            <Col style={{}} span={3}>
                                <Button
                                    style={{marginLeft: "40px"}}
                                    type="primary"
                                    size="large"
                                    htmlType='submit'
                                    onClick={(e) => this.syncTripInES(e)}
                                >
                                    Sync Trip to ES
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Row>
                <Row style={{ padding: '10px 10px 10px 22px' }} />
                {this.state.showConfigFlag
                    ?
                    <ShowTripData
                        label='Trip Data:'
                        configBoxStyle={configBoxStyle}
                        getConfigJson={this.getJsonString(this.state.data)}
                        onClickCopy={() => this.copyJsonToClipboard(this.state.data)}
                    />
                    : ''
                }
            </div >
        )
    }
}

const Trip_Data_Form = Form.create({ name: 'Trip_Data_Form' })(TripData)
export default Trip_Data_Form