const addressFromLatLng = (latitude, longitude) => {
  const lat = parseFloat(latitude)
  const lng = parseFloat(longitude)

  return new Promise((resolve, reject) => {
    const locationObj = {
      lat,
      lng,
    }
    const geocoder = new window.google.maps.Geocoder()
    return geocoder.geocode({ latLng: locationObj }, (results, status) => {
      if (
        status === window.google.maps.GeocoderStatus.OK &&
        results[0] &&
        results[0].formatted_address
      ) {
        resolve({
          shortName: results[0].address_components[0].long_name,
          address: results[0].formatted_address,
          placeId: results[0].place_id,
          latLng: {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          },
          type: 'latlng',
        })
      } else {
        reject({ lat, lng })
      }
    })
  })
}

export { addressFromLatLng }
