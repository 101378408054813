import React from 'react'
import { InputNumber, Form, message, Button, Row, Col, Divider, DatePicker, Checkbox } from 'antd'
import { css } from '@emotion/css'
import { convertQsToJson } from 'utils/common'
import fetchData from 'utils/fetchData'

const TRIP_SERVICE_API_URL =  window._env_.REACT_APP_ALERT_CONFIG_API_URL
const SATELLIZER_TOKEN = localStorage.getItem('satellizer_token')
class LastMileThreshold extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            companyId: '',
            branchId: '',
            departmentId: '',
            lastMileThreshold: undefined,
            extraContext: undefined
        }
    }

    componentDidMount() {
        const { search } = this.props.location;
        const parsedDt = convertQsToJson(search)
        if (!parsedDt.companyId) {
            this.setState({ companyId: '' })
            message.info('Select a company to continue')
        } else {
            this.setState({ 
                companyId: parsedDt.companyId,
                branchId: parsedDt.branchId,
                departmentId: parsedDt.departmentId 
            }, () => this.fetchLastMileData())
        }
    }

    componentDidUpdate(prevProps) {
        const { search } = this.props.location;
        const prevSearch = prevProps.location.search;
      
        if (search !== prevSearch) {
          const parsedDt = convertQsToJson(search);
          if (parsedDt.companyId) {
            this.setState({ companyId: parsedDt.companyId }, () => {
              if (parsedDt.companyId && parsedDt.branchId) {
                this.setState({ branchId: parsedDt.branchId }, () => {
                  if (parsedDt.companyId && parsedDt.branchId && parsedDt.departmentId) {
                    this.setState({ departmentId: parsedDt.departmentId }, () => {
                      this.fetchLastMileData();
                    });
                  } else {
                    this.fetchLastMileData();
                  }
                });
              } else {
                this.fetchLastMileData();
              }
            });
          } else {
            this.props.form.resetFields();
            this.setState({
                companyId: '',
                branchId: '',
                departmentId: '',
                lastMileThreshold: undefined,
                extraContext: undefined
            });
          }
        }
      }

    handleSubmit = e => {
        e.preventDefault()
        if(this.state.companyId === "")   {
            message.info('Select a company to save')
        } else {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.saveLastMileConfig();
                }
            })
        }
    }

    saveLastMileConfig = (data) => {
        let extracontext = {};
        if(this.state.extraContext && this.state.lastMileThreshold){
            extracontext = this.state.extraContext
            extracontext["lastMileThreshold"] = this.state.lastMileThreshold
        } else {
            extracontext["lastMileThreshold"]= this.state.lastMileThreshold;
        }
        let payload = {
            companyId: this.state.companyId,
            branchId: this.state.branchId ,
            departmentId: this.state.departmentId,
            extraContext: extracontext
        }

        const url =  `${TRIP_SERVICE_API_URL}/v1/lego/feature-permission/tripAlerts/add`
        
        fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${SATELLIZER_TOKEN}`,
            },
            body: JSON.stringify(payload),
        })
        .then(res => res.json())
        .then(response => {
            if (response && response.success) {
                message.success('Saved successfully');
                this.fetchLastMileData();
            } else {
                message.error('Something went wrong');
            }
        })
        .catch(error => {
            message.error('Something went wrong');
        });
    }

    fetchLastMileData = () => {
        const {companyId, branchId, departmentId} = this.state;
        let queryParams = ''
        if(companyId) queryParams = `companyId=${companyId}`
        if(companyId && branchId) queryParams = `companyId=${companyId}&branchId=${branchId}`
        if(companyId && branchId && departmentId) queryParams = `companyId=${companyId}&branchId=${branchId}&departmentId=${departmentId}`
        
        if(queryParams != ''){
            const url =  `${TRIP_SERVICE_API_URL}/v1/lego/feature-permission/tripAlerts/get?${queryParams}`
            const headers = {
                'Authorization': `Bearer ${SATELLIZER_TOKEN}`,
            }
            fetchData(url, 'GET', {}, headers).then(res => {
                if(res && res.data && res.data.length > 0){
                    let extraContext = JSON.parse(res.data[0].extraContext)
                    if(extraContext.lastMileThreshold){
                        this.setState({
                            extraContext: extraContext,
                            lastMileThreshold: extraContext.lastMileThreshold
                        })
                    }
                } else {
                    this.setState({
                        extraContext: undefined,
                        lastMileThreshold: undefined
                    })
                }
            })
            .catch(error => {
                this.setState({
                    extraContext: undefined,
                    lastMileThreshold: undefined
                })
            })
        }
    }

    handleChange = (value) => {
        this.setState({lastMileThreshold: value})
    }

    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <div>
                <Row
                    span={20}
                    className={css`
                    padding: 20px;
                    background: #fff;
                    border-bottom: 1px solid #e8e8e8;
                    `}
                    gutter={16}>
                    <Form>
                        <Row>
                            <Col style={{margin: "10px"}} span={6}>
                                <label>
                                    <h3>Last Mile Threshold Setting</h3>
                                </label>
                            </Col>
                        </Row>
                        <Row style={{padding: "10px"}}>
                            <Col style={{ paddingTop: '8px' }} span={5}>
                                <label>
                                    <span style={{ color: 'red' }}>*</span>Last Mile Threshold :
                                </label>
                            </Col>
                            <Col span={2}>
                                <Form.Item>
                                    {getFieldDecorator('lastmile', {
                                        rules: [
                                            { required: true, message: 'This field is required' },
                                            {
                                                pattern: /^(?:100|[1-9]?[0-9])$/,
                                                message: 'Please enter a valid number between 1 and 100',
                                            },
                                        ],
                                        initialValue: this.state.lastMileThreshold,
                                    })(<InputNumber size="large" min={1} onChange={this.handleChange} />)}
                                </Form.Item>
                            </Col>
                            <Col span={6}></Col>
                            <Col span={3}>
                                <Button
                                    type="primary"
                                    size="large"
                                    htmlType='submit'
                                    onClick={(e) => this.handleSubmit(e)}
                                >
                                    Save
                                </Button>
                            </Col>
                            </Row>
                    </Form>
                </Row>
            </div >
        )
    }
}

const Last_Mile_Threshold_Form = Form.create({ name: 'Last_Mile_Threshold_Form' })(LastMileThreshold)
export default Last_Mile_Threshold_Form