import React from 'react'
import { Form,Select,Input,Button,Row,Col,message } from 'antd'
import 'containers/routesMaster/App.css'
import { withRouter } from 'react-router'
import { convertQsToJson} from 'utils/common'
import fetchData from 'utils/fetchData'
import './dashboard.scss'
import rlsRoles from './rlsRoles'

const PHOENIX_API = window._env_.REACT_APP_TRANSDB_API_URL
class DashboardConfigForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isCreate: true,
      customerConfigId:null,
      dashboardSlug:[''],
      dashboardName:[''],
      dashboardWorkspace:[''],
      allFlag: {
        dashboardDisableFlag: true,
      },
      dashboardType:'',
      userId:[],
      menulist:[],
      dashboardReportId:[''],
      dashboardWorkspaceId:[''],
      dashboardRlsRoles:[[]]
    }
    this.handleDashboardType = this.handleDashboardType.bind(this);
  }

componentDidMount(){
    const { search } = this.props.location;
    const parsedDt = convertQsToJson(search)
    if (!parsedDt.companyId) {
      message.info('Select a company to continue')
    }else{
       this.fetchDashboardMenu();
       if(this.props.match.params.id){
        this.setState({ isCreate: false}, () => { 
          this.fetchDashboard(this.props.match.params.id)
        })
       }
    }
}

componentDidUpdate(prevProps){
    const {search} = this.props.location;
    if(search !== prevProps.location.search){
      this.fetchDashboardMenu();
      if(this.props.match.params.id){
        this.setState({isCreate: false}, () => { 
          this.fetchDashboard(this.props.match.params.id)
        })
       }
    }
}

fetchDashboard = async(id) => {
      const url = `${PHOENIX_API}saas/data/automationdashboard/getDashboardbyId?`;
      const payload = {
        id:id
      }
      await fetchData(url,'GET',payload).then(response => {
             this.setDashboardConfig(response,id);
      }
      ).catch(error => {
            message.error('Error while fetching dashboard details')
      });
}

setDashboardConfig = (response,id) => {
  const { extraContext,dashboardType} = response.result;
  if(response.status == 'ok'){
    const dashbaordDetails = JSON.parse(extraContext);
    const objectArray = Object.entries(dashbaordDetails);

    let tempDashboardWorkSpace= [];
    let tempDashboardName=[];
    let tempDashboardSlug =[];
    let userId=[];
    
    let tempDashboardReportId = [];
    let tempDashboardWorkSpaceId = [];
    let tempDashboardRlsRoles = [];

    if(dashboardType == 'zoho'){
      // objectArray.forEach(([key, value]) => {
      //   tempDashboardName.push(value.dashboard);
      //   tempDashboardSlug.push(key);
      //   tempDashboardWorkSpace.push(value.workspace);
      // });
      // const items = {
      //   dashboardDisableFlag: 'dashboardDisableFlag',
      // }
      // const newObj = {}
      // Object.keys(items).forEach((key, value) => {
      //   let itemList = tempDashboardName;
      //   newObj[items[key]] = itemList.length > 1 ? false : true
      //   return newObj
      // })
      // this.setState({
      //   customerConfigId :id,
      //   dashboardSlug:tempDashboardSlug,
      //   dashboardName:tempDashboardName, 
      //   dashboardWorkspace:tempDashboardWorkSpace,
      //   allFlag: newObj,
      //   dashboardType,

      // });
      // this.props.form.setFieldsValue({
      //   dashboardSlug:tempDashboardSlug,
      //   dashboardName:tempDashboardName,
      //   dashboardWorkspace: tempDashboardWorkSpace,
      //   userId: userId

      // })

      objectArray.forEach(([key, value]) => {
        if(value.power_bi){
          tempDashboardSlug.push(key);
          tempDashboardReportId.push(value.power_bi.report_id);
          tempDashboardWorkSpaceId.push(value.power_bi.workspace_id);
          tempDashboardRlsRoles.push(value.power_bi.rls_roles);
        }
      });
      const items = {
        dashboardDisableFlag: 'dashboardDisableFlag',
      }
      const newObj = {}
      Object.keys(items).forEach((key, value) => {
        let itemList = tempDashboardReportId;
        newObj[items[key]] = itemList.length > 1 ? false : true
        return newObj
      })
      this.setState({
        customerConfigId :id,
        dashboardSlug:tempDashboardSlug,
        dashboardReportId:tempDashboardReportId,
        dashboardWorkspaceId:tempDashboardWorkSpaceId,
        dashboardRlsRoles:tempDashboardRlsRoles,
        allFlag: newObj,
        dashboardType,
      });
      this.props.form.setFieldsValue({
        dashboardSlug:tempDashboardSlug,
        dashboardReportId:tempDashboardReportId,
        dashboardWorkspaceId:tempDashboardWorkSpaceId,
        dashboardRlsRoles:tempDashboardRlsRoles,
        userId: userId
      })
    }
    else{
      dashbaordDetails.enabled_users.forEach((value) => {
        tempDashboardSlug.push(value)
      });
      dashbaordDetails.dashboard_tab.forEach((value) => {
        tempDashboardWorkSpace.push(value.dashboard_id);
        tempDashboardName.push(value.name);
      });
      const items = {
        dashboardDisableFlag: 'dashboardDisableFlag',
      }
      const newObj = {}
      Object.keys(items).forEach((key, value) => {
        let itemList = tempDashboardName;
        newObj[items[key]] = itemList.length > 1 ? false : true
        return newObj
      })
      this.setState({
        customerConfigId :id,
        dashboardWorkspace:tempDashboardWorkSpace,
        dashboardName:tempDashboardName, 
        userId:tempDashboardSlug,
        allFlag: newObj,
        dashboardType,
      });
      this.props.form.setFieldsValue({
        dashboardWorkspace:tempDashboardWorkSpace,
        dashboardName:tempDashboardName, 
        userId: tempDashboardSlug
      })
    }
  }
}

  handleDashboardName = (i, e) => {
    let t = this.state.dashboardName
    t[i] = e.target.value
    this.setState({ dashboardName: t })
  }

  handleDashboardWorkspace = (i, e) => {
    let t = this.state.dashboardWorkspace
    t[i] = e.target.value
    this.setState({ dashboardWorkspace: t })
  }

  handleDashboardData = (i, type, e) => {
    let t = this.state[type]
    if(type == 'dashboardRlsRoles'){
      t[i] = e
    }
    else{
      t[i] = e.target.value
    }
    this.setState({ type: t })
  }

  handleDashboardType = (value) => {
    this.setState({ dashboardType: value })
  }

  handleSlug = e => {
    this.setState({ dashboardSlug: [e] })
  }

  setUserId = e => {
    this.setState({ userId: e })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.createAndUpdateDashboard(values)
      }
    })
  }

  createAndUpdateDashboard(dashboarddata) {
    const { search } = this.props.location;
    const parsedDt = convertQsToJson(search)
    const companyId = parsedDt.companyId || ''
    const branchId = parsedDt.branchId || ''
    const { customerConfigId,
      dashboardName,dashboardSlug,
      dashboardWorkspace,dashboardType,isCreate,userId,
      dashboardReportId,dashboardWorkspaceId,dashboardRlsRoles } = this.state;
    const typeDashboard = isCreate ? dashboarddata.dashboardType:dashboardType;
    if(companyId){
       let type ='POST'
       const payload = {
        companyId,
        branchId,
        customerConfigId,
        dashboardName,
        dashboardSlug,
        dashboardWorkspace,
        typeDashboard,
        userId,
        isCreate,
        dashboardReportId,
        dashboardWorkspaceId,
        dashboardRlsRoles
       };
       const url = `${PHOENIX_API}saas/data/automationdashboard/setDashboard?`;
       fetchData(url,type,payload).then(res => {
        if(res.status == 'ok'){
          const companyId = res.result?res.result.companyId:null;
          message.success(`Dashboard set successfully for companyIds [ ${companyId} ] `);
          }else{
          message.error('Dashboard is not updated');
        }
        this.props.history.push({
            pathname: '/dashboard-settings',
            search: search,
        })
      }
      ).catch(error => {
        message.error(error)
      });
    }
  }

  deleteFields = (dashboardName,dashboardSlug,dashboardWorkspace,dashboardReportId,dashboardWorkspaceId,dashboardRlsRoles,index, flag, dashboardType) => {
    let tempDashboardType = this.state[dashboardType]
    let tempDashboard = this.state[dashboardName]
    let tempSlug = this.state[dashboardSlug]
    let tempWorkspace = this.state[dashboardWorkspace]

    let tempReportId = this.state[dashboardReportId]
    let tempWorkspaceId = this.state[dashboardWorkspaceId]
    let tempRlsRoles = this.state[dashboardRlsRoles]

    const { allFlag } = this.state
    tempDashboard.splice(index, 1)
    tempSlug.splice(index, 1)
    tempWorkspace.splice(index, 1)

    tempReportId.splice(index, 1)
    tempWorkspaceId.splice(index, 1)
    tempRlsRoles.splice(index, 1)

    this.props.form.setFieldsValue({[dashboardName]:tempDashboard,[dashboardWorkspace]:tempWorkspace,[dashboardReportId]:tempReportId,[dashboardWorkspaceId]:tempWorkspaceId,[dashboardRlsRoles]:tempRlsRoles})
    
    // if (tempDashboard.length === 1) {
    //   allFlag[flag] = true
    // }
    // this.setState({ allFlag, type: tempDashboard})

    if(tempDashboardType == 'zoho'){
      if (tempReportId.length === 1) {
        allFlag[flag] = true
      }
      this.setState({ allFlag, type: tempReportId})
    }
    else{
      if (tempDashboard.length === 1) {
        allFlag[flag] = true
      }
      this.setState({ allFlag, type: tempDashboard})
    }
  }

  fetchDashboardMenu = () => { 
    let url = `${PHOENIX_API}saas/data/automationdashboard/getDashboardMenu?`
    let payload= {
        accessKey:process.env.REACT_APP_TRANSDB_KEY,
        companyId:1
    }
    fetchData(url,'GET',payload).then(res => {
        if (res.status =='ok'){
          this.setState({
            menulist: res.result,  
          })
          return res.result;
        } else {
          message.error(res.result.message);
        }
      }).catch(error => {
        message.error(error);
      });   
  }

  render() {
    const {
      form: { getFieldDecorator },
    } = this.props
    const { isCreate,dashboardName,dashboardType ,userId ,menulist, dashboardReportId, dashboardSlug}= this.state;
    let dasboardkey=[];
    return ( 
      <div  className="dashbaordConfigForm">
        <h3>{`${isCreate ? 'Create' : 'Update'} Dashboard Config`}</h3>
        <Form onSubmit={this.handleSubmit}>
        { isCreate ? 
        <Row>
          <Col span={10}>
            <Form.Item label="Dashbaord Type">
               {getFieldDecorator(`dashboardType`, {
               rules: [
                {
                   required: true,
                   whitespace: true,
                   message: 'Please select dashboard type',
                }
              ]
              })(
                <Select defaultValue="none"
                 onChange={this.handleDashboardType}
                 >
                  {/* <option value="zoho">Zoho</option> */}
                  <option value="zoho">PowerBI</option>
                  {/* <option value="looker">Looker</option> */}
                </Select>
               )}
            </Form.Item> 
          </Col>
        </Row> 
          :'' }
        { (isCreate && dashboardType === 'zoho') ?  
        <Row>
          <Col span={10}>
          <Form.Item label="Select Dashboard Menu">
                    {getFieldDecorator('menulist', {
                      rules: [{ required: true, message: 'Please select menulist' }],
                    })(
                      <Select
                        placeholder="Select a event"
                        onChange={this.handleSlug}
                      >
                        {menulist &&
                          menulist.map(data => (
                             dasboardkey = data.url.split('/'),
                            <option key={data.id} value={dasboardkey[2]==='pbi'?dasboardkey[3]:dasboardkey[2]}>{data.name}</option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
          </Col>
        </Row> : '' }
         { dashboardType === 'looker' ?  
         <Row>
            <Col span={10}> 
               <Form.Item label="Looker UserId">
                {getFieldDecorator('userId', {
                  rules: [
                    {
                      required:  true,
                      message: 'Please input userId',
                    },
                  ],
                  })(
                  <Select mode="tags" onChange={this.setUserId}>
                   {userId.userids && userId.userids.map((e, key) => {
                        return <option key={key} value={e.value}>{e.label}</option>;
                    })}
                  </Select>
                )}
              </Form.Item> 
            </Col>
          </Row> : '' }
          {dashboardType == 'looker' && dashboardName && dashboardName.map((item, i) => (
            <Row key={i}>
              <Col span={10}>
              <Form.Item label={dashboardType == 'looker' ? "Looker Dashboard Name":"Zoho Dashboard Name"}>
                {getFieldDecorator(`dashboardName[${i}]`, {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter valid dashboard name',
                    },
                  ],
                })(
                <Input 
                    type="text"
                    onChange={this.handleDashboardName.bind(this, i)}
                />
                )}
              </Form.Item>
              </Col>
              <Col span={1}></Col>
              <Col span={10}>
              <Form.Item label={dashboardType =='looker'? "Looker dashboard Id":"workspace"}>
                {getFieldDecorator(`dashboardWorkspace[${i}]`, {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter valid dashboard workspace',
                    },
                  ],
                })(
                  <Input
                    type="text"
                    onChange={this.handleDashboardWorkspace.bind(this, i)}
                  />
                )}
              </Form.Item>
              </Col> 

              <Col span={1} className="btn-layout">
                <Button
                    disabled={this.state.allFlag.dashboardDisableFlag}
                    onClick={() =>
                      this.deleteFields('dashboardName', 'dashboardSlug','dashboardWorkspace','dashboardReportId','dashboardWorkspaceId','dashboardRlsRoles',i, 'dashboardDisableFlag', 'dashboardType')
                    }
                >
                    X
                </Button>
              </Col>
            </Row>
          ))}
          
          
          {
            dashboardType == 'zoho' && dashboardReportId && dashboardReportId.map((item,i) => {
              return (
              <Row key={i}>
                {
                  <Row>
                  {
                    (!isCreate) ?
                      <Row>
                        <Col>
                          <h4></h4>
                          <h4>{`Dashboard Key: ${dashboardSlug[i]}`}</h4>
                        </Col>
                      </Row>
                    :''
                  }
                  <Col span={10}>
                    <Row>
                      <Col>
                        <Form.Item label={"PowerBI ReportId"}>
                          {
                            getFieldDecorator(`dashboardReportId[${i}]`, {
                              rules: [
                                {
                                  required: true,
                                  message : 'Please enter valid PowerBI ReportId',
                                },
                              ],
                            })(
                              <Input
                                type="text"
                                onChange={this.handleDashboardData.bind(this,i,'dashboardReportId')}
                              />
                            )
                          }
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item label={"PowerBI rlsRoles"}>
                          {
                            getFieldDecorator(`dashboardRlsRoles[${i}]`, {
                              rules: [
                                {
                                  required: true,
                                  message : 'Please enter valid PowerBI Rls Roles',
                                },
                              ],
                            })(
                              <Select mode="tags" tokenSeparators={[',']} onChange={this.handleDashboardData.bind(this, i, 'dashboardRlsRoles')}>
                                {rlsRoles.roles && rlsRoles.roles.map(e => {
                                  return <option value={e}>{e}</option>;
                                })}
                              </Select>
                            )
                          }
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
  
                  <Col span={1}></Col>
  
                  <Col span={10}>
                    <Row>
                      <Col>
                        <Form.Item label={"PowerBI WorkspaceId"}>
                          {
                            getFieldDecorator(`dashboardWorkspaceId[${i}]`, {
                              rules: [
                                {
                                  required: true,
                                  message : 'Please enter valid PowerBI WorkspaceId',
                                },
                              ],
                            })(
                              <Input
                                type="text"
                                onChange={this.handleDashboardData.bind(this,i, 'dashboardWorkspaceId')}
                              />
                            )
                          }
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
  
                  <Col span={1} className="btn-layout">
                    <Button
                        disabled={this.state.allFlag.dashboardDisableFlag}
                        onClick={() =>
                          this.deleteFields('dashboardName', 'dashboardSlug','dashboardWorkspace','dashboardReportId','dashboardWorkspaceId','dashboardRlsRoles',i, 'dashboardDisableFlag', 'dashboardType')
                        }
                    >
                        X
                    </Button>
                  </Col>
                  </Row>

                }
                
              </Row>
            )})
          }
          
            <Row gutter={16}>
              <Col offset={10} span={8}>
                  <Button
                      className="cta" 
                      htmlType="submit"
                    >
                    Submit
                  </Button>
                  <Button className="cta" onClick={() => this.props.history.push({
                    pathname: '/dashboard-settings',
                    search: this.props.location.search,
                   })}>
                    Back
                  </Button>
              </Col>
            </Row>
        </Form>
      </div>
    )
  }
}

const WrappedAddPlacesForm = Form.create({ name: 'register' })(DashboardConfigForm)

export default withRouter(WrappedAddPlacesForm)

