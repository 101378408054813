import React from 'react'
import { Input, Form, message, Button, Row, Col, Divider, DatePicker, Select } from 'antd'
import moment from 'moment';
import { css } from '@emotion/css'

import fetchData from 'utils/fetchData'

const TRIP_SERVICE_URL = window._env_.REACT_APP_TRIP_SERVICE_API_URL
const COMPANY_ID_1_TOKEN = window._env_.COMPANY_ID_1_TOKEN

const Option = Select.Option

const InsertPingInput = ({ label, decorator, getFieldDecorator, required, message }) => {
    return (
        <Row gutter={16}>
            <Col style={{ paddingTop: '8px' }} span={3}>
                <label>
                    {required ? <span style={{ color: 'red' }}>*</span> : ''} {label} :
                </label>
            </Col>
            <Col span={6}>
                <Form.Item>
                    {getFieldDecorator(decorator, {
                        rules: [{ required: required, message: message }]
                    })(<Input size="large" />)}
                </Form.Item>
            </Col>
        </Row>
    )
}

class InsertPing extends React.Component {
    constructor(props) {
        super(props)
    }

    insertPing = (values) => {
        let url = `${TRIP_SERVICE_URL}/v1/saas/data/manualping?token=${COMPANY_ID_1_TOKEN}`
        let payload = {
            'trip_id': values.tripId,
            'device_time_utc': values.deviceTime,
            'coordinates': {
                'lat': values.lat,
                'lng': values.long
            },
            'locationSource': values.locationSource
        }
        if(values.speed){
            payload.speed = parseInt(values.speed)
        }
        if(values.temperature){
            payload.temperature = parseInt(values.temperature)
        }
        let headers = {
            'Content-Type': 'application/json',
            'token': COMPANY_ID_1_TOKEN
        }
        fetchData(url, 'POST', payload, headers).then((res) => {
            if (res && res.success && res.data) {
                for(let msg of res.data.success){
                    message.success(msg)
                }
                for(let msg of res.data.failure){
                    message.error(msg)
                }
            } else {
                if(res.message){
                    message.error(res.message)
                }
                else{
                    message.error('Issue in inserting a ping')
                }
            }
        }).catch(error => {
            message.error(error);
        });
    }

    handleSubmit = e => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.insertPing(values)
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <div>
                <Row
                    span={20}
                    className={css`
                    padding: 30px;
                    background: #fff;
                    border-bottom: 1px solid #e8e8e8;
                    `}
                    gutter={16}>
                    <h3 className={css`textAlign:center, marginTop:15px`}>Insert A Manual Ping</h3>
                    <Divider />
                    <Form onSubmit={this.handleSubmit}>
                        <Col span={20}>
                            <InsertPingInput
                                label="Trip ID"
                                decorator='tripId'
                                getFieldDecorator={getFieldDecorator}
                                required={true}
                                message={'Please enter trip id'}
                            />
                            <InsertPingInput
                                label="Latitude"
                                decorator='lat'
                                getFieldDecorator={getFieldDecorator}
                                required={true}
                                message={'Please enter latitude'}
                            />
                            <InsertPingInput
                                label="Longitude"
                                decorator='long'
                                getFieldDecorator={getFieldDecorator}
                                required={true}
                                message={'Please enter longitude'}
                            />
                            <Row gutter={16}>
                                <Col style={{ paddingTop: '8px' }} span={3}>
                                    <label>
                                        <span style={{ color: 'red' }}>*</span> Device Time :
                                    </label>
                                </Col>
                                <Col span={6}>
                                    <Form.Item>
                                        {getFieldDecorator('deviceTime', {
                                            rules: [{ required: true, message: 'Please enter device time' }],
                                            initialValue: moment()
                                        })
                                            (<DatePicker
                                                style={{ width: '235px' }}
                                                placeholder="Select Device Time"
                                                showTime
                                                size="large"
                                                format="YYYY-MM-DD HH:mm:ss"
                                            />
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col style={{ paddingTop: '8px' }} span={3}>
                                    <label>
                                        <span style={{ color: 'red' }}>*</span> Source :
                                    </label>
                                </Col>
                                <Col span={6}>
                                    <Form.Item>
                                        {getFieldDecorator('locationSource', {
                                            rules: [{ required: true, message: 'Please select location source' }],
                                        })(
                                            <Select
                                                size="large"
                                                placeholder="Select location source"
                                            >
                                                <Option value="SIM">SIM</Option>
                                                <Option value="GPS">GPS</Option>
                                                <Option value="MANUAL">MANUAL</Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <InsertPingInput
                                label="Speed"
                                decorator='speed'
                                getFieldDecorator={getFieldDecorator}
                                required={false}
                            />
                            <InsertPingInput
                                label="Temperature"
                                decorator='temperature'
                                getFieldDecorator={getFieldDecorator}
                                required={false}
                            />
                            <Col style={{ paddingLeft: '127px', paddingTop: '25px' }} span={3}>
                                <Button
                                    type="primary"
                                    size="large"
                                    htmlType='submit'
                                >
                                    Insert Ping
                                </Button>
                            </Col>
                        </Col>
                    </Form>
                </Row>
            </div >
        )
    }
}

const Insert_Ping_Form = Form.create({ name: 'Insert_Ping_Form' })(InsertPing)
export default Insert_Ping_Form