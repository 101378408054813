import React from 'react'
import { Table, Row, Button, message } from 'antd'
import request from 'utils/request'

class ViewTransporters extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      error: null,
      isLoaded: false,
      items: [],
    }

    this.edit = this.edit.bind(this)
  }

  componentDidMount() {
    this.fetchTransporter()
  }

  fetchTransporter() {
    request(`${window._env_.REACT_APP_API_URL}/transporters`).then(
      result => {
        if (result.success) {
          this.setState({
            isLoaded: true,
            items: result,
          })
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        this.setState({
          isLoaded: true,
          error,
        })
      }
    )
  }

  deleteTransporter(id) {
    request(`${window._env_.REACT_APP_API_URL}/transporters/${id}`, { method: 'DELETE' }).then(
      result => {
        if (result.success) {
          message.success('Successfully deleted !')
          this.fetchTransporter()
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        message.error('Error while deleting')
      }
    )
  }

  edit(id) {
    console.log('editing', id)
    this.props.history.push(`/transporters/${id}/edit`)
  }

  delete(id) {
    this.deleteTransporter(id)
  }

  render() {
    const columns = [
      {
        dataIndex: 'name',
        title: 'Transporter name',
      },
      {
        dataIndex: 'branch',
        title: 'Branch',
      },
      {
        dataIndex: 'contactPerson',
        title: 'Contact Person',
      },
      {
        dataIndex: 'phoneNumber',
        title: 'Phone Number',
      },
      {
        dataIndex: 'address',
        title: 'Address',
      },
      {
        dataIndex: 'active',
        title: 'Active',
      },
      {
        dataIndex: 'cta',
        render: (text, record) => (
          <div>
            <Button icon="edit" onClick={() => this.edit(record.id)} />
            <Button icon="delete" onClick={() => this.delete(record.id)} />
          </div>
        ),
      },
    ]

    return (
      <Row>
        {this.state.isLoaded ? (
          <Table columns={columns} dataSource={this.state.items} rowKey={record => record.uid} />
        ) : (
          <div>spinner</div>
        )}
      </Row>
    )
  }
}
export default ViewTransporters
