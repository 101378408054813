import React from 'react'
import { Row, Button, Table, Checkbox, Modal, message } from 'antd'
import { convertQsToJson } from 'utils/common'
import fetchData from 'utils/fetchData'
const PHOENIX_API = window._env_.REACT_APP_TRANSDB_API_URL

class DashboardList extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      allConfiguration: [],
      checked: false,
      visible: false,
      modeToggle: '',
      customerConfigId: '',
      dtVar:{}
    }
  }

  componentDidMount() {
    const { search } = this.props.location;
    const parsedDt = convertQsToJson(search)
    if (!parsedDt.companyId) {
      message.info('Select a company to continue')
    }else{
      this.queryParams(parsedDt)
    }
  } 

  componentDidUpdate(prevProps){
    const { search } = this.props.location;
    const parsedDt = convertQsToJson(search)
    const  prevSearch = prevProps.location.search
    if (search !== prevSearch){
        this.queryParams(parsedDt)
    }
  }

  queryParams = (parsedDt)=>{
    const dtVar = {
      cId : parsedDt.companyId || '',
      bId : parsedDt.branchId || '',
      dId : parsedDt.departmentId || ''
    }
    this.setState({ dtVar },() =>{
      this.getDashboardList();
     }
    )
  }

  handleOnChange = data => {
    this.setState({  
        visible: true,
        modeToggle: data.enabled,
        customerConfigId: data.id, 
      },()=>{})
  }

  handleCancel = () => {
    this.setState({ visible: false },() => { 
    })
  }
  
  handleNotification = () => {
    let url =`${PHOENIX_API}saas/data/automationdashboard/toggleDashboard?customerConfigId=${this.state.customerConfigId}`
    fetchData(url,'POST').then(res => {
        if (res.result.status === true) {
          this.setState({ visible: false })
          this.getDashboardList();
        }
    }).catch(error => {
      message.error('Error while disabling dashboard')
    });
    this.props.history.push({
      pathname: '/dashboard-settings',
      search: this.props.location.search,
    })    
  }
  
  goEditConfigForm = data => {
    let qs = this.props.location.search;
    if (qs.length > 0) {
      this.props.history.push({
        pathname: `/dashboard-settings/edit/${data.id}`,
        search: qs,
      })
    }
  }
  
  getDashboardList(){
    const {cId,bId,dId}=this.state.dtVar;
    let url = `${PHOENIX_API}saas/data/automationdashboard/getDashboardList?companyId=${cId}&branchId=${
      bId}&departmentId=${dId}`;
    let payload= {
      companyId:cId,
      branchId:bId,
      active:1
    }
    fetchData(url,'GET',payload).then(res => {
          if(res.result.status == true){
            this.setState({ allConfiguration: res.result.data});
          }else{
            this.setState({ allConfiguration:res.result.data});
            message.error(res.result.message);
          }
      }).catch(error => {
        message.error('Error while fetching data');
      });   
  }   

  render() {  
    const columns = [
      {
        title: 'Dashboard Name',
        key: 'featureKey',
        dataIndex: 'featureKey',
      },
      {
        title: 'Enable Dashboard',
        key: 'enabled',
        render: (text, record, i) => {
          return (
            <Checkbox checked={record.enabled} onChange={() => this.handleOnChange(record)} />
          )
        },
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
            <span>
              <Button onClick={() => this.goEditConfigForm(record)}>Edit</Button>
            </span>
        ),
      },
    ];
    const { allConfiguration,visible,modeToggle}= this.state;
    for(var i=0;i<allConfiguration.length;i++){
      if(allConfiguration[i]['featureKey']=='zoho'){
        allConfiguration[i]['featureKey']='powerbi';
      }
    }
    return (
      <div>
        <Row style={{ padding: '10px 10px 10px 22px' }} />
        <Row>
          <Table
            columns={columns}
            dataSource={allConfiguration}
            rowKey={record => record.customerConfigId}
          />
        </Row>
        <Modal
          width={450}
          height={450}
          footer={null}
          title="Enable Configuration"
          visible={ visible }
          onCancel={this.handleCancel}
        >
          <p>
            Are you sure want to{' '}
            { modeToggle ? <span>disable</span> : <span>enable</span>} dashboard?
          </p>
          <div style={{ textAlign: 'right' }}>
            <Button onClick={() => this.handleNotification()}>Yes</Button>
            <Button onClick={this.handleCancel}>No</Button>
          </div>
        </Modal>
      </div>
    )
  }
}
export default DashboardList
