import { Row } from 'antd'
import React from 'react'
import SubHeader from 'utils/subHeader'
import 'containers/routesMaster/App.css'
import { withRouter } from 'react-router'

class EtaPreferenceContainer extends React.Component {
  render() {
    return (
      <Row>
        <SubHeader/>
        {this.props.children}
      </Row>
    )
  }
}

export default withRouter(EtaPreferenceContainer)
