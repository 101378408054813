import _ from "lodash";
import * as authService from "./authService";

const isAuthorized = (permissions = [], allPermissions) => {
  const userPermissions = JSON.parse(
    authService.getForKey(authService.tokenKeys.USER_PERMISSIONS)
  );
  if (userPermissions == null) return;
  const finalPermissions = {};

  permissions.map((permission) => {
    const type =
      allPermissions[permission] && allPermissions[permission].permission_type;
    let mask1 = [];
    let mask2 = [];
    let isset = false;
    switch (type) {
      case "CD":
        mask1 = userPermissions.permission_create_mask;
        mask2 = allPermissions[permission].permission_create_mask;
        break;
      case "U":
        mask1 = userPermissions.permission_update_mask;
        mask2 = allPermissions[permission].permission_update_mask;
        break;
      case "R":
        mask1 = userPermissions.permission_read_mask;
        mask2 = allPermissions[permission].permission_read_mask;
        break;
      default:
        break;
    }
    if (mask1.length > mask2.length) {
      mask2 = padZeros(mask2, mask1.length);
    } else if (mask2.length > mask1.length) {
      mask1 = padZeros(mask1, mask2.length);
    }
    mask1.forEach((value, index) => {
      if (value & mask2[index]) {
        isset = true;
      }
    });
    finalPermissions[permission] = isset;
  });
  const isDisplay = _.values(finalPermissions).every((item) => item == true);
  return isDisplay;
};

const padZeros = (mask, length) => {
    const paddingSize = length - mask.length;
    const paddingArray = Array.from(Array(paddingSize), () => 0);
    const paddedArray = mask.concat(paddingArray);
    return paddedArray;
  };
  
export {
  isAuthorized,
};
