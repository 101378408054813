import qs from 'qs'

function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null
  }
  return response.json()
}

/**
 * Checks if a network request came back fine, and throws an error if not
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status <= 400) {
    return response
  }
  const error = new Error(response.statusText)
  error.response = response
  throw error
}

function request(type, api, payload = {}, header = {}, isAPuRL) {
  let url = `${
    isAPuRL ? window._env_.REACT_APP_AP_CONFIG_URL : window._env_.REACT_APP_API_URL
  }/${api}`
  const headers = { 'Content-Type': 'application/json', 'token': localStorage.getItem('satellizer_token'),...header }
  const options = {
    method: type,
    headers,
  }

  if (type === 'POST' || type === 'PUT') {
    options.body = JSON.stringify(payload)
  }

  if (type === 'GET' || type === 'DELETE') {
    if (payload) {
      url = url + `?${qs.stringify(payload)}`
    }
  }
  return fetch(url, options)
    .then(checkStatus)
    .then(parseJSON)
    .then(data => data)
    .catch(err => err)
}

export default request
