import React from 'react'
import { Form, Input, Select, Button, Upload, Icon, Row, Col, message, Divider } from 'antd'
import request from 'utils/request'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import { addressFromLatLng } from 'utils/geocode'
import { GoogleMap, withGoogleMap, Marker } from 'react-google-maps'
import mapStyle from 'utils/MapStyle.json'
import { constructUrl } from 'utils/common'
import { geocodeByPlaceIdFix } from 'utils/geocodeByPlaceIdFix'
import { convertQsToJson } from 'utils/common'
import { css } from '@emotion/css'
import _ from 'lodash'
const { TextArea } = Input
const Option = Select.Option
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
}

class AddConsignees extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      consignee: {},
      latitude: null,
      longitude: null,
      edit: false,
    }
  }
  componentWillMount() {
    if (this.props.match.params.id) {
      this.setState({ edit: true })
    }
  }

  componentDidMount() {
    if (this.state.edit) {
      this.fetchConsignee(this.props.match.params.id)
    }
  }

  fetchConsignee(id) {
    const url = constructUrl('/saas/lego/company_partners/fetch', this.props.location.search, {
      id: id,
      pt: 'CONSIGNEE',
    })
    request(url).then(
      result => {
        if (result.success) {
          const c = result.data[0]
          this.setState({
            latitude: c.place.google.geometry.location.lat,
            longitude: c.place.google.geometry.location.lng,
          })
          this.props.form.setFieldsValue({
            code: c.code,
            name: c.name,
            type: c.type,
            email: c.email,
            phone: c.phone,
            address: c.place.address,
            addressLabel: c.place.label,
            googleAddress: c.place.google.formatted_address,
            latitude: c.place.google.geometry.location.lat,
            longitude: c.place.google.geometry.location.lng,
            geofenceRadius: c.place.geofenceRadius,
            cpId: c.place.cpId,
            cpCode: c.place.cpCode,
          })
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        message.error('Error while deleting')
      }
    )
  }

  buildPayload(data) {
    return [
      {
        code: data.code,
        name: data.name,
        type: data.type,
        phone: data.phone,
        email: data.email,
        place: {
          cpCode: data.cpCode,
          google: {
            place_id: this.state.googlePlaceId,
          },
          label: data.addressLabel,
          address: data.address,
          geofenceRadius: data.geofenceRadius,
        },
      },
    ]
  }

  buildEditPayload(data) {
    return {
      code: data.code,
      name: data.name,
      type: data.type,
      phone: data.phone,
      email: data.email,
      place: {
        cpId: data.cpId,
        cpCode: data.cpCode,
        label: data.addressLabel,
        address: data.address,
        geofenceRadius: data.geofenceRadius,
      },
    }
  }

  addConsignee(data) {
    const url = constructUrl('/saas/lego/company_partners/create', this.props.location.search, {
      pt: 'CONSIGNEE',
    })
    const payLoad = this.buildPayload(data)
    if (this.props.location.search === '') {
      return message.error('Select a company to continue')
    }

    request(url, {
      method: 'POST',
      body: JSON.stringify(payLoad),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(
      result => {
        if (result.success) {
          message.success('Consignee added successfully !')
          this.props.history.push({
            pathname: '/consignees',
            search: this.props.location.search,
          })
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        message.error('Error while adding')
      }
    )
  }

  updateConsignee(data) {
    const url = constructUrl('/saas/lego/company_partners/update', this.props.location.search, {
      pt: 'CONSIGNEE',
    })
    const payLoad = this.buildEditPayload(data)
    payLoad.id = parseInt(this.props.match.params.id)

    request(url, {
      method: 'POST',
      body: JSON.stringify(payLoad),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(
      result => {
        if (result.success) {
          message.success('Consignee updated successfully !')
          this.props.history.push({
            pathname: '/consignees',
            search: this.props.location.search,
          })
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        message.error('Error while editing')
      }
    )
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const parsedDt = convertQsToJson(this.props.location.search)

        if (
          Object.keys(parsedDt).length > 1 &&
          parsedDt.companyType == 'TRANSPORTER' &&
          _.isEmpty(parsedDt.consignorId)
        ) {
          message.error('Please select a consignor to proceed')
          return
        }

        if (this.state.edit === false) {
          this.addConsignee(values)
        } else {
          this.updateConsignee(values)
        }
      }
    })
  }

  normFile = e => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  validateToWhitespace = (rule, value, callback) => {
    if (value && value.trim() === '') {
      callback('Blank spaces not allowed')
    } else {
      callback()
    }
  }

  mobileNumberValidator = (rule, value, callback) => {
    let mobileregex = new RegExp('^[6-9]\\d{9}$')
    if (mobileregex.test(value) && value.length === 10) {
      callback()
    } else {
      callback('Please enter valid mobile number')
    }
  }

  handleSelect = (address, placeId) => {
    this.props.form.setFieldsValue({
      googleAddress: address,
    })

    if (this.state.edit) {
      return
    }

    geocodeByPlaceIdFix(placeId, ['name', 'geometry', 'place_id', 'formatted_address'])
      .then(results => {
        const { place_id, geometry, formatted_address, name } = results
        this.props.form.setFieldsValue({
          latitude: geometry.location.lat(),
          longitude: geometry.location.lng(),
          address: formatted_address,
          addressLabel: name,
        })

        this.setState({
          address: formatted_address,
          addressLabel: name,
          googlePlaceId: place_id,
          latitude: geometry.location.lat(),
          longitude: geometry.location.lng(),
        })
      })
      .catch(error => console.error('Error', error))
  }

  setAddressFromLat = e => {
    this.setState({ latitude: e.target.value }, async () => {
      if (this.state.longitude) {
        const { latLng, address, placeId } = await addressFromLatLng(
          this.state.latitude,
          this.state.longitude
        )

        this.props.form.setFieldsValue({
          googleAddress: address,
          address: address,
          placeId,
        })
        this.setState({
          latitude: latLng.lat,
          longitude: latLng.lng,
          googlePlaceId: placeId,
        })
      } else {
        console.error('longitude not set')
      }
    })
  }

  setAddressFromLng = e => {
    this.setState({ longitude: e.target.value }, async () => {
      if (this.state.latitude) {
        const { latLng, address, placeId } = await addressFromLatLng(
          this.state.latitude,
          this.state.longitude
        )

        this.props.form.setFieldsValue({
          googleAddress: address,
          address: address,
          placeId,
        })
        this.setState({
          latitude: latLng.lat,
          longitude: latLng.lng,
          googlePlaceId: placeId,
        })
      } else {
        console.error('latitude not set')
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const Map = withGoogleMap(props => (
      <GoogleMap
        defaultZoom={15}
        defaultCenter={{ lat: props.lat, lng: props.lng }}
        defaultOptions={{
          fullscreenControl: true,
          panControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          styles: mapStyle,
        }}
      >
        <Marker position={{ lat: props.lat, lng: props.lng }} />
      </GoogleMap>
    ))

    return (
      <div className={css`
          padding: 20px;
        `}
      >
        <div style={{ display: 'none' }} id="bharghav" />
        <Row gutter={16}>
          <Col span={12} offset={6}>
            {this.state.edit ? (
              <h2 style={{ textAlign: 'center', marginBottom: '40px' }}>Update consignee</h2>
            ) : (
              <h2 style={{ textAlign: 'center', marginBottom: '40px' }}>Add a new consignee</h2>
            )}

            <Form onSubmit={this.handleSubmit}>
              {this.state.edit && getFieldDecorator('cpId')(<Input type="hidden" />)}
              <Form.Item {...formItemLayout} label="Consignee code">
                {getFieldDecorator('code', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter consignee code',
                    },
                    {
                      validator: this.validateToWhitespace,
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="Consignee Name">
                {getFieldDecorator('name', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter consignee name',
                    },
                    {
                      validator: this.validateToWhitespace,
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="Type">
                {getFieldDecorator('type', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select type',
                    },
                    {
                      validator: this.validateToWhitespace,
                    },
                  ],
                })(
                  <Select>
                    <Option value="CUSTOMER">CUSTOMER</Option>
                    <Option value="WAREHOUSE">WAREHOUSE</Option>
                    <Option value="JIT_WAREHOUSE">JIT_WAREHOUSE</Option>
                    <Option value="DC">DC</Option>
                    <Option value="MDC">MDC</Option>
                    <Option value="RDC">RDC</Option>
                    <Option value="OEM">OEM</Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="Email id">
                {getFieldDecorator('email', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter your email id',
                    },
                    {
                      validator: this.validateToWhitespace,
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="Mobile No.">
                {getFieldDecorator('phone', {
                  rules: [
                    {
                      required: true,
                    },
                    {
                      validator: this.mobileNumberValidator,
                    },
                  ],
                })(<Input type="number" />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="Google address">
                {getFieldDecorator('googleAddress', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter address',
                    },
                  ],
                })(
                  <PlacesAutocomplete onSelect={this.handleSelect}>
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div>
                        <Input
                          {...getInputProps({
                            placeholder: 'Type an address here..',
                            className: 'location-search-input',
                          })}
                          disabled={this.state.edit ? true : false}
                        />
                        <div
                          className="autocomplete-dropdown-container"
                          css = {css`
                          position: 'absolute',
                          zIndex: '1000',
                          overflow: 'scroll',
                          width: '472px',
                          borderRadius: '4px'
                          `}
                        >
                          {loading && <div>Loading...</div>}
                          {suggestions.map(suggestion => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item'
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                              : { backgroundColor: '#ffffff', cursor: 'pointer' }
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <div className={css`padding:8px`}>{suggestion.description}</div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                )}
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                label="Latitude"
                help={this.state.edit ? 'The field cannot be edited' : ''}
              >
                {getFieldDecorator('latitude', {
                  rules: [
                    {
                      required: true,
                      message: 'Enter latitude',
                    },
                  ],
                })(
                  <Input
                    onChange={this.setAddressFromLat}
                    disabled={this.state.edit ? true : false}
                  />
                )}
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                label="Longitude"
                help={this.state.edit ? 'The field cannot be edited' : ''}
              >
                {getFieldDecorator('longitude', {
                  rules: [
                    {
                      required: true,
                      message: 'Enter longitude',
                    },
                  ],
                })(
                  <Input
                    onChange={this.setAddressFromLng}
                    disabled={this.state.edit ? true : false}
                  />
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="Address label">
                {getFieldDecorator('addressLabel', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter an Display Address',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="Code">
                {getFieldDecorator('cpCode', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter a code',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="Address">
                {getFieldDecorator('address', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter an address',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="Geofence Radius">
                {getFieldDecorator('geofenceRadius', {
                  rules: [
                    {
                      required: true,
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              {this.state.latitude && this.state.longitude && (
                <Map
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `400px`, marginTop: '40px' }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  lat={parseFloat(this.state.latitude)}
                  lng={parseFloat(this.state.longitude)}
                />
              )}
              <Form.Item
                className={css`
                  text-align: center;
                `}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    )
  }
}
const AddConsigneeForm = Form.create({ name: 'consignee_form' })(AddConsignees)
export default (AddConsignees, AddConsigneeForm)
