import React, { Component } from 'react'
import { Popconfirm, Row, Button, message, Table, Icon } from 'antd'
import request from 'utils/requestLego'
import './apConfig.scss'

export default class APConfiguration extends Component {
  state = {
    config: [],
    pagination: { pageSize: 10, current: 1, total: 0 },
  }
  componentDidMount() {
    const {
      pagination: { current },
    } = this.state
    this.fetchData(current)
  }

  fetchData = pageNumber => {
    const url = `saas/v1/ap/config/fetch/all`
    const params = {
      page_size: this.state.pagination.pageSize,
      page_number: Number(pageNumber) - 1,
    }
    request('GET', url, params, undefined, true)
      .then(result => {
        if (result && result.success) {
          const { pagination } = this.state
          pagination.total = result.data.total
          pagination.current = pageNumber
          this.setState({
            config: [...result.data.data],
            pagination,
          })
        } else {
          message.error('Something went wrong. Please try again.')
        }
      })
      .catch(() => message.error('Something went wrong. Please try again.'))
  }

  createConfig = () => {
    this.props.history.push('/ap-configuration/add')
  }

  deleteConfig = configId => {
    request('DELETE', `saas/v1/ap/config/${configId}`, {}, undefined, true)
      .then(res => {
        if (res.success) {
          const {
            pagination: { current },
          } = this.state
          this.fetchData(current)
        }
      })
      .catch(() => message.error('Something went wrong. Please try again.'))
  }

  handleTableChange = pagination => {
    this.fetchData(pagination.current)
  }

  render() {
    const { config, pagination } = this.state
    const columns = [
      {
        title: <p>CBDT Id</p>,
        dataIndex: 'cbdt_id',
        key: 'cbdt_id',
      },
      {
        title: <p>Type</p>,
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: <p>Value</p>,
        dataIndex: 'value',
        render: text => {
          const jsonDt =
            typeof text === 'string' || text instanceof String ? text : JSON.stringify(text)
          return (
            <div style={{ overflow: 'auto', maxWidth: '600px', paddingBottom: '16px' }}>
              {jsonDt}
            </div>
          )
        },
      },
      {
        title: '',
        render: (text, record) => {
          return (
            <div>
              <Button
                type="primary"
                onClick={() => this.props.history.push(`/ap-configuration/${record.id}/edit`)}
              >
                Edit
              </Button>
              <Popconfirm
                placement="left"
                title="Are you sure want to delete?"
                onConfirm={() => this.deleteConfig(record.id)}
                okText="Yes"
                cancelText="No"
                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
              >
                <Button>Delete</Button>
              </Popconfirm>
            </div>
          )
        },
      },
    ]
    return (
      <div>
        <Row style={{ padding: '10px 10px 10px 22px' }}>
          <Button onClick={this.createConfig} size="large" type="primary">
            Create Configuration
          </Button>
        </Row>
        <Row className="apConfigMain">
          <Table
            dataSource={config}
            columns={columns}
            rowKey={record => record.id}
            className="apConfigTable"
            pagination={pagination}
            onChange={this.handleTableChange}
          />
        </Row>
      </div>
    )
  }
}
