import { Form, Button, Row } from 'antd'
import React from 'react'
import SubHeader from 'utils/subHeader'
import 'containers/routesMaster/App.css'
import { withRouter } from 'react-router'

class RoutesMain extends React.Component {
  addRoute = () => {
    let qs = ''
    if (this.props.location.search.length > 0) {
      qs = `${this.props.location.search}`
    }
    this.props.history.push({
      pathname: '/routes/add',
      search: qs,
    })
  }

  uploadRoute = () => {
    let qs = ''
    if (this.props.location.search.length > 0) {
      qs = `${this.props.location.search}`
    }
    this.props.history.push({
      pathname: '/routes/upload',
      search: qs,
    })
    // this.props.history.push('/routes/upload')
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values)
      }
    })
  }

  cta = () => {
    return (
      <div>
        <Button size="large" type="primary" onClick={this.addRoute}>
          Add Route
        </Button>
        <Button style={{ marginLeft: 10 }} size="large" type="primary" onClick={this.uploadRoute}>
          Upload
        </Button>
      </div>
    )
  }

  render() {
    return (
      <Row>
        <SubHeader innerhtml={this.cta} />
        {this.props.children}
      </Row>
    )
  }
}

export default withRouter(Form.create({ name: 'uploadFile_validator' })(RoutesMain))
