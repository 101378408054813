import { Button, Col, Icon, Row, Modal, Upload, message, Table, Input } from 'antd'
import moment from 'moment'
import React, { Component } from 'react'
import './bulk-upload.scss'
import { BULK_UPLOAD_COLUMNS } from './constants'
import { forEach } from 'lodash'
import fetchData from 'utils/fetchData'
import fetchFormData from 'utils/fetchFormData'
import { css } from '@emotion/css'

const { BULK_UPLOAD, REACT_APP_ENTITY_SERVICE_URL } = window._env_;
const { componentMap, headingMap, downloadFiles, accept } = BULK_UPLOAD;

const { Dragger } = Upload
class BulkUpload extends Component {
  state = {
    file: null,
    email: undefined,
    uploadedData: [],
    isValidEmail: true,
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props

    if (params.component) {
      this.getUploadedData(componentMap[params.component])
    }
  }

  getUploadedData = type => {
    const token = localStorage.getItem('satellizer_token')
    const decoded_token = JSON.parse(Buffer.from(token.split('.')[1], 'base64'))
    const parent_fteid = decoded_token.ucv.entity_guid
    let url = `${REACT_APP_ENTITY_SERVICE_URL}/v1/batch?parent_fteid=${parent_fteid}&type=${type}`
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('satellizer_token')}`,
    }

    fetchData(url, 'GET', {}, headers)
      .then(res => {
        if (res && res.success && res.data) {
          this.setState({ uploadedData: res.data })
        } else {
          res.message.map(msg => message.error(msg))
        }
      })
      .catch(error => {
        console.log('error is ', error)
      })
  }

  saveBulkUpload = email => {
    const { file } = this.state
    const {
      match: { params },
    } = this.props

    if (!file) {
      message.info('Please select a file first')
      return
    }

    let formData = new FormData()
    formData.append('files', file)

    if (email) formData.append('email', email)

    let url = `${REACT_APP_ENTITY_SERVICE_URL}/v1/bulk-upload/${
      componentMap[params.component]
    }/upload`
    fetchFormData(url, 'POST', formData)
      .then(res => {
        if (res && res.success && res.data) {
          message.info('File uploaded successfully')
          this.setState({
            file: null,
            showEmailModal: false,
          })
          this.getUploadedData(componentMap[params.component])
        } else {
          res.message.map(msg => message.error(msg))
        }
      })
      .catch(error => {
        message.error('Failed to upload')
      })
  }

  onSave = () => {
    const { file } = this.state

    if (!file) {
      message.info('Please select a file first')
      return
    }

    this.setState({ showEmailModal: true })
  }

  onCancel = () => this.props.history.goBack()

  onClickEmailModal = email => {
    this.saveBulkUpload(email)
  }

  onCancelEmailModal = () => {
    this.setState({ showEmailModal: false })
  }

  handleEmailChange = e => {
    const email = e.target.value
    if (/^\S+@\S+[\.][0-9a-z]+$/.test(email)) {
      this.setState({ isValidEmail: true, email })
    } else {
      this.setState({ isValidEmail: false, email })
    }
  }

  render() {
    let {
      match: { params },
    } = this.props
    const { uploadedData, email, isValidEmail } = this.state

    const { showEmailModal } = this.state
    forEach(BULK_UPLOAD_COLUMNS, ({ key }, index) => {
      switch (key) {
        case 'created_at':
          BULK_UPLOAD_COLUMNS[index].render = (text, { created_at }) =>
            moment(created_at).format('DD/MM/YYYY HH:mm')
          break
      }
    })

    const props = {
      name: 'file',
      accept: accept,
      multiple: false,
      showUploadList: {
        showPreviewIcon: true,
        showDownloadIcon: false,
        showRemoveIcon: true,
      },
      onRemove: _ => this.setState({ file: null }),
      beforeUpload: file => {
        this.setState({ file })
        return false
      },
      fileList: this.state.file ? [this.state.file] : [],
    }

    return (
      <div>
        <div
          className={css`
            padding: 20px 30px;
            background: #fff;
            border-bottom: 1px solid #e8e8e8;
          `}
        >
          <p
            className={css`
              margin: 0px;
              font-size: 24px;
              color: #000;
            `}
          >
            Upload ({headingMap[params.component]})
          </p>
        </div>
        <div className="bulk-upload-container">
          <div className="bulk-upload-box">
            <Row>
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">Please upload Excel file here</p>
              </Dragger>
            </Row>
            <Row type="flex" justify="space-between">
              <Col>
                <Button
                  type="link"
                  download={downloadFiles[params.component]}
                  href={require(`./assets/${downloadFiles[params.component]}`)}
                  style={{ margin: '1rem 1rem 1rem 0' }}
                >
                  Sample Excel file
                </Button>
              </Col>
              <Row type="flex">
                <Col>
                  <Button
                    style={{
                      width: '80px',
                      margin: '1rem 0 1rem 1rem ',
                    }}
                    onClick={this.props.history.goBack}
                  >
                    {' '}
                    Cancel{' '}
                  </Button>
                </Col>
                <Col>
                  <Button
                    style={{
                      width: '80px',
                      margin: '1rem 0 1rem 1rem ',
                    }}
                    type="primary"
                    onClick={this.onSave}
                  >
                    {' '}
                    Upload{' '}
                  </Button>
                </Col>
              </Row>
            </Row>
          </div>

          <div>
            <Table
              columns={BULK_UPLOAD_COLUMNS}
              dataSource={uploadedData}
              rowKey={record => record.fteid}
            />
          </div>
        </div>

        {showEmailModal && (
          <div className="ant-modal-mask">
            <Modal
              title={'Bulk Upload'}
              okText={'Yes'}
              cancelText={'No'}
              onOk={() => this.onClickEmailModal(email)}
              onCancel={e => this.onCancelEmailModal(e)}
              visible={showEmailModal}
            >
              <div className="pruned-modal-header-text">
                Add email address to receive bulk upload complete notification:
              </div>
              <Input
                placeholder="Enter your email"
                value={email}
                onChange={this.handleEmailChange}
              />
              {!isValidEmail && (
                <span
                  style={{
                    color: 'red',
                  }}
                >
                  Please enter valid email
                </span>
              )}
            </Modal>
          </div>
        )}
      </div>
    )
  }
}

export default BulkUpload
