export default function CustomField(){
    return  [
        {
            fieldId: 'driverNumbers',
            displayName: 'Driver Number'
        },
        {
            fieldId: 'driverName',
            displayName:'Driver Name'
        },
        {
            fieldId: 'vehicleNumber',
            displayName: 'Vehicle number'
        },
        {
            fieldId: 'locationSource',
            displayName: 'Location Source'
        },
        {
            fieldId: 'portableTrackerId',
            displayName: 'Portable Tracker ID'
        },
        {
            fieldId:'lrnumber',
            displayName:'Lorry Receipt Number'
        }
    ]
}
