import React, { Component } from 'react'
import { Form, Input, Select, Button, message } from 'antd'
import request from 'utils/requestLego'
import './apConfig.scss'

const FormItem = Form.Item
const Option = Select.Option
const { TextArea } = Input

class APConfigForm extends Component {
  state = {
    isCreate: true,
  }
  componentDidMount() {
    if (this.props.match.path.indexOf('/ap-configuration/:id/edit') > -1) {
      const { id } = this.props.match.params
      request('GET', `saas/v1/ap/config/${id}`, {}, undefined, true)
        .then(result => {
          if (result && result.success) {
            const {
              data: { cbdt_id, type, value: jsonDt, partner_cbdt_id: partnerCBDT },
            } = result
            const partner_cbdt_id = partnerCBDT || 0
            const value =
              typeof jsonDt === 'string' || jsonDt instanceof String
                ? jsonDt
                : JSON.stringify(jsonDt)
            this.props.form.setFieldsValue({
              cbdt_id,
              partner_cbdt_id,
              type,
              value,
            })
            this.setState({
              isCreate: false,
            })
          } else {
            message.error('Something went wrong. Please try again.')
          }
        })
        .catch(() => message.error('Something went wrong. Please try again.'))
    } else {
      this.setState({
        isCreate: true,
      })
    }
  }

  submitConfigForm = () => {
    this.props.form.validateFields((err, formValues) => {
      if (!err) {
        formValues.value = JSON.parse(formValues.value)
        const { isCreate } = this.state
        if (isCreate) {
          request('POST', `saas/v1/ap/config`, formValues, undefined, true)
            .then(result => {
              if (result && !result.success) {
                message.error(result.message[0], 10)
              } else if (result && result.success) {
                this.props.form.resetFields()
                message.success('Config created Successfully ', 10)
              } else {
                message.error('Something went wrong. Please try again.', 10)
              }
            })
            .catch(err => console.log('error is ', err))
        } else {
          const { id } = this.props.match.params
          request('PUT', `saas/v1/ap/config/${id}`, formValues, undefined, true)
            .then(result => {
              if (result && !result.success) {
                message.error(result.message[0], 10)
              } else if (result && result.success) {
                message.success('Config updated Successfully ', 10)
              } else {
                message.error('Something went wrong. Please try again.', 10)
              }
            })
            .catch(err => console.log('error is ', err))
        }
      }
    })
  }

  render() {
    const {
      form: { getFieldDecorator },
    } = this.props
    const { isCreate } = this.state
    return (
      <div className="apConfigFormMain">
        <h3>{`${isCreate ? 'Create' : 'Update'} AP Config`}</h3>
        <Form>
          <FormItem label="CBDT ID">
            {getFieldDecorator('cbdt_id', {
              rules: [
                {
                  required: true,
                  message: 'Please enter the CBDT ID',
                },
              ],
            })(<Input className="cbdtId" style={{ height: '40px' }} />)}
          </FormItem>
          <FormItem label="Partner CBDT ID">
            {getFieldDecorator('partner_cbdt_id', {
              rules: [
                {
                  required: true,
                  message: 'Please enter the partner CBDT ID',
                },
              ],
            })(<Input className="partner_cbdt_id" style={{ height: '40px' }} />)}
          </FormItem>
          <FormItem label="Type">
            {getFieldDecorator('type', {
              rules: [
                {
                  required: true,
                  message: 'Please select the type',
                },
              ],
            })(
              <Select
                placeholder="Select a type"
                onChange={this.selectHandler}
                style={{ height: '40px' }}
              >
                <Option value="DESK">DESK</Option>
                <Option value="SETTINGS">SETTINGS</Option>
                <Option value="EXCEPTION_CHARGES">EXCEPTION_CHARGES</Option>
              </Select>
            )}
          </FormItem>
          <FormItem label="Sample JSON">
            {getFieldDecorator('value', {
              rules: [
                {
                  required: true,
                  message: 'Please enter the JSON',
                },
                {
                  pattern: /(?<=\{)\s*[^{]*?(?=[\},])/,
                  message: 'Invalid JSON',
                },
              ],
            })(<TextArea autosize={{ minRows: 5 }} />)}
          </FormItem>
        </Form>
        <div className="ctaMain">
          <Button type="primary" className="cta" onClick={this.submitConfigForm}>
            Submit
          </Button>
          <Button className="cta" onClick={() => this.props.history.push('/ap-configuration')}>
            Back
          </Button>
        </div>
      </div>
    )
  }
}

const APConfigFormPage = Form.create({ name: 'APConfigForm' })(APConfigForm)

export default APConfigFormPage
