export default function fetchFormData(url,type,payload, customHeaders) {

  let headers;
  if(customHeaders){
    headers = {
      ...customHeaders
    }
  }
  else{
    headers = {
        Authorization: `Bearer ${localStorage.getItem('satellizer_token')}`,
    }
  }
  let options = {
    method: type,
    headers: headers,
    body: payload
  }

  return fetch(url, { ...options })
    .then(res => res.json())
    .then(response => response)
    .catch(err => err)
}
