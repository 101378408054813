import { Popconfirm, Table, Modal, message, Button } from 'antd'
import React from 'react'
import { GoogleMap, withGoogleMap, Marker } from 'react-google-maps'
import { convertQsToJson, constructUrl } from 'utils/common'
import mapStyle from 'utils/MapStyle.json'
import request from 'utils/request'
import 'containers/routesMaster/App.css'
import _ from 'lodash'
import { withRouter } from 'react-router'

class KnownPlacesList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false,
      companyPlaces: [],
      fetchUrl: null,
      deleteUrl: null,
    }
  }

  componentDidMount() {
    const parsedDt = convertQsToJson(this.props.location.search)
    if (!parsedDt.companyId) {
      message.info('Select a company to continue')
    } else {
      this.fetchKnownPlacesData(this.props.location.search)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search === '') {
      this.setState({ companyPlaces: [] })
    }

    const parsedDtThis = convertQsToJson(this.props.location.search)
    const parsedDt = convertQsToJson(nextProps.location.search)
    if (parsedDt.companyId !== undefined && !_.isEqual(parsedDtThis, parsedDt)) {
      this.fetchKnownPlacesData(nextProps.location.search)
    }
  }

  fetchKnownPlacesData = qs => {
    const url = constructUrl('/saas/lego/company_places/fetch', qs)
    request(url).then(
      result => {
        if (result.success) {
          this.setState({ companyPlaces: result.data })
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        message.error('Error while fetching routes data')
      }
    )
  }

  deleteKnownPlace = (id, partnerId) => {
    let url
    if (partnerId !== undefined) {
      url = constructUrl('/saas/lego/company_places/delete', this.props.location.search, {
        cpId: id,
        partnerId: partnerId,
      })
    } else {
      url = constructUrl('/saas/lego/company_places/delete', this.props.location.search, {
        cpId: id,
      })
    }

    request(url, {
      method: 'DELETE',
    }).then(
      result => {
        if (result.success) {
          message.success(`Company place ${id} deleted successfully`)
          this.props.history.push({
            pathname: '/known-places',
            search: this.props.location.search,
          })
          this.fetchKnownPlacesData(this.props.location.search)
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        message.error('Error while deleting known place')
      }
    )
  }

  editKnownPlace = id => {
    this.props.history.push({
      pathname: `/known-places/${id}/edit`,
      search: this.props.location.search,
    })
  }

  showModal = (address, lat, lng) => {
    const latitude = parseFloat(lat)
    const longitude = parseFloat(lng)

    this.setState({
      visible: true,
      modalTitle: address,
      modalLat: latitude,
      modalLng: longitude,
    })
  }

  handleOk = e => {
    this.setState({
      visible: false,
    })
  }

  handleCancel = e => {
    this.setState({
      visible: false,
    })
  }

  render() {
    const dataSource = this.state.companyPlaces

    const columns = [
      {
        title: 'Id',
        dataIndex: 'cpId',
        key: 'cpId',
      },
      {
        title: 'Address label',
        dataIndex: 'label',
        key: 'label',
        width: '30%',
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        width: '30%',
      },
      {
        title: 'Updated By',
        dataIndex: 'updatedBy',
        key: 'updatedBy',
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        render: (text, record) => (
          <span>
            <Button
              onClick={() =>
                this.showModal(
                  record.address,
                  record.google.geometry.location.lat,
                  record.google.geometry.location.lng
                )
              }
            >
              Show
            </Button>
            <Button onClick={() => this.editKnownPlace(record.cpId)}>Edit</Button>
            <Popconfirm
              placement="top"
              title="Are you sure to delete this known place ?"
              onConfirm={() => this.deleteKnownPlace(record.cpId, record.partnerId)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger">Delete</Button>
            </Popconfirm>
          </span>
        ),
      },
    ]

    const Map = withGoogleMap(props => (
      <GoogleMap
        defaultZoom={15}
        defaultCenter={{ lat: props.lat, lng: props.lng }}
        defaultOptions={{
          fullscreenControl: true,
          panControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          styles: mapStyle,
        }}
      >
        <Marker position={{ lat: props.lat, lng: props.lng }} />
      </GoogleMap>
    ))

    return (
      <div>
        <Table dataSource={dataSource} columns={columns} />
        <Modal
          title={this.state.modalTitle}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Map
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            lat={this.state.modalLat}
            lng={this.state.modalLng}
          />
        </Modal>
      </div>
    )
  }
}

export default withRouter(KnownPlacesList)
