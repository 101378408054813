import React, { Component } from 'react'
import { Row, Spin } from 'antd'
import { Marker } from 'react-google-maps'
import { InfoBox } from 'react-google-maps/lib/components/addons/InfoBox'
import { css } from '@emotion/css'

export default class CircleMarker extends Component {
  state = {
    showInfoWindow: false,
  }

  handleDragEnd = coord => {
    const lat = coord.latLng.lat()
    const lng = coord.latLng.lng()
    if (window.google) {
      const geocoder = new window.google.maps.Geocoder()
      const my_location = {
        lat,
        lng,
      }
      geocoder.geocode({ latLng: my_location }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
          this.props.changeWayPoint(this.props.wayPoint, lat, lng, results[0].proper_address)
        }
      })
    } else {
      this.props.changeWayPoint(this.props.wayPoint, lat, lng)
    }
  }

  render() {
    const {
      icon,
      zIndex,
      label,
      position,
      animation,
      draggable,
      wayPoint: { label: placeName },
    } = this.props
    return (
      <Marker
        onMouseOut={() => this.setState({ showInfoWindow: false })}
        onClick={() => this.setState({ showInfoWindow: true })}
        icon={icon}
        label={label}
        zIndex={zIndex}
        position={position}
        animation={animation}
        draggable={draggable}
        onDragEnd={this.handleDragEnd}
      >
        {this.state.showInfoWindow && (
          <InfoBox
            onCloseClick={() =>
              this.setState({
                showInfoWindow: false,
              })
            }
            options={{
              alignBottom: true,
              pixelOffset: new window.google.maps.Size(-15, -15),
              closeBoxURL: ``,
              enableEventPropagation: true,
            }}
          >
            <div
              className={css`width: 160,
              backgroundColor: '#FFF',
              borderRadius: 4,
              border: 1px solid #CCC,
              color: #444,
              padding: 8`
              }
            >
              <Row type="flex" align="middle">
                {placeName ? placeName : <Spin size="small" />}
              </Row>
            </div>
          </InfoBox>
        )}
      </Marker>
    )
  }
}
