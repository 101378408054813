const convertQsToJson = str => {
  let pairs = str.slice(1).split('&')

  let result = {}
  pairs.forEach(function(pair) {
    pair = pair.split('=')
    result[pair[0]] =
      decodeURIComponent(pair[1] || '') === 'NaN' ? '' : decodeURIComponent(pair[1] || '')
  })

  return JSON.parse(JSON.stringify(result))
}

const convertJsonToQs = data => {
  return Object.keys(data)
    .map(key => key + '=' + data[key])
    .join('&')
}

const getQueryParams = paramObj => {
  const query = Object.keys(paramObj)
    .map(k => `${k}=${paramObj[k]}`)
    .join('&')
  return query
}

const constructUrl = (baseApiUrl, qs, data = {}, isAPuRL) => {
  let url
  if (isAPuRL) {
    url = qs
      ? `${window._env_.REACT_APP_AP_CONFIG_URL}/${baseApiUrl}?${convertJsonToQs(qs)}`
      : `${window._env_.REACT_APP_AP_CONFIG_URL}/${baseApiUrl}`
    return url
  }
  const parsedDt = convertQsToJson(qs)
  const qObj = {}
  qObj.c = parsedDt.consignorId || parsedDt.companyId || ''
  qObj.b = parsedDt.branchId || ''
  qObj.d = parsedDt.departmentId || ''
  qObj.t = 'CONSIGNOR'
  const obj = { ...qObj, ...data }
  url = `${window._env_.REACT_APP_API_URL}${baseApiUrl}?${convertJsonToQs(obj)}`
  return url
}


const constructPhoenixUrl = (baseApiUrl, qs, data = {}, isAPuRL) => {
  let url
  if (isAPuRL) {
    url = qs
      ? `${window._env_.REACT_APP_TRANSDB_API_URL}/${baseApiUrl}?${convertJsonToQs(qs)}`
      : `${window._env_.REACT_APP_TRANSDB_API_URL}/${baseApiUrl}`
    return url
  }
  const parsedDt = convertQsToJson(qs)
  const qObj = {}
  qObj.c = parsedDt.consignorId || parsedDt.companyId || ''
  qObj.b = parsedDt.branchId || ''
  qObj.d = parsedDt.departmentId || ''
  qObj.t = 'CONSIGNOR'

  const obj = { ...qObj, ...data }

  url = `${window._env_.REACT_APP_TRANSDB_API_URL}${baseApiUrl}&${convertJsonToQs(obj)}`
  return url
}

const constructCbdtUrl = (baseApiUrl, qs, data = {}, isAPuRL) => {
  let url
  if (isAPuRL) {
    url = qs
      ? `${window._env_.REACT_APP_AP_CONFIG_URL}/${baseApiUrl}?${convertJsonToQs(qs)}`
      : `${window._env_.REACT_APP_AP_CONFIG_URL}/${baseApiUrl}`
    return url
  }
  const parsedDt = convertQsToJson(qs)
  const qObj = {}
  qObj.c = parsedDt.consignorId || parsedDt.companyId || ''
  qObj.b = parsedDt.branchId || ''
  qObj.d = parsedDt.departmentId || ''
  qObj.t = parsedDt.companyType || 'CONSIGNOR'
  const obj = { ...qObj, ...data }
  url = `${window._env_.REACT_APP_API_URL}${baseApiUrl}?${convertJsonToQs(obj)}`
  return url
}

const sortListBasedOnKey = (list, key) => {
  list.sort((a, b) => {
    const keyA = a[key].toLowerCase()
    const keyB = b[key].toLowerCase()
    if (keyA < keyB) {
      return -1
    }
    if (keyA > keyB) {
      return 1
    }
    return 0
  });
}

export { convertQsToJson, getQueryParams, constructUrl ,constructPhoenixUrl,constructCbdtUrl, sortListBasedOnKey}
