import React from 'react'
import { Table, Row, Button, message, Spin } from 'antd'
import request from 'utils/request'
import _ from 'lodash'
import { convertQsToJson, constructUrl } from 'utils/common'

class ListConsignees extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
    }
  }

  componentDidMount() {
    const parsedDt = convertQsToJson(this.props.location.search)
    if (!parsedDt.companyId) {
      message.info('Select a company to continue')
    } else {
      this.fetchConsignee(this.props.location.search)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search === '') {
      this.setState({ data: [] })
    }

    const parsedDtThis = convertQsToJson(this.props.location.search)
    const parsedDt = convertQsToJson(nextProps.location.search)
    if (parsedDt.companyId !== undefined && !_.isEqual(parsedDtThis, parsedDt)) {
      this.fetchConsignee(nextProps.location.search)
    }
  }

  fetchConsignee(qs) {
    const url = constructUrl('/saas/lego/company_partners/fetch', qs, { pt: 'CONSIGNEE' })
    request(url).then(
      result => {
        if (result.success) {
          this.setState({
            isLoaded: true,
            data: result.data,
          })
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        this.setState({
          isLoaded: true,
          error,
        })
      }
    )
  }

  delete(id) {
    const url = constructUrl('/saas/lego/company_partners/delete', this.props.location.search, {
      id: id,
      pt: 'CONSIGNEE',
    })
    request(url, {
      method: 'DELETE',
    }).then(
      result => {
        if (result.success) {
          message.success(`Consignee ${id} deleted successfully`)
          this.props.history.push({
            pathname: '/consignees',
            search: this.props.location.search,
          })
          this.fetchConsignee(this.props.location.search)
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        message.error('Error while deleting consignee')
      }
    )
  }

  edit(id) {
    this.props.history.push({
      pathname: `/consignees/${id}/edit`,
      search: this.props.location.search,
    })
  }

  render() {
    const columns = [
      {
        dataIndex: 'code',
        title: 'Consignee code',
      },
      {
        dataIndex: 'name',
        title: 'Consignee name',
      },
      {
        dataIndex: 'phone',
        title: 'Phone no.',
      },
      {
        dataIndex: 'email',
        title: 'Email id',
      },
      {
        dataIndex: 'place.cpCode',
        title: 'Display address',
      },

      {
        dataIndex: 'updatedBy',
        title: 'Updated By',
      },
      {
        dataIndex: 'cta',
        title: '',
        render: (text, record) => (
          <div>
            <Button icon="edit" onClick={() => this.edit(record.id)} />
            <Button icon="delete" onClick={() => this.delete(record.id)} />
          </div>
        ),
      },
    ]

    return (
      <Row>
        <Table columns={columns} dataSource={this.state.data} rowKey={record => record.id} />
      </Row>
    )
  }
}
export default ListConsignees
