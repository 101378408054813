import React from 'react'
import PlacesAutocomplete, { geocodeByAddress, geocodeByPlaceId } from 'react-places-autocomplete'
import { css } from '@emotion/css'

import { Input } from 'antd'
class MapSearchBox extends React.Component {
  state = {
    address: '',
  }

  handleChange = address => {
    this.setState({ address })
    if (!address) {
      this.props.addLatLng('', '', address, this.props.recordKey)
    }
  }

  checkLatLng = address => {
    const reg = /^[\d.,]+$/
    return new Promise((resolve, reject) => {
      if (reg.test(address) && window.google) {
        const splitLatLng = address.split(',')
        const lat = Number(splitLatLng[0])
        const lng = Number(splitLatLng[1])
        const my_location = {
          lat,
          lng,
        }
        const geocoder = new window.google.maps.Geocoder()
        return geocoder.geocode({ latLng: my_location }, (results, status) => {
          if (
            status === window.google.maps.GeocoderStatus.OK &&
            results[0] &&
            results[0].formatted_address
          ) {
            resolve({
              address: results[0].formatted_address,
              placeId: results[0].place_id,
              latLng: {
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng(),
              },
              type: 'latlng',
            })
          }
          reject({ address })
        })
      } else {
        reject({ address })
      }
    })
  }

  handleSelect = (addressOrLatLng, googlePlaceId) => {
    const { recordKey } = this.props
    if (googlePlaceId) {
      geocodeByPlaceId(googlePlaceId)
        .then(results => {
          const { place_id: googlePlaceId, formatted_address: address, geometry } = results[0]
          this.setState({ address })
          this.props.addLatLng(
            googlePlaceId,
            {
              lat: geometry.location.lat(),
              lng: geometry.location.lng(),
            },
            address,
            recordKey
          )
        })
        .catch(error => console.error('Error', error))
    } else {
      this.checkLatLng(addressOrLatLng)
        .then(addressInfo => {
          const { address, type, placeId, latLng } = addressInfo
          this.setState({ address })
          if (type) {
            this.props.addLatLng(placeId, latLng, address, recordKey)
          } else {
            geocodeByAddress(addressOrLatLng)
              .then(results => {
                const { place_id: googlePlaceId, formatted_address: address, geometry } = results[0]
                this.setState({ address })
                this.props.addLatLng(
                  googlePlaceId,
                  {
                    lat: geometry.location.lat(),
                    lng: geometry.location.lng(),
                  },
                  address,
                  recordKey
                )
              })
              .catch(error => console.error('Error', error))
          }
        })
        .catch(() => {
          geocodeByAddress(addressOrLatLng)
            .then(results => {
              let googlePlaceId = results[0].place_id

              this.setState({ address: results[0].formatted_address })

              this.props.addLatLng(
                googlePlaceId,
                {
                  lat: results[0].geometry.location.lat(),
                  lng: results[0].geometry.location.lng(),
                },
                results[0].formatted_address,
                recordKey
              )
            })
            .catch(error => console.error('Error', error))
        })
    }
  }

  render() {
    const { address } = this.state
    return (
      <PlacesAutocomplete value={address} onChange={this.handleChange} onSelect={this.handleSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <Input
              {...getInputProps({
                placeholder: 'Search Place / lat - lng',
                className: 'location-search-input',
              })}
              value={address}
            />
            <div
              className="autocomplete-dropdown-container"
              stylecss={css`position:absolute, zIndex:1000, height:200px, overflow:scroll`}
            >
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' }
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <div className={css`padding:8px`}>{suggestion.description}</div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    )
  }
}
export default MapSearchBox
