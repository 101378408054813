import React, { Component } from "react";
import { Tag } from "antd";

export const BULK_UPLOAD_COLUMNS = [
    {
        dataIndex: 'fteid',
        key: 'fteid',
        title: 'ID',
        render: (fteid) => (
            <Tag color="blue">
              {typeof fteid === "string"
                ? fteid.toUpperCase().slice(4, 12)
                : fteid}{" "}
            </Tag>
          ),
        width: '25%'
    },
    {
        dataIndex: 'filename',
        key: 'filename',
        title: 'Batch File Name',
        width: '25%'
    },
    {
        dataIndex: 'status',
        key: 'status',
        title: 'Status',
        width: '25%'
    },
    {
        dataIndex: 'created_at',
        key: 'created_at',
        title: 'Uploaded At',
        width: '25%'
    }
];