export const cronException = {
	crons: [
		{ value: '0 30 18 * * ?', label: 'Everyday 12 am' },
		{ value: '0 30 22 * * ?', label: 'Everyday 4 am' },
		{ value: '0 30 23 * * ?', label: 'Everyday 5 am' },
        { value: '0 30 0 * * ?', label: 'Everyday 6 am' },
		{ value: '0 30 1 * * ?', label: 'Everyday 7 am' },
		{ value: '0 30 2 * * ?', label: 'Everyday 8 am' },
        { value: '0 0 3 * * ?', label: 'Everyday 8:30 am' },
        { value: '0 30 3 * * ?', label: 'Everyday 9 am' },
        { value: '0 0 4 * * ?', label: 'Everyday 9:30 am' },
        { value: '0 30 4 * * ?', label: 'Everyday 10 am' },
        { value: '0 0 5 * * ?', label: 'Everyday 10:30 am' },
        { value: '0 30 5 * * ?', label: 'Everyday 11 am' },
		{ value: '0 0 6 * * ?', label: 'Everyday 11:30 am' },
        { value: '0 30 6 * * ?', label: 'Everyday 12 pm' },
		{ value: '0 0 7 * * ?', label: 'Everyday 12:30 pm' },
        { value: '0 30 7 * * ?', label: 'Everyday 1 pm' },
		{ value: '0 0 8 * * ?', label: 'Everyday 1:30 pm' },
        { value: '0 30 8 * * ?', label: 'Everyday 2 pm' },
		{ value: '0 0 9 * * ?', label: 'Everyday 2:30 pm' },
        { value: '0 30 9 * * ?', label: 'Everyday 3 pm' },
		{ value: '0 0 10 * * ?', label: 'Everyday 3:30 pm' },
        { value: '0 30 10 * * ?', label: 'Everyday 4 pm' },
		{ value: '0 0 11 * * ?', label: 'Everyday 4:30 pm' },
        { value: '0 30 11 * * ?', label: 'Everyday 5 pm' },
		{ value: '0 0 12 * * ?', label: 'Everyday 5:30 pm' },
        { value: '0 30 12 * * ?', label: 'Everyday 6 pm' },
		{ value: '0 0 13 * * ?', label: 'Everyday 6:30 pm' },
        { value: '0 30 13 * * ?', label: 'Everyday 7 pm' },
		{ value: '0 0 14 * * ?', label: 'Everyday 7:30 pm' },
        { value: '0 30 14 * * ?', label: 'Everyday 8 pm' },
		{ value: '0 0 15 * * ?', label: 'Everyday 8:30 pm' },
        { value: '0 30 15 * * ?', label: 'Everyday 9 pm' },
		{ value: '0 0 16 * * ?', label: 'Everyday 9:30 pm' },
        { value: '0 30 16 * * ?', label: 'Everyday 10 pm' },
		{ value: '0 0 17 * * ?', label: 'Everyday 10:30 pm' },
        { value: '0 30 17 * * ?', label: 'Everyday 11 pm' },
		{ value: '0 0 18 * * ?', label: 'Everyday 11:30 pm' },
	{
		value: '0 */15 * ? * *',
		label: 'Every 15 minutes'
	}, {
		value: '0 */30 * ? * *',
		label: 'Every 30 minutes'
	}, {
		value: '0 */45 * ? * *',
		label: 'Every 45 minutes'
	}, {
		value: '0 15,30,45 * ? * *',
		label: 'Every hour at minutes 15, 30 and 45'
	}, {
		value: '0 0 */2 ? * *',
		label: 'Every two hour'
	}, {
		value: '0 0 */3 ? * *',
		label: 'Every three hours'
	}, {
		value: '0 0 */4 ? * *',
		label: 'Every four hours'
	}, {
		value: '0 0 */6 ? * *',
		label: 'Every six hours'
	}, {
		value: '0 0 */8 ? * *',
		label: 'Every eight hours'
	}, {
		value: '0 0 */12 ? * *',
		label: 'Every twelve hours'
	}, {
		value: '0 0 */1 ? * *',
		label: 'Every one hour'
	}]
}

export default cronException