import React from 'react'
import { Row, Col, Divider, Checkbox, Radio, Select, Button, Input } from 'antd'
import SubHeader from 'utils/subHeader'
import '../epodConfigurations/epodStyle.scss'
const RadioGroup = Radio.Group
const Option = Select.Option

class EPODConfigurations extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      issueList: ['1', '2', '3'],
      epodList: ['Consignee', 'Driver'],
      smsCommunication: ['Start Of Trip', 'Last Mile', 'When Consignee calls', 'X hour befor ETA'],
      actorsList: [
        'Driver No.',
        'Cleaner No.',
        'Transporter No.',
        'Consignee No.',
        'Consignor No.',
        'Internal Ops',
      ],
      statusList: [
        'OTP Success',
        'Upload Success',
        'OTP or Upload Success',
        'OTP & Upload Success',
      ],
      tripStatus: ['No Imapact', 'Trip is immediately closed', 'Trip closed after Y'],
      enableFlow: false,
      enableOtp: 'yes',
      mandatoryOtp: 'no',
      mandatoryOtpField: true,
      enableUpload: 'yes',
      mandatoryUpload: 'no',
      mandatoryUploadField: true,
      enableCallback: true,
      enableFeedback: true,
      hourFlag: false,
      defaultStatus: 'OTP or Upload Success',
      tripCloseFlag: false,
    }
  }
  handleChangeEPODflow = value => {
    if (value.length) {
      this.setState({ enableFlow: true })
    } else {
      this.setState({ enableFlow: false, hourFlag: false, tripCloseFlag: false })
    }
  }
  onChangeOtpFlow = e => {
    if (e.target.value === 'no') {
      this.setState({ enableOtp: 'no', mandatoryOtpField: false })
    } else {
      this.setState({ enableOtp: 'no', mandatoryOtpField: true })
    }
  }
  enableUploadFlow = e => {
    if (e.target.value === 'yes') {
      this.setState({ enableUpload: 'yes', mandatoryUploadField: true })
    } else {
      this.setState({ enableUpload: 'yes', mandatoryUploadField: false })
    }
  }
  handleRequestCallback = e => {
    this.setState({ enableCallback: e.target.checked })
  }
  handleFeedbackChange = e => {
    this.setState({ enableFeedback: e.target.checked })
  }
  handleSmsCommunication = value => {
    let temp = value.filter(item => item === 'X hour befor ETA')
    if (temp[0]) {
      this.setState({ hourFlag: true })
    } else {
      this.setState({ hourFlag: false })
    }
  }
  handleActorsChange = value => {
    console.log('actors', value)
  }
  handleChange = value => {
    console.log(`selected ${value}`)
  }
  handleTripStatusChanges = e => {
    console.log('trip status', e.target.value)
    if (e.target.value === 'Trip closed after Y') {
      this.setState({ tripCloseFlag: true })
    } else {
      this.setState({ tripCloseFlag: false })
    }
  }

  render() {
    const {
      issueList,
      epodList,
      smsCommunication,
      actorsList,
      enableFlow,
      enableOtp,
      enableUpload,
      mandatoryOtp,
      mandatoryOtpField,
      mandatoryUpload,
      mandatoryUploadField,
      enableCallback,
      enableFeedback,
      hourFlag,
      statusList,
      defaultStatus,
      tripStatus,
      tripCloseFlag,
    } = this.state
    return (
      <Row className="epodConfig">
        <SubHeader />
        <h2 className="text-center" style={{ paddingTop: '20px' }}>
          EPOD Configuration
        </h2>
        <Divider />
        <Row type="flex">
          <Col className="epod-left" span={12}>
            <p>Enable EPOD Flow For :</p>
          </Col>
          <Col span={12}>
            <Checkbox.Group style={{ width: '40%' }} onChange={this.handleChangeEPODflow}>
              {epodList &&
                epodList.map((item, key) => (
                  <Checkbox className="mar-left-15" value={item} key={key}>
                    {item}
                  </Checkbox>
                ))}
            </Checkbox.Group>
          </Col>
        </Row>
        {enableFlow ? (
          <Row>
            <Row type="flex">
              <Col className="epod-left" span={12}>
                <p>Enable OTP Flow :</p>
              </Col>
              <Col span={12}>
                <Row className="mar-left-15">
                  <RadioGroup onChange={this.onChangeOtpFlow} defaultValue={enableOtp}>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </RadioGroup>
                </Row>
              </Col>
            </Row>
            {mandatoryOtpField ? (
              <Row type="flex">
                <Col className="epod-left" span={12}>
                  <p>OTP Mandatory:</p>
                </Col>
                <Col span={12}>
                  <Row className="mar-left-15">
                    <RadioGroup defaultValue={mandatoryOtp}>
                      <Radio value="yes">Yes</Radio>
                      <Radio value="no">No</Radio>
                    </RadioGroup>
                  </Row>
                </Col>
              </Row>
            ) : (
              ''
            )}
            <Row type="flex">
              <Col className="epod-left" span={12}>
                <p>Enable Upload Flow :</p>
              </Col>
              <Col span={12}>
                <Row className="mar-left-15">
                  <RadioGroup onChange={this.enableUploadFlow} defaultValue={enableUpload}>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </RadioGroup>
                </Row>
              </Col>
            </Row>
            {mandatoryUploadField ? (
              <Row type="flex">
                <Col className="epod-left" span={12}>
                  <p> Upload Mandatory :</p>
                </Col>
                <Col span={12}>
                  <Row className="mar-left-15">
                    <RadioGroup defaultValue={mandatoryUpload}>
                      <Radio value="yes">Yes</Radio>
                      <Radio value="no">No</Radio>
                    </RadioGroup>
                  </Row>
                </Col>
              </Row>
            ) : (
              ''
            )}
            <Row type="flex">
              <Col className="epod-left" span={12}>
                <p>List of Issue :</p>
              </Col>
              <Col span={12}>
                <Row className="mar-left-15">
                  <p style={{ marginBottom: '0' }}>
                    <Checkbox>Enable</Checkbox>
                  </p>
                  <Select
                    mode="tags"
                    style={{ width: '200px' }}
                    placeholder="Select Issue"
                    onChange={this.handleChange}
                  >
                    {issueList && issueList.map((item, key) => <Option key={key}>{item}</Option>)}
                  </Select>
                </Row>
              </Col>
            </Row>
            <Row style={{ marginTop: '10px' }} type="flex">
              <Col className="epod-left" span={12}>
                <p>Callback Request :</p>
              </Col>
              <Col span={12}>
                <Row className="mar-left-15">
                  <p style={{ marginBottom: '0' }}>
                    <Checkbox onChange={this.handleRequestCallback} defaultChecked={enableCallback}>
                      Enable
                    </Checkbox>
                  </p>
                  {enableCallback ? (
                    <p style={{ marginTop: '10px' }} className="callRequest">
                      <Checkbox>Freight Tiger Internal Ops</Checkbox>
                      <br />
                      <Checkbox>Company Point Of Contact</Checkbox>
                    </p>
                  ) : (
                    ''
                  )}
                </Row>
              </Col>
            </Row>
            <Row style={{ marginTop: '10px' }} type="flex">
              <Col className="epod-left" span={12}>
                <p>Feedback :</p>
              </Col>
              <Col span={12}>
                <Row className="mar-left-15">
                  <p>
                    <Checkbox onChange={this.handleFeedbackChange} defaultChecked={enableFeedback}>
                      Enable
                    </Checkbox>
                  </p>
                  {enableFeedback ? (
                    <Select
                      className="callRequest"
                      mode="tags"
                      placeholder="Feedback Question"
                      onChange={this.handleChange}
                    >
                      {issueList && issueList.map((item, key) => <Option key={key}>{item}</Option>)}
                    </Select>
                  ) : (
                    ''
                  )}
                </Row>
              </Col>
            </Row>
            <Row style={{ marginTop: '10px' }} type="flex">
              <Col className="epod-left" span={12}>
                <p>SMS Communication Time :</p>
              </Col>
              <Col span={12}>
                <Row>
                  <Checkbox.Group style={{ width: '40%' }} onChange={this.handleSmsCommunication}>
                    {smsCommunication &&
                      smsCommunication.map((item, key) => (
                        <Checkbox className="mar-left-15" value={item} key={key}>
                          {item}
                        </Checkbox>
                      ))}
                  </Checkbox.Group>
                  {hourFlag ? (
                    <p className="mar-left-15" style={{ marginTop: '10px' }}>
                      <Input style={{ width: '60%' }} />
                      <Button style={{ width: '15%', marginLeft: '10px' }}>Submit</Button>
                    </p>
                  ) : (
                    ''
                  )}
                </Row>
              </Col>
            </Row>
            {mandatoryOtpField ? (
              <Row style={{ marginTop: '10px' }} type="flex">
                <Col className="epod-left" span={12}>
                  <p>OTP Recived from which all actors :</p>
                </Col>
                <Col span={12}>
                  <Row>
                    <Checkbox.Group
                      style={{ width: '40%' }}
                      defaultValue={['Driver No.', 'Consignee No.', 'Internal Ops']}
                      onChange={this.handleActorsChange}
                    >
                      {actorsList &&
                        actorsList.map((item, key) => (
                          <Checkbox
                            className="mar-left-15"
                            defaultChecked={item === 'Driver No.'}
                            value={item}
                            key={key}
                          >
                            {item}
                          </Checkbox>
                        ))}
                    </Checkbox.Group>
                  </Row>
                </Col>
              </Row>
            ) : (
              ''
            )}
            <Row style={{ marginTop: '10px' }} type="flex">
              <Col className="epod-left" span={12}>
                <p>EPOD status change from Pending to Succees :</p>
              </Col>
              <Col span={12}>
                <Row className="mar-left-15">
                  <Select
                    placeholder="Status"
                    defaultValue={defaultStatus}
                    style={{ width: '200px' }}
                  >
                    {statusList &&
                      statusList.map((item, key) => (
                        <Option key={key} value={item}>
                          {item}
                        </Option>
                      ))}
                  </Select>
                </Row>
              </Col>
            </Row>
            {mandatoryUploadField ? (
              <Row style={{ marginTop: '10px', marginBottom: '20px' }} type="flex">
                <Col className="epod-left" span={12}>
                  <p>System action on Trip Status :</p>
                </Col>
                <Col span={12}>
                  <Row className="mar-left-15">
                    <RadioGroup style={{ width: '40%' }} onChange={this.handleTripStatusChanges}>
                      {tripStatus &&
                        tripStatus.map((item, key) => (
                          <Radio value={item} key={key}>
                            {item}
                          </Radio>
                        ))}
                    </RadioGroup>
                    {tripCloseFlag ? (
                      <p className="mar-left-15" style={{ marginTop: '10px' }}>
                        <Input style={{ width: '60%' }} />
                        <Button style={{ width: '15%', marginLeft: '10px' }}>Submit</Button>
                      </p>
                    ) : (
                      ''
                    )}
                  </Row>
                </Col>
              </Row>
            ) : (
              ''
            )}
          </Row>
        ) : null}
      </Row>
    )
  }
}
export default EPODConfigurations
