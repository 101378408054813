export const FilterKeys = {
    filters: [{
            value: 'current_escalation_level',
            label: 'Current escalation level',
            inputType: 'number',
            fieldType: 'int'
        },
        {
            value: 'level_1_escalated_at',
            label: 'Level 1 escalated at',
            inputType: 'number',
            fieldType: 'date'
        },
        {
            value: 'level_2_escalated_at',
            label: 'Level 2 escalated at',
            inputType: 'number',
            fieldType: 'date'
        },
        {
            value: 'level_3_escalated_at',
            label: 'Level 3 escalated at',
            inputType: 'number',
            fieldType: 'date'
        },
        {
            value: 'alert_close_time',
            label: 'Alert close time',
            inputType: 'number',
            fieldType: 'date'
        },
        {
            value: 'folder',
            label: 'Folder',
            inputType: 'text',
            fieldType: 'text'
        },
        {
            value: 'customer_code',
            label: 'Customer code',
            inputType: 'text',
            fieldType: 'text'
        },
        {
            value: 'customer_name',
            label: 'Customer name',
            inputType: 'text',
            fieldType: 'text'
        },
        {
            value: 'primary_source',
            label: 'Primary source',
            inputType: 'text',
            fieldType: 'text'
        },
        {
            value: 'last_source',
            label: 'Last source',
            inputType: 'text',
            fieldType: 'text'
        },
        {
            value: 'created_by',
            label: 'Created by',
            inputType: 'text',
            fieldType: 'text'
        },
        {
            value: 'alert_type',
            label: 'Alert type',
            inputType: 'text',
            fieldType: 'text'
        },
        {
            value: 'alert_name',
            label: 'Alert name',
            inputType: 'text',
            fieldType: 'text'
        },
        {
            value: 'alert_status',
            label: 'Alert status',
            inputType: 'text',
            fieldType: 'text'
        },
        {
            value: 'ticket_id',
            label: 'Ticket Id',
            inputType: 'text',
            fieldType: 'text'
        },
        {
            value: 'ticket_status',
            label: 'Ticket Status',
            inputType: 'text',
            fieldType: 'text'
        },
        {
            value: 'due_time',
            label: 'Due time',
            inputType: 'number',
            fieldType: 'date'
        },
        {
            value: 'assignee',
            label: 'Assignee',
            inputType: 'text',
            fieldType: 'text'
        },
        {
            value: 'priority',
            label: 'Priority',
            inputType: 'text',
            fieldType: 'text'
        },
        {
            value: 'last_comment',
            label: 'Last comment',
            inputType: 'text',
            fieldType: 'text'
        },
        {
            value: 'closure_reason',
            label: 'Closure reason',
            inputType: 'text',
            fieldType: 'text'
        },
        {
            value: 'closure_comment',
            label: 'Closure comment',
            inputType: 'text',
            fieldType: 'text'
        },
        {
            value: 'closure_time',
            label: 'Closure time',
            inputType: 'number',
            fieldType: 'date'
        },
        {
            value: 'created_at',
            label: 'Created at',
            inputType: 'number',
            fieldType: 'date'
        },
        {
            value: 'updated_at',
            label: 'Updated at',
            inputType: 'number',
            fieldType: 'date'
        },
        {
            value: 'closure_mechanism',
            label: 'Closure mechanism',
            inputType: 'text',
            fieldType: 'text'
        },
        {
            value: 'closed_by',
            label: 'Closed by',
            inputType: 'text',
            fieldType: 'text'
        },
        {
            value: 'sub_status',
            label: 'Sub status',
            inputType: 'text',
            fieldType: 'text'
        }
    ]
}

export default FilterKeys