import React from "react";
import { Form, Select, Input, Button, Row, Col, message } from "antd";
import { withRouter } from "react-router";
import { convertQsToJson } from "utils/common";
import fetchData from "utils/fetchData";
import "./autoclosure.scss";
import InputField from "./field";
import CustomField from "./customfield";
import { css } from "@emotion/css";
const Option = Select.Option;
const PHOENIX_API = window._env_.REACT_APP_TRANSDB_API_URL;

class AutoClosureConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      customerConfigId: null,
      threshold: null,
      geofenceRadiusInMeter: null,
      companyName: "",
      allFlag: {
        addFieldDisable: true,
      },
      enableStaBreach: false,
      enableAutoClosureRule: false,
      autoClosureType: "",
      fieldList: [],
      lastMileMinutes: null,
      customValue: [],
      minDistance: [""],
      maxDistance: [],
      transitBuffer: [],
      allCustomList: [],
      minTimeAtDestination: [],
      minTimeAfterDestination: [],
      autoClosureRuleSubject: [""],
      firstCustomValue: [],
      secondCustomValue: [],
      firstCustomOperator: [],
      secondCustomOperator: [],
      firstFieldValue: [],
      secondFieldValue: [],
      joinOperator: [],
    };
    this.handleAutoClosureType = this.handleAutoClosureType.bind(this);
  }

  componentDidMount() {
    const { search } = this.props.location;
    const parsedDt = convertQsToJson(search);
    if (!parsedDt.companyId) {
      message.info("Select a company to continue");
    } else {
      if (this.props.match.params.id) {
        this.setState({ edit: true }, () => {
          this.fetchAutoClosure(this.props.match.params.id);
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { search } = this.props.location;
    if (search !== prevProps.location.search) {
      if (this.props.match.params.id) {
        this.setState({ edit: true }, () => {
          this.fetchAutoClosure(this.props.match.params.id);
        });
      }
    }
  }

  fetchAutoClosure = async (id) => {
    const url = `${PHOENIX_API}saas/data/autoclosure/getAutoClosureById?`;
    const { search } = this.props.location;
    const parsedDt = convertQsToJson(search);
    const payload = {
      id: id,
      companyId: parsedDt.companyId,
      branchId: parsedDt.branchId,
    };
    await fetchData(url, "GET", payload)
      .then((response) => {
        this.setAutoClosureConfig(response, id);
      })
      .catch((error) => {
        message.error("Error while fetching dashboard details");
      });
  };

  setAutoClosureConfig = (response, id) => {
    const { data, success } = response.result;
    if (success == true) {
      if (data && data.identifier) {
        this.handleAutoClosureType(data.identifier);
        this.setState({
          customerConfigId: id,
          autoClosureType: data.identifier,
        });
        switch (data.identifier) {
          case "ACTION_AUTO_CLOSURE_ON_EXIT":
            this.setState({
              companyName: data.companyName,
              threshold: data.threshold,
              geofenceRadiusInMeter: data.geofenceRadiusInMeter,
            });
            this.props.form.setFieldsValue({
              companyName: data.companyName,
              autoClosureType: data.identifier,
              threshold: data.threshold,
              geofenceRadiusInMeter: data.geofenceRadiusInMeter,
            });
            break;
          case "ACTION_AUTO_CLOSURE_ON_ENTRY":
            this.setState({
              companyName: data.companyName,
              threshold: data.threshold,
              geofenceRadiusInMeter: data.geofenceRadiusInMeter,
            });
            this.props.form.setFieldsValue({
              companyName: data.companyName,
              autoClosureType: data.identifier,
              threshold: data.threshold,
              geofenceRadiusInMeter: data.geofenceRadiusInMeter,
            });
            break;
          case "AUTO_CLOSURE_EPOD":
            this.setState({
              threshold: data.threshold,
            });
            this.props.form.setFieldsValue({
              threshold: data.threshold,
              autoClosureType: data.identifier,
            });
            break;
          case "ACTION_AUTO_CLOSURE_ON_LAST_MILE":
            this.setState({
              lastMileMinutes: data.lastMileMinutes,
            });
            this.props.form.setFieldsValue({
              lastMileMinutes: data.lastMileMinutes,
              autoClosureType: data.identifier,
            });
            break;
          case "ACTION_AUTO_CLOSURE_ON_STA_BREACH":
            this.setState({
              minDistance: data.minDistance,
              maxDistance: data.maxDistance,
              transitBuffer: data.transitTimeBuffers,
            });
            this.props.form.setFieldsValue({
              minDistance: data.minDistance,
              maxDistance: data.maxDistance,
              transitBuffer: data.transitTimeBuffers,
              autoClosureType: data.identifier,
              unloading_time_buffer: data.unloading_time_buffer,
              loading_time_buffer: data.loadingTimeBuffer,
            });
            break;
          case "AUTO_CLOSURE_RULE_BASED":
            const inputlist = InputField(data.identifier);
            const firstFieldValue = this.removeSingleQuote(data.firstFieldValue)
            const secondFieldValue = this.removeSingleQuote(data.secondFieldValue)

            this.setState(
              {
                enableAutoClosureRule: true,
                autoClosureRuleSubject: data.autoClosureRuleSubject,
                threshold: data.staleTripsThreshold,
                fieldList: inputlist,
                firstCustomValue: data.firstCustomValue,
                secondCustomValue: data.secondCustomValue,
                firstCustomOperator: data.firstCustomOperator,
                secondCustomOperator: data.secondCustomOperator,
                firstFieldValue: firstFieldValue,
                secondFieldValue: secondFieldValue,
                joinOperator: data.joinOperator,
                minTimeAtDestination: data.minTimeAtDestination,
                minTimeAfterDestination: data.minTimeAfterDestination,
                autoClosureRuleSubject: data.autoClosureRuleSubject,
              },
              () => {
                this.props.form.setFieldsValue({
                  threshold: data.staleTripsThreshold,
                  autoClosureType: data.identifier,
                  firstCustomValue: data.firstCustomValue,
                  secondCustomValue: data.secondCustomValue,
                  firstCustomOperator: data.firstCustomOperator,
                  secondCustomOperator: data.secondCustomOperator,
                  firstFieldValue: firstFieldValue,
                  secondFieldValue: secondFieldValue,
                  joinOperator: data.joinOperator,
                  minTimeAtDestination: data.minTimeAtDestination,
                  minTimeAfterDestination: data.minTimeAfterDestination,
                  autoClosureRuleSubject: data.autoClosureRuleSubject,
                });
              }
            );
            break;
          default:
            break;
        }
      }
    }
  };

  handleChange = (name, e) => {
    let change = {};
    change[name] = e.target.value;
    this.setState(change);
  };

  handleAutoClosureType = async (value) => {
    const closureType = value ? value : "ACTION_AUTO_CLOSURE_ON_ENTRY";
    const inputlist = InputField(closureType);
    if (closureType == "ACTION_AUTO_CLOSURE_ON_STA_BREACH") {
      this.setState(
        {
          enableStaBreach: true,
          enableAutoClosureRule: false,
          fieldList: inputlist,
        },
        () => {}
      );
    } else if (closureType == "AUTO_CLOSURE_RULE_BASED") {
      const allCustomList = await this.getAllCustomList();
      if (allCustomList) {
        this.setState(
          {
            enableAutoClosureRule: true,
            enableStaBreach: false,
            allCustomList: allCustomList,
            fieldList: inputlist,
          },
          () => {}
        );
      }
    } else {
      this.setState(
        {
          enableStaBreach: false,
          enableAutoClosureRule: false,
          autoClosureType: value,
          fieldList: inputlist,
        },
        () => {}
      );
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.createAndUpdateAutoClosure(values);
      }
    });
  };

  createAndUpdateAutoClosure(data) {
    const { search } = this.props.location;
    const parsedDt = convertQsToJson(search);
    const companyId = parsedDt.companyId || "";
    const branchId = parsedDt.branchId || "";
    const { customerConfigId, edit } = this.state;
    if (companyId) {
      let type = "POST";
      const payload = {
        companyId,
        branchId,
        autoClosureType: data.autoClosureType,
        edit,
        customerConfigId,
      };
      switch (data.autoClosureType) {
        case "ACTION_AUTO_CLOSURE_ON_EXIT":
          payload.threshold = data.threshold;
          payload.companyName = data.companyName;
          payload.geofenceRadiusInMeter = data.geofenceRadiusInMeter;
          break;
        case "ACTION_AUTO_CLOSURE_ON_ENTRY":
          payload.threshold = data.threshold;
          payload.companyName = data.companyName;
          payload.geofenceRadiusInMeter = data.geofenceRadiusInMeter;
          break;
        case "AUTO_CLOSURE_EPOD":
          payload.threshold = data.threshold;
          break;
        case "ACTION_AUTO_CLOSURE_ON_LAST_MILE":
          payload.lastMileMinutes = data.lastMileMinutes;
          break;
        case "ACTION_AUTO_CLOSURE_ON_STA_BREACH":
          payload.minDistance = data.minDistance;
          payload.maxDistance = data.maxDistance;
          payload.transitBuffer = data.transitBuffer;
          payload.unloading_time_buffer = data.unloading_time_buffer;
          payload.loading_time_buffer = data.loading_time_buffer;
          break;
        case "AUTO_CLOSURE_RULE_BASED":
          const firstFieldValue = this.appendSingleQuote(data.firstFieldValue)
          const secondFieldValue = this.appendSingleQuote(data.secondFieldValue)

          payload.staleTripsThreshold = data.threshold;
          payload.name = data.autoClosureRuleSubject;
          payload.min_time_at_destination = data.minTimeAtDestination;
          payload.min_time_after_destination = data.minTimeAfterDestination;
          payload.firstCustomValue = data.firstCustomValue;
          payload.secondCustomValue = data.secondCustomValue;
          payload.firstCustomOperator = data.firstCustomOperator;
          payload.secondCustomOperator = data.secondCustomOperator;
          payload.firstFieldValue = firstFieldValue;
          payload.secondFieldValue = secondFieldValue;
          payload.joinOperator = data.joinOperator;
          break;
        default:
          break;
      }
      const url = `${PHOENIX_API}saas/data/autoclosure/createAndUpdateAutoClosure?`;
      fetchData(url, type, payload)
        .then((res) => {
          if (res.status == "ok") {
            const companyId =
              res.result && res.result.data ? res.result.data.companyId : null;
            message.success(
              `Auto Closure set successfully for companyIds [ ${companyId} ] `
            );
          } else {
            message.error("Auto Closure is not updated");
          }
          this.props.history.push({
            pathname: "/auto-closure",
            search: search,
          });
        })
        .catch((error) => {
          message.error(error);
        });
    }
  }

  getAllCustomList = async () => {
    const customlist = await this.getCustomFieldbyCompanyId();
    let customvalue='custom_values.';
    for (let index = 0; index < customlist.length; index++) {
      customlist[index].fieldId = customvalue.concat(customlist[index].fieldId) ;
    }
    const addTripField = CustomField();
    return [...customlist, ...addTripField];
  };

  getCustomFieldbyCompanyId = async () => {
    const url = `${PHOENIX_API}saas/data/autoclosure/getCustomField?`;
    const { search } = this.props.location;
    const parsedDt = convertQsToJson(search);
    const payload = {
      companyId: parsedDt.companyId || "",
      branchId: parsedDt.branchId || "",
    };
    const result = await fetchData(url, "GET", payload)
      .then((response) => {
        return response.result.data;
      })
      .catch((error) => {
        message.error("Error while fetching custom field by companyId");
      });
    return result;
  };

  addFields = (inputField, flag) => {
    let tempMinDistance = this.state[inputField];
    const { allFlag } = this.state;
    if (tempMinDistance[tempMinDistance.length - 1]) {
      tempMinDistance.push("");
    }
    if (tempMinDistance.length > 1) {
      allFlag[flag] = false;
    }
    this.setState({ [inputField]: tempMinDistance, allFlag });
  };

  deleteFields = (
    addField,
    operators,
    inputvalue,
    index,
    flag,
    firstCustomValue,
    firstCustomOperator,
    firstFieldValue,
    joinOperator,
    secondCustomValue,
    secondCustomOperator,
    secondFieldValue
  ) => {
    let tempField = this.state[addField];
    let tempOperator = this.state[operators];
    let tempInputValue = this.state[inputvalue];
    const { allFlag } = this.state;
    tempField.splice(index, 1);
    tempOperator.splice(index, 1);
    tempInputValue.splice(index, 1);
    this.props.form.setFieldsValue({
      [addField]: tempField,
      [operators]: tempOperator,
      [inputvalue]: tempInputValue,
    });
    if (tempField.length === 1) {
      allFlag[flag] = true;
    }
    if (firstCustomValue && firstCustomOperator && firstFieldValue) {
      let tempFirstCustomValue = this.state[firstCustomValue];
      let tempFirstCustomOperator = this.state[firstCustomOperator];
      let tempFirstFieldValue = this.state[firstFieldValue];
      let tempjoinOperator = this.state[joinOperator];
      let tempsecondCustomValue = this.state[secondCustomValue];
      let tempsecondCustomOperator = this.state[secondCustomOperator];
      let tempsecondFieldValue = this.state[secondFieldValue];
      tempFirstCustomValue.splice(index, 1);
      tempFirstCustomOperator.splice(index, 1);
      tempFirstFieldValue.splice(index, 1);
      tempjoinOperator.splice(index, 1);
      tempsecondCustomValue.splice(index, 1);
      tempsecondCustomOperator.splice(index, 1);
      tempsecondFieldValue.splice(index, 1);
      this.props.form.setFieldsValue({
        [addField]: tempField,
        [operators]: tempOperator,
        [inputvalue]: tempInputValue,
        [firstCustomValue]: tempFirstCustomValue,
        [firstCustomOperator]: tempFirstCustomOperator,
        [firstFieldValue]: tempFirstFieldValue,
        [joinOperator]: tempjoinOperator,
        [secondCustomValue]: tempsecondCustomValue,
        [secondCustomOperator]: tempsecondCustomOperator,
        [secondFieldValue]: tempsecondFieldValue,
      });
    }
    this.setState({ allFlag, type: tempField });
  };

  handleCustomValue = (index, value) => {
    const { customValue } = this.state;
    let t = customValue;
    t[index] = value;
    this.setState({
      customValue: t,
    });
  };

  handleMinDistance = (i, value) => {
    const { minDistance } = this.state;
    let t = minDistance;
    t[i] = value;
    this.setState({
      minDistance: t,
    });
  };

  handleAutoClosureSubject = (i, event) => {
    const { autoClosureRuleSubject } = this.state;
    let t = autoClosureRuleSubject;
    t[i] = event.target.value;
    this.setState({
      autoClosureRuleSubject: t,
    });
  };

  handleMaxDistance = (i, value) => {
    const { maxDistance } = this.state;
    let t = maxDistance;
    t[i] = value;
    this.setState({
      maxDistance: t,
    });
  };

  handleTransitBuffer = (i, value) => {
    const { transitBuffer } = this.state;
    let t = transitBuffer;
    t[i] = value;
    this.setState({
      transitBuffer: t,
    });
  };

  removeSingleQuote=(arrVal)=>Array.isArray(arrVal) ? arrVal.map((v)=>v && v.replaceAll("'", "")): arrVal
  
  appendSingleQuote=(arrVal)=>Array.isArray(arrVal) ? arrVal.map((v)=> v && `'${v}'`) : arrVal

  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;
    const {
      edit,
      fieldList,
      customValue,
      enableAutoClosureRule,
      enableStaBreach,
      minDistance,
      maxDistance,
      autoClosureType,
      autoClosureRuleSubject,
      allCustomList,
      transitBuffer,
    } = this.state;
    return (
      <div className="autoclosure">
        <h3
          className={css`
            text-align: center;
            margin-bottom: 40px;
          `}
        >{`${edit ? "Update" : "Create"} Auto Closure Config`}</h3>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col span={10} offset={1}>
              <Form.Item label="Auto Closure Type">
                {getFieldDecorator(`autoClosureType`, {
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: "Please select auto closure type",
                    },
                  ],
                })(
                  <Select
                    disabled={edit}
                    defaultValue="none"
                    onChange={this.handleAutoClosureType}
                  >
                    <Option value="ACTION_AUTO_CLOSURE_ON_ENTRY">
                      ACTION AUTO CLOSURE ON ENTRY
                    </Option>
                    <Option value="ACTION_AUTO_CLOSURE_ON_EXIT">
                      ACTION AUTO CLOSURE ON EXIT
                    </Option>
                    <Option value="AUTO_CLOSURE_RULE_BASED">
                      AUTO CLOSURE RULE BASED
                    </Option>
                    <Option value="ACTION_AUTO_CLOSURE_ON_STA_BREACH">
                      ACTION AUTO CLOSURE ON STA BREACH
                    </Option>
                    <Option value="AUTO_CLOSURE_EPOD">AUTO CLOSURE EPOD</Option>
                    <Option value="ACTION_AUTO_CLOSURE_ON_LAST_MILE">
                      ACTION AUTO CLOSURE ON LAST MILE
                    </Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={4} offset={3}>
              {enableAutoClosureRule ? (
                <Form.Item label="Add More Filter">
                  <Button
                    href="javascript4:void(0);"
                    onClick={() =>
                      this.addFields(
                        "autoClosureRuleSubject",
                        "addFieldDisable"
                      )
                    }
                  >
                    + Add More Filter
                  </Button>
                </Form.Item>
              ) : enableStaBreach ? (
                <Form.Item label="Add More Filter">
                  <Button
                    href="javascript4:void(0);"
                    onClick={() =>
                      this.addFields("minDistance", "addFieldDisable")
                    }
                  >
                    + Add More Filter
                  </Button>
                </Form.Item>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row>
            {fieldList &&
              fieldList.map((input, i) => (
                <Col span={10} key={i} offset={1}>
                  <Form.Item label={input.label}>
                    {getFieldDecorator(`${input.value}`, {
                      rules: [
                        {
                          required: input.required,
                          message: "Please enter valid company name",
                        },
                      ],
                    })(
                      <Input
                        type={input.inputType}
                        placeholder={input.placeholder}
                        onChange={this.handleChange.bind(this, input.value)}
                      />
                    )}
                  </Form.Item>
                </Col>
              ))}
          </Row>
          {enableStaBreach ? (
            <Row>
              {minDistance &&
                minDistance.map((item, i) => (
                  <Row className="filter" key={i}>
                    <Col span={6} key={i} offset={1}>
                      <Form.Item label={`Transit Min Distance ${i + 1}(In Km)`}>
                        {getFieldDecorator(`minDistance[${i}]`, {
                          rules: [
                            {
                              required: true,
                              message: "Please select min distance",
                            },
                          ],
                        })(
                          <Input
                            placeholder="Please Fill Input Value"
                            onChange={this.handleMinDistance.bind(this, i)}
                            type="number"
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={6} key={i + 1} offset={1}>
                      <Form.Item label={`Transit Max Distance ${i + 1}(In Km)`}>
                        {getFieldDecorator(`maxDistance[${i}]`, {
                          rules: [
                            {
                              required: true,
                              message: "Please select max distance",
                            },
                          ],
                        })(
                          <Input
                            placeholder="Please Fill max distance"
                            onChange={this.handleMaxDistance.bind(this, i)}
                            type="number"
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={6} key={i + 2} offset={1}>
                      <Form.Item label={`Transit Buffer ${i + 1}(In Sec)`}>
                        {getFieldDecorator(`transitBuffer[${i}]`, {
                          rules: [
                            {
                              required: true,
                              message: "Please select transit buffer",
                            },
                          ],
                        })(
                          <Input
                            placeholder="Please fill transit buffer"
                            onChange={this.handleTransitBuffer.bind(this, i)}
                            type="number"
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <Form.Item label={`Remove ${i}`}>
                        <Button
                          className="deleteButton"
                          onClick={() =>
                            this.deleteFields(
                              "minDistance",
                              "maxDistance",
                              "transitBuffer",
                              i,
                              "addFieldDisable"
                            )
                          }
                        >
                          X
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
            </Row>
          ) : (
            ""
          )}
          {enableAutoClosureRule ? (
            <Row>
              {autoClosureRuleSubject &&
                autoClosureRuleSubject.map((item, i) => (
                  <Row className="filter" key={i}>
                    <Col span={6} offset={1}>
                      <Form.Item label={`Auto closure subject ${i + 1}`}>
                        {getFieldDecorator(`autoClosureRuleSubject[${i}]`, {
                          rules: [
                            {
                              required: true,
                              message: "Please Enter Auto Closure Subject",
                            },
                          ],
                        })(
                          <Input
                            placeholder="Please Enter Auto Closure Subject"
                            onChange={this.handleAutoClosureSubject.bind(
                              this,
                              i
                            )}
                            type="text"
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={6} offset={1}>
                      <Form.Item label={`Min time at destination ${i + 1}(In Min)`}>
                        {getFieldDecorator(`minTimeAtDestination[${i}]`, {
                          rules: [
                            {
                              required: true,
                              message: "Please Enter Time In Min",
                            },
                          ],
                        })(
                          <Input
                            placeholder="Please Enter Input Value"
                            onChange={this.handleMinDistance.bind(this, i)}
                            type="number"
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={6} offset={1}>
                      <Form.Item label={`Min time after destination ${i + 1}(In Min)`}>
                        {getFieldDecorator(`minTimeAfterDestination[${i}]`, {
                          rules: [
                            {
                              required: true,
                              message: "Please Enter Time In Min",
                            },
                          ],
                        })(
                          <Input
                            placeholder="Please Enter Input Value"
                            onChange={this.handleMinDistance.bind(this, i)}
                            type="number"
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={2} offset={1}>
                      <Form.Item label={`Remove ${i + 1}`}>
                        <Button
                          className="deleteButton"
                          onClick={() =>
                            this.deleteFields(
                              "autoClosureRuleSubject",
                              "minTimeAtDestination",
                              "minTimeAfterDestination",
                              i,
                              "addFieldDisable",
                              "firstCustomValue",
                              "firstCustomOperator",
                              "firstFieldValue",
                              "joinOperator",
                              "secondCustomValue",
                              "secondCustomOperator",
                              "secondFieldValue"
                            )
                          }
                        >
                          X
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col span={5} offset={1}>
                      <Form.Item label={`First Custom Value ${i + 1}`}>
                        {getFieldDecorator(`firstCustomValue[${i}]`, {
                          rules: [
                            {
                              required: true,
                              whitespace: true,
                              message: "Please select customvalue",
                            },
                          ],
                        })(
                          <Select
                            defaultValue="none"
                            placeholder="Please Select Custom Value"
                            onChange={this.handleCustomValue.bind(this, i)}
                          >
                            {allCustomList &&
                              allCustomList.map((e) => {
                                return (
                                  <Option key={e.fieldId} value={e.fieldId}>
                                    {e.displayName}
                                  </Option>
                                );
                              })}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={5} offset={1}>
                      <Form.Item label={`First Custom Operator ${i + 1}`}>
                        {getFieldDecorator(`firstCustomOperator[${i}]`, {
                          rules: [
                            {
                              required: true,
                              whitespace: true,
                              message: "Please select custom operator",
                            },
                          ],
                        })(
                          <Select
                            defaultValue="none"
                            onChange={this.handleOpeartor}
                            placeholder="Please Select Custom Operator"
                          >
                            <Option value="==">Equal To</Option>
                            <Option value="!=">Not Equal To</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={5} offset={1}>
                      <Form.Item label={`First Field Value ${i + 1}`}>
                        {getFieldDecorator(`firstFieldValue[${i}]`, {
                          rules: [
                            {
                              required: true,
                              message: "Please select field value",
                            },
                          ],
                        })(
                          <Input
                            placeholder="Please input field value"
                            onChange={this.handleMinDistance.bind(this, i)}
                            type="text"
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={5} offset={1}>
                      <Form.Item label={`Join Operator`}>
                        {getFieldDecorator(`joinOperator[${i}]`, {
                          rules: [
                            {
                              required: false,
                              message: "Join two field by following operator",
                            },
                          ],
                        })(
                          <Select
                            defaultValue="none"
                            onChange={this.handleOpeartor}
                            placeholder="Select Join Operator"
                          >
                            <Option value={"&&"}>AND</Option>
                            <Option value="||">OR</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={5} offset={1}>
                      <Form.Item label={`Second Custom Value ${i + 1}`}>
                        {getFieldDecorator(`secondCustomValue[${i}]`, {
                          rules: [
                            {
                              required: false,
                              message: "Please select customvalue",
                            },
                          ],
                        })(
                          <Select
                            defaultValue="none"
                            placeholder="Please Select Custom Value"
                            onChange={this.handleCustomValue.bind(this, i)}
                          >
                            {allCustomList &&
                              allCustomList.map((e) => {
                                return (
                                  <Option key={e.fieldId} value={e.fieldId}>
                                    {e.displayName}
                                  </Option>
                                );
                              })}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={5} offset={1}>
                      <Form.Item label={`Second Custom Operator ${i + 1}`}>
                        {getFieldDecorator(`secondCustomOperator[${i}]`, {
                          rules: [
                            {
                              required: false,
                              message: "Please select custom operator",
                            },
                          ],
                        })(
                          <Select
                            defaultValue="none"
                            onChange={this.handleOpeartor}
                            placeholder="Please Select Custom Operator"
                          >
                            <Option value="==">Equal To</Option>
                            <Option value="!=">Not Equal To</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={5} offset={1}>
                      <Form.Item label={`Second Field Value ${i + 1}`}>
                        {getFieldDecorator(`secondFieldValue[${i}]`, {
                          rules: [
                            {
                              required: false,
                              message: "Please select field value",
                            },
                          ],
                        })(
                          <Input
                            placeholder="Please input field value"
                            onChange={this.handleMinDistance.bind(this, i)}
                            type="text"
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
            </Row>
          ) : (
            ""
          )}
          <Row gutter={16}>
            <Col offset={10} span={8}>
              <Button className="cta" htmlType="submit">
                Submit
              </Button>
              <Button
                className="cta"
                onClick={() =>
                  this.props.history.push({
                    pathname: "/auto-closure",
                    search: this.props.location.search,
                  })
                }
              >
                Back
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

const WrappedAddPlacesForm = Form.create({ name: "register" })(
  AutoClosureConfig
);

export default withRouter(WrappedAddPlacesForm);
