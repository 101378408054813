const setOperatorList = (fieldType) => {
	switch (fieldType) {
		case 'date':
			return [{
				value: "minutes_diff_lt",
				label: "Time diff less than (Min)"
			}, {
				value: "minutes_diff_gt",
				label: "Time diff greater than (Min)"
			}];
		case 'text':
			return [{
				value: "in",
				label: "In (comma separated)"
			}, {
				value: "not_in",
				label: "Not in (comma separated)"
			}, {
				value: "eq",
				label: "Equal to"
			}, {
				value: "ne",
				label: "Not equal to"
			}];
		case 'int':
			return [{
				value: "eq",
				label: "Equal to"
			}, {
				value: "ne",
				label: "Not equal to"
			}, {
				value: "gt",
				label: "Greater than"
			}, {
				value: "gte",
				label: "Greater than equal to"
			}, {
				value: "lt",
				label: "Less than"
			}, {
				value: "lte",
				label: "Less than equal"
			}];
		default:
			return [];
	}
}

export default setOperatorList;
