import React, { PureComponent } from 'react'
import { css } from '@emotion/css'


class Loader extends PureComponent {
  render() {
    return (
    <div className={css`padding-top:120px;padding-bottom:60px;`} >
        <div
        className={css`
        border: 8px solid #a3a3a3;
        border-radius: 50%;
        border-top: 8px solid #F6BF43;
        width: 80px;
        height: 80px;
        margin-left: auto;
        margin-right: auto;
        -webkit-animation: spin 2s linear infinite; /* Safari */
        animation: spin 2s linear infinite;
        /* Safari */
        @-webkit-keyframes spin {
            0% {
            -webkit-transform: rotate(0deg);
            }
            100% {
            -webkit-transform: rotate(360deg);
            }
        }

        @keyframes spin {
            0% {
            transform: rotate(0deg);
            }
            100% {
            transform: rotate(360deg);
            }
        }
        `}
        />
        </div>
    )
  }
}

export default Loader
