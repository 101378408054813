import { Button, Row } from 'antd'
import React from 'react'
import SubHeader from 'utils/subHeader'
import 'containers/routesMaster/App.css'
import { withRouter } from 'react-router'

class AlertTicketUIConfig extends React.Component { 
  
    addAlertAndTicketOnUI = () => {
        this.props.history.push({
        pathname: '/display-alert-ticket/add',
        search: this.props.location.search,
        })
    }
    
    cta = () => {
        return (
        <div>
            <Button style={{ marginLeft: 10 }} size="large" type="primary"
               onClick={this.addAlertAndTicketOnUI}
            > Add Alert And Ticket On UI
            </Button>
        </div>
        )
    }

    render() {
        return (
        <Row>
            <SubHeader innerhtml={this.cta} />
              {this.props.children}
        </Row>
        )
    }
}

export default withRouter(AlertTicketUIConfig)
