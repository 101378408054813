export const alertsName = {
    alerts : [      
        {  value: 'long_stoppage', label:'Long Stoppage' },
        {  value: 'route_deviation', label:'Route deviation' },
        {  value: 'detention', label:'Detention' },
        {  value: 'detention_origin', label:'Detention origin' },
        {  value: 'detention_waypoint', label:'Detention waypoint' },
        {  value: 'detention_destination', label:'Detention Destination' },
        {  value: 'untracked', label:'Untracked' },
        {  value: 'transit_delay', label:'Transit Delay' },
        {  value: 'ewb_expiry', label:'Ewb Expiry' },
        {  value: 'continuous_driving', label:'Continuous Driving' },
        {  value: 'sta_breach', label:'Sta Breach' },
        {  value: 'tracking_interrupted', label:'Tracking Interrupted' }, 
        {  value: 'night_driving', label:'Night Driving' },
        {  value: 'over_speeding', label:'Over Speeding' },
        {  value: 'trip_common_assets_ft', label:'Trip common asset' },
        {  value: 'trip_validation_failure', label:'Trip validation failure' },
        {  value: 'waypoints_breach', label:'Waypoint Breach' },
        {  value: 'diversion', label:'Diversion' },
        {  value: 'temperature_breached', label:'Temperature Breached' },
        {  value: 'driver_change', label:'Driver Change' }
    ]
}

export default alertsName