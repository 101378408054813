import { constructCbdtUrl,convertQsToJson} from 'utils/common'
import request from 'utils/request'
import { message } from 'antd'


const getCbdt = (search) => {
    const parsedDtThis = convertQsToJson(search)
    if(parsedDtThis.companyId){
      const cbdtUrl = constructCbdtUrl('/saas/lego/cbdt',search)
       return request(cbdtUrl,{
        method: 'GET',
      }).then(result => {
        if(result.success){
          return result.data.id
        }else{
          message.error(result.message[0])
        }     
      })
    }  
}

export { getCbdt }
