import React from 'react'
import { Input, Form, message, Button, Row, Col, Divider, DatePicker, Checkbox } from 'antd'
import { css } from '@emotion/css'
import { convertQsToJson } from 'utils/common'
import fetchData from 'utils/fetchData'


const MAP_VIEW_API_URL = window._env_.REACT_APP_ALERT_CONFIG_API_URL
const SATELLIZER_TOKEN = localStorage.getItem('satellizer_token')

class EnableMapView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            companyId: '',
            branchId: '',
            departmentId: '',
            joinLocationsWithArrow: false,
            showTrail: false,
        }
    }

    componentDidMount() {
        const { search } = this.props.location;
        const parsedDt = convertQsToJson(search)
        if (!parsedDt.companyId) {
            this.setState({ companyId: '' })
            message.info('Select a company to continue')
        } else {
            this.setState({ 
                companyId: parsedDt.companyId,
                branchId: parsedDt.branchId,
                departmentId: parsedDt.departmentId 
            }, () => this.fetchMapViewData())
        }
    }

    componentDidUpdate(prevProps) {
        const { search } = this.props.location;
        const prevSearch = prevProps.location.search;

        if (search !== prevSearch) {
          const parsedDt = convertQsToJson(search);
      
          if (parsedDt.companyId) {
            this.setState({ companyId: parsedDt.companyId }, () => {
              if (parsedDt.companyId && parsedDt.branchId) {
                this.setState({ branchId: parsedDt.branchId }, () => {
                  if (parsedDt.companyId && parsedDt.branchId && parsedDt.departmentId) {
                    this.setState({ departmentId: parsedDt.departmentId }, () => {
                      this.fetchMapViewData();
                    });
                  } else {
                    this.fetchMapViewData();
                  }
                });
              } else {
                this.fetchMapViewData();
              }
            });
          } else {
            this.setState({
                companyId: '',
                branchId: '',
                departmentId: '',
                joinLocationsWithArrow: false,
                showTrail: false,
            });
          }
        }
      }
      


    handleSubmit = e => {
        e.preventDefault()
        if(this.state.companyId === "")   {
            message.info('Select a company to save')
        } else {
            this.saveMapViewConfig();
        }
    }

    saveMapViewConfig = (data) => {
        let payload = {
            companyId: this.state.companyId,
            branchId: this.state.branchId ,
            departmentId: this.state.departmentId,
            extraContext: {
                join_locations_with_arrow: this.state.joinLocationsWithArrow,
                show_trail: this.state.showTrail,
            }
        }

        const url = `${MAP_VIEW_API_URL}/v1/lego/feature-permission/map_view/add`
        
        fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${SATELLIZER_TOKEN}`,
            },
            body: JSON.stringify(payload),
        })
        .then(res => res.json())
        .then(response => {
            if (response && response.success) {
                message.success('Saved successfully');
                this.fetchMapViewData();
            } else {
                message.error('Something went wrong...');
            }
        })
        .catch(error => {
            message.error('Something went wrong');
        });
    }

    fetchMapViewData = () => {
        const {companyId, branchId, departmentId} = this.state;
        let queryParams = ''
        if(companyId) queryParams = `companyId=${companyId}`
        if(companyId && branchId) queryParams = `companyId=${companyId}&branchId=${branchId}`
        if(companyId && branchId && departmentId) queryParams = `companyId=${companyId}&branchId=${branchId}&departmentId=${departmentId}`
        
        if(queryParams != ''){
            const url =  `${MAP_VIEW_API_URL}/v1/lego/feature-permission/map_view/get?${queryParams}`
            const headers = {
                'Authorization': `Bearer ${SATELLIZER_TOKEN}`,
            }
            fetchData(url, 'GET', {}, headers).then(res => {
                if(res && res.data && res.data.length > 0){
                    let extraContext = JSON.parse(res.data[0].extraContext)
                    this.setState({
                        joinLocationsWithArrow: extraContext.join_locations_with_arrow,
                        showTrail: extraContext.show_trail
                    })
                } else {
                    this.setState({
                        joinLocationsWithArrow: false,
                        showTrail: false
                    })
                }
            }).catch (error => this.setState({
                joinLocationsWithArrow: false,
                showTrail: false
            }))
        }
    }

    handleOnChange(type,value){
        if(type === "join_location_with_arrow") this.setState({joinLocationsWithArrow: value})
        if(type === "show_trail") this.setState({showTrail: value})
    }

    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <div>
                <Row
                    span={20}
                    className={css`
                    padding: 20px;
                    background: #fff;
                    border-bottom: 1px solid #e8e8e8;
                    `}
                    gutter={16}>
                    <Form>
                        <Row>
                            <Col style={{margin: "10px"}} span={6}>
                                <label>
                                    <h3>Map View Setting</h3>
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{margin: "10px"}} span={6}>
                                <label>
                                    Join Locations With Arrow :
                                    <span style={{"margin-left": "20px"}}>
                                        <Checkbox checked={this.state.joinLocationsWithArrow} onChange={(e) => this.handleOnChange("join_location_with_arrow", e.target.checked)} />
                                    </span>
                                </label>
                            </Col>
                            <Col style={{margin: "10px"}} span={3}>
                                <label>
                                    Show Trail :
                                    <span style={{"margin-left": "20px"}}>
                                        <Checkbox checked={this.state.showTrail} onChange={(e) => this.handleOnChange('show_trail',e.target.checked )} />
                                    </span>
                                </label>
                            </Col>
                            <Col span={3}></Col>
                            <Col style={{"margin-left": "25px" }} span={3}>
                                <Button
                                    type="primary"
                                    size="large"
                                    htmlType='submit'
                                    onClick={(e) => this.handleSubmit(e)}
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Row>
            </div >
        )
    }
}

const Enable_Map_view_Form = Form.create({ name: 'Enable_Map_view_Form' })(EnableMapView)
export default Enable_Map_view_Form