import React from 'react'
import { Avatar } from 'antd'
import { onLogout } from 'utils/storage'
import { css } from '@emotion/css'

const logout = () => {
  onLogout()
  window.location.href = '/'
}

const User = ({ name }) => {
  return (
    <div className={css`float:right`}>
      <Avatar size="large" icon="user" />
      <div style={{ 
        color:'#FFF',
        float: 'right',
        padding: '0px 8px 0px 24px',
        marginTop:'-10px'
      }}>
        <span >{name}</span>
      </div>
      <div style={{
         color:'#FFF', 
         cursor: 'pointer',
         color:'#1890ff',
         float: 'right',
         padding: '16px 4px 0px 2px',
         marginRight:'-80px',
         marginBottom:'-80px'
      }}>
        <span onClick={() => logout()} >Logout  </span>
      </div>
    </div>
  )
}

export default User
