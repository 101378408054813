import React from 'react'
import { Table, Select, Popconfirm, Form, Row, Button, Modal, Input, message, Icon } from 'antd'
import Highlighter from 'react-highlight-words'
import fetchData from 'utils/fetchData'
import { sortListBasedOnKey } from 'utils/common'
import { css } from '@emotion/css'

const Notification_API = window._env_.REACT_APP_NOTIFICATION_API_URL
const Option = Select.Option
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
}

const FormItem = Form.Item
const EditableContext = React.createContext()

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
)

const EditableFormRow = Form.create()(EditableRow)

class EditableCell extends React.Component {
  render() {
    const { editing, dataIndex, title, inputType, record, index, ...restProps } = this.props
    return (
      <EditableContext.Consumer>
        {form => {
          const { getFieldDecorator } = form
          return (
            <td {...restProps}>
              {editing ? (
                <FormItem style={{ margin: 0 }}>
                  {getFieldDecorator(dataIndex, {
                    rules: [
                      {
                        required: true,
                        message: `Please Input ${title}!`,
                      },
                    ],
                    initialValue: record[dataIndex],
                  })(
                    <Select
                      style={{ width: 160 }}
                      onChange={this.handleChange}
                      placeholder="Status"
                    >
                      <Option value="ONBOARDED">ONBOARDED</Option>
                      <Option value="PENDING">PENDING</Option>
                    </Select>
                  )}
                </FormItem>
              ) : (
                restProps.children
              )}
            </td>
          )
        }}
      </EditableContext.Consumer>
    )
  }
}

class EventList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      eventData: [],
      editingKey: '',
      searchText: '',
      eventCreated: false,
      filterStatus: [],
    }
    this.columns = [
      {
        title: 'Event Name',
        dataIndex: 'name',
        width: '33%',
        editable: false,
        ...this.getColumnSearchProps('name'),
      },
      {
        title: 'Status',
        dataIndex: 'event_status',
        width: '33%',
        editable: true,
        filters: [{ text: 'ONBOARDED', value: 'ONBOARDED' }, { text: 'PENDING', value: 'PENDING' }],
      },

      {
        title: 'operation',
        dataIndex: 'operation',
        render: (text, record) => {
          const editable = this.isEditing(record)
          return (
            <div>
              {editable ? (
                <span>
                  <EditableContext.Consumer>
                    {form => <Button onClick={() => this.save(form, record)}>Save</Button>}
                  </EditableContext.Consumer>
                  <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.id)}>
                    <Button>Cancel</Button>
                  </Popconfirm>
                </span>
              ) : (
                <Button
                  disabled={record.event_status === 'ONBOARDED'}
                  onClick={() => this.edit(record.id)}
                >
                  Edit
                </Button>
              )}
            </div>
          )
        },
      },
    ]
  }
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = clearFilters => {
    clearFilters()
    this.setState({ searchText: '' })
  }
  async componentDidMount() {
    const resp = await this.fetchAllEvent()
    if (resp && resp.value) {
      this.setState({ eventData: resp.value, allData: resp.value })
    }
  }
  async fetchAllEvent() {
    return await fetchData(`${Notification_API}/notification/events`)
      .then(result => {
        if(result && result.value){
          sortListBasedOnKey(result.value, 'name')
        }
        return result;
      })
      .catch(function(result) {
        console.log(result)
      })
  }

  isEditing = record => record.id === this.state.editingKey

  cancel = () => {
    this.setState({ editingKey: '', visible: false })
  }

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return
      }
      const newData = [...this.state.eventData]
      const index = newData.findIndex(item => key.id === item.id)
      if (index > -1) {
        const item = newData[index]
        newData.splice(index, 1, {
          ...item,
          ...row,
        })
        this.setState({ eventData: newData, editingKey: '' })
        const data = {
          id: key.id,
          event_status: row.event_status,
        }
        this.createEvent(data)
      }
    })
  }
  showModal = () => {
    this.props.form.resetFields()
    this.setState({
      visible: true,
    })
  }
  handleCancel = e => {
    this.setState({
      visible: false,
      eventCreated: false,
    })
  }
  createEvent(eventData) {
    let eventdata
    if (eventData.name) {
      eventdata = {
        name: eventData.name,
      }
    }
    if (eventData.event_status) {
      eventdata = {
        id: eventData.id,
        event_status: eventData.event_status,
      }
    }

    fetchData(`${Notification_API}/notification/save-event`,'POST',eventdata).then(async res => {
        if(res){
          const resp = await this.fetchAllEvent()
          this.setState({ eventData: resp.value, allData: resp.value })
          this.setState({ visible: false })
          if (eventData.name) {
            message.info('Event added successfully')
          } else {
            message.info('Event updated successfully')
            let status = this.state.filterStatus
            if (this.state.filterStatus.length === 1) {
              let filterArray = resp.value.filter(item => item.event_status === status[0])
              this.setState({ eventData: filterArray })
            }
          }
        }
        if (res.status === 500) {
          this.setState({ eventCreated: true })
        }
      })
      .catch(err => console.log(err))
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.createEvent(values)
      }
    })
  }
  edit(key) {
    this.setState({ editingKey: key })
  }

  handleTableChange = async (pagination, filters, sorter) => {
    let tempdata = this.state.allData
    if (filters.event_status) {
      const tempStatus = filters.event_status
      this.setState({ filterStatus: tempStatus })
      if (filters.event_status.length === 1) {
        let filterArray = tempdata.filter(item => item.event_status === filters.event_status[0])
        this.setState({ eventData: filterArray })
      } else {
        this.setState({ eventData: tempdata })
      }
    }
  }
  render() {
    const { getFieldDecorator } = this.props.form
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    }

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col
      }
      return {
        ...col,
        onCell: record => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      }
    })

    return (
      <div>
        <Row style={{ padding: '10px 10px 10px 22px' }}>
          <Button onClick={this.showModal} size="large" type="primary">
            Create Event
          </Button>
        </Row>
        <Modal
          footer={null}
          title="Creat Event"
          visible={this.state.visible}
          onCancel={this.handleCancel}
        >
          <Form onSubmit={this.handleSubmit}>
            <Form.Item label="Event Name" {...formItemLayout}>
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter event name',
                  },
                  {
                    pattern: /^[a-zA-Z0-9.]*$/,
                    message: 'Please enter valid name',
                  },
                  {
                    pattern: /^.{3,}$/,
                    message: 'Please enter atleast 3 character',
                  },
                ],
              })(<Input size="large" />)}

              {this.state.eventCreated ? (
                <span style={{ color: 'red' }}>Event is already created</span>
              ) : null}
            </Form.Item>
            <Form.Item
              className={css`
                text-align: center;
              `}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Table
          components={components}
          bordered={true}
          dataSource={this.state.eventData}
          columns={columns}
          rowKey={record => record.id}
          rowClassName="editable-row"
          pagination={{
            onChange: this.cancel,
          }}
          onChange={this.handleTableChange}
        />
      </div>
    )
  }
}

const editRouteForm = Form.create({ name: 'create_event' })(EventList)
export default (EventList, editRouteForm)
