import React from 'react'
import { Form, Input, Button, Upload, Icon, Row, Col, Divider, message } from 'antd'
import request from 'utils/request'
import { css } from '@emotion/css'
const { TextArea } = Input

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
}

class AddTransporters extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      transporter: {},
      edit: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentWillMount() {
    if (this.props.match.params.id) {
      this.setState({ edit: true })
    }
  }

  componentDidMount() {
    if (this.state.edit) {
      this.fetchTransporter(this.props.match.params.id)
    }
  }

  fetchTransporter(id) {
    request(`${window._env_.REACT_APP_API_URL}/transporters/${id}`).then(
      result => {
        if (result.success) {
          this.setState({
            transporter: result,
          })
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        message.error('Error while fetching details')
      }
    )
  }

  addTransporter(data) {
    request(`${window._env_.REACT_APP_API_URL}/transporters`, {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(
      result => {
        if (result.success) {
          this.props.history.push('/transporters')
          message.success('Saved successfully')
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        message.error('Error while deleting')
      }
    )
  }

  updateTransporter(id) {
    request(`${window._env_.REACT_APP_API_URL}/transporters/${id}`, {
      method: 'patch',
      body: JSON.stringify({ id: id }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(
      result => {
        if (result.success) {
          this.props.history.push('/transporters')
          message.success('Saved successfully')
        } else {
          result.message.map(msg => message.error(msg))
        }
      },
      error => {
        message.error('Error while deleting')
      }
    )
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values)
        this.addTransporter(values)
      }
    })
  }

  normFile = e => {
    console.log('Upload event:', e)
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <div
        className={css`
          margin-top: 30px;
        `}
      >
        <Row gutter={16}>
          <Col span={16}>
            <Form onSubmit={this.handleSubmit}>
              <Form.Item {...formItemLayout} label="Transporter Name">
                {getFieldDecorator('transporterName', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter transporter name',
                    },
                  ],
                  initialValue: this.state.transporter.transporterName,
                })(<Input />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="Upload Logo">
                {getFieldDecorator('upload', {
                  valuePropName: 'fileList',
                  getValueFromEvent: this.normFile,
                })(
                  <Upload name="logo" action="/upload.do" listType="picture">
                    <Button>
                      <Icon type="upload" /> Click to upload
                    </Button>
                  </Upload>
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="Branch Name">
                {getFieldDecorator('branch', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter branch name',
                    },
                  ],
                  initialValue: this.state.transporter.branch,
                })(<Input />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="Address">
                {getFieldDecorator('address', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter address',
                    },
                  ],
                  initialValue: this.state.transporter.address,
                })(<TextArea row={6} />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="State">
                {getFieldDecorator('state', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter state',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="City">
                {getFieldDecorator('city', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter city',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <p>CONTACT INFO</p>
              <Divider />
              <Form.Item {...formItemLayout} label="Person Name">
                {getFieldDecorator('contactPerson', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter your name',
                    },
                  ],
                  initialValue: this.state.transporter.contactPerson,
                })(<Input />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="Mobile No.">
                {getFieldDecorator('phoneNumber', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter mobile number',
                    },
                  ],
                  initialValue: this.state.transporter.phoneNumber,
                })(<Input />)}
              </Form.Item>
              <Form.Item
                className={css`
                  text-align: center;
                `}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    )
  }
}

const AddTransportersForm = Form.create()(AddTransporters)
export default (AddTransporters, AddTransportersForm)
