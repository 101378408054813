import React from 'react'
import { CSVLink } from 'react-csv'
import { Button, Row, Col, Icon, message } from 'antd'
import { HotTable } from '@handsontable/react'
import _ from 'lodash'
import request from 'utils/request'
import GeoUtil from 'utils/geoUtils'
import { addressFromLatLng } from 'utils/geocode'
import { convertQsToJson, constructUrl } from 'utils/common'
import { geocodeByAddress } from 'react-places-autocomplete'
import 'handsontable-pro/dist/handsontable.full.css'
import ImportFileComponent from 'components/common/ImportFileComponent'
import { geocodeByPlaceIdFix } from 'utils/geocodeByPlaceIdFix'

const google = window.google

class KnownPlacesUpload extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      downloadData: [],
      isLoaded: false,
      placesData: [[]],
      autocompleteData: [],
      autocompleteDataPlaceId: [],
      company: null,
      branch: null,
      department: null,
      atLeastOneSuccess: false,
      ImportFileComponentKey: Date.now(),
    }

    this.displaySuggestions = this.displaySuggestions.bind(this)
  }

  componentDidMount() {
    const parsedDt = convertQsToJson(this.props.location.search)
    const cId = parsedDt.companyId || ''
    const bId = parsedDt.branchId || ''
    const dId = parsedDt.departmentId || ''

    this.setState({
      company: cId,
      branch: bId,
      department: dId,
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search === '') {
      this.setState({ routesData: [] })
    }

    const parsedDt = convertQsToJson(nextProps.location.search)
    const cId = parsedDt.companyId || ''
    const bId = parsedDt.branchId || ''
    const dId = parsedDt.departmentId || ''

    this.setState({
      company: cId,
      branch: bId,
      department: dId,
    })
  }

  async saveKnownPlaces() {
    if (!this.state.company) {
      message.error('Please select a company before saving')
      return
    }

    const data = this.refs.hot.hotInstance.getData()

    if (data.length == 1 && data[0].filter(d => d !== null).length == 0) {
      message.error('No data to upload, upload a file or paste some data')
      return
    }

    const parsedDt = convertQsToJson(this.props.location.search)

    if (
      Object.keys(parsedDt).length > 1 &&
      parsedDt.companyType == 'TRANSPORTER' &&
      _.isEmpty(parsedDt.consignorId)
    ) {
      message.error('Please select a consignor to proceed')
      return
    }

    const createUrl = constructUrl('/saas/lego/company_places/create', this.props.location.search, {
      bulkUpload: true,
      allOrNone: false,
    })
    const url = createUrl

    let addKnownPlaceData = _.map(data, s => {
      return {
        google: { place_id: s[7] },
        label: s[1],
        address: s[2],
        cpCode: s[0],
        geofenceRadius: s[6],
      }
    })

    addKnownPlaceData = addKnownPlaceData.filter(s => s.google.place_id !== null)

    await request(url, {
      method: 'POST',
      body: JSON.stringify(addKnownPlaceData),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(result => {
      const res = result.data
      const hot = this.refs.hot.hotInstance
      const hotData = hot.getData()

      let i = 0
      while (i < hotData.length) {
        if (hot.isEmptyRow(hotData[i])) {
          i++
        } else {
          if (res[i] && res[i].cpId) {
            hot.setDataAtCell(i, 8, 'Success')
            this.setState({ atLeastOneSuccess: true })
          } else {
            const d = hot.getDataAtCell(i, 8)
            let str = res[i];
            try {
              str = str.map(s => {
                return s.replace(/cpCode/, 'Code')
              })
            } catch (err) {
              str = res[i];
            }
            hot.setDataAtCell(i, 8, str || d)
          }
          i++
        }
      }
    })
  }

  async updateLatLng(d) {
    let i = 1

    const hot = this.refs.hot.hotInstance
    if (hot.isEmptyRow(d[1])) {
      message.error('Please add some data to the csv before uploading')
      return
    }

    while (i < d.length) {
      const tempRow = d[i]
      if (d[i][3] === '') {
        const { placeId } = await addressFromLatLng(d[i][4], d[i][5])
        tempRow[7] = placeId
      } else {
        await geocodeByAddress(d[i][3])
          .then(results => {
            const { place_id, geometry } = results[0]

            if (
              d[i][4] !== '' &&
              d[i][4] !== undefined &&
              (d[i][5] !== '' && d[i][5] !== undefined)
            ) {
              tempRow[4] = d[i][4]
              tempRow[5] = d[i][5]
            } else {
              tempRow[4] = geometry.location.lat()
              tempRow[5] = geometry.location.lng()
            }

            const distanceBetween = GeoUtil.distance(
              parseFloat(geometry.location.lat()),
              parseFloat(geometry.location.lng()),
              parseFloat(d[i][4]),
              parseFloat(d[i][5])
            )

            if (distanceBetween > 30) {
              tempRow[8] = 'Error: Distance more than 30kms'
            } else {
              tempRow[7] = place_id
            }
          })
          .catch(error => {
            tempRow[8] = `Error: ${error}`
          })
      }

      if(this.state.placesData.length == 1 && this.state.placesData[0].length == 0){
        this.setState({
          placesData: [...this.state.placesData[0], tempRow],
        })
      } else {
        this.setState({
          placesData: this.state.placesData.concat([tempRow]),
        })
      }
      
      i++
    }
  }

  displaySuggestions(predictions, status) {
    if (status !== google.maps.places.PlacesServiceStatus.OK) {
      return
    }
    const arr = []
    const arrPlaceId = []
    predictions.forEach(function(prediction) {
      arr.push(prediction.description)
      arrPlaceId.push(prediction.place_id)
    })
    this.setState({ autocompleteData: arr, autocompleteDataPlaceId: arrPlaceId })
  }

  removeSuccess() {
    let data = this.refs.hot.hotInstance.getData()
    data = data.filter(s => s[8] !== 'Success')
    this.setState({
      placesData: data,
    })
  }

  resetData() {
    this.setState({
      placesData: [[]],
      ImportFileComponentKey: Date.now(),
    })
  }

  cta() {
    return (
      <div>
        <Col xs={12}>
          <div style={{ float: 'left' }}>
            <ImportFileComponent
              handleParse={d => this.updateLatLng(d)}
              inputKey={this.state.ImportFileComponentKey}
            />
          </div>
          <Button type="primary" onClick={() => this.saveKnownPlaces()}>
            Submit
          </Button>
        </Col>
        <Col xs={12} style={{ textAlign: 'right' }}>
          {this.state.atLeastOneSuccess && (
            <a href="javascript:void(0);" onClick={() => this.removeSuccess()}>
              <Icon type="cross" />
              Remove Success
            </a>
          )}
          <CSVLink
            style={{ marginLeft: '20px' }}
            headers={[
              'Code',
              'Address Label',
              'Address',
              'Google Address',
              'Latitude',
              'Longitude',
              'Geofence Radius',
            ]}
            data={this.state.downloadData}
            filename={'known-places.csv'}
            onClick={() => {
              this.setState({
                downloadData: this.refs.hot.hotInstance.getData(),
              })
            }}
          >
            <Icon type="download" /> Download
          </CSVLink>
          <Button type="primary" style={{ marginLeft: '20px' }} onClick={() => this.resetData()}>
            <Icon type="undo" /> Reset
          </Button>
        </Col>
      </div>
    )
  }

  render() {
    const { placesData } = this.state
    const placesDataCopy = [...placesData] // this is required to be done because when passing state object directly, HOT was adding a blank object after every row
    return (
      <div>
        <div style={{ display: 'none' }} id="bharghav" />
        <Row style={{ padding: '20px 20px 0 20px' }}>{this.cta()}</Row>
        <Row style={{ padding: '20px' }}>
          <HotTable
            data={placesDataCopy}
            colHeaders={[
              '<p class="tooltip" title="Place code">Code</p>',
              'Address Label',
              'Address',
              'Google Address',
              'Latitude',
              'Longitude',
              'Geofence Radius',
              '<span style="color: #f0f0f0">Geocoding Status<span>',
              'Success Status',
            ]}
            colWidths={[50, 50, 50, 50, 50, 50, 50, 0.1, 50]}
            manualColumnResize={true}
            maxRows={600}
            manualRowResize={true}
            columns={[
              {},
              {},
              {},
              {
                type: 'autocomplete',
                source: async (query, process) => {
                  const service = new google.maps.places.AutocompleteService()
                  if (query.length > 0) {
                    await service.getQueryPredictions({ input: query }, this.displaySuggestions)
                  }

                  process(this.state.autocompleteData)
                },
                strict: true,
              },
              {},
              {},
              {},
              {},
              {
                readOnly: true,
                renderer: (instance, td, row, col, prop, value, cellProperties) => {
                  td.style.background = '#dddddd'
                  if (value === 'Success') {
                    td.style.color = 'green'
                  } else if (value) {
                    td.style.color = 'red'
                  }
                  td.innerHTML = value
                  return td
                },
              },
            ]}
            rowHeaders={true}
            minSpareRows={1}
            minRows={1}
            ref="hot"
            width={1200}
            stretchH="all"
            afterChange={async (changes, src) => {
              if (src == 'CopyPaste.paste' || src == 'edit') {
                //both address and lat lng changes. Basically a row was pasted

                if (
                  changes[3] !== undefined &&
                  changes[4] !== undefined &&
                  changes[5] !== undefined &&
                  changes[3][3] !== '\n' &&
                  changes[5][3] !== '' &&
                  changes[4][3] !== '\n' &&
                  changes[5][3] !== '\n' &&
                  changes[4][3] !== '' &&
                  changes[5][3] !== ''
                ) {
                  const addressllChanges = changes.filter(c => {
                    return c[1] == 3
                  })
                  for (let i = 0; i < addressllChanges.length; i++) {
                    const data = this.refs.hot.hotInstance.getData()
                    const row = data[addressllChanges[i][0]]
                    data[addressllChanges[i][0]][8] = ''

                    await geocodeByAddress(addressllChanges[i][3])
                      .then(results => {
                        const { place_id, geometry } = results[0]

                        const distanceBetween = GeoUtil.distance(
                          parseFloat(geometry.location.lat()),
                          parseFloat(geometry.location.lng()),
                          parseFloat(row[4]),
                          parseFloat(row[5])
                        )

                        if (distanceBetween > 30) {
                          data[addressllChanges[i][0]][8] = 'Error: Distance more than 30kms'
                        } else {
                          data[addressllChanges[i][0]][8] = null
                          data[addressllChanges[i][0]][7] = place_id
                        }
                      })
                      .catch(error => {
                        data[addressllChanges[i][0]][8] = `Error: ${error}`
                      })
                    this.setState({
                      placesData: data,
                    })
                  }

                  return
                }

                const addressChanges = changes.filter(c => {
                  return c[1] == 3
                })
                for (let i = 0; i < addressChanges.length; i++) {
                  const data = this.refs.hot.hotInstance.getData()
                  const row = data[addressChanges[i][0]]

                  if (row[3] == '' || row[3] == undefined || row[3] == null || row[3] == '\n') {
                    const { placeId } = await addressFromLatLng(row[4], row[5])
                    data[addressChanges[i][0]][8] = null
                    data[addressChanges[i][0]][7] = placeId
                  } else {
                    const placeIdIndex = this.state.autocompleteData.indexOf(addressChanges[i][3])
                    await geocodeByPlaceIdFix(this.state.autocompleteDataPlaceId[placeIdIndex], [
                      'name',
                      'geometry',
                      'place_id',
                      'formatted_address',
                    ])
                      .then(results => {
                        const { place_id, geometry, formatted_address, name } = results

                        // if lat long not blank
                        if (
                          row[4] !== '' &&
                          row[4] !== undefined &&
                          row[4] !== null &&
                          row[4] !== '\n' &&
                          (row[5] !== '' &&
                            row[5] !== undefined &&
                            row[5] !== null &&
                            row[5] !== '\n')
                        ) {
                          // data[addressChanges[i][0]][4] = row[4]
                          // data[addressChanges[i][0]][5] = row[5]
                          data[addressChanges[i][0]][1] = name
                          data[addressChanges[i][0]][2] = formatted_address
                          data[addressChanges[i][0]][4] = geometry.location.lat()
                          data[addressChanges[i][0]][5] = geometry.location.lng()
                          data[addressChanges[i][0]][8] = null
                          data[addressChanges[i][0]][7] = place_id
                        } else {
                          data[addressChanges[i][0]][1] = name
                          data[addressChanges[i][0]][2] = formatted_address
                          data[addressChanges[i][0]][4] = geometry.location.lat()
                          data[addressChanges[i][0]][5] = geometry.location.lng()
                          data[addressChanges[i][0]][8] = null
                          data[addressChanges[i][0]][7] = place_id
                        }
                      })
                      .catch(error => {
                        data[addressChanges[i][0]][8] = `Error: ${error}`
                      })
                  }
                  this.setState({
                    placesData: data,
                  })
                }

                const llchanges = changes.filter(c => {
                  return c[1] == 4 || c[1] == 5
                })

                for (let i = 0; i < llchanges.length; i++) {
                  const data = this.refs.hot.hotInstance.getData()
                  const row = data[llchanges[i][0]]

                  if (row[4] && row[5]) {
                    const { placeId, address } = await addressFromLatLng(row[4], row[5])
                    data[llchanges[i][0]][7] = placeId
                    data[llchanges[i][0]][3] = address
                  } else {
                    console.log('either lat or long is missing')
                  }

                  this.setState({
                    placesData: data,
                  })
                }
              }
            }}
          />
        </Row>
      </div>
    )
  }
}

// 0: (4) ["Display address", "address", "latitude", "longitude"]
// 1: (4) ["KOR1", "Koramangala, Bengaluru", "", ""]
// 2: (4) ["IND1", "Indiranagar, Bengaluru", "", ""]
// 3: (4) ["HSR1", "HSR Layout, Bengaluru", "", ""]
// 4: (4) ["JAM1", "Whitefield, Bengaluru", "", ""]
export default KnownPlacesUpload
