import React from 'react'
import readXlsxFile from 'read-excel-file'

const ImportFileComponent = props => {
  let fileReader

  const handleFileRead = () => {
    let arr = []
    let content = fileReader.result
    content = content.split('\n')
    content.forEach(row => {
      const sr = row.split(/,(?=(?:[^"]|"[^"]*")*$)/)
      const newsr = []
      sr.forEach(element => {
        newsr.push(element.replace(/(^"|"$)/g, ''))
      })
      arr.push(newsr)
    })
    props.handleParse(arr)
  }

  const handleFile = (filepath, file) => {
    const fileType = filepath.split('.').pop()

    switch (fileType) {
      case 'xlsx':
        readXlsxFile(file).then(rows => {
          props.handleParse(rows)
        })

        break
      case 'csv':
        fileReader = new FileReader()
        fileReader.onloadend = handleFileRead
        fileReader.readAsText(file)
    }
  }

  return (
    <input
      type="file"
      onChange={e => handleFile(e.target.value, e.target.files[0])}
      key={props.inputKey}
    />
  )
}

export default ImportFileComponent
