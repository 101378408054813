import React from 'react'
import { Row, Button, Table, Checkbox, Modal, message } from 'antd'
import { convertQsToJson, sortListBasedOnKey } from 'utils/common'
import fetchData from 'utils/fetchData'
const Notification_API = window._env_.REACT_APP_NOTIFICATION_API_URL
class ConfigurationList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      allConfiguration: [],
      checked: false,
      cId: '',
      bId: '',
      dId: '',
      uId: '',
      gId: '',
      rcId: '',
      rbId: '',
      rdId: '',
      visible: false,
      modeToggle: '',
      customerConfigId: '',
    }
  }
  qureryParams = queryData => {
    const parsedDt = convertQsToJson(queryData)
    const cId = parsedDt.companyId || ''
    const bId = parsedDt.branchId || ''
    const dId = parsedDt.departmentId || ''
    const uId = parsedDt.uID || ''
    const gId = parsedDt.gID || ''
    const rcId = parsedDt.relatedCompanyId || ''
    const rbId = parsedDt.relatedBranchId || ''
    const rdId = parsedDt.relatedDepartmentId || ''
    this.setState({ cId, bId, dId, uId, gId, rcId, rbId, rdId }, () =>
      this.fetchAllconfigurations().then(resp => {
        if (resp && resp.value) {
          this.setState({ allConfiguration: resp.value })
        }
      })
    )
  }
  componentWillReceiveProps(nextProps) {
    let queryData = nextProps.location.search
    this.qureryParams(queryData)
  }

  componentDidMount() {
    const parsedDt = convertQsToJson(this.props.location.search)
    if (!parsedDt.companyId) {
      message.info('Select a company to continue')
    }else{
      let queryData = this.props.location.search
      this.qureryParams(queryData)
    }
  }

  convertQsToJson(str) {
    let pairs = str.slice(1).split('&')
    let result = {}
    pairs.forEach(function(pair) {
      pair = pair.split('=')
      result[pair[0]] = decodeURIComponent(pair[1] || '')
    })
    return JSON.parse(JSON.stringify(result))
  }
  gotoEditConfigForm = data => {
    let qs = ''
    if (this.props.location.search.length > 0) {
      qs = `${this.props.location.search}`
    }
    this.props.history.push({
      pathname: `/configuration-list/edit-notification-config/${data.customerConfigId}`,
      search: qs,
    })
  }

  handleOnChange = data => {
    this.setState({
      visible: true,
      modeToggle: data.isEnabled,
      customerConfigId: data.customerConfigId,
    })
  }

  
  handleCancel = () => {
    this.setState({ visible: false })
  }
  handleNotification = () => {
    fetchData(`${Notification_API}/notification/toggle-config/${this.state.customerConfigId}`,'POST')
      .then(res => {
        if (res) {
          this.setState({ visible: false })
          this.fetchAllconfigurations().then(resp => {
            this.setState({ allConfiguration: resp.value })
          })
        }
      })
      .catch(err => console.log(err))
  }
  fetchAllconfigurations() {
    return fetchData(
      `${Notification_API}/notification/configurations?company_id=${this.state.cId}&branch_id=${
        this.state.bId
      }&department_id=${this.state.dId}&user_id=${this.state.uId}&group_id=${this.state.gId}&related_company_id=${this.state.rcId}&related_branch_id=${this.state.rbId}&related_department_id=${this.state.rdId}`
    )
      .then(result => {
        if(result && result.value){
          sortListBasedOnKey(result.value, 'eventName')
        }
        return result;
      })
      .catch(function(result) {
        console.log(result)
      })
  }
  render() {
    const columns = [
      {
        title: 'Event',
        key: 'eventName',
        dataIndex: 'eventName',
      },
      {
        title: 'Enable Notificaton',
        key: 'isEnabled',
        render: (text, record, i) => {
          return (
            <Checkbox checked={record.isEnabled} onChange={() => this.handleOnChange(record)} />
          )
        },
      },
      {
        title: 'Mode',
        key: 'mode',
        dataIndex: 'mode',
      },

      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Button onClick={() => this.gotoEditConfigForm(record)}>Edit</Button>
          </span>
        ),
      },
    ]

    return (
      <div>
        <Row style={{ padding: '10px 10px 10px 22px' }} />
        <Row>
          <Table
            columns={columns}
            dataSource={this.state.allConfiguration}
            rowKey={record => record.customerConfigId}
          />
        </Row>
        <Modal
          width={450}
          height={450}
          footer={null}
          title="Enable Configuration"
          visible={this.state.visible}
          onCancel={this.handleCancel}
        >
          <p>
            Are you sure want to{' '}
            {this.state.modeToggle ? <span>disable</span> : <span>enable</span>} notification?
          </p>
          <div style={{ textAlign: 'right' }}>
            <Button onClick={() => this.handleNotification()}>Yes</Button>
            <Button onClick={this.handleCancel}>No</Button>
          </div>
        </Modal>
      </div>
    )
  }
}
export default ConfigurationList
