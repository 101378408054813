import { Form, Select,Button, Row, Col, message } from "antd";
import React from "react";
import "containers/routesMaster/App.css";
import { withRouter } from "react-router";
import { convertQsToJson } from "utils/common";
import fetchData from "utils/fetchData";
import 'components/sidebarmenu/sidebarmenu.scss';
const Option = Select.Option
const TRANSDB_API = window._env_.REACT_APP_TRANSDB_API_URL;
class AlertTicketUIConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      customerConfigId: null,
      config:[],
      fetchCompleteList: [],
      fetchAlertList:[],
      featurekey:'',
      alertType:'',
      id:null
    };
  }
  
  componentDidMount() {
    const { search } = this.props.location;
    const parsedDt = convertQsToJson(search);
    if (!parsedDt.companyId) {
      message.info("Select a company to continue");
    } else {
      this.fetchAlertList();
      if (this.props.match.params.id) {
        this.setState({edit:true},async() => {
         await this.fetchUIConfigById(this.props.match.params.id);
        })
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { search } = this.props.location;
    const prevSearch = prevProps.location.search;
    if (search !== prevSearch) {
      this.fetchAlertList();
      const parsedDt = convertQsToJson(search);
      if (parsedDt.companyId) {
        this.setState({edit:true},async() => {
          await this.fetchUIConfigById(this.props.match.params.id);
         })
      }
    }
  }

  saveUIConfig = (data) => {
    let url = `${TRANSDB_API}saas/data/onBoardingAPI/updateUIConfig?`;
    const { search } = this.props.location;
    const { edit, customerConfigId } = this.state;
    const parsedDt = convertQsToJson(search);
    if (!parsedDt.companyId) {
      return message.error("Select a company to continue");
    }
    let payload = {
      companyId: parsedDt.companyId || "",
      branchId: parsedDt.branchId || "",
      extracontext: data.config.map((config) => config),
      featureKey: data.featurekey ? data.featurekey : null,
      enabled: 1,
    };
    if(edit){
      payload.id = customerConfigId;
    }
    fetchData(url, "POST", payload)
      .then((res) => {
        if (res.status == "ok") {
          message.success(res.result.message);
        } else {
          message.error(res.result.message);
        }
        this.props.history.push({
          pathname: "/display-alert-ticket",
          search: this.props.location.search,
        });
      })
      .catch((error) => {
        message.error(error);
      });
  };

  fetchUIConfigById = async(id,featureKey) => {
    const { search } = this.props.location;
    const parsedDt = convertQsToJson(search);
    const url = `${TRANSDB_API}saas/data/onBoardingAPI/fetchUIConfigById?`;
    let payload = {
      id: id || null,
      companyId: parsedDt.companyId || "",
      branchId: parsedDt.branchId || "",
      featureKey:featureKey ||""
    };
    await fetchData(url, "GET", payload)
      .then((res) => {
        if(res && res.result && (res.result.success == true)){
          const { extraContext , featureKey } = res.result;
          if(extraContext && featureKey){
            this.props.form.setFieldsValue({
              config: extraContext,
              featurekey:featureKey,
              customerConfigId:id
            });
            this.setState({
              config: extraContext,
              featurekey:featureKey,
              customerConfigId:id
            });
            return res.result;
          }
        }
    })
    .catch((error) => {
        message.error(error);
    });
  };

  fetchAlertList= () => {
    const url = `${TRANSDB_API}saas/data/onBoardingAPI/fetchAlertList?`;
    fetchData(url, "GET")
      .then((res) => {
        if(res.status == "ok") {
          const alertList= res.result.data;
          this.setState({
            fetchAlertList: alertList
          });
        }
    })
    .catch((error) => {
        message.error(error);
    });
  };

  handleAlertType = (value) => {
    let id = null;
    this.setState({ alertType:value },() => {
       this.fetchUIConfigById(id,value);
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.saveUIConfig(values)
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { edit,fetchAlertList} = this.state;
    const { search } = this.props.location;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    return (
      <div className='sidebarForm' id='bharghav'>
          <Row> 
             <h2 className="headingMargin">
              {edit ? "Update UI Config" : "Set UI Config"}
            </h2>
            <Form {...formItemLayout} onSubmit={this.handleSubmit}>
            <Col span={12} offset={5}>
            <Row> 
              <Form.Item label='Set featurekey'>
                    {getFieldDecorator("featurekey", {
                      rules: [
                        {
                          required: true,
                          message: "Please select field"
                        },
                      ],
                    })(
                    <Select disabled={edit}
                         onChange={this.handleAlertType} >
                          <Option key="useNeoAlertModule"  value="useNeoAlertModule">Alert On Control Tower </Option>
                          <Option key="neoTicketModule" value="neoTicketModule">Ticket On My Trips</Option>
                    </Select>
                    )}
              </Form.Item>
            </Row>  
            <Row>  
              <Form.Item label="Set Config">
                    {getFieldDecorator('config', {
                      rules: [
                        { 
                          required: true,
                          message: 'Please select config'
                        }
                      ],
                    })(
                    <Select mode='tags' tokenSeparators={[","]}>
                      { fetchAlertList &&
                        fetchAlertList.map((data) => {
                        return (
                          <Option key={data.featureKey} value={data.featureKey}>
                            {data.featureKey}
                          </Option>
                        );
                      })}
                    </Select>
                    )}
              </Form.Item>
            </Row>  
            </Col>  
            <Col span={24} offset={5}> 
            <Form.Item style={{ marginTop: "20px", textAlign: "center" }}>
                <Button type='primary' htmlType='submit'>
                  Submit
                </Button>
                <Button
                  className='cta'
                  onClick={() =>
                    this.props.history.push({
                      pathname: "/display-alert-ticket",
                      search: search,
                    })
                  }
                >
                Back
              </Button>
            </Form.Item>
            </Col> 
            </Form>
          </Row> 
      </div>
    );
  }
}

const WrappedAddPlacesForm = Form.create({ name: "register" })(
   AlertTicketUIConfig
);
export default withRouter(WrappedAddPlacesForm);
