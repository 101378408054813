export const rlsRoles = {
    roles : [
        "Shipper_Company_Trips",
        "Shipper_Company_Indent",
        "ceat_custom_company_indent",
        "ceat_custom_branch_indent",
        "Shipper_Branch_Trips",
        "Shipper_Branch_Indent",
        "Trans_Company_Trips",
        "Trans_Company_Indent",
        "Trans_Branch_Trips",
        "Trans_Branch_Indent",
        "Consignee_Company_Trips",
        "Consignee_Branch_Trips",
        "All"
    ]
}

export default rlsRoles