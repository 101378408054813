import React from 'react'
import { Form, message, Button, Row, Col, AutoComplete, Table, Select, Input,Pagination } from 'antd'
import { css } from '@emotion/css'

import { convertQsToJson } from 'utils/common'
import fetchData from 'utils/fetchData'
import configKeys from '../legoConfig/configKeys'

const Option = AutoComplete.Option
const EQS_API = window._env_.REACT_APP_EQS_SERVICE_API_URL
const REACT_APP_ENTITY_SERVICE = window._env_.REACT_APP_ENTITY_SERVICE_URL


const ShowEntityConfig = ({label1, label2, configBoxStyle, fteId, getConfigJson, onClickCopy, totalPage, currentPage, showPagination, getPartners}) => {
    const handlePageChange = (page) => {
        getPartners({}, page);
    };
    return (
        <Col style={{ padding: '10px', background: '#fff' }}>
            <Row >
                <Col span={1} />
                <Col span={3} style={{ padding: '8px' }}>
                    <strong>{label1}</strong>
                </Col>
                <Col span={15} style={configBoxStyle}>
                    {fteId}
                </Col>
            </Row>
            <Row style={{ padding: '10px 10px 10px 22px' }} />
            <Row>
                <Col span={1} />
                <Col span={3} style={{ padding: '8px' }}>
                    <strong>{label2}</strong>
                </Col>
                <Col span={15}>
                    <div style={configBoxStyle}>
                        <pre>{getConfigJson}</pre>
                    </div>
                    <div style={{"display": 'flex',"flexDirection": 'row', "alignItems": 'center', "justifyContent": 'space-between', "margin-top": "10px"}}>
                        <Button onClick={onClickCopy} style={{ marginTop: '8px' }}>
                            Copy Config JSON
                        </Button> 
                            {showPagination ? 
                                <Pagination
                                    current={currentPage}
                                    onChange={handlePageChange}
                                    total={totalPage}  // Replace with the total number of items you have
                                    pageSize={20}  // Replace with the number of items per page
                                    showSizeChanger={false}  // Optional: show options to change page size
                                    // pageSizeOptions={['10', '20', '50']}  // Optional: page size options
                                /> : ""
                            }
                    </div>                  
                </Col>
            </Row>
        </Col>
    )
}

class LegoConfig extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            companyId: '',
            showConfigFlag: false,
            fteId: '',
            config: {},
            partnerConfig: {},
            partnerType: undefined,
            pageNumber: undefined,
            partnerOptions: {
                Consignee: 'CEE',
                Consignor: 'CNR',
                Transporter: 'TRN'
            },
            totalPage: 1,
            currentPage: 1,
            shouldShowPagination: false
        }
    }

    componentDidMount() {
        const { search } = this.props.location;
        const parsedDt = convertQsToJson(search)
        if (!parsedDt.companyId) {
            this.setState({ companyId: '' })
            message.info('Select a company to continue')
        } else {
            this.setState({ companyId: parsedDt.companyId })
            this.getFTEId(parsedDt);
        }
    }
    componentDidUpdate(prevProps) {
        const { search } = this.props.location;
        const prevSearch = prevProps.location.search
        if (search !== prevSearch) {
            const parsedDt = convertQsToJson(search)
            if (!parsedDt.companyId) {
                this.setState({ companyId: '' })
            }
            else {
                this.setState({ companyId: parsedDt.companyId })
                this.getFTEId(parsedDt);
            }
        }
    }

    getFTEId = (parsedDt) => {
        let url = `${EQS_API}/v1/company/get-fteid?`
        let payload = {
            c: parsedDt.companyId || '',
            b: parsedDt.branchId || '',
            d: parsedDt.departmentId || ''
        }
        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('satellizer_token')}`
        }

        fetchData(url, 'GET', payload, headers).then((res) => {
            if (res && res.success && res.data) {
                this.setState({ fteId: res.data.fteid })
            } else {
                this.setState({ fteId: '' })
            }
        }).catch(error => {
            this.setState({ fteId: '' });
            message.error(error);
        });

        this.setState({ showConfigFlag: false })
    }

    getEntityConfig = (data) => {
        let type = "company"
        if(this.state.fteId.startsWith('BRH')) type = "branch"
        let url = `${REACT_APP_ENTITY_SERVICE}/v1/${type}/${this.state.fteId}`
        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('satellizer_token')}`
        }
        
        fetchData(url, 'GET', {}, headers).then(res => {
            
            if (res && res.success && res.data) {
                this.setState({ config: res.data })
                this.setState({ showConfigFlag: true })
                this.setState({ shouldShowPagination: false })
            } else {
                this.setState({ config: {} })
            }
        }).catch(error => {
            this.setState({ config: {} });
            message.error(error);
        });
    }

    getPartnerConfig = (data, currentPage = 1) => {
        let page = currentPage
        this.setState({currentPage: page})

        let filterObj = {"c":this.state.companyId,
        "partner_type":this.state.partnerOptions[this.state.partnerType]}
        const queryParams = {
            filter: JSON.stringify(filterObj)};
        const encodedParams = Object.keys(queryParams).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`).join('&');
        
        let url = `${EQS_API}/v1/company/morpheus-partners?${encodedParams}&page=${page}&size=20`
        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('satellizer_token')}`
        }
        
        fetchData(url, 'GET', {}, headers).then(res => {
            
            if (res && res.success && res.data) {
                this.setState({ partnerConfig: res.data })
                this.setState({totalPage:res.pagination.total})
                this.setState({ showConfigFlag: true })
                this.setState({ shouldShowPagination: true })
            } else {
                this.setState({ partnerConfig: {} })
            }
        }).catch(error => {
            this.setState({ partnerConfig: {} });
            message.error(error);
        });

        // this.setState({ showConfigFlag: true })
        // this.setState({ configKey: data.configKey })
        // console.log('thsi.state=============',this.state)
    }

    handleSubmit = e => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.getConfig(values)
            }
        })
    }

    getJsonString = (jsonData) => {

        let jsonString = ''
        try{
            jsonString = JSON.stringify(jsonData, null, 2)
        }
        catch(err){
            message.error('Error in getting json string');
        }
        return jsonString;
    }

    copyJsonToClipboard = (jsonData) => {
        try{
            const jsonString = this.getJsonString(jsonData);
            navigator.clipboard.writeText(jsonString);
            message.success('JSON Copied to Clipboard');
        }
        catch(err){
            message.error('Error in copyJsonToClipboard');
        }
    };

    onSelecthandler = value => {
        this.setState({ 
            "partnerType": value 
        })
        // const qs = this.convertJsonToQs({"alertKey":value})
        // this.props.history.push({
        //   pathname: this.props.location.pathname,
        //   search: `?${qs}`,
        // })
    }

    handleInputChange = (value) => {
        this.setState({
            pageNumber: value
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form
        const configBoxStyle = {
            maxHeight: '350px', overflowY: 'auto', border: '1px solid #d9d9d9',
            padding: '8px', background: '#fafafa'
        }
        return (
            <div>
                
                    <Form>
                            <Row
                                className={css`
                                background: #fff;
                                padding-bottom:25px;
                                padding-left: 20px;
                                border-bottom: 1px solid #e8e8e8;`}
                            >
                                <Col style={{ paddingTop: '27px' }} span={3}>
                                    <Button
                                        disabled={!this.state.companyId}
                                        type="primary"
                                        size="large"
                                        htmlType='submit'
                                        onClick={this.getEntityConfig}
                                    >
                                        GET Entity
                                    </Button>
                                </Col>
                            </Row>
                            <Row
                                className={css`
                                padding: 15px 20px 25px 20px;
                                background: #fff;
                                border-bottom: 1px solid #e8e8e8;`}
                            >
                                {/* <Col span={1} /> */}
                                <Col 
                                    span={4}
                                    className={css`
                                    margin-right: 20px;`}
                                >
                                    <label className="subHeaderLabel"><span style={{ color: 'red' }}>*</span>Partner Type</label>
                                    <Select
                                        className="dropdownwidth"
                                        placeholder="Select Partner type"
                                        size="large"
                                        onSelect={this.onSelecthandler}
                                        value={this.state.partnerType}
                                        >
                                        {
                                            Object.keys(this.state.partnerOptions).map((option, key) => (
                                                <Option key={key} value={option}>
                                                    {option}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Col>
                                {/* <Col span={4}
                                    className={css`
                                    margin-right: 20px;`}>
                                    <label className="subHeaderLabel">Page</label>
                                    <Input
                                        className={css`width: 100%;`}
                                        size="large"
                                        placeholder='Enter page number'
                                        value={this.state.pageNumber}
                                        onChange= {(e) => this.handleInputChange(e.target.value)}
                                    />
                                </Col> */}
                                <Col style={{ paddingTop: '27px' }} span={3}>
                                    <Button
                                        disabled={!this.state.companyId || !this.state.partnerType}
                                        type="primary"
                                        size="large"
                                        htmlType='submit'
                                        onClick={this.getPartnerConfig}
                                    >
                                        GET Partners
                                    </Button>
                                </Col>
                            </Row>
                    </Form>
                {/* </Row> */}
                <Row style={{ padding: '10px 10px 10px 22px' }} />
                {this.state.showConfigFlag ?
                    <ShowEntityConfig
                        label1='Entity ID:'
                        label2='Detail:'
                        configBoxStyle={configBoxStyle}
                        fteId={this.state.fteId}
                        getConfigJson={this.state.shouldShowPagination ? this.getJsonString(this.state.partnerConfig) : this.getJsonString(this.state.config)}
                        onClickCopy={() => this.copyJsonToClipboard(this.state.config)}
                        totalPage= {this.state.totalPage}
                        currentPage= {this.state.currentPage}
                        showPagination={this.state.shouldShowPagination}
                        getPartners={this.getPartnerConfig}
                    />
                    : ''
                }
            </div >
        )
    }
}

const Lego_Config_Form = Form.create({ name: 'Lego_Config_Form' })(LegoConfig)
export default Lego_Config_Form